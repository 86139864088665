// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

// components
import AddWebsite from "./websites/addWebsite";
import Dialog from "./Dialog";
import AddUser from "./users/addUser";
import AddOrganization from "../pages/organization/addOrganization";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// colors
import colorPalette from "../theme/colors";

// icons
import { BiSearch } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiChevronRight, FiChevronsRight } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { GiOrganigram } from "react-icons/gi";

// constants
import { RoutingConstants, Titles } from "../constants";

// actions
import { toggleHamburger, closeHamburger } from "../store/actions/dashboard";
import { logOut } from "../store/actions/auth";
import { selectOrganization } from "../store/actions/dashboard";
import { BsArrowLeft } from "react-icons/bs";

/**
 * @function MobileHeader
 * @returns {JSX}
 * @description This is the header, shown for mobile and tablet devices
 */
const MobileHeader = (props) => {
  const navigate = useNavigate();
  const disableSelect1 = useLocation().pathname.includes("/website/");
  // destructure props
  const {
    title,
    toggleHamburger,
    closeHamburger,
    hamburgerCollapsed,
    roleId,
    triggerLogout,
    loggedIn,
    orgsList,
    selectOrganization,
    selectedOrgId
  } = props;
  const org_id = props?.org_id || null;
  const onSearchIconClick = props?.onSearchIconClick || null;
  const [expandItem, setExpandItem] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  let sidebarData = [
    {
      title: Titles.dashboard,
      path: RoutingConstants.dashboard,
      icon: RenderSvg(svgNames.dashboardBlack),
    },
    {
      title: Titles.websites,
      path: RoutingConstants.websites.webRoot,
      icon: RenderSvg(svgNames.websitesBlack),
    },
    {
      title: "Feedback",
      icon: RenderSvg(svgNames.feedBack),
      path: "#",
    },
    {
      title: 'Workspace',
      icon: RenderSvg(svgNames.myOrgs),
      path: "/workspace",
    },
    {
      title: Titles.myProfile,
      icon: RenderSvg(svgNames.myProfile),
      path: "",
    },
  ];

  // Show users tab in sidebar only if the logged in user is Admin (roldId will be 0 in redux store)
  if (roleId === 0) {
    sidebarData.splice(2, 0, {
      title: Titles.users,
      path: RoutingConstants.users.usersList,
      icon: RenderSvg(svgNames.users),
    });
  }

  return (
    <div
      className={`flex flex-row items-center bg-white justify-between border-b border-grayBorder`}
    >
      {/* LHS items */}
      <div className="flex flex-row items-center">
        <div onClick={() => {toggleHamburger(); setExpandItem(null)}} className="p-3">
          <GiHamburgerMenu
            color={colorPalette.manganeseBlack}
            className={"text-2xl"}
          />
        </div>
        <div className="bg-primary px-3 py-5">
          {RenderSvg(svgNames.collapsedLogo)}
        </div>
        {props.backUrl && (
          <button onClick={() => navigate(-1)}>
            <BsArrowLeft className="ml-3" />
          </button>
        )}
        <p className="font-semibold text-lg p-3">{title}</p>
      </div>

      {/* RHS items */}
      {/* Handle Websites page */}
      {title === Titles.websites && (
        <div className="flex flex-row items-center">
          {roleId === 0 && <AddWebsite org_id={org_id} />}
        </div>
      )}
      {/* Handle Users page */}
      {title === Titles.users && (
        <div className="flex flex-row items-center">
          <div className="p-3" onClick={() => onSearchIconClick()}>
            <BiSearch className="text-3xl" />
          </div>
          {process.env.REACT_APP_DISABLE_ADD_USER === "false" && <AddUser
            websites={props?.websites}
            existingUsers={props?.existingUsers}
          />}
        </div>
      )}
      {/* Handle My Orgs page */}
      {title === Titles.myOrganization && (
        <div className="flex flex-row items-center">
          {roleId === 0 && <AddOrganization/>}
        </div>
      )}
      {showConfirmDialog && (
        <Dialog
          onYesClick={() => {
            setShowConfirmDialog(false);
            triggerLogout();
            if (!loggedIn) {
              navigate(RoutingConstants.login);
            }
          }}
          onNoClick={() => {
            setShowConfirmDialog(false);
          }}
          title={`Logout`}
          subtitle={"Are you sure want to logout?"}
        />
      )}
      {!hamburgerCollapsed && (
        <div className="flex flex-col absolute bg-white top-14 w-full border border-grayLight drop-shadow-2xl shadow-lg z-10" id="mobileMenu">
          {sidebarData?.map((i, index) => {
            return i?.path?.length > 0 ? (
              <Link
                className="border-b border-grayLight px-6 py-2"
                key={index}
                to={i.path}
              >
                <div
                  onClick={() => {
                    if(i?.title?.length > 0 && i.title.toLowerCase() === "feedback" && i.path === "#"){
                      window.showCollectorDialog();
                    }
                    toggleHamburger()
                  }}
                  className="flex justify-between"
                >
                  <div className="flex">
                    <p className="text-primary text-base">{i.icon}</p>
                    <p className="ml-5 text-primary text-base font-semibold">
                      {i?.title || "-"}
                    </p>
                  </div>
                </div>
              </Link>
            ) : (
              <div
                onClick={() => {
                  expandItem !== index && setExpandItem(index);
                }}
                className="border-b border-grayLight flex justify-between px-6 py-2 cursor-pointer"
                key={index}
                to={i.path}
              >
                <div className="flex w-11/12">
                  <p className="text-primary text-base">{i.icon}</p>
                  <div className="flex flex-col w-full">
                    <p className="ml-5 text-primary text-base font-semibold">
                      {i?.title || "-"}
                    </p>
                    {/* Build the submenu for My Orgs */}
                    {expandItem === index &&
                      i?.title === Titles.switchOrganization && (
                        <div className="flex flex-col ml-4 mt-2">
                          <div className="w-full ml-4 p-1 bg-white text-xs font-semibold max-h-52 overflow-y-auto">
                            {roleId === 0 && (
                              <AddOrganization displayType="link"/>
                            )}
                            {orgsList?.length > 0 &&
                              orgsList?.map((val, index) => {
                                return (
                                  <button
                                    className={`flex items-center w-10/12 p-2 list-none  ${
                                      selectedOrgId === val.id &&
                                      "bg-grayLight font-bold"
                                    } cursor-pointer text-black overflow-x-auto border-b border-grayLight`}
                                    key={index}
                                    onClick={() => {
                                      toggleHamburger();
                                      if (disableSelect1) {
                                        // navigate to websites of that org chosen
                                        selectOrganization({ orgid: val.id });
                                        navigate(
                                          RoutingConstants.websites.webRoot
                                        );
                                        return;
                                      }
                                      selectOrganization({ orgid: val.id });
                                    }}
                                  >
                                    <GiOrganigram className="mr-3 text-sm" />
                                    <span className="text-sm overflow-x-auto text-left">
                                      {val.name}
                                    </span>
                                  </button>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    {/* Build the submenu for My Profile */}
                    {expandItem === index && i?.title === Titles.myProfile && (
                      <div className="flex flex-col ml-8 mt-2">
                        <div
                          onClick={() => {
                            toggleHamburger();
                            navigate('/profile');
                          }}
                          className="flex items-center w-full justify-between overflow-x-auto border-b border-grayLight"
                        >
                          <p className="ml-5 py-2 text-primary text-sm font-semibold flex">
                            Profile Settings
                          </p>
                          <FiChevronsRight className="ml-10 text-sm" />
                        </div>
                        <div
                          onClick={() => {
                            setShowConfirmDialog(true);
                            closeHamburger()
                          }}
                          className="flex items-center w-full justify-between overflow-x-auto border-b border-grayLight"
                        >
                          <p className="ml-5 py-2 text-primary text-sm font-semibold flex ">
                            {Titles.logout}
                          </p>
                          <FiChevronsRight className="ml-10 text-sm" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-1">
                  {expandItem === index ? (
                    <FiChevronDown className="text-base" />
                  ) : (
                    <FiChevronRight className="text-base" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    hamburgerCollapsed: state.dashboard.hamburgerCollapsed,
    roleId: state.auth.roleId,
    loggedIn: state.auth.loggedIn,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  toggleHamburger,
  closeHamburger,
  triggerLogout: logOut,
  selectOrganization,
};

// default exports
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
