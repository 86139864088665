/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {useParams} from "react-router-dom";
import "chart.js/auto";
import posthog from 'posthog-js'

// icons
import { BsFillExclamationSquareFill } from "react-icons/bs";

import DefaultPage from "../../components/layout/defaultPage";
import AuditSummary from "../../components/reports/auditSummary";
import AuditReport from "../../components/reports/auditReport";
import ShareReport from "../../components/reports/shareReport";
// actions
import { getAuditStage, getActiveSubscription, getWebsiteSettings, editWebsiteSettings } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import ReportHeader from "../../components/reports/reportHeader";
import EmbedScript from "../../components/embedScript";
/**
 * @function ViewReport
 * @returns {JSX}
 */
const ViewFullReport = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const { id, jobId } = useParams();
  const { userProfile, triggerAuditStage, triggerLogout, triggerActiveSubscription, triggerGetWebsiteSettings} = props;
  const [activeSubscription, setActiveSubscription] = useState({})
  const [openTab, setOpenTab] = React.useState(1);
  const [showEmbedScript, setShowEmbedScript] = useState(false);

  useEffect(() => {
    triggerActiveSubscription({
      payload: {
        websiteId: id,
      },
      onSuccess: (res) => { 
        setActiveSubscription(res)
      },
      onError: (errorMsg) => {
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  }, [])

  useEffect(() => {
    triggerAuditStage({
      payload: {
        jobId: jobId,
      },
      onSuccess: () => { },
      onError: (errorMsg) => {
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  }, [jobId])

  const [autoApplyFixes, setAutoApplyFixes] = useState(true)
  useEffect(() => {
    posthog.capture('Full audit report Viewed successfully', { property: 'value' })
    triggerGetWebsiteSettings({ 
      payload: {
        id: id,
      },
      onSuccess: (res) => {
        setAutoApplyFixes(res?.auto_apply_fixes)
      }, 
      onError: () => {} 
    });
  }, [id]);

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
    <div className="bg-[#f2f2f2] h-screen">
      <ReportHeader activeSubscription={activeSubscription} pageRoute="fullReport" />
      {!autoApplyFixes &&
          <div className="bg-floralWhite border border-grayLight p-4 flex flex-row overflow-x-auto">
            <div className="mr-2">
              <BsFillExclamationSquareFill
                color="#FF5353"
                className="text-2xl"
              />
            </div>
            <p className="text-base text-left text-black font-light">Any changes you make to the fixes will not reflect as the Apply fixes  settings is turned OFF for this site.</p>
          </div>
      }
      <section className="px-4 md:px-16 font-mukta pt-10">
        <div className="flex justify-between border-b mb-4 flex-col sm:flex-row items-start sm:items-end">
          <ul className="flex items-center gap-6" role="tablist">
            <li
              className={`-mb-[1px] cursor-pointer text-xl p-1 leading-normal ${openTab === 1 ? 'border-b border-b-2 border-[#FED600] font-bold' : ''}`}
              onClick={e => {e.preventDefault();setOpenTab(1)}}
              data-toggle="tab"
              role="tablist"
            >
              Summary
            </li>
            <li
              className={`-mb-[1px] cursor-pointer text-xl p-1 leading-normal ${openTab === 2 ? 'border-b border-b-2 border-[#FED600] font-bold' : ''}`}
              onClick={e => {e.preventDefault();setOpenTab(2)}}
              data-toggle="tab"
              role="tablist"
            >
              Report
            </li>
          </ul>
          <div className="flex items-center gap-2 mb-2">
            <button onClick={() => setShowEmbedScript(true)} className="cursor-pointer border py-1 px-3 flex items-center gap-2">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
              </svg>
              Apply fixes
            </button>
            <a href={`${process.env.REACT_APP_BASE_URL}/downloadReport/${jobId}`} target="_blank" className="cursor-pointer border py-1 px-3 flex items-center gap-2" rel="noreferrer">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
              </svg>
              Download
            </a>
            <ShareReport />
          </div>
        </div>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6" style={{maxHeight: 'calc(100vh - 190px)'}}>
          <div className="flex-auto">
            <div className="tab-content tab-space">
              <div className={openTab === 1 ? 'block' : 'hidden' }><AuditSummary /></div>
              <div className={openTab === 2 ? 'block' : 'hidden' }><AuditReport autoApplyFixes={autoApplyFixes} /></div>
            </div>
          </div>
        </div>
        {showEmbedScript && <EmbedScript setShowEmbedScript={setShowEmbedScript} />}
      </section>
    </div>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    selectedOrg: state?.dashboard?.data?.organizations?.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ),
    userProfile: state.dashboard.userProfile,
    updateElementLoading: state.dashboard.updateElementLoading,
    auditStatus: state.dashboard.auditStatus,
    scfCount: state.dashboard.scfCount
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerAuditStage: getAuditStage,
  triggerLogout: logOut,
  triggerActiveSubscription: getActiveSubscription,
  triggerGetWebsiteSettings: getWebsiteSettings,
  triggerAutoApplyUpdates: editWebsiteSettings,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ViewFullReport);
