/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// constants
import { RoutingConstants, Strings } from "../../constants";

// actions
import { logOut } from "../../store/actions/auth";
import { editUser } from "../../store/actions/dashboard";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";

// icons
import PieLoader from "../../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// Can be a string as well. Need to ensure each key-value pair ends with ;
/* const override = css`
  border-color: "white";
`; */

/**
 * @function GeneralInfo
 * @returns {JSX}
 */
const GeneralInfo = (props) => {
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const { loggedIn, accessToken, userProfile, triggerUpdateUser, triggerLogout } = props;
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.contact_number ? userProfile?.contact_number : null)
  const [phoneNumberError, setphoneNumberError] = useState(null)
  const defaultValues = {
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    email: userProfile?.email,
  };
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const watchAllFields = watch();
  const initialValues = {...defaultValues, phoneNumber: userProfile?.contact_number}
  const updatedValues = {...watchAllFields, phoneNumber}
  const contentUpdated = JSON.stringify(updatedValues) !== JSON.stringify(initialValues)

  const onChangeHandler = (phone, country, e) => {
    setPhoneNumber(phone)
    if(country.dialCode === phone) {
      setphoneNumberError('* Phone number is mandatory')
    } else {
      setphoneNumberError(null)
    }
 }
  useEffect(() => {
    if (!loggedIn || !accessToken) {
      window.location.replace(RoutingConstants.login);
    }
  }, [loggedIn, accessToken]);

  const onSuccess = (successMsg) => {
    setErrorFromApi({
      type: "success",
      msg: "User has been updated successfully !",
    });
    setLoading(false);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setLoading(false);
  };
  const submit = (data) => {
    if(phoneNumber === null || phoneNumberError) {
      setphoneNumberError('* Phone number is mandatory')
    }
    else if(!contentUpdated) {
      setErrorFromApi({
        type: 'error',
        msg: 'No changes made'
      })
    } else {
      setLoading(true)
      const payload_1 = {
        firstName: data?.firstName?.trim(),
        lastName: data?.lastName?.trim(),
        contact_number: phoneNumber?.replace(" ", ""),
        id: userProfile?.id
      };
      triggerUpdateUser({ payload_1, onSuccess, onError });
    }
  };
  useEffect(() => {
    if(contentUpdated) {
      setErrorFromApi(null)
    }
  },[contentUpdated]);

  return (
    <div className="max-w-full sm:p-4">
      <div className="grid lg:grid-cols-2">
        <div className="w-full sm:max-w-md">
          <form onSubmit={handleSubmit(submit)} className="w-full p-4 sm:py-0">
            <fieldset className="w-full m-auto text-lg mb-7">
              <div className="text-base text-black font-bold mb-2">
                First Name
              </div>
              <input
                type="text"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                    errors.firstName && "border-red-500"
                }`}
                id="firstName"
                name="firstName"
                placeholder="Enter your first name"
                {...register("firstName", {
                    required: {
                    value: true,
                    message: "* First name is mandatory",
                    },
                    maxLength: {
                    value: 40,
                    message: "* Maximum 40 characters allowed",
                    },
                    pattern: {
                    value: /^(?![0-9]+$)[a-zA-Z0-9 ]{1,}$/,
                    message:
                        "* Should be alpha numerical",
                    },
                })}
                />
                {errors.firstName && (
                <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                    {errors.firstName.message}
                </div>
                )}
            </fieldset>
            <fieldset className="w-full m-auto text-lg mb-7">
              <div className="text-base text-black font-bold mb-2">
                Last Name
              </div>
              <input
                type="text"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                    errors.lastName && "border-red-500"
                }`}
                id="lastName"
                name="lastName"
                placeholder="Enter your last name"
                {...register("lastName", {
                    required: {
                    value: true,
                    message: "* Last name is mandatory",
                    },
                    maxLength: {
                    value: 40,
                    message: "* Maximum 40 characters allowed",
                    },
                    pattern: {
                    value: /^(?![0-9]+$)[a-zA-Z0-9 ]{1,}$/,
                    message:
                        "* Should be alpha numerical",
                    },
                })}
                />
                {errors.lastName && (
                <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                    {errors.lastName.message}
                </div>
                )}
            </fieldset>
            <fieldset className="w-full m-auto text-lg mb-7">
                <div className="text-base text-black font-bold mb-2">
                Email ID
                </div>
                <input
                type="text"
                className={`pointer-events-none opacity-40 w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                    errors.email && "border-red-500"
                }`}
                id="email"
                name="email"
                placeholder="Enter your email address"
                {...register("email", {
                    required: {
                    value: true,
                    message: "* E-mail is mandatory",
                    },
                    maxLength: {
                    value: 300,
                    message: "* Invalid Email Id",
                    },
                    minLength: {
                    value: 6,
                    message: "* Invalid Email Id",
                    },
                    pattern: {
                    value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "* Invalid email format",
                    },
                })}
                />
                {errors.email && (
                <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                    {errors.email.message}
                </div>
                )}
            </fieldset>
            <fieldset className={`w-full m-auto text-lg mb-7 ${errors.phoneNumber && 'errorField'}`}>
              <div className="text-base text-black font-bold mb-2">
                Phone Number
                {/* <span className="text-lg text-red-500 font-bold">
                  {"*"}
                </span> */}
              </div>
              <PhoneInput
                country={'us'}
                value={phoneNumber}
                onChange={onChangeHandler}
                containerClass="phoneNum"
                inputClass="phoneInput"
                buttonClass="countryDropdown"
              />
              {phoneNumberError && (
                <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                    {phoneNumberError}
                </div>
              )}
            </fieldset>
            {errorFromApi !== null && (
              <div
                className={`${
                  errorFromApi?.type === "error"
                    ? "bg-floralWhite "
                    : "bg-aliceBlue"
                } border border-grayLight my-8 p-3 mt-4 flex items-center flex-row overflow-x-auto`}
              >
                <div className="mr-2">
                  <BsFillExclamationSquareFill
                    color={
                      errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                    }
                    className="text-2xl"
                  />
                </div>
                <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
              </div>
            )}
            <button
              disabled={errorFromApi}
              type="submit"
              className={`border cursor-pointer text-white font-bold p-2 px-8 tracking-small flex items-center 
              ${ loading || (Object.keys(errors).length > 0) || !contentUpdated || !phoneNumber || phoneNumberError
                  ? " border-gray bg-gray"
                  : "border-black bg-cyanBlue border-none"}
                ${(loading) && 'pointer-events-none'}
              `}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                  <span className="ml-4">{Strings.loading}</span>
                </div>
              ) : 'Save Changes'}
              {
                loading ? '' :
              <svg className="ml-2.5" width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.775513 10H24.449" stroke="white" strokeWidth="3"/>
                <path d="M16.4592 1.41846L25.0408 10.0001L16.4592 18.5817" stroke="white" strokeWidth="3"/>
                </svg>
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.accessToken,
    userProfile: state.dashboard.userProfile,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  triggerUpdateUser: editUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
