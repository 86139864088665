/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

// Actions
import { editWorkspace } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../PieLoader";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function ViewUser
 * @returns {JSX}
 */
const EditWorkspace = (props) => {
  const orgData = { ...props.orgData };
  const { triggerEditWorkspace, triggerLogout } = props;
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  const initState = {
    orgName: `${
      orgData.name.slice().charAt(0).toUpperCase() +
      orgData.name.slice(1)
    }`,
    isActive: props.orgData.isActive,
  };
  const [orgDetail, setOrgDetail] = useState(initState);

  const onChangeHandler = (e) => {
    setOrgDetail({
      ...orgDetail,
      [e.target.name]: e.target.value,
    });
    const inputTagElement_1 = document.getElementById("orgName");
    if (
      document.activeElement === inputTagElement_1
    ) {
      setErrorFromApi(null);
    }
  };

  const [orgStatus, setOrgStatus] = useState(orgDetail.isActive);

  const onSuccess = (successMsg) => {
    setErrorFromApi({
      type: "success",
      msg: "Workspace has been updated successfully !",
    });
    setLoading(false);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    reset(initState, {
      keepErrors: false,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setLoading(false);
  };

  const isUserUpdated =
    initState.orgName !== orgDetail.orgName ||
    initState.isActive !== orgStatus;

  const validationPassed = 
   !errors.orgName

  const submit = (e) => {
    setLoading(true);
    const payload_1 = {
      name: orgDetail.orgName,
      isActive: orgStatus,
      id: orgData.id,
    };
    triggerEditWorkspace({ payload_1, onSuccess, onError });
  };

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="overflow-x-hidden outline-none focus:outline-none">
          <div className="">
            <div>
              <div className="relative sm:p-4">
                <div className="w-full sm:max-w-md">
                  <form onSubmit={handleSubmit(submit)} className="w-full">
                    <>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-base text-black font-bold mb-2">
                          Workspace Name
                        </div>
                        <input
                          value={orgDetail.orgName}
                          type="text"
                          className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm py-2 px-3 font-medium border-grayBorder focus:border-primary ${
                            errors.orgName && "border-red-500"
                          }`}
                          id="orgName"
                          name="orgName"
                          {...register("orgName", {
                            required: {
                              value: true,
                              message: "* Please enter workspace name",
                            },
                            maxLength: {
                              value: 40,
                              message: "* Maximum 40 characters allowed",
                            },
                            minLength: {
                              value: 2,
                              message: "* Minimum 2 characters required",
                            },
                            pattern: {
                              value: /^(?![0-9]+$)[a-zA-Z0-9-_ ]{2,}$/,
                              message: "* Only alphanumeric characters allowed",
                            },
                          })}
                          onChange={(e) => onChangeHandler(e)}
                        />
                        {errors.orgName && (
                          <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                            {errors.orgName.message}
                          </div>
                        )}
                      </fieldset>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-sm text-grayDeep font-semibold mb-2">
                          Status
                        </div>
                        <div className="flex items-center">
                          <div
                            className={`h-6 w-12 p-1 cursor-pointer border ${
                              orgStatus
                                ? "border-primary bg-aliceBlue"
                                : "border-grayNobel bg-white"
                            }`}
                            onClick={() => {
                              setOrgStatus(!orgStatus);
                              setErrorFromApi(null);
                            }}
                            style={{ padding: "3px" }}
                          >
                            <div
                              className={`h-4 w-4 transform duration-300 ease-in-out ${
                                orgStatus
                                  ? "bg-primary transform translate-x-6"
                                  : "bg-grayNobel "
                              } `}
                            ></div>
                          </div>
                            <p className={`text-lg font-normal pl-2 ${!orgStatus && 'text-grayDeep'}`}>
                              {orgStatus ? "Active" : "Inactive"}
                            </p>
                        </div>
                      </fieldset>
                      {errorFromApi !== null && (
                        <div
                          className={`${
                            errorFromApi?.type === "error"
                              ? "bg-floralWhite "
                              : "bg-aliceBlue"
                          } border border-grayLight p-3 mt-4 flex items-center flex-row overflow-x-auto`}
                        >
                          <div className="mr-2">
                            <BsFillExclamationSquareFill
                              color={
                                errorFromApi?.type === "error"
                                  ? "#FF5353"
                                  : "#000000"
                              }
                              className="text-2xl"
                            />
                          </div>
                          <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                        </div>
                      )}
                      <div className="flex my-6">
                        <button
                          type="submit"
                          className={`sm:w-4/6 border cursor-pointer text-white font-bold p-2 px-8 tracking-small ${
                            loading || !isUserUpdated || !validationPassed || errorFromApi
                              ? "pointer-events-none border-gray bg-gray"
                              : "border-primary bg-cyanBlue"
                          }`}
                        >
                          {loading ? (
                            <div className="flex flex-row justify-center items-center">
                              <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                              <span className="ml-4">Loading</span>
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerEditWorkspace: editWorkspace,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EditWorkspace);
