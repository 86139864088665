// library imports
import axios from "axios";

// constants
import { apiEndpoints } from "../constants";

// local storage
import LocalStorageHandler from "../utils/StateCacheStorage";

/**
 * @function login
 * @param {Object} payload
 * @returns {JSON}
 */
export const login = async (payload = {}) => {
  const { baseUrl, login } = apiEndpoints;
  const loginUrl = `${baseUrl}${login}`;
  try {
    const response = await axios.post(loginUrl, payload, { timeout: 5000 });
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * @function register
 * @param {Object} payload
 * @returns {JSON}
 */
export const register = async (payload = {}) => {
  const { baseUrl, register } = apiEndpoints;
  const registerUrl = `${baseUrl}${register}`;
  try {
    const response = await axios.post(registerUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @function sendOtp
 * @param {Object} payload
 * @returns {JSON}
 */
export const sendOtp = async (payload = {}) => {
  const { baseUrl, sendOtp } = apiEndpoints;
  const sendOtpUrl = `${baseUrl}${sendOtp}`;
  try {
    const response = await axios.post(sendOtpUrl, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * @function validateOtp
 * @param {Object} payload
 * @returns {JSON}
 */
export const validateOtp = async (payload = {}) => {
  const { baseUrl, validateOtp } = apiEndpoints;
  const validateOtpUrl = `${baseUrl}${validateOtp}`;
  try {
    const response = await axios.post(validateOtpUrl, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * @function resetPassword
 * @param {Object} payload
 * @returns {JSON}
 */
export const resetPassword = async (payload = {}) => {
  const { baseUrl, resetPassword } = apiEndpoints;
  const resetPasswordUrl = `${baseUrl}${resetPassword}`;
  try {
    const response = await axios.post(resetPasswordUrl, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * @function changePassword
 * @param {Object} payload
 * @returns {JSON}
 */
export const changePassword = async (payload = {}) => {
  const { baseUrl, changePassword } = apiEndpoints;
  const changePasswordUrl = `${baseUrl}${changePassword}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(changePasswordUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifyUserEmailApi = async ({id}) => {
  const { baseUrl, verifyUserEmail } = apiEndpoints;
  const verifyUserEmailUrl = `${baseUrl}${verifyUserEmail}/${id}`;
  try {
    const response = await axios.get(verifyUserEmailUrl);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePassword = async (payload = {}) => {
  const { baseUrl } = apiEndpoints;
  const updatePasswordUrl = `${baseUrl}/updatePassword`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(updatePasswordUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
