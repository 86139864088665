/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import { useSearchParams, useNavigate } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Strings } from "../constants";
import { connect } from "react-redux";

// Actions
import { createOrg } from "../store/actions/dashboard";
import { logOut } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import PieLoader from "../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";

// token expired handler
import TokenExpired from "../utils/TokenExpired";

// Can be a string as well. Need to ensure each key-value pair ends with ;
/* const override = css`
  border-color: "white";
`; */

/**
 * @function OrganizationSetup
 * @returns {JSX}
 */
const OrganizationSetup = (props) => {
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // state variables
  const [loader, setLoader] = useState(true)
  const [disableSave, setDisableSave] = useState(false);
  const { triggerCreateOrg, triggerLogout, loading, isTempPassword, userEmail } = props;
  const [searchParams] = useSearchParams();
  const fromDashboard = searchParams.get("addedOrg");
  const [formStep, setFormStep] = useState(0);
  const [prefillData, setPrefillData] = useState(null)
  const [userData, setUserData] = useState({
    name: "",
    type: "",
    domain: "",
    nickname: "",
  });
  const [country, setCountry] = useState({
    country: "",
  });
  const [countryError, setCountryError] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(null);

  const LAUNCHES_QUERY = `{
    form_entries(filter:{Email:{_eq: "${userEmail}"}}){
      id,
      Company_Name,
      Average_Page_Views_Expected,
      Number_Of_Sites_Managed,
      Phone,
      Primary_Website,
      Page_Path
    }
  }`;

  useEffect(() => {
    fetch('https://dev0-core.fscms.co/graphql/datamodel/9fa8a6bf-32f2-43c6-997f-15c6413fb8e6/22418955-17a4-4f90-b133-b8ca3280d532', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcxM2M3NjA1LTdiMGMtNDgxNS1iMDQ3LWJjMWRmMGM1YWU4MSIsInN1cGVyX2FkbWluIjp0cnVlLCJhcHBfYWNjZXNzIjp0cnVlLCJhZG1pbl9hY2Nlc3MiOnRydWUsImlhdCI6MTY1OTk1NjgwMiwiaXNzIjoiZGlyZWN0dXMifQ.WwCti8IlJXhaOrImF8CmQiv3t7J7FvnfRQZq0J5X3Fg`,
      },
      body: JSON.stringify({query: LAUNCHES_QUERY}),
    }).then(response => response.json())
    .then(data => {
      setPrefillData(data?.form_entries[0])
      setUserData({
        name: data?.form_entries[0]?.Company_Name,
        type: "",
        domain: data?.form_entries[0]?.Primary_Website,
        nickname: "",
      });
      setLoader(false)
      const hr = document.createElement('hr');
      const hr1 = document.createElement('hr');
      const selectDivider = document.getElementById('country')
      selectDivider.insertBefore(hr, selectDivider[1] );
      selectDivider.insertBefore(hr1, selectDivider[5] );
    })
  }, []);
  const defaultValues = {
    name: prefillData?.Company_Name,
    domain: prefillData?.Primary_Website
  };
  // form hooks
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues
  });

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  /**
   * @function onChangeHandler
   * @description handle input changes
   * @param {any} e
   */
  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  /**
   * @function selectCountryHandler
   * @description handle change in country choosing
   * @param {any} e
   */
  const selectCountryHandler = (e) => {
    setCountry({ country: e });
    e?.length > 0 ? setCountryError(false) : setCountryError(true);
  };

  const onSuccess = () => {
    window.location.replace(RoutingConstants.dashboard);
  };

  const onError = (errorMsg) => {
    setDisableSave(false);
    setErrorFromApi(errorMsg || "Something went wrong!!!");
    // if token expired, route to login
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
        navigate(RoutingConstants.login);
        return;
      }, 1500);
    }
    // if org creation is success, goto dashboard even if 2nd API call - Add website is failed
    if (!errorMsg.includes("Org creation failed")) {
      setTimeout(() => {
        navigate(RoutingConstants.dashboard);
      }, 2000);
    }
  };

  const firstStepValidationPassed =
    !errors.name &&
    userData?.name?.length > 0 &&
    !errors?.type &&
    userData?.type?.length > 0 &&
    country.country.length > 0 &&
    !countryError;

  const secondStepValidationPassed =
    userData?.domain?.length > 0 &&
    !errors.domain &&
    userData?.nickname?.length > 0 &&
    !errors.nickname;

  /**
   * @function submit
   * @description handle submit action
   */
  const submit = () => {
    if (!firstStepValidationPassed || !secondStepValidationPassed) {
      return;
    }
    setDisableSave(true);
    const payload_1 = {
      name: userData.name,
      type: userData.type,
      country: country.country,
    };
    const payload_2 = {
      url: userData.domain,
      name: userData.nickname,
    };
    triggerCreateOrg({
      orgObj: payload_1,
      websiteObj: payload_2,
      onSuccess,
      onError,
    });
  };

  const orgNames = [
    "Select your organization type",
    "Information Technology",
    "Healthcare",
    "Education",
    "FMCG",
    "Others",
  ];


  return (
    <>
    {!loader &&
    <div className={`max-w-full`}>
      <div className="grid lg:grid-cols-2">
        <div className="px-8 md:px-12 lg:px-20">
          <div className={`w-full flex my-5 mt-20 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          <div className="w-full pt-20 overflow-auto" style={{ height: "calc(100vh - 100px)"}}>
            <form onSubmit={handleSubmit(submit)}>
              {formStep === 0 && (
                <div className={`flex flex-1 flex-col justify-center mb-14`}>
                  <h2 className="font-bold text-black text-xl md:text-2xl mb-6 w-full">
                    {"Setup your Account"}
                  </h2>
                  <div>
                    <fieldset className="mb-2">
                      <div className="w-full mb-1.5 text-base text-black font-bold">
                        {`${Strings.orgName}  `}
                        {/* <span className="text-lg text-red-500 font-bold">
                          {"*"}
                        </span> */}
                      </div>
                      <input
                        type="text"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] rounded-none focus:border-primary py-2 px-3 text-sm font-normal ${
                          errors.name && "border-b-2 border-red-500"
                        }`}
                        id="name"
                        name="name"
                        placeholder={Strings.enterOrgName}
                        defaultValue={defaultValues.name}
                        value={userData.name}
                        {...register("name", {
                          required: {
                            value: true,
                            message: "* Please enter your organization name",
                          },
                          maxLength: {
                            value: 40,
                            message: "* Maximum 40 characters allowed",
                          },
                          minLength: {
                            value: 2,
                            message: "* Minimum 2 characters required",
                          },
                          pattern: {
                            value: /^(?![0-9]+$)[a-zA-Z0-9-_ ]{2,}$/,
                            message: "* Only alphanumeric characters allowed",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.name && (
                        <span className="text-xs text-red-500 font-semibold">
                          {errors.name.message}
                        </span>
                      )}
                    </fieldset>
                    <fieldset className="mb-2">
                      <div className="w-full mb-1.5 text-base text-black font-bold">
                        {`${Strings.orgType}  `}
                        {/* <span className="text-lg text-red-500 font-bold">
                          {"*"}
                        </span> */}
                      </div>
                      <select
                        className={`w-full bg-white rounded-none ${
                          errors?.type && "border-b-2 border-red-500"
                        } placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary py-2 px-3 text-sm font-normal `}
                        id="type"
                        name="type"
                        {...register("type", {
                          required: {
                            value: true,
                            message: "* Please choose organization type",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      >
                        {orgNames.map((org, index) => {
                          return (
                            <option
                              key={`organization_type${index}`}
                              value={`${
                                org === "Select your organization type" ? "" : org
                              }`}
                            >
                              {org}
                            </option>
                          );
                        })}
                      </select>
                      {errors.type && (
                        <span className="text-xs text-red-500 font-semibold">
                          {userData?.type.length === 0 && errors.type.message}
                        </span>
                      )}
                    </fieldset>
                    <fieldset className="mb-2">
                      <div className="w-full mb-1.5 text-base text-black font-bold">
                        {`${Strings.country}  `}
                        {/* <span className="text-lg text-red-500 font-bold">
                          {"*"}
                        </span> */}
                      </div>
                      <CountryDropdown
                        priorityOptions={['US', 'GB', 'DE', 'CA']}
                        defaultOptionLabel={"Select your country"}
                        className={`w-full bg-white rounded-none  ${
                          countryError && "border-red-500"
                        } placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary py-2 px-3 text-sm font-normal`}
                        id="country"
                        name="country"
                        value={country.country}
                        onChange={selectCountryHandler}
                      />
                      {countryError && (
                        <span className="text-xs text-red-500 font-semibold">
                          {"* Please choose a country"}
                        </span>
                      )}
                    </fieldset>
                  </div>
                  <button
                    disabled={ errorFromApi }
                    className={`cursor-pointer text-white font-bold w-full text-sm mt-10 py-3.5 ${
                      firstStepValidationPassed &&
                      !errorFromApi ?
                      "bg-black"
                      : "bg-gray"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (firstStepValidationPassed) {
                        setCountryError(false);
                        setFormStep(1);
                      } else {
                        if (country?.country?.length === 0) {
                          setCountryError(true);
                        }
                        if (country?.country?.length > 0) {
                          setCountryError(false);
                        }
                        if (userData?.name?.length === 0) {
                          setError("name", {
                            type: "manual",
                            message: "* Please enter organization name",
                          });
                        }
                        if (userData?.type?.length === 0) {
                          setError("type", {
                            type: "manual",
                            message: "* Please choose organization type",
                          });
                        }
                      }
                    }}
                  >
                    {"NEXT"}
                  </button>
                </div>
              )}
              {formStep === 1 && (
                <div
                  className={`flex flex-1 flex-col justify-center mb-14`}
                >
                <div className="mb-3">
                  <button
                    className={`text-black font-semibold flex items-center`}
                    onClick={() => {
                      setFormStep(0);
                    }}
                  >
                    <BsArrowLeft className="text-lg inline mr-1" />
                    BACK
                  </button>
                  </div>
                  <h2 className="font-bold text-black text-xl md:text-2xl mb-6">
                    {"Add your website info"}
                  </h2>
                  <fieldset className="mb-2">
                    <div className="w-full mb-1.5 text-base text-black font-bold">
                      {`Website URL `}
                      {/* <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span> */}
                    </div>
                    <input
                      type="text"
                      className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary py-2 px-3 text-sm font-normal overflow-x-auto ${
                        errors.domain && "border-red-500"
                      }`}
                      id="domain"
                      name="domain"
                      placeholder={"https://www.example.com"}
                      defaultValue={defaultValues.name}
                      value={userData?.domain?.toLowerCase()}
                      {...register("domain", {
                        required: {
                          value: true,
                          message: "* Please enter a website",
                        },
                        pattern: {
                          value:
                            /(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                          message:
                            "* Please enter valid URL",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.domain && (
                      <span className="text-xs text-red-500 font-semibold">
                        {errors.domain.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="mb-2">
                    <div className="w-full mb-1.5 text-base text-black font-bold">
                      {`Website name `}
                      {/* <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span> */}
                    </div>
                    <input
                      type="text"
                      className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary  py-2 px-3 text-sm font-normal overflow-x-auto ${
                        errors.nickname && "border-b-2 border-red-500"
                      }`}
                      id="nickname"
                      name="nickname"
                      placeholder={"Short identification name for this website"}
                      {...register("nickname", {
                        required: {
                          value: true,
                          message: "* Please enter a nickname",
                        },
                        maxLength: {
                          value: 40,
                          message: "* Maximum 40 characters allowed",
                        },
                        minLength: {
                          value: 2,
                          message: "* Minimum 2 characters required",
                        },
                        pattern: {
                          value: /^(?![0-9]+$)[a-zA-Z0-9-_ ]{2,}$/,
                          message: "* Only alphanumeric characters allowed",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.nickname && (
                      <span className="text-xs text-red-500 font-semibold">
                        {errors.nickname.message}
                      </span>
                    )}
                  </fieldset>
                  {errorFromApi !== null && (
                    <div className="bg-floralWhite border border-grayLight mx-8 md:mx-12 lg:mx-20 p-3 flex flex-row overflow-x-auto mt-5">
                      <div className="mr-2">
                        <BsFillExclamationSquareFill
                          color="#FF5353"
                          className="text-2xl"
                        />
                      </div>
                      <p className="text-sm text-left text-black font-light">{`${errorFromApi}`}</p>
                    </div>
                  )}
                <div
                        className={`grid grid-cols-6 mt-5 bg-white ${
                          "w-full"  
                        }`}
                      >
                        {!fromDashboard && <button
                          onClick={(e) => {
                              e.preventDefault();
                              navigate(RoutingConstants.dashboard);
                          }}
                          className="col-span-2 py-3.5 font-bold text-sm text-grayNobel border-t border-grayBorder"
                        >
                          {"SKIP"}
                        </button>}
                        <button
                          disabled={ errorFromApi }
                          className={`${!fromDashboard? 'col-span-4': 'col-span-6'} font-bold text-sm text-white py-3.5 ${
                              loading && "pointer-events-none bg-gray"
                            } ${
                                !secondStepValidationPassed ||
                                errorFromApi
                                ? "bg-gray"
                                : "bg-black"
                            }`}
                          type="submit"
                        >
                          {loading || disableSave ? (
                      <div className="flex flex-row justify-center items-center">
                        <span className="mx-2">{Strings.loading}</span>
                        <PieLoader
                      color={"white"}
                      loading={loading}
                      size={5}
                    />
                      </div>
                    ) : (
                      Strings.submit
                    )}
                        </button>
                      </div>
                </div>
              )}
            </form>
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
    }
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.dashboard.loading,
    orgsList: state.dashboard.data?.organizations || [],
    isTempPassword: state.auth.isTempPassword,
    selectedOrgId: state.dashboard.selectedOrganization,
    userEmail: state.auth.userEmail
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateOrg: createOrg,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSetup);
