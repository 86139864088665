import { useEffect, useState } from "react";

const Checkbox = ({ label, checked, ...props }) => {
  const defaultChecked = checked ? checked : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const optionData = props.optionData;
console.log("Props: ", props.data.cardName);
  useEffect(()=> {
    props.reset && setIsChecked(false);
  },[props.reset]);

  const handleChange = (e) => {
    setIsChecked((prev) =>  !prev); 
    props.parentCallback(e, !isChecked);
  }
    return (
      <div>
        <input
          className="w-3 h-3 appearance-none border border-black checked:appearance-none checked:bg-black checked:border-black"
          key={props.key}
          type="checkbox"
          value={optionData.radioName}
          id={`${props.data.cardName}_${optionData.radioName}`}
          checked={isChecked}
          onChange={(e) => handleChange(e)}
        />
        <label htmlFor={`${props.data.cardName}_${optionData.radioName}`} className="cursor-pointer font-medium pl-1.5">{optionData.radioLabel}</label>
      </div>
    );
  };
  export default Checkbox;