/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

// components
import DefaultPage from "../../components/layout/defaultPage";
import AddOrganization from "../organization/addOrganization";
import ManageUsers from "../../components/workspace/manageUsers";

// constants
import { RoutingConstants} from "../../constants";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";
// svg

// actions
import { getUsers, editUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import EditWorkspace from "../../components/workspace/editWorkspace";
import Security from "../../components/users/security";
import ManageSites from "../../components/workspace/manageSites";
import MobileHeader from "../../components/MobileHeader";
import { selectOrganization } from "../../store/actions/dashboard";
import SectionLoader from "../../components/SectionLoader";

/**
 * @function UserDetails
 * @param {Object} props
 * @returns {JSX}
 */
const WorkspaceDetail = (props) => {
  const isMobile = ScreenIsMobile();
  const {
    currentOrgUsers,
    userProfile,
    currentOrgObject,
    orgsList,
    selectedOrgId,
    selectOrganization,
    currentWorkspaceUsers,
    roleId
  } = props;

  const navigate = useNavigate();
  const [openTab, setOpenTab] = React.useState(1);
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const disableSelect1 = useLocation().pathname.includes("/website/");
  const { id } = useParams();
  const userData = currentOrgUsers.find(val => val.id === Number(id))
  const selectedOrg = orgsList?.filter((o) => o.id === selectedOrgId)[0] || [];

  if (!Boolean(orgsList.length)) {
    return (
      <DefaultPage userProfile={userProfile} showSidebar={true}>
        <SectionLoader content={['No data to display']} isNoData={true} />
      </DefaultPage>
    )
  }

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section>
        {isMobile ?
          <>
            <MobileHeader
              title={`${selectedOrg.name.length > 0 ? selectedOrg.name + ` Settings` : "-NA-"}`}
            />
            <div className="border-b border-grayLight">
              <div id="switchWorkspace" className="flex ml-auto w-fit">
                {roleId === 0 && <AddOrganization/>}
                <div onClick={(e) => {
                    e.preventDefault();
                    setShowOrgDropdown(!showOrgDropdown);
                  }} className="p-2 flex w-max items-center cursor-pointer font-semibold text-xs uppercase "> 
                    <span className="mr-2" > SwITch Workspace </span>
                    <svg width="12" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M6.00474 6.99572L-4.00543e-05 0.99572L12.0095 0.99572L6.00474 6.99572Z" fill="#000000"/>
                    </svg>
                </div>
              </div>
            </div>
          </>
          :
          <section className="flex flex-wrap items-center justify-between border-b border-grayLight pl-6 font-semibold">
            <div className="flex items-center text-xl">
            {`${selectedOrg.name.length > 0 ? selectedOrg.name + ` Settings` : "-NA-"}`}
            </div>
            <div id="switchWorkspace" className="flex items-center">
                {roleId === 0 && <AddOrganization/>}
                <div onClick={(e) => {
                  e.preventDefault();
                  setShowOrgDropdown(!showOrgDropdown);
                }} className="p-6 ml-auto flex w-max items-center cursor-pointer font-semibold uppercase "> 
                  <span className="mr-4" > SwITch Workspace </span>
                  <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.00474 6.99572L-4.00543e-05 0.99572L12.0095 0.99572L6.00474 6.99572Z" fill="#000000"/>
                  </svg>
                </div>
            </div>
          </section>
        }
        <div className="flex flex-wrap relative">
          <div className="w-full sm:flex h-91">
            <div className="sm:w-72 flex justify-center sm:block border-b-0 md:border-b sm:border-r border-grayLight" role="tablist" >
              <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-between border-l mb:border-l-0 border-b border-grayLight text-xs md:text-base font-bold uppercase px-4 py-3.5 md:px-5 md:py-3 leading-normal hover:bg-primary hover:text-white hover:border-none " +
                    (openTab === 1 ? "text-white bg-primary border-none" : "text-block bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(1);}}
                  data-toggle="tab"
                  href="#generalInfo"
                  role="tablist"
                >
                  General Info <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 1 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
              <div className="text-center">
                <a
                  className={
                    "group flex items-center border-l mb:border-l-0 border-b border-grayLight text-xs md:text-base font-bold uppercase px-4 py-3 md:px-5 md:py-3 leading-normal hover:bg-primary hover:text-white hover:border-none " +
                    (openTab === 3 ? "text-white bg-primary border-none" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(3); setShowOrgDropdown(false) }}
                  data-toggle="tab"
                  href="#manageSites"
                  role="tablist"
                >
                  Sites <span className="border border-primary bg-white text-primary text-xs leading-[10px] p-1 min-w-[22px] min-h-[22px] ml-4"> {selectedOrg?.websites?.length} </span> <BsArrowRight className={`text-xl hidden ml-auto sm:group-hover:block ${openTab === 3 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
              <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-left border-x mb:border-x-0 border-b border-grayLight text-xs md:text-base font-bold uppercase px-4 py-3 md:px-5 md:py-3 leading-normal hover:bg-primary hover:text-white hover:border-none " +
                    (openTab === 4 ? "text-white bg-primary border-none" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(4); setShowOrgDropdown(false) }}
                  data-toggle="tab"
                  href="#ManageUsers"
                  role="tablist"
                >
                  Users <span className="border border-primary bg-white text-primary text-xs leading-[10px] p-1 min-w-[22px] min-h-[22px] ml-4">{currentWorkspaceUsers}</span> <BsArrowRight className={`ml-auto text-xl hidden sm:group-hover:block ${openTab === 4 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
              <div className="text-center hidden ">
                <a
                  className={
                    "group flex items-center justify-between border-l mb:border-l-0 border-b border-grayLight text-xs md:text-base font-bold uppercase px-4 md:px-5 py-3 leading-normal hover:bg-primary hover:text-white hover:border-none " +
                    (openTab === 5 ? "text-white bg-primary border-none" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(5); }}
                  data-toggle="tab"
                  href="#BillingInfo"
                  role="tablist"
                >
                  Billing Info <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 5 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
              <div className="text-center hidden ">
                <a
                  className={
                    "group flex items-center justify-between border-l mb:border-l-0 border-b border-grayLight text-xs md:text-base font-bold uppercase px-4 py-3 md:px-5 md:py-3 leading-normal hover:bg-primary hover:text-white hover:border-none " +
                    (openTab === 6 ? "text-white bg-primary border-none" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(6); }}
                  data-toggle="tab"
                  href="#Invoice"
                  role="tablist"
                >
                  Invoices <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 6 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
            </div>
            <div className="break-words bg-white w-full mb-6 rounded">
              <div className="p-4 md:p-8 ">
                <div className="tab-content tab-space overflow-x-hidden">
                  <div className={openTab === 1 ? "block" : "hidden"} id="generalInfo">
                    <EditWorkspace
                      key={selectedOrgId}
                      orgData={selectedOrg}
                      websites={currentOrgObject?.websites}
                    />
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="security">
                    <Security
                      userData={userData}
                      websites={currentOrgObject?.websites}
                    />
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="manageSites">
                    <ManageSites isTabVisible = {openTab === 3}/>
                  </div>
                  <div className={openTab === 4 ? "block" : "hidden"} id="ManageUsers">
                    <ManageUsers isTabVisible = {openTab === 4}/>
                  </div>
                  {/* <div className={openTab === 5 ? "block" : "hidden"} id="BillingInfo">
                    <BillingInfo isTabVisible = {openTab === 5}/>
                  </div>
                  <div className={openTab === 6 ? "block" : "hidden"} id="Invoice">
                    <Invoice isTabVisible = {openTab === 6}/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute right-0">
              {showOrgDropdown && (
                <div
                  style={{ width: document.getElementById("switchWorkspace").offsetWidth }}
                  className="z-50 border border-grayLight shadow-md bg-white text-xs font-semibold max-h-60 overflow-y-auto"
                >
                  {orgsList?.length > 0 &&
                    orgsList?.map((val, index) => {
                      return (
                        <button
                          className={`grid w-full px-2.5 pt-[19px] pb-[13px] list-none  ${
                            selectedOrgId === val.id && "bg-ghost"
                          } hover:text-secondary cursor-pointer text-black overflow-x-auto border-b border-x border-grayLight`}
                          key={index}
                          onClick={() => {
                            if (disableSelect1) {
                              // navigate to websites of that org chosen
                              selectOrganization({ orgid: val.id });
                              navigate(RoutingConstants.websites.webRoot);
                              return;
                            }
                            selectOrganization({ orgid: val.id });
                            setShowOrgDropdown(false);
                          }}
                        >
                          <div className="flex">
                          <div  className="flex flex-col">
                            <div className="text-lg font-bold text-left">
                              {val.name}
                            </div>
                            <div className="text-sm font-light text-left">
                              {val?.websites?.length} Websites
                            </div>
                          </div>
                          <div className={`w-3 h-3 rounded-full my-auto ml-auto ${val.isActive === true ? "bg-bluishGreen" : "bg-gray"}`}></div></div>
                        </button>
                      );
                    })}
                </div>
              )}
            </div>
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    currentOrgUsers: state.dashboard.currentOrgUsers,
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || [],
    currentWorkspaceUsers: state.dashboard.currentWorkspaceUsersCount,
    roleId: state.auth.roleId
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerGetUsers: getUsers,
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
  selectOrganization,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceDetail);
