// library imports
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Strings } from "../constants";

// actions
import { register } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import PieLoader from "../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// Can be a string as well. Need to ensure each key-value pair ends with ;
/* const override = css`
  border-color: "white";
`; */

/**
 * @function Login
 * @returns {JSX}
 */
const Register = (props) => {
  const { token, firstName, lastName, email } = useParams();
  const isMobile = ScreenIsMobile();
  // destructure the props
  const { triggerRegister, loading } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  // state variables
  const [userData, setUserData] = useState({
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: "",
    cpassword: "",
  });
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [disableSignup, setDisableSignup] = useState(false);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the textinputs
   */
  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("password");
    const inputTagElement_2 = document.getElementById("cpassword");

    // If the user edits the input boxes, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value.trim() });
  };

  const onRegisterSuccessCallback = (res) => {
    setDisableSignup(false);
    setErrorFromApi({
      type: "success",
      msg: "Thank you for filling out our sign up form. We are glad that you joined us. ",
    });
    document.getElementById('registerForm').reset()
    setUserData({
      password: "",
      cpassword: "",
    })
  };

  const onRegisterErrorCallback = (errorMsg) => {
    setDisableSignup(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
  };

  const inputsValidated =
    userData.password?.length >= 8 &&
    userData.password?.length <= 30 &&
    !errors.password &&
    userData.cpassword?.length >= 8 &&
    userData.cpassword?.length <= 30 &&
    !errors.cpassword &&
    userData.password === userData.cpassword;

  const submit = () => {
    if (!inputsValidated) {
      return;
    }
    if (userData.password !== userData.cpassword) {
      setErrorFromApi({
        type: "error",
        msg: "Passwords entered are not matching",
      });
      return;
    }
    setDisableSignup(true);
    const payload = {
      token: token,
      password: userData.password,
      onSuccess: onRegisterSuccessCallback,
      onError: onRegisterErrorCallback,
    };
    triggerRegister(payload);
  };
  return (
    <div className={`max-w-full h-screen overflow-hidden`}>
      <div className="grid lg:grid-cols-2">
        <div className="px-8 md:px-12 lg:px-20">
          <div className={`w-full flex mt-20  my-5 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          <div className="w-full pt-20 overflow-auto" style={{ height: "calc(100vh - 100px)"}}>
            <form onSubmit={handleSubmit(submit)} id="registerForm">
              <h2 className="font-bold text-black text-2xl mb-4">
                {"Sign Up"}
              </h2>
              <fieldset className="mb-4">
                <div className="w-full font-semibold mb-2 text-grayDeep text-sm">
                  {`${Strings.password}  `}
                  <span className="text-lg text-red-500 font-bold">{"*"}</span>
                </div>
                <input
                  type="password"
                  className={`placeholder-gray w-full border border-grayBorder focus:border-primary outline-none py-2 px-3 text-sm font-normal overflow-x-auto ${
                    errors.password && "border-red-500"
                  }`}
                  placeholder={Strings.enterPassword}
                  id="password"
                  name="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "* Password is mandatory",
                    },
                    maxLength: {
                      value: 60,
                      message: "* Maximum 60 characters allowed",
                    },
                    minLength: {
                      value: 8,
                      message: "* Minimum 8 characters required",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
                {errors.password && (
                  <span className="text-xs text-red-500 font-semibold">
                    {errors.password.message}
                  </span>
                )}
              </fieldset>
              <fieldset className={`mb-4`}>
                <div className="w-full font-semibold mb-2 text-grayDeep text-sm">
                  {`Confirm Password  `}
                  <span className="text-lg text-red-500 font-bold">{"*"}</span>
                </div>
                <input
                  type="password"
                  className={`placeholder-gray w-full border border-grayBorder focus:border-primary outline-none py-2 px-3 text-sm font-normal overflow-x-auto ${
                    errors.cpassword && "border-red-500"
                  }`}
                  placeholder={"Re-enter your password"}
                  id="cpassword"
                  name="cpassword"
                  {...register("cpassword", {
                    required: {
                      value: true,
                      message: "* Please confirm password",
                    },
                    maxLength: {
                      value: 60,
                      message: "* Maximum 60 characters allowed",
                    },
                    minLength: {
                      value: 8,
                      message: "* Minimum 8 characters required",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
                {!errors?.cpassword?.message &&
                  userData.password.length > 0 &&
                  userData.cpassword.length > 0 &&
                  userData.password !== userData.cpassword && (
                    <span className="text-xs text-red-500 font-semibold">
                      {"*Passwords entered are not matching"}
                    </span>
                  )}

                {errors.cpassword && (
                  <span className="text-xs text-red-500 font-semibold">
                    {errors.cpassword.message}
                  </span>
                )}
              </fieldset>
              {errorFromApi !== null && (
              <div className="w-full mt-5 -mb-5">
                <div
                  className={`${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight flex items-center w-full p-2`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">
                  {`${errorFromApi?.msg}`}
                  {errorFromApi?.type === "success" && 
                    <> Click <Link className="text-cyanBlue font-bold underline leading-8" to={RoutingConstants.login}>here</Link> to login</>
                  }
                  </p>
                  
                </div>
              </div>
              )}
              <button
                disabled={loading || disableSignup || errorFromApi}
                type="submit"
                className={`cursor-pointer text-white font-bold w-full text-sm mt-10 py-3.5 ${
                  errorFromApi || loading || disableSignup
                    ? ` pointer-events-none bg-gray`
                    : inputsValidated && !errorFromApi
                    ? ` bg-black`
                    : `bg-gray`
                }`}
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2">{Strings.loading}</span>
                    <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                  </div>
                ) : (
                  "Sign Up"
                )}
              </button>
              <div className="w-full mt-4">
                <p className={`text-grayDeep text-base text-center`}>
                  {`Already have an account? `}
                  <Link
                    to={RoutingConstants.login}
                    className="font-bold text-lg text-nero cursor-pointer underline"
                  >
                    {Strings.signIn}
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg" style={{ height: errors ? "105vh" : "90vh" }}>
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerRegister: register,
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
