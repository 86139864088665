/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants} from "../constants";

// actions
import { verifyUserEmail } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import { BsHourglassSplit } from "react-icons/bs";
import { BsFillExclamationSquareFill } from "react-icons/bs";


/**
 * @function Login
 * @returns {JSX}
 */
const VerifyUser = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // destructure the props
  const {
    triggerVerifyUserEmail,
    loggedIn,
    accessToken,
  } = props;

  const [loading, setLoading] = useState(true);
  const [varificationData, setVerificationData] = useState({});
  const [errorFromApi, setErrorFromApi] = useState(null);
  useEffect(() => {
    if (loggedIn && accessToken) {
      navigate(RoutingConstants.dashboard);
    }
    else{
      triggerVerifyUserEmail({payload: { id }, onSuccess, onError})
    }
  }, [])

  const onSuccess = response => {
    setLoading(false);
    if(response.err){
      setVerificationData({
        alreadyVerified: true,
        msg: "Your account has been verified already!, Redirecting to Login..."
      });
    }
    else{
    setVerificationData({
      alreadyVerified: false,
      msg: `Your account has been verified, Redirecting to Login...`
    });
    }
    setTimeout(() => {
      window.location.pathname.includes("/verify") && navigate(RoutingConstants.login)
    },3000)
  }

  const onError = msg => {
    setLoading(false);
    setErrorFromApi({
        type: "error",
        msg: msg || "Something went wrong!!!"
    })
  }

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username and password textinputs
   */

  return (
    <div className={`max-w-full`}>
      <div className="grid lg:grid-cols-2">
        <div className="flex flex-wrap flex-col justify-between min-h-screen">
          <div className={`w-full px-8 md:px-12 lg:px-20 flex mt-20 my-10 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          <div className="flex flex-1 flex-col justify-center">
            {loading
            ? <>
                <h2 className="verify-account-text font-bold text-black text-center text-4xl px-8 md:px-12 lg:px-20 -mt-48 mb-6">
                    {`Verifying your account...`}
                </h2>
                <div className="text-center">
                    <BsHourglassSplit className="text-3xl text-orange-500 inline-block" />
                </div>
                </>
            :   errorFromApi
                ? <h2 className="verify-account-text font-bold text-black text-center text-4xl px-8 md:px-12 lg:px-20 -mt-48 mb-6">
                    {`Account Verification Failed`}
                 </h2>
                :
                <p className={`${varificationData?.alreadyVerified? "text-grayDeep": "text-primary" } text-xl px-8 md:px-12 lg:px-20 -mt-48 mb-6`}>
                    {varificationData?.msg}
                </p>
            }
            {errorFromApi !== null && (
                <div
                    className={`${
                    errorFromApi?.type === "error"
                        ? "bg-floralWhite "
                        : "bg-aliceBlue"
                    } border border-grayLight mx-8 md:mx-12 lg:mx-20 p-3 mt-4 flex flex-row overflow-x-auto`}
                >
                    <div className="mr-2">
                    <BsFillExclamationSquareFill
                        color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                        }
                        className="text-2xl"
                    />
                    </div>
                    <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.accessToken,
    isOrganizationPresent: state.auth.isOrganizationPresent,
    isTempPassword: state.auth.isTempPassword,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
    triggerVerifyUserEmail: verifyUserEmail,
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
