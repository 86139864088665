// library imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import notify_desktop from "../../assets/images/notify_desktop.png";

// actions
import { getAuditData } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import TokenExpired from "../../utils/TokenExpired";

/**
 * @function AuditSummary
 * @returns {JSX}
 */
const AuditSummary = (props) => {
  const { jobId } = useParams();
  const { triggerLogout, triggerAuditData} = props;
  const [statusFromApi, setStatusFromApi] = useState(null);
  const[auditData, setAuditData] = useState({})
  useEffect(() => {
    triggerAuditData({
      payload: {
        jobId: jobId,
      },
      onSuccess: (res) => {
        setAuditData(res)
        setStatusFromApi(null)
      },
      onError: (errorMsg) => {
        setStatusFromApi(errorMsg)
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId])

  const auditDetails = auditData?.auditDetails
  const imageSrc = auditDetails?.website_image
  const scoreSummary = [
    {label:'Ax Score', score:auditData?.auditDetails?.axScore?.toFixed(1) || '-', status:auditData?.auditDetails?.accessible_status}, 
    {label:'Level A', score:auditData?.auditDetails?.level_details?.A?.score?.toFixed(1) || '-', status:auditData?.auditDetails?.level_details?.A?.status}, 
    {label:'Level AA', score:auditData?.auditDetails?.level_details?.AA?.score?.toFixed(1) || '-', status:auditData?.auditDetails?.level_details?.AA?.status}, 
    {label:'Level AAA', score:auditData?.auditDetails?.level_details?.AAA?.score?.toFixed(1) || '-', status:auditData?.auditDetails?.level_details?.AAA?.status}
  ]
  const otherDataSummary = [
    {label:'Pages',count:auditData?.pages?.count}, 
    {label:'Rule Violations',count:auditData?.auditDetails?.rule_violations}, 
    {label:'Instances of inaccessibility',count:auditData?.auditDetails?.instances_of_inaccessibility}
  ]
  const pageTitles = ['Page Title', 'Page URL', 'Failures', 'Needs Attention', 'Recommendations', 'Level A', 'Level AA', 'Level AAA']
  const pageData = auditData?.pages?.data

  return (
    <>
    {statusFromApi &&
      <div className="flex flex-col items-center justify-center h-auto over-flow-hidden">
        <img
          src={notify_desktop}
          alt="Notify_icon"
          className="flex items-center justify-center"
        />
        <p className="mt-8 text-lg font-medium text-center">
          {statusFromApi}
        </p>
      </div>
    }
    {!statusFromApi &&
      <section className="font-mukta my-10 overflow-y-auto" style={{height: "calc(100vh - 210px)"}}>
        <div className="flex mx-5 text-base">
          <div className="mr-10">
            <div className="mx-auto w-[21rem] h-[11rem] bg-center bg-cover border border-8 border-b-[20px] rounded-lg " style={{ backgroundImage: `url(${imageSrc})` }} ></div>
            <svg className="w-[25rem] h-4" viewBox="0 0 360 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.0699 0.537798C18.569 0.37559 19.0905 0.292969 19.6153 0.292969H340.017C340.538 0.292969 341.056 0.374311 341.551 0.534055L355.722 5.1016C361.148 6.85041 359.889 14.8605 354.188 14.8605H5.56134C-0.132318 14.8605 -1.39896 6.86517 4.0159 5.10534L18.0699 0.537798Z" fill="black"/>
            </svg>
          </div>
          <div className="col-span-2 font-normal">
            {
              scoreSummary.map((title, index) => <div key={index} className="grid grid-cols-9 mb-2">
                <div className="col-span-4">{ title?.label } </div>
                <div className="font-bold w-7 col-span-1" font-bold>{ title?.score } </div>
                <div className={`col-span-4 px-1 pt-1 text-xs text-white text-center w-fit h-fit pb-1 bg-${ title?.status?.toLowerCase() ==='inaccessible'? 'black': title?.status?.toLowerCase() ==='non-compliant' ? 'pizzaz' : 'limeGreen'}`}>{ title?.status } </div>
                </div>)
            }
            <div className="mt-5">
              {otherDataSummary.map((title, index) => <div key={index} className="flex mb-1">
                <div className="pr-2.5">{ title?.label } </div>
                <div className="font-bold text-left" font-bold>{ title?.count } </div>
              </div>)}
            </div>
          </div>
        </div>
        <div className="w-full text-left mt-14 mb-10">
            <div className="grid grid-cols-9 gap-x-6 font-bold border-b border-b-grayNobel text-base">
              {
                pageTitles.map((pageTitle, index) => <p className={ index === 1 ?'col-span-2':'truncate hover:text-clip'} key={index}>{pageTitle}</p>)
              }
            </div>
            {
              pageData && pageData.map((value, index) => {
                const elePadding = index ? 'pb-2.5' : 'py-2.5'
                return (
                  <div className="grid grid-cols-9 gap-x-6" key={index}>
                    <p className={`truncate  ${elePadding}`}>{ value.page_title }</p>
                    <p className={`col-span-2 truncate  ${elePadding}`}>{ value.page_url }</p>
                    <p className={elePadding}>{ value.failures }</p>
                    <p className={elePadding}>{ value.needs_attension }</p>
                    <p className={elePadding}>{ value.recommendations }</p>
                    <p className={elePadding}>{ value.levelACompliance }</p>
                    <p className={elePadding}>{ value.levelAACompliance }</p>
                    <p className={elePadding}>{ value.levelAAACompliance }</p>
                  </div>
                )
              })
            }
          </div>
      </section>
    }
    </>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    // auditData: state.dashboard.auditData,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
    triggerLogout: logOut,
    triggerAuditData: getAuditData,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(AuditSummary);
