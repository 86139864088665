// library imports
import axios from "axios";

// constants
import { apiEndpoints } from "../constants";

// local storage
import LocalStorageHandler from "../utils/StateCacheStorage";

export const dashboard = async () => {
  const { baseUrl, dashboard } = apiEndpoints;
  const dashboardUrl = `${baseUrl}${dashboard}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(dashboardUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createSite = async (payload) => {
  const { baseUrl, website } = apiEndpoints;
  const createWebsiteUrl = `${baseUrl}${website}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(createWebsiteUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const removeSite = async (payload) => {
  const { baseUrl, website } = apiEndpoints;
  const removeWebsiteUrl = `${baseUrl}${website}/${payload.id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.delete(removeWebsiteUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createOrganization = async (payload) => {
  const { baseUrl, org } = apiEndpoints;
  const createOrgUrl = `${baseUrl}${org}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(createOrgUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateOrganization = async ({ id, ...payload }) => {
  const { baseUrl, org } = apiEndpoints;
  const updateOrgUrl = `${baseUrl}${org}/${id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(updateOrgUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const siteReport = async (payload) => {
  const { baseUrl, siteReport } = apiEndpoints;
  const reportUrl = `${baseUrl}${siteReport}/${payload.jobId}?orgId=${
    payload?.orgId
  }&withax=${payload?.withax || false}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(reportUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const pageReport = async (payload) => {
  const { baseUrl, pageReport } = apiEndpoints;
  const reportUrl = `${baseUrl}${pageReport}/${payload.jobId}?orgId=${payload?.orgId}&linkId=${payload?.linkId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(reportUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const runAudit = async (payload) => {
  const { baseUrl, runAudit } = apiEndpoints;
  const runAuditUrl = `${baseUrl}${runAudit}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(runAuditUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addUser = async (payload) => {
  const { baseUrl, user } = apiEndpoints;
  const createUserUrl = `${baseUrl}${user}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(createUserUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const editUser = async ({ id, ...payload }) => {
  const { baseUrl, user } = apiEndpoints;
  const editUserUrl = `${baseUrl}${user}/${id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(editUserUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const mapUserWithWebsite = async (payload) => {
  const { baseUrl, mapUserWithWebsite } = apiEndpoints;
  const mapUserUrl = `${baseUrl}${mapUserWithWebsite}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(mapUserUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const userProfile = async () => {
  const { baseUrl, getUserProfile } = apiEndpoints;
  const getUserProfileUrl = `${baseUrl}${getUserProfile}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getUserProfileUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUsers = async () => {
  const { baseUrl, users } = apiEndpoints;
  const getUsersUrl = `${baseUrl}${users}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getUsersUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getElementsListApi = async (payload) => {
  const { baseUrl, getElementsList } = apiEndpoints;
  const elementsListUrl = `${baseUrl}${getElementsList}/${
    payload.jobId
  }?orgId=${payload?.orgId}&page=${payload?.page || 1}&limit=${payload?.limit || 10}${payload?.category? `&category=${payload.category}`: ''}${payload?.level? `&level=${payload.level}`: ''}${payload?.severity? `&severity=${payload.severity}`: ''}${payload?.fix? `&fix=${payload.fix}`: ''}${payload?.sort? `&sort=${payload.sort}`: ''}${payload?.search? `&text=${payload.search}`: ''}${payload?.group? `&group=${payload.group}`: ''}${payload?.rulesList? `&rules_list=${payload.rulesList}`: ''}${payload?.group_selector? `&group_selector=${payload.group_selector}`: ''}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(elementsListUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getElementOccurrenceApi = async (payload) => {
  const { baseUrl, getInstanceData } = apiEndpoints;
  const elementOccurrenceUrl = `${baseUrl}${getInstanceData}/${
    payload.eleId
  }?jobId=${payload?.jobId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(elementOccurrenceUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export const updateElementApi = async (payload) => {
  const { baseUrl, updateElement } = apiEndpoints;
  const updateElementUrl = `${baseUrl}${updateElement}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(updateElementUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDashboardData = async (payload) => {
  const { baseUrl, dashboardData } = apiEndpoints;
  const getDashboardDataUrl = `${baseUrl}${dashboardData}?orgId=${payload?.orgId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getDashboardDataUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSiteAnalyticsData = async (payload) => {
  const { baseUrl, dashboardData } = apiEndpoints;
  const getSiteAnalyticsDataUrl = `${baseUrl}${dashboardData}?orgId=${payload?.orgId}&siteId=${payload?.siteId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getSiteAnalyticsDataUrl, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifyUser = async (payload) => {
  const { baseUrl, verifyUser } = apiEndpoints;
  const getverifyUserUrl = `${baseUrl}${verifyUser}/${payload.auditObj.siteId}/${payload.auditObj.orgId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(getverifyUserUrl, payload.verifyObj, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getWebsiteSettings = async (payload) => {
  const { baseUrl, websiteSettings } = apiEndpoints;
  const getWebsiteSettingsUrl = `${baseUrl}${websiteSettings}/${payload.id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWebsiteSettingsUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const editWebsiteSettings = async (payload, payload_1) => {
  const { baseUrl, websiteSettings } = apiEndpoints;
  const editWebsiteSettingsUrl = `${baseUrl}${websiteSettings}/${payload.id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(editWebsiteSettingsUrl, payload_1, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const restoreWebsite = async (payload, payload_1) => {
  const { baseUrl } = apiEndpoints;
  const restoreWebsiteUrl = `${baseUrl}/website/restore/${payload.id}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(restoreWebsiteUrl, payload_1, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAuditData = async (payload) => {
  const { baseUrl, auditData } = apiEndpoints;
  const getAuditDataUrl = `${baseUrl}${auditData}/${payload.jobId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getAuditDataUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAuditScore = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getAuditScoreUrl = `${baseUrl}/getAuditScoreData/${payload.jobId}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getAuditScoreUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAuditRule = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getAuditScoreUrl = `${baseUrl}/getAuditRuleData/${payload.jobId}?level=${payload.level}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getAuditScoreUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAuditStage = async (payload) => {
  const { baseUrl, auditStage } = apiEndpoints;
  const getAuditStageUrl = `${baseUrl}${auditStage}/${payload.jobId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getAuditStageUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const changePassword = async ({ id, ...payload }) => {
  const { baseUrl } = apiEndpoints;
  const changePassword = `${baseUrl}/updatePassword`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(changePassword, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getActiveSubscription = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getActiveSubscriptionUrl = `${baseUrl}/subscription/current/${payload.websiteId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getActiveSubscriptionUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getWorkspaceSites = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getWorkspaceSitesUrl = `${baseUrl}/org/${payload.orgId}/websites/count`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWorkspaceSitesUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSiteData = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getWorkspaceSitesUrl = `${baseUrl}/user/count/${payload.websiteId}/${payload.roleId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWorkspaceSitesUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSiteDataByRole = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getWorkspaceSitesUrl = `${baseUrl}/user/count/${payload.websiteId}/${payload.UserRoleId}`;
  // build the header
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWorkspaceSitesUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const editWorkspace = async ({ id, ...payload }) => {
  const { baseUrl } = apiEndpoints;
  const editWorkspaceUrl = `${baseUrl}/org/partial/${id}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.put(editWorkspaceUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getWorkspaceSiteData = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getWorkspaceSitesUrl = `${baseUrl}/org/${payload.id}/websites?limit=${payload.limit}&page=${payload.page}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWorkspaceSitesUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getWorkspaceUsersData = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getWorkspaceSitesUrl = `${baseUrl}/org/${payload.id}/users?limit=${payload.limit}&page=${payload.page}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getWorkspaceSitesUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const shareReport = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const createUserUrl = `${baseUrl}/shareReport`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.post(createUserUrl, payload, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSignature = async (payload) => {
  const { baseUrl } = apiEndpoints;
  const getAuditDataUrl = `${baseUrl}/website/getSignature/${payload.orgId}`;
  const headers = {
    Authorization: `Bearer ${LocalStorageHandler.get("ACCESS_TOKEN")}`,
  };
  try {
    const response = await axios.get(getAuditDataUrl, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};
