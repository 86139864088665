import React from "react";
import DonutChart from 'react-donut-chart';

const ScoreChart = (props) => {
  return (
    <>
      <div className="relative m-auto" style={{width: `130px`, height: `130px`}}>
        <DonutChart
          width="130"
          height="130"
          colors={props?.colors}
          className={props?.className}
          data={props?.data}
          emptyColor="#000000"
          legend={false}
          clickToggle={false}
          toggledOffset={false}
          strokeColor={false}
          selectedOffset={0}
          emptyOffset={0.15}
        />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full flex flex-col items-center justify-center bg-white text-primary w-[110px] h-[110px] ">
          <p className={`text-xs tracking[-0.02em]  ${props?.status?.toLowerCase() === 'inaccessible' ? '' : 'border-b' } $ leading-none`}>{props?.score?.label}</p>
          <h3 className={`font-montserrat font-bold text-3xl`}>{props?.score?.value}</h3>
        </div>
      </div>
      <div className="mt-2">
        <p className={`text-center mb-3 text-xs font-medium ${props?.ruleFailures === '' && 'opacity-0'}`}>{props?.ruleFailures} Rule Failures</p>
        <p className={`${props?.status?.toLowerCase() === 'compliant' ? 'bg-supernova' : props?.status?.toLowerCase() === 'non-compliant' ? 'bg-white' : 'bg-primary text-white'} ${props?.ruleFailures === '' && 'relative -top-4'} p-1.5 text-xs uppercase text-primary font-bold w-max mx-auto sm:w-full text-center tracking-[-0.02em] border border-solid`}>{props?.status}</p>
      </div>
    </>
  );
};

// exports
export default ScoreChart;
