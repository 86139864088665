// library imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Titles } from "../constants";

// actions
import { logOut } from "../store/actions/auth";
import { selectOrganization } from "../store/actions/dashboard";

// components
import Dialog from "./Dialog";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import { VscFeedback } from "react-icons/vsc";

/**
 * @function Sidebar
 * @returns {JSX}
 */
const Sidebar = (props) => {
  const {
    sidebarCollapsed,
    roleId,
    triggerLogout,
    loggedIn,
    userProfile,
    orgsList,
    selectedOrgId,
  } = props;
  const navigate = useNavigate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const current = window.location.pathname;
  let sidebarData = [
    {
      title: Titles.dashboard,
      path: RoutingConstants.dashboard,
      icon: RenderSvg(svgNames.dashboardBlack),
    },
    {
      title: Titles.websites,
      path: RoutingConstants.websites.webRoot,
      icon: RenderSvg(svgNames.websitesBlack),
    },
  ];

  // Show users tab in sidebar only if the logged in user is Admin (roldId will be 0 in redux store)
  if (roleId === 0) {
    sidebarData.push({
      title: Titles.users,
      path: RoutingConstants.users.usersList,
      icon: RenderSvg(svgNames.users),
    });
  }

  const renderSidebarItem = (i, index) => {
    const isDashboard = i.title === Titles.dashboard;
    const isWebsites = i.title === Titles.websites;
    const isUsers = i.title === Titles.users;
    let currentItemIsSelected;

    // If the selected item is dashboard || or if the URL is like this: https://wally.com/ with no route, then default select dashboard
    if (isDashboard && current === "/") {
      currentItemIsSelected = true;
    }

    // If website tab is selected OR if the user navigates to any report page, etc, website tab should be shown as SELECTED
    if (isWebsites && current.includes("/website")) {
      currentItemIsSelected = true;
    }

    if (isUsers && current.includes("/users")) {
      currentItemIsSelected = true;
    }

    return (
      <Link key={index} to={i.path}>
        <div
          className={`group py-3 text-center ${
            sidebarCollapsed || isMobile ? "justify-center" : "px-5"
          } items-center text-sm font-normal hover:border-primary ${
            i.path === current || currentItemIsSelected
              ? "border-r text-gold border-primary hover:bg-opacity-80"
              : "text-gray border-transparent"
          }`}
        >
          <div className={`group-hover:text-gold sidebar_element ${ i.path === current || currentItemIsSelected ? "text-gold" : "text-white"}`}>
            {i.icon}
          </div>
          <div className={`group-hover:text-gold  mt-0.5 text-1xs leading-12 ${i.path === current || currentItemIsSelected ? "text-gold font-bold" : "text-white"}`}>
            {i.title}
          </div>
        </div>
      </Link>
    );
  };

  const renderMyOrgs = () => {
    let selectedOrgName = "";
    if (orgsList?.length > 0) {
      selectedOrgName =
        orgsList?.filter((o) => o.id === selectedOrgId)[0]?.name || "";
    }
    return (
      <Link to='/workspace'>
        <div
          className={`sidebar_element group w-full py-3 px-5 text-sm font-normal hover:border-primary ${
            current.includes("/workspace")
              ? "border-r text-gold !font-bold border-primary hover:bg-opacity-80"
              : "text-gray border-transparent"
          }`}
        >
          <span className={`group-hover:text-gold ${current.includes("/workspace") ? 'text-gold' : 'text-white' }`}>{RenderSvg(svgNames.myOrgs)}</span>
          {!isMobile && (
            <div className="group-hover:text-gold w-full text-center">
              <div className={`mt-0.5 text-1xs leading-[10px] w-full text-center`}>
                {`${selectedOrgName.length > 0 ? selectedOrgName : ""}`}
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  };
  const renderMyProfile = () => {
    if (userProfile) {
      if (
        userProfile.firstName?.length > 0 &&
        userProfile.lastName?.length > 0
      ) {
        
      }
    }
    return (
      <Link
        to="/profile"
        className={
          `group relative w-full py-3 px-5 items-center text-sm font-normal hover:border-primary text-center
          ${current.includes("/profile")
          ? "border-r border-grayLight text-gold !font-bold hover:bg-opacity-80"
          : "text-gray border-transparent"
          }`
        }
      >
        <span className={`group-hover:text-gold sidebar_element ${current.includes("/profile")? 'text-gold': 'text-white'}`}>
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 15.1617C6.98724 15.1617 3.71924 11.8937 3.71924 7.8809C3.71924 3.8681 6.98724 0.600098 11 0.600098C15.0128 0.600098 18.2808 3.8681 18.2808 7.8809C18.2808 11.8937 15.0128 15.1617 11 15.1617ZM11 2.6749C8.13484 2.6749 5.80164 5.0081 5.80164 7.8733C5.80164 10.7385 8.13484 13.0717 11 13.0717C13.8652 13.0717 16.1984 10.7385 16.1984 7.8733C16.1984 5.0081 13.8652 2.6749 11 2.6749Z" fill="currentColor"/>
            <path d="M21.4045 23.4835H19.3221C19.3221 18.8931 15.5905 15.1615 11.0001 15.1615C6.40971 15.1615 2.6781 18.8931 2.6781 23.4835H0.595703C0.595703 17.7455 5.26211 13.0791 11.0001 13.0791C16.7381 13.0791 21.4045 17.7455 21.4045 23.4835Z" fill="currentColor"/>
          </svg>
        </span>
        {!isMobile && (
          <div className={`group-hover:text-gold w-full mt-0.5 text-1xs leading-12  ${current.includes("/profile")? 'text-gold': 'text-white'}`}>
            {!isMobile && 'Profile'}
          </div>
        )}
        <div>
          {showProfile && (
            <div
              className={`top-0 left-full z-50 border border-grayLight absolute shadow-md bg-white text-xs font-semibold overflow-y-auto w-48 max-h-28`}
            >
              <div
                onClick={() => {
                  navigate(RoutingConstants.organization.orgRoot);
                }}
                className="flex items-center justify-between p-3 hover:bg-grayLight"
              >
                <span className="text-black text-sm text-left ml-2 mr-5 hover:text-primary">
                  {"My Organizations"}
                </span>
              </div>
              <div
                onClick={() => setShowConfirmDialog(true)}
                className="flex items-center justify-between p-3 hover:bg-grayLight"
              >
                <span className="text-black text-left text-sm ml-2 mr-5 hover:text-primary">
                  {"Logout"}
                </span>
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  };

  const isMobile = ScreenIsMobile();
  return (
    <div
      onClick={() => {
        if (showProfile) {
          setShowProfile(false);
        }
      }}
      className="w-full h-screen flex flex-wrap content-between bg-primary text-white"
    >
      <div className="w-full pb-3">
        <Link to={RoutingConstants.dashboard}>
          <div className={`flex items-center justify-center py-4`}>
            {!isMobile ? (
              sidebarCollapsed ? (
                RenderSvg(svgNames.wallyLogo)
              ) : (
                <div
                  className={`h-full object-cover w-8 sm:w-20 md:w-24 lg:w-32`}
                >
                  {RenderSvg(svgNames.logo)}
                </div>
              )
            ) : (
              <div>{RenderSvg(svgNames.collapsedLogo)}</div>
            )}
          </div>
        </Link>
        <div className="mb-8">
          {sidebarData.map((i, index) => renderSidebarItem(i, index))}
        </div>
      </div>
      <div className="w-full flex flex-col justify-center">
        <div className={`${(sidebarCollapsed || isMobile) && "flex justify-center"}`}>
          <button onClick={()=>window.showCollectorDialog()} className="w-full py-3 itext-sm font-normal text-gray border-transparent group">
            <div className="group-hover:text-gold text-white">{<VscFeedback className="w-7 h-7 m-auto" />}</div>
            {!isMobile && (
              <div className="group-hover:text-gold w-full mt-0.5 text-1xs leading-12">Feedback</div>
            )}
          </button>
        </div>
        <div
          className={`${
            (sidebarCollapsed || isMobile) && "flex justify-center"
          }`}
        >
          {renderMyOrgs()}
        </div>
        <div
          className={`${
            (sidebarCollapsed || isMobile) && "flex justify-center"
          }`}
        >
          {renderMyProfile()}
        </div>
        <div
          className={`${
            (sidebarCollapsed || isMobile) && "flex justify-center"
          }`}
        >
          {showConfirmDialog && (
            <Dialog
              onYesClick={() => {
                setShowConfirmDialog(false);
                triggerLogout();
                if (!loggedIn) {
                  navigate(RoutingConstants.login);
                }
              }}
              onNoClick={() => {
                setShowConfirmDialog(false);
              }}
              title={`Logout`}
              subtitle={"Are you sure want to logout?"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    sidebarCollapsed: state.dashboard.sidebarCollapsed,
    roleId: state.auth.roleId,
    loggedIn: state.auth.loggedIn,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  selectOrganization,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
