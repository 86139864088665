/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";

// Actions
import { editUser, mapUserWebsites, getSiteData } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../PieLoader";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import UpgradePlan from "../plan/upgradePlan";

/**
 * @function SitePermissions
 * @returns {JSX}
 */
const SitePermissions = (props) => {
  const { id } = useParams();
  const { currentOrgObject, currentOrgUsers, triggerSiteData } = props;
  const userData = currentOrgUsers.find((i) => i.id === Number(id));
  const websites = currentOrgObject?.websites;
  const { triggerLogout, mapUserWebsites } = props;
  const [items, setItems] = useState([]);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [buttontext, setButtontext] = useState('')
  const [showModal, setShowModal] = useState(false)
  const isCurrentWebsiteActive = currentOrgObject.isActive

  const onSuccess = (successMsg) => {
    setErrorFromApi({
      type: "success",
      msg: "Site Permissions has been updated successfully !",
    });
    setLoading(false);
    setLoadingId(null);
    setItems([]);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    setLoading(false);
    setLoadingId(null);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const sitePermissions = websites.filter((i) =>
    userData.websiteIds.includes(i.id)
  );
  const mappedsites = sitePermissions.map((i) => i.id);

  const removeObj = (val) => {
    if(isCurrentWebsiteActive) {
      setErrorFromApi(null);
      setLoadingId(val.id);
      const updatedSites = sitePermissions.filter((site) => site.id !== val.id);
      const requestObj = {
        userId: Number(id),
        websiteIds:
          updatedSites?.length > 0 ? updatedSites?.map((i) => i.id) : [],
      };
      const payload = { requestObj, onSuccess, onError };
      mapUserWebsites(payload);
    }
  };
  const unselectedWebsites = websites.filter(
    (i) => !userData.websiteIds.includes(i.id)
  );
  const options = Array.from(
    new Set(
      unselectedWebsites.map((i, index) => {
        return { name: i.url, id: i.id };
      })
    )
  );

  const handleSelect = (selectedList) => {
    setErrorFromApi(null);
    setItems(selectedList);
  };
  useEffect(() => {
    if(!props?.isTabVisible){
      setItems([]);
    }
  },[props?.isTabVisible])

  const submitForm = (e) => {
    e.preventDefault();
    if(isCurrentWebsiteActive) {
      setLoading(true);
      setButtontext('')
      triggerSiteData({
        payload: {
          websiteId: items[0].id,
          roleId: 2,
          UserRoleId: props?.userData?.roleId
        },
        onSuccess: (res) => {
          if(res?.data?.data?.unlimited || res?.data?.data?.pending > 0) {
            const requestObj = {
              userId: Number(id),
              websiteIds: [...mappedsites, items[0].id],
            };
            const payload = { requestObj, onSuccess, onError };
            mapUserWebsites(payload);
          } else {
            setShowModal(true);
            setButtontext(null)
            setLoading(false)
          }
        },
        onError: (errorMsg) => {
          setLoading(false);
          setButtontext(null)
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              triggerLogout();
            }, 1500);
          }
        }
      });
    }
  };

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="inset-0 z-50 outline-none focus:outline-none">
          <div className="relative sm:p-4 mb-80">
            <div>
              <h3 className="text-lg font-semibold mb-4">
                Manage site permissions
              </h3>
              <section>
                {sitePermissions.map((val, index) => {
                  return (
                    <div
                      className="group text-sm border-t border-grayBorder py-2 last:border-b grid grid-cols-8 items-center"
                      key={index}
                    >
                      <p className="font-medium col-span-2">{val.name}</p>
                      <p className="w-full truncate col-span-4 sm:col-span-5">{val.url}</p>
                      <button
                        className={`${
                          loadingId === val.id
                            ? "bg-red-500 text-white opacity-100"
                            :!isCurrentWebsiteActive ? 'border-gray text-gray pointer-events-none opacity-100 md:opacity-0'
                            : "text-red-500 border-red-500 bg-white opacity-100 md:opacity-0"
                        } group-hover:opacity-100 border px-2 py-1 text-xs font-normal mb-2 md:mb-0 w-max ml-auto col-span-2 sm:col-span-1`}
                        style={{ transition: "all 500ms ease" }}
                        onClick={() => removeObj(val)}
                      >
                        {loadingId === val.id ? (
                          <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                            <span className="ml-2">Loading</span>
                          </div>
                        ) : (
                          "Remove"
                        )}
                      </button>
                    </div>
                  );
                })}
              </section>
              {errorFromApi !== null && (
                <div
                  className={`${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 my-8 flex items-center flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
              )}
              <form
                onSubmit={submitForm}
                className="site_dropdown sm:flex items-start justify-between my-10"
              >
                <Multiselect
                  placeholder="Select a site to manage"
                  singleSelect={true}
                  options={options}
                  selectedValues={items}
                  onSelect={handleSelect}
                  displayValue="name"
                  className="placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary"
                />
                <button
                  type="submit"
                  className={`border cursor-pointer text-white font-bold p-2 px-2 sm:px-8 tracking-small mt-4 sm:mt-0 ${
                    loading || items.length === 0
                      ? "pointer-events-none border-gray bg-gray"
                      : "border-cyanBlue bg-cyanBlue"
                  } ${!isCurrentWebsiteActive && 'bg-gray border-gray pointer-events-none'}`}
                >
                  {loading ? (
                    <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                      <span className="ml-4">Loading</span>
                    </div>
                  ) : (
                    "Add Site"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && <UpgradePlan content={buttontext} />}
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
    currentOrgUsers: state.dashboard.currentOrgUsers,
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
  mapUserWebsites,
  triggerSiteData: getSiteData,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(SitePermissions);
