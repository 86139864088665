/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DefaultPage from "../../components/layout/defaultPage";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import SectionLoader from "../../components/SectionLoader";

// strings
import { RoutingConstants, Titles } from "../../constants";

// Actions
import { updateOrg } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// components
import Dialog from "../../components/Dialog";
import MobileHeader from "../../components/MobileHeader";
import AddOrganization from "./addOrganization";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";
// token expired handler
import TokenExpired from "../../utils/TokenExpired";

/**
 * @function Organization
 * @param {Object} props
 * @returns {JSX}
 */
const Organization = (props) => {
  const isMobile = ScreenIsMobile();
  const { orgsList, userProfile, roleId, isTempPassword } = props;
  const { triggerLogout, triggerUpdateOrg } = props;
  const [updatingOrg, setUpdatingOrg] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const navigate = useNavigate();

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  useEffect(() => {
    if (showSearchBox) {
      document.getElementById("searchbox").focus();
    }
  }, [showSearchBox]);

  const handleSearchIconClick = () => {
    setShowSearchBox(!showSearchBox);
    setSearchTerm("");
  };

  const filteredOrg = [...orgsList]?.filter((val) => {
    if (searchTerm === "") {
        return val;
    } else if (val?.email?.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else if (val?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else if (val?.type?.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else return "";
  })

  const onError = (errorMsg) => {
    toast.dismiss();
    toast.error(errorMsg || "Something went wrong!!!");
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setUpdatingOrg(null);
    setShowConfirmDialog(false);
  };

  const toggleActive = (org) => {
    const payload1 = {
      email: org.email,
      name: org.name,
      type: org.type,
      country: org.country,
      state: org.state,
      isActive: org.isActive === true ? false : true,
    };
    const payload2 = {
      url: "",
      organization: org.id,
    };
    triggerUpdateOrg({
      orgObj: payload1,
      websiteObj: payload2,
      onSuccess: () => {
        setUpdatingOrg(null);
        setShowConfirmDialog(false);
      },
      onError,
    });
  };

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      {isMobile ? (
        <MobileHeader
          title={Titles.myOrganization}
          onSearchIconClick={() => handleSearchIconClick()}
        />
        )
      :
        <section className="flex flex-wrap items-center justify-between border-b border-grayLight pl-6 font-semibold">
          <div className="flex items-center text-xl">
            {Titles.myOrganization}
          </div>
          <div className="flex items-center">
            <div onClick={() => handleSearchIconClick()} className="p-6 border-l border-grayLight cursor-pointer">
                <BiSearch className="text-2xl" />
            </div>      
            {roleId === 0 && <AddOrganization/>}
          </div>
        </section>
      }
      {showSearchBox && (
        <section className="flex flex-1 items-center pl-7 pr-2 py-3 bg-tileGray border-b border-grayLight">
          <input
            id="searchbox"
            name="searchbox"
            type="text"
            className="flex w-full focus:outline-none text-xl text-black bg-tileGray"
            placeholder="Type here to start searching"
            onChange={(e) => setSearchTerm(e.target.value.trim())}
          />
          <MdClose
            className="text-2xl cursor-pointer"
            onClick={() => {
              setShowSearchBox(false);
              setSearchTerm("");
            }}
          />
        </section>
      )}
      <section className={`overflow-y-auto ${showSearchBox ? 'h-80' : 'h-91'}`}>
        {showConfirmDialog && (
          <Dialog
            onYesClick={() => {
              toggleActive(updatingOrg);
            }}
            onNoClick={() => {
              setShowConfirmDialog(false);
              setUpdatingOrg(null);
            }}
            title={`${updatingOrg?.isActive ? "Archiving" : "Activating"} " ${
              updatingOrg?.name
            } " `}
            subtitle={"Are you sure want to proceed?"}
          />
        )}
        {orgsList?.length === 0 && (
          <div className="p-6 py-4 text-center flex items-center justify-center h-full">
            <SectionLoader
              content={["No data to display"]}
              isNoData={true}
            />
          </div>
        )}
        {filteredOrg?.length > 0 ?
            filteredOrg.map((val, index) => {
                return (
                  <Link to={roleId === 0? "/organization/edit/" + val.id: "#"} key={index} className="group">
                    <div className="flex justify-between border-b border-grayLight">
                      <div className="px-6 py-2 w-9/12 flex justify-between">
                        <div>
                          <p className="overflow-x-auto text-lg font-bold">
                            {`${
                            val.name.charAt(0).toUpperCase() +
                            val.name.slice(1)
                            }`}
                          </p>
                          <p className="whitespace-nowrap overflow-hidden text-ellipsis w-full text-base">
                            {`${val.websites?.filter(web => !web.isDeleted)?.length > 0? val.websites.filter(web => !web.isDeleted).length + ' websites': 'No Websites'}`}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-3/12">
                        <div className="px-8"><div className={`w-3 h-3 rounded-full m-auto ${val.isActive === true ? "bg-bluishGreen" : "bg-gray"}`}></div></div>
                        <p style={{transition: "all 500ms ease"}} className="w-0 group-hover:w-16 bg-primary text-white h-full flex items-center justify-center"><BsArrowRight className="text-2xl" /></p>
                      </div>
                    </div>
                  </Link>
                );
            })
            :
            <div className="p-6 py-4 text-center flex items-center justify-center h-full">
              <SectionLoader
                content={searchTerm !== "" ? "We couldn't find any results." : "No data to display."}
                isNoData={true}
              />
            </div>
        }
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    roleId: state.auth.roleId,
    orgsList: state.dashboard.data?.organizations || [],
    userProfile: state.dashboard.userProfile,
    isTempPassword: state.auth.isTempPassword,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateOrg: updateOrg,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Organization);
