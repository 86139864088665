/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, Chart } from "react-chartjs-2";

// components
import DefaultPage from "../components/layout/defaultPage";
import MobileHeader from "../components/MobileHeader";
import ScreenIsMobile from "../utils/ScreenIsMobile";
import SectionLoader from "../components/SectionLoader";

// constants
import { RoutingConstants, Titles } from "../constants";

// actions
import { logOut } from "../store/actions/auth";
import { fetchDashboard, getDashboardData, getUsers } from "../store/actions/dashboard";
import { closeHamburger } from "../store/actions/dashboard";

// token expired handler
import TokenExpired from "../utils/TokenExpired";

/**
 * @function Dashboard
 * @param {JSON} props
 * @returns {JSX}
 */
const Dashboard = (props) => {
  // utils
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();

  // destructure props
  const {
    loggedIn,
    triggerLogout,
    accessToken,
    fetchDashboard,
    userProfile,
    isTempPassword,
    closeHamburger,
    getDashboardData,
    dashboardData,
    selectedOrgId,
    triggerGetUsers,
    orgsList
  } = props;

  /**
   * @function handleLogout
   */
  const handleLogout = () => {
    triggerLogout();
    if (!loggedIn || !accessToken) {
      // navigate to login
      navigate(RoutingConstants.login);
    }
  };

  /**
   * @function onError
   * @param {string} errorMsg
   * @returns {void}
   */
  const onError = (errorMsg) => {
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        handleLogout();
      }, 1500);
    }
  };

  // did mount - whenever the screen is mounted, make an API call to fetch dashboard data
  useEffect(() => {
    fetchDashboard({
      onError,
      onSuccess: () => {},
    });
    // always scroll to top - because react preserves scroll position by default
    window.scrollTo(0, 0);
    triggerGetUsers({ onSuccess: () => {}, onError });
  }, []);
  const [loader, setLoader] = useState(true)
  useEffect(() => {
    if (selectedOrgId) {
      const payload = {
        orgId: selectedOrgId
      }
      getDashboardData({
        payload,
        onSuccess: () => {
          setLoader(false)
        },
        onError: (errorMsg) => {
          setLoader(false)
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              handleLogout();
            }, 1500);
          }
        }
      });
    }
    // always scroll to top - because react preserves scroll position by default
    window.scrollTo(0, 0);
  }, [selectedOrgId]);

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);
  const horizontalBaroptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItem) {
            const str = tooltipItem[0]?.dataset?.originalLabel[tooltipItem[0].dataIndex];
            const maxwidth=45;
            var returnedArray = [];
            var splittedString = str.split(" ");
            var temp = "";
            splittedString.forEach(function(item, index){
              if(temp.length > 0){
                var concat = temp + ' ' + item;
                if(concat.length > maxwidth){
                  returnedArray.push(temp);
                  temp = "";
                }
                else{
                  if(index === (splittedString.length-1)) {
                    returnedArray.push(concat);
                    return;
                  }
                  else {
                    temp = concat;
                    return;
                  }
                }
              }
          
              if(index === (splittedString.length-1)) {
                returnedArray.push(item);
                return;
              }
          
              if(item.length < maxwidth) {
                temp = item;
              }
              else {
                returnedArray.push(item);
              }
          
            });
            return returnedArray;
          }
        }
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false
        },
      },
      y: {
        barPercentage: 0.4,
        grid: {
          display: false
        },
      }
    }
  };

  const horizontalBarRule = {
    labels: dashboardData?.top_rule_failures?.map((i) => i?.key?.length > 20? i?.key?.substring(0, 17) + "...": i?.key) || [],
    datasets: [
      {
        data: dashboardData?.top_rule_failures?.map((i) => i?.sum) || [],
        backgroundColor: "#000000",
        borderColor: "#fff",
        barThickness: 20,
        minBarLength: 2,
        originalLabel:dashboardData?.top_rule_failures?.map((i) => i?.key) || [],
      }
    ],
  };
  const horizontalBarPage = {
    labels: dashboardData?.top_affected_pages.map((i) => i?.title?.length > 20? i?.title?.substring(0, 17) + "...": i?.title) || [],
    datasets: [
      {
        data: dashboardData?.top_affected_pages.map((i) => i?.scf) || [],
        backgroundColor: "#000000",
        borderColor: "#fff",
        barThickness: 20,
        minBarLength: 2,
        originalLabel:dashboardData?.top_affected_pages.map((i) => i?.title) || [],
      }
    ],
  };

  const fixesRatio = {
    labels: dashboardData?.no_of_scf_vs_fixes.map((i) => i?.month?.length > 20? i?.month?.substring(0, 17) + "...": i?.month) || [],
    datasets: [
      {
        grouped: true,
        categoryPercentage: 0.35,
        barPercentage: 1,
        barThickness: 20,
        data: dashboardData?.no_of_scf_vs_fixes.map((i) => i?.total_scf) || [],
        label: "No. of success criterion failures",
        borderColor: "#000000",
        backgroundColor: "#000000",
        fill: true
      },
      {
        grouped: true,
        categoryPercentage: 0.35,
        barPercentage: 1,
        barThickness: 20,
        data: dashboardData?.no_of_scf_vs_fixes.map((i) => i?.total_autofix) || [],
        label: "No. of fixes generated",
        borderColor: "#FC8763",
        backgroundColor: "#FC8763",
        fill: true
      }
    ]
  };
  const fixesRatioOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    layout: {
      padding: {
        left: 75,
        right: 75,
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false
        },
      },
      x: {
        grid: {
          display: false
        },
      }
    }
  };

  const auditData = {
    labels: dashboardData?.no_of_audited_pages.map((i) => i?.Month?.length > 20? i?.Month?.substring(0, 17) + "...": i?.Month) || [],
    datasets: [
      {
        type: 'bar',
        fill: true,
        barPercentage: 0.35,
        barThickness: 20,
        backgroundColor: '#000000',
        data: dashboardData?.no_of_audited_pages.map((i) => i?.audit_count) || [],
        label: "No. of audits",
      },
      {
        type: 'line',
        pointStyle:'rect',
        borderJoinStyle: 'bevel',
        borderColor: '#FC8763',
        borderWidth: 1.5,
        backgroundColor: '#FC8763',
        data: dashboardData?.no_of_audited_pages.map((i) => i?.page_count) || [],
        label: "Pages Audited",
      },
    ],
  };

  if (!Boolean(orgsList.length)) {
    return (
      <DefaultPage userProfile={userProfile} showSidebar={true}>
        <SectionLoader content={['No data to display']} isNoData={true} />
      </DefaultPage>
    )
  }

  return (
    <DefaultPage
      showSidebar={true}
      handleLogout={handleLogout}
      userProfile={userProfile}
    >
      {/* Show Header only on mobile & tablets */}
      {isMobile ? 
        <MobileHeader title={Titles.dashboard} /> 
      : 
        <div className="p-3 py-4 font-bold text-2xl border-b border-grayLight">
          Dashboard
        </div>
      }

      {/* Show notify icon at the center of desktop page */}
      <div
        onClick={() => {
          closeHamburger();
        }}
      >
        <section className='overflow-y-auto h-[91vh]'>
          {loader ?
            <SectionLoader content={['We are crunching a bunch of numbers.',' We will notify you once it is ready']}/>
          : 
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
              <div className="bg-aliceBlue p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className="mb-6 text-6xl">
                    {dashboardData?.no_of_audits[0]?.count || 0}
                  </h2>
                  <div className="bg-primary py-1 px-2 text-white text-sm lg:text-sm inline-block">AUDITS</div>
                </div>
              </div>

              <div className="bg-aliceBlue p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {dashboardData?.audit_duration[0].sum || 0}
                  </h2>
                  <div className="bg-primary py-1 px-2 text-white text-sm lg:text-sm">
                    AUDIT MINUTES
                  </div>
                </div>
              </div>

              <div className="bg-floralWhite p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {dashboardData?.total_scf_count[0]?.sum || 0}
                  </h2>
                  <div className="border border-salmon text-salmon py-1 px-2 text-sm lg:text-sm">
                    SUCCESS CRITERION FAILURES
                  </div>
                </div>
              </div>

              <div className="bg-greenLight p-6 sm:min-h-14 flex items-center justify-center border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {dashboardData?.auto_fix_count[0]?.sum || 0}
                  </h2>
                  <div className="border border-greenSucceed text-greenSucceed py-1 px-2 text-sm lg:text-sm">
                    AUTO FIXES GENERATED
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 text-center">
              <div className="px-6 py-7 sm:border-r border-b border-grayLight">
                <h3 className="inline-block p-2 leading-none mb-3 text-cyanBlue border border-cyanBlue font-semibold text-center">TOP {dashboardData?.top_rule_failures?.length} RULES FAILURES</h3>
                <Bar className="max-h-44" options={horizontalBaroptions} data={horizontalBarRule} />
              </div>
              <div className="px-6 py-7 sm:border-r border-b border-grayLight">
                <h3 className="inline-block p-2 leading-none mb-3 text-cyanBlue border border-cyanBlue font-semibold text-center">TOP {dashboardData?.top_affected_pages.length} AFFECTED PAGES</h3>
                <Bar className="max-h-44" options={horizontalBaroptions} data={horizontalBarPage} />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 text-center">
              <div className="text-center px-6 py-7 sm:border-r border-b border-grayLight">
                <div className="flex justify-center items-center gap-4">
                  <h3 className="inline-block p-2 leading-none text-cyanBlue border border-cyanBlue font-semibold text-center">AUDITS RAN</h3>
                  <div className="flex items-center text-sm"><div className="bg-primary w-3 h-3 mr-2"></div>No. of audits</div>
                  <div className="flex items-center text-sm"><div className="bg-salmon w-3 h-3 mr-2"></div>Pages Audited</div>
                </div>
                <Chart className="h-64" options={fixesRatioOptions} data={auditData} />
              </div>
              <div className="text-center px-6 py-7 sm:border-r border-b border-grayLight">
                <div className="flex justify-center items-center gap-8">
                  <h3 className="inline-block p-2 leading-none text-cyanBlue border border-cyanBlue font-semibold text-center">Fixes ratio</h3>
                  <div className="flex items-center text-sm"><div className="bg-primary w-3 h-3 mr-2"></div>No. of success criterion failures</div>
                  <div className="flex items-center text-sm"><div className="bg-salmon w-3 h-3 mr-2"></div>No. of fixes generated</div>
                </div>
                <Bar className="h-64 w-32" options={fixesRatioOptions}  data={fixesRatio} />
              </div>
            </div>
          </>
          }
        </section>
      </div>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.accessToken,
    isTempPassword: state.auth.isTempPassword,
    userProfile: state.dashboard.userProfile,
    dashboardData: state.dashboard.dashboardData,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || []
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  fetchDashboard,
  closeHamburger,
  getDashboardData,
  triggerGetUsers: getUsers,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
