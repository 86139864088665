/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

// Actions
import { editUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../PieLoader";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function ViewUser
 * @returns {JSX}
 */
const EditUser = (props) => {
  const userData = { ...props.userData };
  const { triggerUpdateUser, triggerLogout, orgsList, selectedOrgId } = props;
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const isCurrentWebsiteActive = orgsList.find(i => i.id === selectedOrgId).isActive

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  const initState = {
    firstName: `${
      userData.firstName.slice().charAt(0).toUpperCase() +
      userData.firstName.slice(1)
    }`,
    lastName: `${
      userData.lastName.slice().charAt(0).toUpperCase() +
      userData.lastName.slice(1)
    }`,
    role: props.userData.roleId,
    isActive: props.userData.isActive,
  };
  const [userDetail, setUserDetail] = useState(initState);

  const onChangeHandler = (e) => {
    setUserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
    const inputTagElement_1 = document.getElementById("firstName");
    const inputTagElement_2 = document.getElementById("lastName");
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
  };

  const [userRole, setUserRole] = useState(
    userDetail.role === 0 ? true : false
  );
  const [userStatus, setUserStatus] = useState(userDetail.isActive);

  const onSuccess = (successMsg) => {
    setErrorFromApi({
      type: "success",
      msg: "User has been updated successfully !",
    });
    setLoading(false);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    reset(initState, {
      keepErrors: false,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setLoading(false);
  };

  const isUserUpdated =
    initState.firstName !== userDetail.firstName ||
    initState.lastName !== userDetail.lastName ||
    initState.role !== (userRole === true ? 0 : 1) ||
    initState.isActive !== userStatus;

  const validationPassed = 
   !errors.firstName &&
    !errors.lastName;

  const submit = (e) => {
    if(isCurrentWebsiteActive){
      setLoading(true);
      const payload_1 = {
        firstName: userDetail.firstName.trim(),
        lastName: userDetail.lastName.trim(),
        roleId: userRole === true ? 0 : 1,
        isActive: userStatus,
        id: userData.id,
      };
      triggerUpdateUser({ payload_1, onSuccess, onError });
    }
  };

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="overflow-x-hidden outline-none focus:outline-none">
          <div className="">
            <div>
              <div className="relative sm:p-4">
                <div className="w-full sm:max-w-md">
                  <form onSubmit={handleSubmit(submit)} className="w-full">
                    <>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-base text-black font-bold mb-2">
                          First Name
                        </div>
                        <input
                          value={userDetail.firstName}
                          type="text"
                          className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm py-2 px-3 font-medium border-grayBorder focus:border-primary ${
                            errors.firstName && "border-red-500"
                          }`}
                          id="firstName"
                          name="firstName"
                          placeholder="Enter your first name"
                          {...register("firstName", {
                            required: {
                              value: true,
                              message: "* First name is mandatory",
                            },
                            maxLength: {
                              value: 40,
                              message: "* Maximum 40 characters allowed",
                            },
                            pattern: {
                              value: /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/,
                              message:
                                "* Should be alpha numerical",
                            },
                          })}
                          onChange={(e) => onChangeHandler(e)}
                        />
                        {errors.firstName && (
                          <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                            {errors.firstName.message}
                          </div>
                        )}
                      </fieldset>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-base text-black font-bold mb-2">
                          Last Name
                        </div>
                        <input
                          value={userDetail.lastName}
                          type="text"
                          className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm py-2 px-3 font-medium border-grayBorder focus:border-primary ${
                            errors.lastName && "border-red-500"
                          }`}
                          id="lastName"
                          name="lastName"
                          placeholder="Enter your last name"
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "* Last name is mandatory",
                            },
                            maxLength: {
                              value: 40,
                              message: "* Maximum 40 characters allowed",
                            },
                            pattern: {
                              value: /^(?![0-9]+$)[a-zA-Z0-9 ]{1,}$/,
                              message:
                                "* Should be alpha numerical",
                            },
                          })}
                          onChange={(e) => onChangeHandler(e)}
                        />
                        {errors.lastName && (
                          <span className="text-xs text-red-500 font-semibold">
                            {errors.lastName.message}
                          </span>
                        )}
                      </fieldset>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-base text-black font-bold mb-2">
                          Status
                        </div>
                        <div className="flex items-center">
                          <div
                            className={`h-6 w-12 p-1 cursor-pointer border ${
                              userStatus
                                ? "border-primary bg-aliceBlue"
                                : "border-grayNobel bg-white"
                            }`}
                            onClick={() => {
                              setUserStatus(!userStatus);
                              setErrorFromApi(null);
                            }}
                            style={{ padding: "3px" }}
                          >
                            <div
                              className={`h-4 w-4 transform duration-300 ease-in-out ${
                                userStatus
                                  ? "bg-primary transform translate-x-6"
                                  : "bg-grayNobel "
                              } `}
                            ></div>
                          </div>
                            <p className={`text-sm font-normal pl-2 ${!userStatus && 'text-grayDeep'}`}>
                              {userStatus ? "Active" : "Inactive"}
                            </p>
                        </div>
                      </fieldset>
                      <fieldset className="w-full m-auto text-lg mb-4">
                        <div className="text-base text-black font-bold mb-2">
                          Organizational Admin
                        </div>
                        <div className="flex items-center">
                          <div
                            className={`h-6 w-12 p-1 cursor-pointer border ${
                              userRole
                                ? "border-primary bg-aliceBlue"
                                : "border-grayNobel bg-white"
                            }`}
                            onClick={() => {
                              setUserRole(!userRole);
                              setErrorFromApi(null);
                            }}
                            style={{ padding: "3px" }}
                          >
                            <div
                              className={`h-4 w-4 transform duration-300 ease-in-out ${
                                userRole
                                  ? "bg-primary transform translate-x-6"
                                  : "bg-grayNobel "
                              } `}
                            ></div>
                          </div>
                          <p className={`text-sm font-normal pl-2 ${!userRole && 'text-grayDeep'}`}>
                              {userRole ? "Yes" : "No"}
                            </p>
                        </div>
                      </fieldset>
                      {errorFromApi !== null && (
                        <div
                          className={`${
                            errorFromApi?.type === "error"
                              ? "bg-floralWhite "
                              : "bg-aliceBlue"
                          } border border-grayLight p-3 mt-4 flex items-center flex-row overflow-x-auto`}
                        >
                          <div className="mr-2">
                            <BsFillExclamationSquareFill
                              color={
                                errorFromApi?.type === "error"
                                  ? "#FF5353"
                                  : "#000000"
                              }
                              className="text-2xl"
                            />
                          </div>
                          <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                        </div>
                      )}
                      <div className="flex my-6">
                        <button
                          type="submit"
                          className={`sm:w-4/6 border cursor-pointer text-white font-bold p-2 px-8 tracking-small ${
                            loading || !isUserUpdated || !validationPassed || errorFromApi || !isCurrentWebsiteActive
                              ? "pointer-events-none border-gray bg-gray"
                              : "border-primary bg-cyanBlue"
                          }`}
                        >
                          {loading ? (
                            <div className="flex flex-row justify-center items-center">
                              <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                              />
                              <span className="ml-4">Loading</span>
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
