// library imports
import { takeLatest, call, put, all } from "redux-saga/effects";

// user imports
import {
  fetchDashboardSuccess,
  fetchDashboardError,
  createWebsiteSuccess,
  createWebsiteError,
  createOrgSuccess,
  createOrgError,
  updateOrgSuccess,
  updateOrgError,
  updateOrgWebsitesSuccess,
  updateOrgWebsitesError,
  removeOrgWebsitesSuccess,
  removeOrgWebsitesError,
  fetchReportSuccess,
  fetchReportError,
  fetchPageReportSuccess,
  fetchPageReportError,
  runAuditSuccess,
  runAuditError,
  createUserSuccess,
  createUserError,
  getUsersSuccess,
  getUsersError,
  editUserSuccess,
  editUserError,
  mapUserWebsitesSuccess,
  mapUserWebsitesError,
  getElementsListSuccess,
  getElementsListError,
  getElementOccurrenceSuccess,
  getElementOccurrenceError,
  updateElementSuccess,
  updateElementError,
  getDashboardDataSuccess,
  getDashboardDataError,
  getSiteAnalyticsDataSuccess,
  getSiteAnalyticsDataError,
  verifyUserSuccess,
  verifyUserError,
  getWebsiteSettingsSuccess,
  getWebsiteSettingsError,
  editWebsiteSettingsSuccess,
  editWebsiteSettingsError,
  restoreWebsiteSuccess,
  restoreWebsiteError,
  getAuditDataSuccess,
  getAuditDataError,
  getAuditStageSuccess,
  getAuditStageError,
  getActiveSubscriptionSuccess,
  getActiveSubscriptionError,
  getWorkspaceSitesSuccess,
  getWorkspaceSitesError,
  getSiteDataSuccess,
  getSiteDataError,
  editWorkspaceSuccess,
  editWorkspaceError,
  getWorkspaceSiteDataSuccess,
  getWorkspaceSiteDataError,
  getWorkspaceUsersSuccess,
  getWorkspaceUsersError,
  shareReportSuccess,
  shareReportError,
  getAuditScoreSuccess,
  getAuditScoreError,
  getAuditRuleSuccess,
  getAuditRuleError,
  getSignatureSuccess,
  getSignatureError
} from "../actions/dashboard";

// axios api call
import {
  createOrganization,
  updateOrganization,
  dashboard,
  siteReport,
  pageReport,
  createSite,
  runAudit,
  addUser,
  mapUserWithWebsite,
  userProfile,
  getUsers as fetchUsersApi,
  editUser as editUserApi,
  getElementsListApi,
  getElementOccurrenceApi,
  removeSite,
  updateElementApi,
  getDashboardData as getDashboardDataApi,
  getSiteAnalyticsData as getSiteAnalyticsDataApi,
  verifyUser as verifyUserApi,
  getWebsiteSettings as fetchWebsiteSettingsApi,
  editWebsiteSettings as editWebsiteSettingsApi,
  restoreWebsite as restoreWebsiteApi,
  getAuditData as fetchAuditDataApi,
  getAuditScore as fetchAuditScoreApi,
  getAuditRule as fetchAuditRuleApi,
  getAuditStage as fetchAuditStageApi,
  getActiveSubscription as fetchActiveSubscriptionApi,
  getWorkspaceSites as fetchWorkspaceSitesApi,
  getSiteData as fetchSiteDataApi,
  getSiteDataByRole as fetchSiteDataByRoleApi,
  editWorkspace as editWorkspaceApi,
  getWorkspaceSiteData as fetchWorkspaceSiteDataApi,
  getWorkspaceUsersData as fetchWorkspaceUsersApi,
  shareReport as shareReportApi,
  getSignature as fetchSignatureApi,
} from "../../api/dashboard";

// action types
import actionTypes from "../types";

// destructuring
const {
  DASHBOARD,
  CREATE_WEBSITE,
  CREATE_ORG,
  UPDATE_ORG,
  UPDATE_ORG_WEBSITES,
  REPORT,
  PAGE_REPORT,
  AUDIT,
  CREATE_USER,
  GET_USERS,
  EDIT_USER,
  MAP_USER_WEBSITES,
  GET_ELEMENTS_LIST,
  GET_ELEMENT_OCCURRENCE,
  REMOVE_ORG_WEBSITES,
  UPDATE_ELEMENT,
  GET_DASHOARD_DATA,
  GET_SITE_ANALYTICS_DATA,
  VERIFY_USER,
  GET_WEBSITE_SETTINGS,
  EDIT_WEBSITE_SETTINGS,
  RESTORE_WEBSITE,
  GET_AUDIT_DATA,
  GET_AUDIT_STAGE,
  GET_ACTIVE_SUBSCRIPTION,
  GET_WORKSPACE_SITES,
  GET_SITE_DATA,
  EDIT_WORKSPACE,
  GET_WORKSPACE_SITE_DATA,
  GET_WORKSPACE_USERS,
  SHARE_REPORT,
  GET_AUDIT_SCORE,
  GET_AUDIT_RULE,
  GET_SIGNATURE
} = actionTypes.dashboard;

export function* fetchDashboard(action) {
  const { onError, onSuccess } = action.payload;
  try {
    const [dashboardResponse, getUserProfileResponse] = yield all([
      call(dashboard),
      call(userProfile),
    ]);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    if (getUserProfileResponse.code !== 200) {
      const errorMsg = getUserProfileResponse?.response?.status === 401? getUserProfileResponse?.response?.data?.message: getUserProfileResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(
      fetchDashboardSuccess({ dashboardResponse, getUserProfileResponse })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(fetchDashboardError(error));
  }
}

export function* createWebsite(action) {
  const { url, name, organization, onSuccess, onError } = action.payload;
  const requestObj = { url, organization, name };
  try {
    // create website API call
    const createWebsiteResponse = yield call(createSite, requestObj);
    if (createWebsiteResponse.code !== 200) {
      const errorMsg = createWebsiteResponse?.response?.status === 401? createWebsiteResponse?.response?.data?.message: createWebsiteResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const runAuditRequestPayload = {
      orgId: organization,
      site: createWebsiteResponse?.data?.message?.url || url,
      siteId: createWebsiteResponse?.data?.message?.id,
    };
    // make API call to run audit
    const runAuditResponse = yield call(runAudit, runAuditRequestPayload);
    if (runAuditResponse.code !== 200) {
      const errorMsg = runAuditResponse?.response?.status === 401? runAuditResponse?.response?.data?.message: runAuditResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(createWebsiteSuccess(dashboardResponse));
    onSuccess();
  } catch (error) {
    onError && onError(error.message);
    yield put(createWebsiteError(error));
  }
}

export function* createOrg(action) {
  const { onSuccess, onError } = action.payload;
  try {
    const response = yield call(createOrganization, action.payload.orgObj);
    if (response.code !== 200) {
      let errorMsg = response?.response?.data?.data?.message;
      errorMsg =
        typeof errorMsg === "string" ? errorMsg : "Something went wrong";
      throw new Error(
        response?.response?.data?.statusCode === 401 ?
        response?.response?.data?.message
        : `Org creation failed: ${errorMsg}`
      );
    }

    // make APi call to create website if website URL is passed in payload
    if (action.payload.websiteObj?.url?.length > 0) {
      let obj = action.payload.websiteObj;
      obj.organization = response.data.message.id;
      const websiteResponse = yield call(createSite, action.payload.websiteObj);
      if (websiteResponse.code !== 200) {
        let websiteFailedMsg = websiteResponse?.response?.data?.data?.message;
        websiteFailedMsg =
          typeof websiteFailedMsg === "string"
            ? websiteFailedMsg
            : "Something went wrong";
        throw new Error(
          websiteResponse?.response?.data?.statusCode === 401 ?
          websiteResponse?.response?.data?.message
          : `Add website failed: ${websiteFailedMsg}`
        );
      }

      // create website succeeded here
      const runAuditRequestPayload = {
        orgId: response.data.message.id,
        site: websiteResponse?.data?.message?.url || action?.payload?.websiteObj?.url,
        siteId: websiteResponse?.data?.message?.id,
      };
      // make API call to run audit
      const runAuditResponse = yield call(runAudit, runAuditRequestPayload);
      if (runAuditResponse.code !== 200) {
        const errorMsg = runAuditResponse?.response?.status === 401? runAuditResponse?.response?.data?.message: runAuditResponse?.response?.data?.data?.message;
        throw new Error(
          typeof errorMsg === "string" ? errorMsg : "Something went wrong"
        );
      }
    }

    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(createOrgSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(createOrgError(error));
  }
}

export function* fetchUpdateOrg(action) {
  const { onSuccess, onError } = action.payload;
  const orgPayload = {
    ...action.payload.orgObj,
    id: action.payload.websiteObj.organization,
  };
  try {
    const response = yield call(updateOrganization, orgPayload);
    if (response.code !== 200) {
      let errorMsg = response?.response?.data?.data?.message;
      errorMsg =
        typeof errorMsg === "string" ? errorMsg : "Something went wrong";
      throw new Error(
        response?.response?.data?.statusCode === 401 ?
        response?.response?.data?.message
        : `Org creation failed: ${errorMsg}`
      );
    }

    // make APi call to create website if website URL is passed in payload
    if (action.payload.websiteObj?.url?.length > 0) {
      const websiteResponse = yield call(createSite, action.payload.websiteObj);
      if (websiteResponse.code !== 200) {
        let websiteFailedMsg = websiteResponse?.response?.data?.data?.message;
        websiteFailedMsg =
          typeof websiteFailedMsg === "string"
            ? websiteFailedMsg
            : "Something went wrong";
        throw new Error(
          websiteResponse?.response?.data?.statusCode === 401 ?
          websiteResponse?.response?.data?.message
          : `Add website failed: ${websiteFailedMsg}`
        );
      }
    }

    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(updateOrgSuccess(dashboardResponse));
    onSuccess(response?.data?.message);
  } catch (error) {
    onError && onError(error.message);
    yield put(updateOrgError(error));
  }
}

export function* fetchUpdateOrgWebsites(action) {
  const { onSuccess, onError } = action.payload;
  try {
    const websiteResponse = yield call(createSite, action.payload.websiteObj);
    if (websiteResponse.code !== 200) {
      let websiteFailedMsg = websiteResponse?.response?.data?.data?.message;
      websiteFailedMsg =
        typeof websiteFailedMsg === "string"
          ? websiteFailedMsg
          : "Something went wrong";
      throw new Error(
        websiteResponse?.response?.data?.statusCode === 401 ?
        websiteResponse?.response?.data?.message
        : `Add website failed: ${websiteFailedMsg}`
      );
    }
    const runAuditRequestPayload = {
      orgId: action?.payload?.websiteObj?.organization,
      site: websiteResponse?.data?.message?.url || action?.payload?.websiteObj?.url,
      siteId: websiteResponse?.data?.message?.id,
    };
    const runAuditResponse = yield call(runAudit, runAuditRequestPayload);
    if (runAuditResponse.code !== 200) {
      const errorMsg = runAuditResponse?.response?.status === 401? runAuditResponse?.response?.data?.message: runAuditResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(websiteResponse?.data?.message);
    yield put(updateOrgWebsitesSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(updateOrgWebsitesError(error));
  }
}

export function* fetchRemoveOrgWebsites(action) {
  const { webSiteRemoveSuccess, webSiteRemoveError } = action.payload;
  try {
    const websiteResponse = yield call(removeSite, action.payload);
    if (websiteResponse.code !== 200) {
      let websiteFailedMsg = websiteResponse?.response?.data?.data?.message;
      websiteFailedMsg =
        typeof websiteFailedMsg === "string"
          ? websiteFailedMsg
          : "Something went wrong";
      throw new Error(
        websiteResponse?.response?.data?.statusCode === 401 ?
        websiteResponse?.response?.data?.message
        : `Add website failed: ${websiteFailedMsg}`
      );
    }

    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    webSiteRemoveSuccess(websiteResponse?.data?.message);
    yield put(removeOrgWebsitesSuccess(dashboardResponse));
  } catch (error) {
    webSiteRemoveError && webSiteRemoveError(error.message);
    yield put(removeOrgWebsitesError(error));
  }
}

export function* fetchReport(action) {
  const { onSuccess1, onSuccess2, onError, ...requestObj } = action.payload;
  try {
    // make api call to fetch report -> withax=false
    // make api call to fetch report -> withax=true
    // make 2 API calls in parallel
    // whichever is ready, return it to UI soon, so that UI shows it instantly
    const [response1, response2] = yield all([
      call(siteReport, requestObj),
      call(siteReport, { ...requestObj, withax: true }),
    ]);

    if (response1.code !== 200) {
      const errorMsg = response1?.response?.status === 401? response1?.response?.data?.message: response1?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess1(response1?.data);

    if (response2.code !== 200) {
      const errorMsg = response2?.response?.status === 401? response2?.response?.data?.message: response2?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess2(response2?.data);

    yield put(
      fetchReportSuccess({
        response1: response1?.data,
        response2: response2?.data,
        payload: requestObj,
      })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(fetchReportError(error));
  }
}

export function* fetchPageLevelReport(action) {
  const { onSuccess, onError, ...requestObj } = action.payload;
  try {
    // make api call to fetch report
    const response = yield call(pageReport, requestObj);
    if (response.code !== 200) {
      const errorMsg = response?.response?.status === 401? response?.response?.data?.message: response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(response?.data);
    yield put(
      fetchPageReportSuccess({ response: response?.data, payload: requestObj })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(fetchPageReportError(error));
  }
}

export function* fetchElementsList(action) {
  const { onSuccess, onError, requestObj } = action.payload;
  try {
    // make api call to fetch elements list
    const response = yield call(getElementsListApi, requestObj);
    if (response.code !== 200) {
      const errorMsg = response?.response?.status === 401? response?.response?.data?.message: response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(response?.data);
    yield put(
      getElementsListSuccess({ response: response?.data, payload: requestObj })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(getElementsListError(error));
  }
}

export function* fetchElementOccurrence(action) {
  const { onSuccess, onError, requestObj } = action.payload;
  try {
    // make api call to fetch elements list
    const response = yield call(getElementOccurrenceApi, requestObj);
    if (response.code !== 200) {
      const errorMsg = response?.response?.status === 401? response?.response?.data?.message: response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(response?.data?.result);
    yield put(
      getElementOccurrenceSuccess({ occurrenceData: response?.data?.result })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(getElementOccurrenceError(error));
  }
}

export function* updateIssueElement(action) {
  const { onSuccess, onError, payload_1, payload_2 } = action.payload;
  try {
    // make api call to update element
    const updateElementResponse = yield call(updateElementApi, payload_1);
    if (updateElementResponse.code !== 200) {
      const errorMsg = updateElementResponse?.response?.status === 401? updateElementResponse?.response?.data?.message: updateElementResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    // make api call to fetch the fresh & updated elements list
    const response = yield call(getElementsListApi, payload_2);
    if (response.code !== 200) {
      const errorMsg = response?.response?.status === 401? response?.response?.data?.message: response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(response?.data);
    yield put(
      updateElementSuccess({ response: response?.data, payload: payload_2 })
    );
  } catch (error) {
    onError && onError(error.message);
    yield put(updateElementError(error));
  }
}

export function* fetchRunAudit(action) {
  const { onSuccess, onError, ...requestObj } = action.payload;
  try {
    // make API call to run audit
    const runAuditResponse = yield call(runAudit, requestObj);
    if (runAuditResponse.code !== 200) {
      const errorMsg = runAuditResponse?.response?.status === 401? runAuditResponse?.response?.data?.message: runAuditResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    // fetch dsahboard APi call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(runAuditResponse?.data?.jobId);
    yield put(runAuditSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(runAuditError(error));
  }
}

export function* createUser(action) {
  const { payload_1, onSuccess, onError } = action.payload;
  try {
    // add user API call
    const adduserResponse = yield call(addUser, payload_1);
    if (adduserResponse.code !== 200) {
      const errorMsg = adduserResponse?.response?.status === 401? adduserResponse?.response?.data?.message: adduserResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    // dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(createUserSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(createUserError(error));
  }
}

export function* mapUserWebsites(action) {
  const { requestObj, onSuccess, onError } = action.payload;
  try {
    // map user API call
    const mapUserPayload = {
      userId: requestObj.userId,
      websiteIds: requestObj.websiteIds,
    };
    const mapUserResponse = yield call(mapUserWithWebsite, mapUserPayload);
    if (mapUserResponse.code !== 200) {
      const errorMsg = mapUserResponse?.response?.status === 401? mapUserResponse?.response?.data?.message: mapUserResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    //get users API call
    const getUsersResponse = yield call(fetchUsersApi);
    if (getUsersResponse.code !== 200) {
      const errorMsg = getUsersResponse?.response?.status === 401? getUsersResponse?.response?.data?.message: getUsersResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(getUsersSuccess(getUsersResponse));

    // dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(mapUserWebsitesSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(mapUserWebsitesError(error));
  }
}

export function* fetchUsers(action) {
  const { onSuccess, onError } = action.payload;
  try {
    //get users API call
    const getUsersResponse = yield call(fetchUsersApi);
    if (getUsersResponse.code !== 200) {
      const errorMsg = getUsersResponse?.response?.status === 401? getUsersResponse?.response?.data?.message: getUsersResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(getUsersSuccess(getUsersResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(getUsersError(error));
  }
}

export function* fetchEditUser(action) {
  const { payload_1, onSuccess, onError } = action.payload;
  try {
    // edit user API call
    const editUserResponse = yield call(editUserApi, payload_1);
    if (editUserResponse.code !== 200) {
      const errorMsg = editUserResponse?.response?.status === 401? editUserResponse?.response?.data?.message: editUserResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    //get users API call
    const getUsersResponse = yield call(fetchUsersApi);
    if (getUsersResponse.code !== 200) {
      const errorMsg = getUsersResponse?.response?.status === 401? getUsersResponse?.response?.data?.message: getUsersResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    // dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const userProfileRes = yield call(userProfile);
    if (userProfileRes.code !== 200) {
      const errorMsg = userProfileRes?.response?.status === 401? userProfileRes?.response?.data?.message: userProfileRes?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(
      `User has been ${
        payload_1.isActive ? "activated" : "deactivated"
      } successfully !`
    );
    yield put(editUserSuccess([getUsersResponse, userProfileRes]));
  } catch (error) {
    onError && onError(error.message);
    yield put(editUserError(error));
  }
}

export function* fetchDashboardData(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    //get users API call
    const getDashboardResponse = yield call(getDashboardDataApi, payload);
    if (getDashboardResponse.code !== 200) {
      const errorMsg = getDashboardResponse?.response?.status === 401? getDashboardResponse?.response?.data?.message: getDashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess();
    yield put(getDashboardDataSuccess(getDashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(getDashboardDataError(error));
  }
}

export function* fetchSiteAnalyticsData(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getSiteAnalyticsResponse = yield call(getSiteAnalyticsDataApi, payload);
    if (getSiteAnalyticsResponse.code !== 200) {
      const errorMsg = getSiteAnalyticsResponse?.response?.status === 401? getSiteAnalyticsResponse?.response?.data?.message: getSiteAnalyticsResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(getSiteAnalyticsResponse.data);
    yield put(getSiteAnalyticsDataSuccess(getSiteAnalyticsResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(getSiteAnalyticsDataError(error));
  }
}

export function* verifyUser(action) {
  const { onSuccess, onError } = action.payload;
  try {
    //Verify user API call
    const verifyUserResponse = yield call(
      verifyUserApi,
      action.payload
    );
    if (verifyUserResponse.code !== 200) {
      const errorMsg = verifyUserResponse?.response?.status === 401? verifyUserResponse?.response?.data?.message: verifyUserResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    // Run Audit
    const analyzerResponse = verifyUserResponse?.data?.wallyax;
    if (analyzerResponse) {
      const runAuditRequestPayload = {
        orgId: action?.payload?.auditObj?.orgId,
        site: action?.payload?.auditObj?.url,
        siteId: action?.payload?.auditObj?.siteId,
      };
      const runAuditResponse = yield call(runAudit, runAuditRequestPayload);
      if (runAuditResponse.code !== 200) {
        const errorMsg = runAuditResponse?.response?.status === 401? runAuditResponse?.response?.data?.message: runAuditResponse?.response?.data?.data?.message;
        throw new Error(
          typeof errorMsg === "string" ? errorMsg : "Something went wrong"
        );
      }
    } else {
      throw new Error("Please embed the Wally Script and Verify Installation");
    }
    // fetch dashboard API call
    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess();
    yield put(verifyUserSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(verifyUserError(error));
  }
}

export function* fetchWebsiteSettings(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    //get Website settings API call
    const getWebsiteSettingsResponse = yield call(fetchWebsiteSettingsApi, payload);
    if (getWebsiteSettingsResponse.status !== 200) {
      const errorMsg = getWebsiteSettingsResponse?.response?.status === 401? getWebsiteSettingsResponse?.response?.data?.message: getWebsiteSettingsResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getWebsiteSettingsResponse.data);
    yield put(getWebsiteSettingsSuccess(getWebsiteSettingsResponse.data));
  } catch (error) {
    onError && onError(error.message);
    yield put(getWebsiteSettingsError(error));
  }
}

export function* editWebsiteSettings(action) {
  const { payload, payload_1, onSuccess, onError } = action.payload;
  try {
    // edit Website settings API call
    const editWebsiteSettingsRes = yield call(editWebsiteSettingsApi, payload, payload_1);
    if (editWebsiteSettingsRes.status !== 200) {
      const errorMsg = editWebsiteSettingsRes?.response?.status === 401? editWebsiteSettingsRes?.response?.data?.message: editWebsiteSettingsRes?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const getWebsiteSettingsResponse = yield call(fetchWebsiteSettingsApi, payload);
    if (getWebsiteSettingsResponse.status !== 200) {
      const errorMsg = getWebsiteSettingsResponse?.response?.status === 401? getWebsiteSettingsResponse?.response?.data?.message: getWebsiteSettingsResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }


    onSuccess(getWebsiteSettingsResponse.data);
    yield put(editWebsiteSettingsSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(editWebsiteSettingsError(error));
  }
}

export function* restoreWebsite(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const restoreWebsiteRes = yield call(restoreWebsiteApi, payload);
    if (restoreWebsiteRes.status !== 200) {
      const errorMsg = restoreWebsiteRes?.response?.status === 401? restoreWebsiteRes?.response?.data?.message: restoreWebsiteRes?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(restoreWebsiteRes?.data);
    yield put(restoreWebsiteSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(restoreWebsiteError(error));
  }
}

export function* fetchAuditData(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    //get Website settings API call
    const getAuditDataResponse = yield call(fetchAuditDataApi, payload);
    if (getAuditDataResponse.status !== 200) {
      const errorMsg = getAuditDataResponse?.response?.status === 401? getAuditDataResponse?.response?.data?.message: getAuditDataResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getAuditDataResponse.data.data.auditData);
    yield put(getAuditDataSuccess(getAuditDataResponse.data.data.auditData));
  } catch (error) {
    onError && onError(error.message);
    yield put(getAuditDataError(error));
  }
}

export function* fetchAuditScore(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getAuditScoreResponse = yield call(fetchAuditScoreApi, payload);
    if (getAuditScoreResponse.status !== 200) {
      const errorMsg = getAuditScoreResponse?.response?.status === 401? getAuditScoreResponse?.response?.data?.message: getAuditScoreResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getAuditScoreResponse.data.data);
    yield put(getAuditScoreSuccess(getAuditScoreResponse.data.data.accessorData));
  } catch (error) {
    onError && onError(error.message);
    yield put(getAuditScoreError(error));
  }
}

export function* fetchAuditRule(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getAuditRuleResponse = yield call(fetchAuditRuleApi, payload);
    if (getAuditRuleResponse.status !== 200) {
      const errorMsg = getAuditRuleResponse?.response?.status === 401? getAuditRuleResponse?.response?.data?.message: getAuditRuleResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getAuditRuleResponse.data.data);
    yield put(getAuditRuleSuccess(getAuditRuleResponse.data.data.accessorData));
  } catch (error) {
    onError && onError(error.message);
    yield put(getAuditRuleError(error));
  }
}

export function* fetchAuditStage(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getAuditStageResponse = yield call(fetchAuditStageApi, payload);
    if (getAuditStageResponse.status !== 200) {
      const errorMsg = getAuditStageResponse?.response?.status === 401? getAuditStageResponse?.response?.data?.message: getAuditStageResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getAuditStageResponse.data.data);
    yield put(getAuditStageSuccess(getAuditStageResponse.data.data));
  } catch (error) {
    onError && onError(error.message);
    yield put(getAuditStageError(error));
  }
}

export function* fetchActiveSubscription(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getActiveSubscriptionResponse = yield call(fetchActiveSubscriptionApi, payload);
    if (getActiveSubscriptionResponse.status !== 200) {
      const errorMsg = getActiveSubscriptionResponse?.response?.status === 401? getActiveSubscriptionResponse?.response?.data?.message: getActiveSubscriptionResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getActiveSubscriptionResponse?.data?.data?.plan);
    yield put(getActiveSubscriptionSuccess(getActiveSubscriptionResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(getActiveSubscriptionError(error));
  }
}

export function* fetchWorkspaceSites(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getWorkspaceSitesResponse = yield call(fetchWorkspaceSitesApi, payload);
    if (getWorkspaceSitesResponse.status !== 200) {
      const errorMsg = getWorkspaceSitesResponse?.response?.status === 401? getWorkspaceSitesResponse?.response?.data?.message: getWorkspaceSitesResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess();
    yield put(getWorkspaceSitesSuccess(getWorkspaceSitesResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(getWorkspaceSitesError(error));
  }
}

export function* fetchSiteData(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getSiteData = yield call(fetchSiteDataApi, payload);
    if (getSiteData.status !== 200) {
      const errorMsg = getSiteData?.response?.status === 401? getSiteData?.response?.data?.message: getSiteData?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const getSiteDataByRole = yield call(fetchSiteDataByRoleApi, payload);
    if (getSiteDataByRole.status !== 200) {
      const errorMsg = getSiteDataByRole?.response?.status === 401? getSiteDataByRole?.response?.data?.message: getSiteDataByRole?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getSiteDataByRole);
    yield put(getSiteDataSuccess(getSiteDataByRole));
  } catch (error) {
    onError && onError(error.message);
    yield put(getSiteDataError(error));
  }
}


export function* fetchEditWorkspace(action) {
  const { payload_1, onSuccess, onError } = action.payload;
  try {
    // edit user API call
    const editWorkspaceResponse = yield call(editWorkspaceApi, payload_1);
    if (editWorkspaceResponse.code !== 200) {
      const errorMsg = editWorkspaceResponse?.response?.status === 401? editWorkspaceResponse?.response?.data?.message: editWorkspaceResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    const dashboardResponse = yield call(dashboard);
    if (dashboardResponse.code !== 200) {
      const errorMsg = dashboardResponse?.response?.status === 401? dashboardResponse?.response?.data?.message: dashboardResponse?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess();
    yield put(editWorkspaceSuccess(dashboardResponse));
  } catch (error) {
    onError && onError(error.message);
    yield put(editWorkspaceError(error));
  }
}

export function* fetchWorkspaceSiteData(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getSiteData = yield call(fetchWorkspaceSiteDataApi, payload);
    if (getSiteData.status !== 200) {
      const errorMsg = getSiteData?.response?.status === 401? getSiteData?.response?.data?.message: getSiteData?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getSiteData.data.data);
    yield put(getWorkspaceSiteDataSuccess(getSiteData.data.data));
  } catch (error) {
    onError && onError(error.message);
    yield put(getWorkspaceSiteDataError(error));
  }
}

export function* fetchWorkspaceUsers(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const getWorkspaceUserdata = yield call(fetchWorkspaceUsersApi, payload);
    if (getWorkspaceUserdata.status !== 200) {
      const errorMsg = getWorkspaceUserdata?.response?.status === 401? getWorkspaceUserdata?.response?.data?.message: getWorkspaceUserdata?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getWorkspaceUserdata);
    yield put(getWorkspaceUsersSuccess(getWorkspaceUserdata));
  } catch (error) {
    onError && onError(error.message);
    yield put(getWorkspaceUsersError(error));
  }
}

export function* shareReport(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    const shareReportRes = yield call(shareReportApi, payload);
    if (shareReportRes.code !== 200) {
      const errorMsg = shareReportRes?.response?.status === 401? shareReportRes?.response?.data?.message: shareReportRes?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(shareReportRes);
    yield put(shareReportSuccess(shareReportRes));
  } catch (error) {
    onError && onError(error.message);
    yield put(shareReportError(error));
  }
}

export function* fetchSignature(action) {
  const { payload, onSuccess, onError } = action.payload;
  try {
    //get Website settings API call
    const getSignatureResponse = yield call(fetchSignatureApi, payload);
    if (getSignatureResponse.status !== 200) {
      const errorMsg = getSignatureResponse?.response?.status === 401? getSignatureResponse?.response?.data?.message: getSignatureResponse?.response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    onSuccess(getSignatureResponse.data.data);
    yield put(getSignatureSuccess(getSignatureResponse.data.data.accessorData));
  } catch (error) {
    onError && onError(error.message);
    yield put(getSignatureError(error));
  }
}

export function* saga() {
  yield takeLatest(DASHBOARD, fetchDashboard);
  yield takeLatest(CREATE_WEBSITE, createWebsite);
  yield takeLatest(CREATE_ORG, createOrg);
  yield takeLatest(UPDATE_ORG, fetchUpdateOrg);
  yield takeLatest(UPDATE_ORG_WEBSITES, fetchUpdateOrgWebsites);
  yield takeLatest(REMOVE_ORG_WEBSITES, fetchRemoveOrgWebsites);
  yield takeLatest(REPORT, fetchReport);
  yield takeLatest(PAGE_REPORT, fetchPageLevelReport);
  yield takeLatest(AUDIT, fetchRunAudit);
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(GET_USERS, fetchUsers);
  yield takeLatest(EDIT_USER, fetchEditUser);
  yield takeLatest(MAP_USER_WEBSITES, mapUserWebsites);
  yield takeLatest(GET_ELEMENTS_LIST, fetchElementsList);
  yield takeLatest(GET_ELEMENT_OCCURRENCE, fetchElementOccurrence);
  yield takeLatest(UPDATE_ELEMENT, updateIssueElement);
  yield takeLatest(GET_DASHOARD_DATA, fetchDashboardData);
  yield takeLatest(GET_SITE_ANALYTICS_DATA, fetchSiteAnalyticsData);
  yield takeLatest(VERIFY_USER, verifyUser);
  yield takeLatest(GET_WEBSITE_SETTINGS, fetchWebsiteSettings);
  yield takeLatest(EDIT_WEBSITE_SETTINGS, editWebsiteSettings);
  yield takeLatest(RESTORE_WEBSITE, restoreWebsite);
  yield takeLatest(GET_AUDIT_DATA, fetchAuditData);
  yield takeLatest(GET_AUDIT_SCORE, fetchAuditScore);
  yield takeLatest(GET_AUDIT_RULE, fetchAuditRule);
  yield takeLatest(GET_AUDIT_STAGE, fetchAuditStage);
  yield takeLatest(GET_ACTIVE_SUBSCRIPTION, fetchActiveSubscription);
  yield takeLatest(GET_WORKSPACE_SITES, fetchWorkspaceSites);
  yield takeLatest(GET_SITE_DATA, fetchSiteData);
  yield takeLatest(EDIT_WORKSPACE, fetchEditWorkspace);
  yield takeLatest(GET_WORKSPACE_SITE_DATA, fetchWorkspaceSiteData);
  yield takeLatest(GET_WORKSPACE_USERS, fetchWorkspaceUsers);
  yield takeLatest(SHARE_REPORT, shareReport);
  yield takeLatest(GET_SIGNATURE, fetchSignature);
}

export function* dashboardSagas() {
  yield all([call(saga)]);
}
