/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

// constants
import { RoutingConstants, Strings } from "../../constants";

// actions
import { changePassword, logOut } from "../../store/actions/auth";

// icons
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../PieLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
/* const override = css`
  border-color: "white";
`; */

/**
 * @function Security
 * @returns {JSX}
 */
const Security = (props) => {
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  // destructure the props
  const { triggerChangePassword, loggedIn, accessToken } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  // state variables
  const initState = {
    password: "",
    cpassword: "",
  };
  const [userData, setUserData] = useState(initState);
  const [disable, setDisable] = useState(false);

  // When not logged in, navigate to Login screen
  // (By chance if the user types "/password/change" route directly in the URL)
  useEffect(() => {
    if (!loggedIn || !accessToken) {
      window.location.replace(RoutingConstants.login);
    }
  }, [loggedIn, accessToken]);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username textinput
   */
  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("password");
    const inputTagElement_2 = document.getElementById("cpassword");
    // If the user edits the input box, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const inputsValidated =
    !errors?.cpassword?.message &&
    userData.password.length > 0 &&
    userData.cpassword.length > 0 &&
    userData.password === userData.cpassword;

  const submit = () => {
    setLoading(true);
    if (!inputsValidated) {
      return;
    }
    setDisable(true);
    const payload = {
      apiPayload: {
        password: userData.password,
      },
      onSuccess: (msg) => {
        setLoading(false);
        setErrorFromApi({
          type: "success",
          msg: msg || "Successful",
        });
      },
      onError: (msg) => {
        setLoading(false);
        setErrorFromApi({
          type: "error",
          msg: msg || "Something went wrong!!!",
        });
      },
    };
    triggerChangePassword(payload);
  };

  return (
    <div className="max-w-full sm:p-4">
      <div className="grid lg:grid-cols-2">
        <div className="w-full sm:max-w-md">
          <form onSubmit={handleSubmit(submit)} className="w-full">
            <fieldset className="w-full m-auto text-lg mb-7">
              <div className="text-lg text-grayDeep font-semibold mb-2">
                New Password
              </div>
              <input
                type="password"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] py-3 px-4 font-medium ${
                  errors.password ? "border-red-500" : "border-gray"
                }`}
                placeholder="Enter the password"
                id="password"
                name="password"
                value={userData.password}
                {...register("password", {
                  required: {
                    value: true,
                    message: "* Password is mandatory",
                  },
                  maxLength: {
                    value: 60,
                    message: "* Maximum 60 characters allowed",
                  },
                  minLength: {
                    value: 8,
                    message: "* Minimum 8 characters required",
                  },
                })}
                onChange={(e) => onChangeHandler(e)}
              />
              {errors.password && (
                <span className="text-xs text-red-500 font-semibold">
                  {errors.password.message}
                </span>
              )}
            </fieldset>
            <fieldset className="w-full m-auto text-lg mb-7">
              <div className="text-lg text-grayDeep font-semibold mb-2">
                Confirm New Password
              </div>
              <input
                type="password"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] py-3 px-4 font-medium ${
                  errors.cpassword ? "border-red-500" : "border-gray"
                }`}
                placeholder="Re-enter the password"
                id="cpassword"
                name="cpassword"
                {...register("cpassword", {
                  required: {
                    value: true,
                    message: "* Please confirm password",
                  },
                  maxLength: {
                    value: 60,
                    message: "* Maximum 60 characters allowed",
                  },
                  minLength: {
                    value: 8,
                    message: "* Minimum 8 characters required",
                  },
                })}
                onChange={(e) => onChangeHandler(e)}
              />
              {!errors?.cpassword?.message &&
                userData.password.length > 0 &&
                userData.cpassword.length > 0 &&
                userData.password !== userData.cpassword && (
                  <span className="text-xs text-red-500 font-semibold">
                    {"* Passwords do not match"}
                  </span>
                )}
              {errors.cpassword && (
                <span className="text-xs text-red-500 font-semibold">
                  {errors.cpassword.message}
                </span>
              )}
            </fieldset>
            {errorFromApi !== null && (
              <div
                className={`${
                  errorFromApi?.type === "error"
                    ? "bg-floralWhite "
                    : "bg-aliceBlue"
                } border border-grayLight my-8 p-3 mt-4 flex items-center flex-row overflow-x-auto`}
              >
                <div className="mr-2">
                  <BsFillExclamationSquareFill
                    color={
                      errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                    }
                    className="text-2xl"
                  />
                </div>
                <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
              </div>
            )}
            <button
              disabled={disable || errorFromApi}
              // type="submit"
              type="button"
              className={`sm:w-4/6 border cursor-pointer text-white font-bold p-2 px-8 tracking-small ${
                loading || !inputsValidated
                  ? "pointer-events-none border-gray bg-gray"
                  : "border-cyanBlue bg-cyanBlue  hover:bg-secondary"
              }`}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                  <span className="ml-4">{Strings.loading}</span>
                </div>
              ) : (
                Strings.submit
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.accessToken,
    loading: state.auth.loading,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerChangePassword: changePassword,
  triggerLogout: logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(Security);
