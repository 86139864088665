// user imports
import actionTypes from "../types";

// destructuring
const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VALIDATE_OTP,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  VERIFY_USER_EMAIL,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR
} = actionTypes.auth;

// if it is api call / request, make it as payload
export const logIn = (payload) => {
  return {
    type: LOGIN,
    payload,
  };
};

// if it is api call success, make it as response
export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    response,
  };
};

// if it is api call error, make it as error
export const loginError = (error) => {
  return {
    type: LOGIN_ERROR,
    error,
  };
};

export const logOut = () => {
  return {
    type: LOGOUT,
  };
};

export const sendOtp = (payload) => {
  return {
    type: SEND_OTP,
    payload,
  };
};

export const sendOtpSuccess = (response) => {
  return {
    type: SEND_OTP_SUCCESS,
    response,
  };
};

export const sendOtpError = (error) => {
  return {
    type: SEND_OTP_ERROR,
    error,
  };
};

export const validateOtp = (payload) => {
  return {
    type: VALIDATE_OTP,
    payload,
  };
};

export const validateOtpSuccess = (response) => {
  return {
    type: VALIDATE_OTP_SUCCESS,
    response,
  };
};

export const validateOtpError = (error) => {
  return {
    type: VALIDATE_OTP_ERROR,
    error,
  };
};

export const resetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const resetPasswordSuccess = (response) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    response,
  };
};

export const resetPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_ERROR,
    error,
  };
};

export const changePassword = (payload) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
  };
};

export const changePasswordSuccess = (response) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    response,
  };
};

export const changePasswordError = (error) => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const updatePassword = (payload) => {
  return {
    type: UPDATE_PASSWORD,
    payload,
  };
};

export const updatePasswordSuccess = (response) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    response,
  };
};

export const updatePasswordError = (error) => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    error,
  };
};

export const register = (payload) => {
  return {
    type: REGISTER,
    payload,
  };
};

export const registerSuccess = (response) => {
  return {
    type: REGISTER_SUCCESS,
    response,
  };
};

export const registerError = (error) => {
  return {
    type: REGISTER_ERROR,
    error,
  };
};

export const verifyUserEmail = (payload) => {
  return {
    type: VERIFY_USER_EMAIL,
    payload,
  };
};

export const verifyUserEmailSuccess = (response) => {
  return {
    type: VERIFY_USER_EMAIL_SUCCESS,
    response,
  };
};

export const verifyUserEmailError = (error) => {
  return {
    type: VERIFY_USER_EMAIL_ERROR,
    error,
  };
};
