/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import posthog from 'posthog-js'

// Actions
import { runAudit } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";
import PieLoader from "../../../components/PieLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// token expired handler
import TokenExpired from "../../../utils/TokenExpired";
import UpgradePlan from "../../../components/plan/upgradePlan";

/**
 * @function ViewUser
 * @returns {JSX}
 */
const GeneralInfo = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  // state variables
  const [loading, setLoading] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(null);
  // destructure props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedOrg, triggerRunAudit, triggerLogout, activeSubscription, newRunAudit } = props;
  let reports = selectedOrg?.websites?.find(
    (w) => w.id.toString() === id
  )?.reports;
  const isCurrentWebsiteActive = selectedOrg?.isActive;
  let website = selectedOrg.websites.find((w) => w.id.toString() === id);
  const onSuccess = (jobId) => {
    posthog.capture('Ran audit successfully', { property: 'value' })
    setErrorFromApi({
      type: "success",
      msg: "Audit initiated Successfully!!!",
    });
    newRunAudit(jobId);
    setLoading(false);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };
  const onError = (errorMsg) => {
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };
  const RunAudit = () => {
    setLoading(true);
    if (!isCurrentWebsiteActive) {
      setErrorFromApi({
        type: "error",
        msg: "Cannot run audit from an archived organization!!!",
      });
      setTimeout(() => {
        setErrorFromApi(null);
      }, 3000);
      setLoading(false);
      return;
    }
    const postObj = {
      orgId: selectedOrg?.id,
      site: website?.url,
      siteId: website?.id,
      onSuccess,
      onError,
    };
    triggerRunAudit(postObj);
  };
  const handleMenuItemClick = (val, index) => {
    navigate(`/website/${id}/reports/${val.jobId}`);
  };
  return (
    <section>
      {activeSubscription?.config?.audit_analytics ?
        <button
          className={`${
            loading
              && "pointer-events-none"
          } bg-primary text-white font-semibold py-2 px-4 w-full ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}`}
          onClick={RunAudit}
        >
          {loading ? (
            <div className="flex flex-row justify-center items-center">
              <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
              <span className="ml-4">Loading</span>
            </div>
          ) : (
            `RUN AUDIT`
          )}
        </button>
        :
        <UpgradePlan
          content={`<button className="bg-primary text-white font-semibold py-2 px-4 w-full ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}">RUN AUDIT</button>`}
        />
      }
      <div id="modal" className="text-black">
        <div className={`overflow-x-hidden outline-none focus:outline-none ${reports.length > 0? 'h-80': 'h-91'}`}>
          <div className="relative p-4">
          {errorFromApi !== null && (
            <div
              className={`${
                errorFromApi?.type === "error"
                  ? "bg-floralWhite "
                  : "bg-aliceBlue"
              } border border-grayLight p-3 mb-6 flex flex-row overflow-x-auto`}
            >
              <div className="mr-2">
                <BsFillExclamationSquareFill
                  color={
                    errorFromApi?.type === "error"
                      ? "#FF5353"
                      : "#000000"
                  }
                  className="text-2xl"
                />
              </div>
              <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
            </div>
          )}
            <div className="w-full">
              {reports
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice()
                .map((val, index) => (
                  <div
                    className={`border-b border-grayBorder flex flex-col cursor-pointer ${index === 0? 'pb-1': 'py-2'}`}
                    key={index}
                    onClick={() => handleMenuItemClick(val, index)}
                  >
                    <span
                      style={{ fontFamily: `Raleway, sans-serif` }}
                      className="font-medium text-lg"
                    >
                      {`Audit #${reports.length - index}`}
                    </span>
                    <span
                      style={{
                        fontSize: 10,
                        fontFamily: `Raleway, sans-serif`,
                      }}
                      className="overflow-x-auto"
                    >
                      {`${moment(val.createdAt + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
    activeSubscription: state.dashboard.activeSubscription,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  triggerRunAudit: runAudit,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
