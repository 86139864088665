/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BsPlusLg } from "react-icons/bs";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useParams, Link, useNavigate } from "react-router-dom";

// constants
import { RoutingConstants, Strings } from "../../constants";

// components
import DefaultPage from "../../components/layout/defaultPage";

const ViewOrganization = (props) => {
  const { isTempPassword } = props;
  const navigate = useNavigate();

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  const { id } = useParams();
  const { orgsList, userProfile, roleId } = props;
  const [viewTab, setViewTab] = useState(0);

  let selectedOrg = null;
  if (orgsList.length > 0) {
    selectedOrg = orgsList.filter((org) => org.id === parseInt(id));
  }

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section className="grid grid-cols-2 flex flex-wrap items-center border-b border-grayLight p-6 py-4 font-semibold">
        <Link
          className={"flex items-center"}
          to={RoutingConstants.organization.orgRoot}
        >
          <MdOutlineKeyboardBackspace className="text-2xl mr-2 cursor-pointer" />
          {selectedOrg.map((i) => i.name)}
        </Link>
        {roleId === 0 && (
          <div className="text-right">
            <Link
              className={
                "bg-primary px-4 py-2 text-white flex w-max items-center ml-auto"
              }
              to={RoutingConstants.organization.add}
            >
              <BsPlusLg className="mr-3 text-xs" />
              {Strings.addNew}
            </Link>
          </div>
        )}
      </section>
      <section style={{ maxHeight: "95vh" }} className="overflow-y-auto">
        <div
          style={{ minHeight: "85vh" }}
          className="border border-grayLight m-4"
        >
          <div className="border-b border-grayLight px-6">
            <button
              className={`border-b-2 font-semibold py-3 mr-10 ${
                viewTab === 0
                  ? "border-primary text-primary"
                  : "border-transparent text-gray"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setViewTab(0);
              }}
            >
              {Strings.basicDetails}
            </button>
            <button
              className={`border-b-2 font-semibold py-3 mr-10 ${
                viewTab === 1
                  ? "border-primary text-primary"
                  : "border-transparent text-gray"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setViewTab(1);
              }}
            >
              {Strings.websites}
            </button>
          </div>
          {selectedOrg.map((i, index) => {
            return (
              <section key={index.toString()}>
                {viewTab === 0 ? (
                  <div className="grid sm:grid-cols-3 gap-8 p-4">
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark">{Strings.emailId}</h6>
                      <p className="">{i.email || "-"}</p>
                    </div>
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark overflow-x-auto">
                        {Strings.orgName}
                      </h6>
                      <p className="">{i.name}</p>
                    </div>
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark">
                        {Strings.businessVertical}
                      </h6>
                      <p className="">{i.type || "-"}</p>
                    </div>
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark">
                        {Strings.businessNo}
                      </h6>
                      <p className="">{i.businessNo || "-"}</p>
                    </div>
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark">{Strings.country}</h6>
                      <p className="">{i.country || "-"}</p>
                    </div>
                    <div className="p-3 font-semibold">
                      <h6 className="mb-2 text-grayDark">{Strings.state}</h6>
                      <p className="">{i.state || "-"}</p>
                    </div>
                  </div>
                ) : i.websites.length > 0 ? (
                  <div>
                    <div className="p-6 py-4 font-medium">
                      {Strings.siteUrls}
                    </div>
                    <div>
                      {i.websites.map((val, index) => {
                        return (
                          <p
                            key={index.toString()}
                            className="p-6 py-4 odd:bg-grayLight"
                          >
                            {val.url}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="p-6 py-4 font-medium">{"No URLs found"}</div>
                )}
              </section>
            );
          })}
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    roleId: state.auth.roleId,
    orgsList: state.dashboard.data?.organizations || [],
    userProfile: state.dashboard.userProfile,
    isTempPassword: state.auth.isTempPassword,
  };
};

// exports
export default connect(mapStateToProps)(ViewOrganization);
