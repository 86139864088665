/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import posthog from 'posthog-js'

// components
import DefaultPage from "../../components/layout/defaultPage";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";
// svg

// actions
import { getUsers, editUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import Security from "./security";
import GeneralInfo from "./generalInfo";
import MobileHeader from "../../components/MobileHeader";
import Dialog from "../../components/Dialog";
import { RoutingConstants } from "../../constants";

/**
 * @function Profile
 * @param {Object} props
 * @returns {JSX}
 */
const Profile = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const isMobile = ScreenIsMobile();
  const {
    userProfile,
    triggerLogout,
    loggedIn,
  } = props;

  const [openTab, setOpenTab] = useState(1);
  const navigate = useNavigate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section>
        {isMobile ?
          <>
            <MobileHeader
              title="Profile Settings"
            />
          </>
          :
          <div className="border-b border-grayLight px-6 py-4 font-semibold flex items-center text-xl">
            Profile Settings
          </div>
        }
        <div className="flex flex-wrap">
          <div className="w-full sm:flex h-91">
            <div className="sm:w-72	flex flex-col justify-between sm:h-full border-b sm:border-b-0 sm:border-r border-grayLight" role="tablist" >
              <div className="flex sm:block">
                <div className="text-center flex-1">
                    <a
                    className={
                        "group flex items-center justify-center sm:justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                        (openTab === 1 ? "text-white bg-primary" : "text-blockbg-white")
                    }
                    onClick={e => { e.preventDefault(); setOpenTab(1);}}
                    data-toggle="tab"
                    href="#generalInfo"
                    role="tablist"
                    >
                    General Info <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 1 ? 'sm:block' : 'hidden'} `} />
                    </a>
                </div>
                <div className="text-center flex-1">
                    <a
                    className={
                        "group flex items-center justify-center sm:justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                        (openTab === 2 ? "text-white bg-primary" : "bg-white")
                    }
                    onClick={e => { e.preventDefault(); setOpenTab(2); }}
                    data-toggle="tab"
                    href="#security"
                    role="tablist"
                    >
                    Security <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 2 ? 'sm:block' : 'hidden'} `} />
                    </a>
                </div>
              </div>
              <div className="hidden sm:block text-center w-full px-5 pb-4">
                <button className="w-full border px-8 py-2 m-auto font-bold text-sm" onClick={() => setShowConfirmDialog(true)}>LOGOUT</button>
                {showConfirmDialog && (
                    <Dialog
                    onYesClick={() => {
                        setShowConfirmDialog(false);
                        triggerLogout();
                        if (!loggedIn) {
                        navigate(RoutingConstants.login);
                        }
                    }}
                    onNoClick={() => {
                        setShowConfirmDialog(false);
                    }}
                    title={`Logout`}
                    subtitle={"Are you sure want to logout?"}
                    />
                )}
              </div>
            </div>
            <div className="break-words bg-white w-full mb-6 rounded">
              <div>
                <div className="tab-content tab-space overflow-x-hidden overflow-y-auto h-80 sm:h-91">
                  <div className={openTab === 1 ? "block" : "hidden"} id="generalInfo">
                    <GeneralInfo />
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="security">
                    <Security />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    currentOrgUsers: state.dashboard.currentOrgUsers,
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
    loggedIn: state.auth.loggedIn,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerGetUsers: getUsers,
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
