/* eslint-disable array-callback-return */
// library imports
import React ,{ useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { editUser, mapUserWebsites } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";
import SectionLoader from "../../../components/SectionLoader";

// icons
import { BsFillExclamationSquareFill } from "react-icons/bs";

// token expired handler
import TokenExpired from "../../../utils/TokenExpired";
import PieLoader from "../../../components/PieLoader";
/**
 * @function ViewUser
 * @returns {JSX}
 */
const GeneralInfo = (props) => {
  const { selectedOrg, triggerLogout, triggerMapUserWebsite,currentOrgUsers, roleId } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(null);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const isCurrentWebsiteActive = selectedOrg.isActive
  let currentWebsiteUsers = [];
  roleId === 0 && selectedOrg.websites.map(web => web.id === parseInt(id) && currentWebsiteUsers.push(...web.users));
  const onSuccess = () => {
    setErrorFromApi({
      type: "success",
      msg: "User has been Deleted!!!",
    });
    setLoading(null);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 2000);
  };

  const onError = (errorMsg) => {
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setLoading(null);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };
  const removeUser = user => {
    const existingWebIds = currentOrgUsers.filter(orgUser => orgUser.id === user.id)?.[0]?.websiteIds;
    const webIds = existingWebIds?.filter(webId => webId !== parseInt(id));
    if(isCurrentWebsiteActive) {
      const payLoad = {
        "userId": user.id,
        "websiteIds": webIds|| []
      }
      setLoading({userId: user.id});
      triggerMapUserWebsite({
        requestObj:payLoad,
        onSuccess,
        onError,
      })
    }
  }
  return (
    <section>
      <div className="border-b border-grayBorder">
        <p className="text-black text-lg font-bold py-3">Manage Users</p>
      </div>
      <div id="modal" className="text-black">
        <div className="overflow-x-hidden outline-none focus:outline-none">
          <div className="relative pt-2">
            <div className="w-full">
              {
                currentWebsiteUsers?.length > 0?
                currentWebsiteUsers.map((user, index) => {
                  return(
                    <div className="border-b border-grayBorder grid grid-cols-6 items-center group font-mukta" key={index}>
                      <p className="py-2 col-span-3">{user.firstName} {user.lastName}</p>
                      <p className="col-span-2 border p-1 leading-none w-[90px] h-auto uppercase text-center">{user.roleId === 0 ? 'Admin' : 'Manager'}</p>
                      <div 
                        style={{transition: "all 500ms ease"}}
                        className={`cursor-pointer w-max ml-auto ${loading?.userId === user.id? 'opacity-100 bg-red-500 text-white' :!isCurrentWebsiteActive ? 'border border-gray text-gray pointer-events-none opacity-100 md:opacity-0' :'opacity-100 md:opacity-0 text-red-500 border-red-500 border bg-white'} leading-none group-hover:opacity-100 px-2 py-1 font-normal`}
                        onClick={() => removeUser(user)}>
                        {loading ? (
                          <div className="flex flex-row justify-center items-center">
                            <span className="mr-2">LOADING</span>
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                          </div>
                        ) : (
                          "Remove"
                        )}
                      </div>
                    </div>
                  )
                }):
                <div className="p-6 py-4 text-center flex items-center justify-center h-full">
                  <SectionLoader 
                    content= {["No data to display."]} 
                    isNoData={true}
                  />
                </div>
              }
              {errorFromApi !== null && (
                <div
                  className={`${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error"
                          ? "#FF5353"
                          : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
    currentOrgUsers: state.dashboard.currentOrgUsers,
    roleId: state.auth.roleId,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
  triggerMapUserWebsite: mapUserWebsites
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
