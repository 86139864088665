// components
import { useViewport } from "../App";

/**
 * @function ScreenIsMobile
 * @returns {Boolean}
 * @description checks for screen size
 */
const ScreenIsMobile = () => {
  const { width } = useViewport();
  return width <= 1023 ? true : false;
};

export default ScreenIsMobile;
