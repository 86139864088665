// library imports
import { all, call } from "redux-saga/effects";

// user imports
import { authSagas } from "./auth";
import { dashboardSagas } from "./dashboard";

// exports
export default function* rootSaga() {
  yield all([call(authSagas), call(dashboardSagas)]);
}
