/* eslint-disable array-callback-return */
// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Strings } from "../constants";

// actions
import { validateOtp } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import PieLoader from "../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   border-color: "white";
// `;

/**
 * @function ForgotPassword
 * @returns {JSX}
 */
const EnterOtp = (props) => {
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // destructure the props
  const {
    loggedIn,
    loading,
    accessToken,
    isOrganizationPresent,
    triggerValidateOtp,
    passwordRetrievalEmail,
  } = props;

  // state variables
  const resetOtpvalues = new Array(6).fill("");
  const [otp, setOtp] = useState(resetOtpvalues);
  const [disable, setDisable] = useState(false);
  const isOtpValidated = otp.join("")?.length === 6 && !isNaN(otp.join(""));
  const [errorFromApi, setErrorFromApi] = useState(null);

  // on screen load, focus the first OTP box
  useEffect(() => {
    document.getElementById("otp_1").focus();
  }, []);
  // whenever these variables from the store changes, this useeffect will be triggered
  useEffect(() => {
    if (loggedIn && !loading && accessToken) {
      if (!isOrganizationPresent) {
        // navigate to org setup
        navigate(`${RoutingConstants.organization.setup}?addedOrg=false`);
      } else if (isOrganizationPresent) {
        // navigate to dashboard
        navigate(RoutingConstants.dashboard);
      }
    }
  }, [loading, loggedIn, accessToken, isOrganizationPresent, navigate]);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username textinput
   */
  const handleChange = (element, index) => {
    setErrorFromApi(null);
    // when ther user enters the input, if it is not a number || OR if the entered char is a space, dont accept it
    // as of now, we accept only digiots 0-9 in regex for <input> tag, but accepts spacing.
    // so if space is entered, reset the HTML DOM by identifying the element ID and update it with empty string ""
    if (isNaN(element.value) || element.value.trim().length === 0) {
      document.getElementById(`otp_${index + 1}`).value = "";
      setOtp([
        ...otp.map((existingElement, position) =>
          position === index ?  "" : existingElement
        ),
      ]);
      return;
    }

    setOtp([
      ...otp.map((existingElement, position) =>
        position === index ? parseInt(element.value) : existingElement
      ),
    ]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isOtpValidated) {
      const setElementFocus = otp.findIndex((i) => i === "");
      document.getElementById(`otp_${setElementFocus + 1}`).focus();
      return;
    }
    setDisable(true);
    const payload = {
      apiPayload: {
        email: passwordRetrievalEmail || "",
        otp: parseInt(otp.join("")),
      },
      onSuccess: (msg) => {
        setErrorFromApi({
          type: "success",
          msg: msg || "Success",
        });
        setTimeout(() => {
          window.location.replace(RoutingConstants.resetPassword);
          setDisable(false);
        }, 1000);
      },
      onError: (msg) => {
        setErrorFromApi({
          type: "error",
          msg: msg || "Something went wrong!!!",
        });
        setTimeout(() => {
          setDisable(false);
          setOtp(resetOtpvalues);
          otp.map((i, index) => {
            document.getElementById(`otp_${index + 1}`).value = "";
          });
          document.getElementById("otp_1").focus();
        }, 1000);
      },
    };
    // trigger login action
    triggerValidateOtp(payload);
  };
  return (
    <div className="max-w-full">
      <div className="grid lg:grid-cols-2">
        <div className="flex flex-wrap flex-col justify-between min-h-screen">
          <div className={`w-full flex px-8 md:px-12 lg:px-20 mt-20 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          {/* Form data */}
          <div className="flex flex-col px-8 md:px-12 lg:px-20 mb-32">
            <h2 className="font-bold text-black text-2xl">
              {`Please enter the reset code you received in your mail to proceed`}
            </h2>
            <div className="mt-10">
              {otp.map((data, index) => {
                const identifier = `otp_${index + 1}`;
                return (
                  <input
                    id={identifier}
                    className="placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:outline-none rounded w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4 md:w-12 md:h-12 text-center drop-shadow-md shadow-lg focus:scale-125"
                    type="text"
                    key={index}
                    name={identifier}
                    maxLength={1}
                    minLength={1}
                    pattern="[0-9]"
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            {errorFromApi !== null && (
              <div
                className={`${
                  errorFromApi?.type === "error"
                    ? "bg-floralWhite"
                    : "bg-aliceBlue"
                } border border-grayLight p-3 mt-10 flex flex-row overflow-x-auto`}
              >
                <div className="mr-2">
                  <BsFillExclamationSquareFill
                    color={
                      errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                    }
                    className="text-2xl"
                  />
                </div>
                <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
              </div>
            )}
          </div>
          <div>
            <button
              className={`cursor-pointer text-white font-bold w-full lg:w-1/2 text-sm tracking-small p-4 bottom-0 fixed ${
                (loading || disable || errorFromApi) &&
                "pointer-events-none bg-gray"
              } ${
                loading
                  ? "bg-gray pointer-events-none"
                  : !errorFromApi && isOtpValidated
                  ? "bg-black"
                  : "bg-gray"
              }`}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                  <span className="mr-2">{Strings.loading}</span>
                  <PieLoader
                    color={"white"}
                    loading={loading}
                    size={5}
                  />
                </div>
              ) : (
                Strings.submit
              )}
            </button>
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    accessToken: state.auth.accessToken,
    isOrganizationPresent: state.auth.isOrganizationPresent,
    passwordRetrievalEmail: state.auth.passwordRetrievalEmail,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerValidateOtp: validateOtp,
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterOtp);
