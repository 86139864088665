// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import parse from "html-react-parser";

// Actions
import { createUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// token expired handler
import { Link } from "react-router-dom";

/**
 * @function AddUser
 * @returns {JSX}
 */
const UpgradePlan = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { orgsList, selectedOrgId } = props;
  const isCurrentWebsiteActive = orgsList.find(i => i.id === selectedOrgId).isActive
  const openPopup = (e) => {
    e.preventDefault();
    if(isCurrentWebsiteActive){setShowModal(true)}
  };
  useEffect(() => {
    if(props?.content === null ){
      setShowModal(true);
    }
  },[props?.content])

  const {
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  return (
    <section>
      <div className={`cursor-pointer h-full flex ${!isCurrentWebsiteActive && 'pointer-events-none'}`} onClick={(e) => openPopup(e)}>
        {props?.content && parse(props.content)}
      </div>
      {showModal === true && (
        <div id="modal" className="text-black">
          <div className="mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed mx-auto inset-0 z-50 outline-none focus:outline-none lg:h-full pb-14 sm:pb-0">
            <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
              <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0 text-left" style={{maxHeight: "90%"}}>
                <div className="relative">
                  <div className="lg:px-10 py-6">
                    <h3 className="text-2xl font-bold">Upgrade</h3>
                    <p className="py-6 text-base font-medium">To know more about our plans, <Link to="#" className="underline">click here</Link></p>
                  </div>
                  <form onSubmit={handleSubmit()} className="w-full">
                    <div className="lg:px-10 lg:mb-10">
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-sm text-grayDeep font-semibold mb-2">
                        Choose the plan
                      </div>
                      <input
                        type="text"
                        className={`pointer-events-none w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.planName && "border-red-500"
                        }`}
                        id="planName"
                        name="planName"
                        placeholder="Plan"
                      />
                      {errors.planName && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.planName.message}
                        </div>
                      )}
                    </fieldset>
                    </div>
                    <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0 ">
                      <button
                        className="col-span-2 py-4 font-bold text-sm text-grayNobel border-t border-grayBorder"
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        {"Cancel"}
                      </button>
                      <button className="bg-gray border-gray col-span-4 font-bold text-sm text-white py-4 tracking-small" type="submit">Next</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateUser: createUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
