const RoutingConstants = {
  root: "/",
  organization: {
    orgRoot: "/organizations",
    setup: "/organization/setup",
    view: "/organization/view/:id",
    add: "/organization/add",
    edit: "/organization/edit/:id",
  },
  dashboard: "/dashboard",
  websites: {
    webRoot: "/websites",
    view: "/website/:id",
    reports: "/website/:id/reports",
    view_report: "/website/:id/reports/fullReport/:jobId",
    view_report_with_rules: "/website/:id/reports/fullReport/:jobId/:rules",
    view_report_interim: "/website/:id/reports/:jobId",
    view_report_full: "/website/:id/reports/fullReportNew/:jobId",
    settings: "/website/:id/settings",
    view_analytics: "/website/:id/analytics/:jobId",
  },
  users: {
    usersList: "/users",
    detail: "/users/:id",
  },
  workspace: {
    workspacedetail: "/workspace",
  },
  login: "/login",
  forgotPassword: "/password/forgot",
  enterOtp: "/password/otp",
  resetPassword: "/password/reset",
  changePassword: "/password/change",
  register: "/register/:token",
  verifyUserEmail: "/verify/:id",
  profile: "/profile"
};

const Titles = {
  dashboard: "Dashboard",
  login: "Login",
  logout: "Logout",
  websites: "Websites",
  users: "Users",
  manageUsers: "Manage users",
  myOrganization: "My Organization",
  editOrganization: "Edit Organization",
  addNewWorkspace: "ADD NEW WORKSPACE",
  forgotYourPassword: "Forgot your Password?",
  enterYourCode: "Enter your code",
  enterNewPassword: "Enter your New Password",
  resetYourPassword: "Reset your password",
  switchOrganization: "Switch Organization",
  myProfile: "Profile",
};

const Strings = {
  loading: "Loading",
  saveChanges: "Save Changes",
  submit: "Submit",
  appTitle: "Wally - Web Accessibility",
  auditRuns: "Audit Runs",
  wallySignIn: "Sign In",
  howDoIGetStarted: "How do I get started Accessibility Service",
  username: "Username",
  password: "Password",
  enterYourEmail: "Enter your email address",
  enterPassword: "Enter your password",
  forgotPassword: "Forgot Password ?",
  signIn: "Sign In",
  sendMeCode: "Send Me Code",
  dontHaveAccount: `Don't have an account? `,
  createNew: "Create New",
  setUpOrg: "Setup Organization",
  orgName: ` Organization Name`,
  addSiteUrl: ` Add Site URL`,
  enterOrgName: "Enter Your Organization Name",
  orgType: "Organization Type",
  country: "Country",
  next: "Next",
  skipForNow: "Skip for Now",
  addSiteDomain: "Add Site Domain",
  save: "Save",
  doItLater: "Do it Later",
  addNewOrg: "Add New Organization",
  profileMenu: [
    { id: "myOrg", display: "My Organization" },
    { id: "logout", display: "Logout" },
  ],
  footer: "Fleet Studio Inc @2022 Copyright",
  basicDetails: "Basic Details",
  websites: "Websites",
  emailId: "Email ID",
  businessVertical: "Business Vertical",
  businessNo: "Business No",
  state: "State",
  pleaseAddUrl: "Please add an url",
  siteUrls: "SITE URLS",
  siteUrl: "Site URL",
  addNewUrl: "Add New Url",
  cancel: "Cancel",
  add: "Add",
  addUrl: "Add URL",
  siteAddedSuccess: "Congratulations!!! New site has been added successfully",
  addNewSite: "Add New Website",
  addSite: "Add Site",
  addNew: "Add New",
  srNo: "SR NO.",
  siteName: "SITE NAME",
  action: "ACTION",
  viewSite: "View Site",
  issueDescription: "Issue Description",
  elementLocation: "Element Location",
  elementSource: "Element Source",
  fixNow: "Fix Now",
  reportName: "REPORT NAME",
  date: "DATE",
  runAudit: "RUN AUDIT",
  auditRunning: "Audit Running…!!!",
  auditInProgress: "Your website audit is in progress. ",
  keepCalm: "Please keep calm and wait.",
  viewAllReports: "View All Reports",
  settings: "Settings",
  runAuditAgain: "Run Audit Again",
  upgradePlan: "Upgrade Plan",
  selectUrl: "Select URL",
  select: "Select",
  selected: "selected",
  subpage: "Sub Page/URLs",
  compliancePassed: "Compliance Passed",
  complianceFailed: "Compliance Failed",
  compiledFailed: "Compiled Failed",
  compiledPassed: "Compiled Passed",
  complianceFix: "Compliance Fix",
  complianceNotify: "Compliance Notify",
  notify: "Notify",
  filterBy: "Filter By",
  all: "All",
  manager: "Manager",
  admin: "Admin",
  reportHistory: "Report History",
  addNewUser: "Add New User",
  addUser: "Add User",
  addNewWorkspace: "ADD NEW WORKSPACE",
  permissions: "Permissions",
  viewUser: "View User",
  back: "Back",
  ok: "Okay",
};

const apiEndpoints = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  login: "/login",
  sendOtp: "/forgotPassword",
  validateOtp: "/validateOtp",
  resetPassword: "/updatePassword",
  changePassword: "/changePassword",
  dashboard: "/dashboard",
  website: "/website",
  org: "/org",
  siteReport: "/siteReport",
  pageReport: "/pageReport",
  getElementsList: "/getAccessorData",
  getInstanceData: "/getInstanceData",
  updateElement: "/saveUserContext",
  runAudit: "/audit",
  user: "/user",
  register: "/marketing/user/validate",
  mapUserWithWebsite: "/website/map",
  getUserProfile: "/profile",
  users: "/users",
  dashboardData: "/getAnalytics",
  verifyUser: "/website/analyzer",
  verifyUserEmail: "/verifyUser",
  websiteSettings: "/website/settings",
  auditData: "/getAuditDataV2",
  auditStage: "/auditStatus"
};

export { RoutingConstants, Titles, Strings, apiEndpoints };
