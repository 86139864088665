// library imports
import { Helmet, HelmetProvider } from "react-helmet-async";
import React from "react";
import { connect } from "react-redux";

// components
import Sidebar from "../sidebar";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";

// constants
import { Strings } from "../../constants";

/**
 * @function DefaultPage
 * @param {Object} props
 * @returns {JSX}
 */
const DefaultPage = (props) => {
  const { sidebarCollapsed } = props;
  const isMobile = ScreenIsMobile();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{Strings.appTitle}</title>
        </Helmet>
      </HelmetProvider>
      <div className="default_page max-h-screen overflow-hidden items-center justify-center">
        <div className={`${sidebarCollapsed ? "flex" : "grid grid-cols-6"} `}>
          {/* Show sidebar under certain constraints */}
          {!isMobile && (
            <div
              className={`min-h-screen ${
                sidebarCollapsed ? "min-w-119 max-w-119" : ""
              }`}
            >
              {props.showSidebar && <Sidebar />}
            </div>
          )}
          {/* If sidebar is collapsed , or if resolution is <= 1024px, then dont dedicate any space for sidebar */}
          <div
            className={`${
              sidebarCollapsed || isMobile ? "col-span-11 w-full" : "col-span-5"
            }`}
          >
            <div className="main">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    sidebarCollapsed: state.dashboard.sidebarCollapsed,
    auth: state.auth,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
