// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Strings, Titles } from "../constants";

// actions
import { sendOtp } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import PieLoader from "../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   border-color: "white";
// `;

/**
 * @function ForgotPassword
 * @returns {JSX}
 */
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // destructure the props
  const {
    triggerSendOtp,
    loggedIn,
    loading,
    accessToken,
    isOrganizationPresent,
  } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  // state variables
  const [userData, setUserData] = useState({
    username: "",
  });
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [disable, setDisable] = useState(false);

  // show focus on email field on load
  useEffect(() => {
    const firstField = document.getElementById("username");
    firstField.focus();
  }, []);

  // whenever these variables from the store changes, this useeffect will be triggered
  useEffect(() => {
    if (loggedIn && !loading && accessToken) {
      if (!isOrganizationPresent) {
        // navigate to org setup
        navigate(`${RoutingConstants.organization.setup}?addedOrg=false`);
      } else if (isOrganizationPresent) {
        // navigate to dashboard
        navigate(RoutingConstants.dashboard);
      }
    }
  }, [loading, loggedIn, accessToken, isOrganizationPresent, navigate]);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username textinput
   */
  const onChangeHandler = (e) => {
    const inputTagElement = document.getElementById("username");
    // If the user edits the input box, disable any error msgs if present
    if (document.activeElement === inputTagElement) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const inputsValidated =
    userData.username?.length >= 6 &&
    userData.username?.length <= 100 &&
    !errors.username;

  const submit = () => {
    if (!inputsValidated) {
      return;
    }
    setDisable(true);
    const payload = {
      apiPayload: {
        email: userData.username,
      },
      onSuccess: (msg) => {
        window.location.pathname.includes("/password/forgot") && navigate(RoutingConstants.enterOtp);
      },
      onError: (msg) => {
        setErrorFromApi({
          type: "error",
          msg: msg || "Something went wrong!!!",
        });
        setDisable(false);
      },
    };
    // trigger send OTP action
    triggerSendOtp(payload);
  };

  return (
    <div className="max-w-full">
      <div className="grid lg:grid-cols-2">
        <div className="px-8 md:px-12 lg:px-20">
          <div className={`w-full flex mt-20 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          <div className="w-full pt-20 overflow-auto" style={{ height: "calc(100vh - 100px)"}}>
            <form onSubmit={handleSubmit(submit)}>
              <h2 className="font-bold text-black text-2xl mb-4">
                {Titles.forgotYourPassword}
              </h2>
              <p className="text-black text-lg mb-6">
                {
                  "Enter your E-mail linked to your account below and we will send you the code to reset your password."
                }
              </p>
              <fieldset className="mb-2">
                <div className="w-full font-bold mb-2 text-black text-base">
                  {`Email  `}
                  {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
                </div>
                <input
                  type="text"
                  className={`placeholder-[#7E7E7E] placeholder:font-semibold w-full outline-none border-b-2 border-[#000000] focus:border-primary outline-none py-2 text-sm font-normal overflow-x-auto ${
                    errors.username && "border-red-500"
                  }`}
                  placeholder={Strings.enterYourEmail}
                  id="username"
                  name="username"
                  {...register("username", {
                    required: {
                      value: true,
                      message: "* E-mail is mandatory",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "* Invalid email format",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
                {errors.username && (
                  <span className="text-xs text-red-500 font-semibold">
                    {errors.username.message}
                  </span>
                )}
              </fieldset>

              {errorFromApi !== null && (
                <div
                  className={`w-full mt-5 -mb-5 ${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 flex flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
              )}
              <button
                onClick={() => {
                  const autosavedUsername =
                    document.getElementById("username").value;
                  reset(
                    {
                      username: autosavedUsername,
                    },
                    {
                      keepErrors: false,
                      keepDirty: true,
                      keepIsSubmitted: false,
                      keepTouched: false,
                      keepIsValid: false,
                      keepSubmitCount: false,
                    }
                  );
                  setUserData({
                    username: autosavedUsername,
                  });
                }}
                disabled={disable || errorFromApi || loading}
                type="submit"
                className={`cursor-pointer flex items-center text-white font-bold w-fit text-base mt-10 mb-4 py-2.5 px-3.5 ${
                  (loading || disable || errorFromApi) &&
                  "pointer-events-none bg-gray"
                } ${
                  loading
                    ? "bg-gray pointer-events-none"
                    : inputsValidated && !errorFromApi 
                    ? `bg-black`
                    : `bg-gray`
                }`}
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mr-2">{Strings.loading}</span>
                    <PieLoader
                      color={"white"}
                      loading={loading}
                      size={5}
                    />
                  </div>
                ) : (
                  "Send Me Code"
                )}
                {                  
                loading ? '' :
                <svg className="ml-2.5" width="24" height="16" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.775513 10H24.449" stroke="white" strokeWidth="3"/>
                <path d="M16.4592 1.41846L25.0408 10.0001L16.4592 18.5817" stroke="white" strokeWidth="3"/>
                </svg>
                }
              </button>
            </form>
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    accessToken: state.auth.accessToken,
    isOrganizationPresent: state.auth.isOrganizationPresent,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerSendOtp: sendOtp,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
