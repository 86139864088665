// user imports
import actionTypes from "../types";

// destructuring
const {
  DASHBOARD,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  SELECT_ORG,
  CREATE_WEBSITE,
  CREATE_WEBSITE_SUCCESS,
  CREATE_WEBSITE_ERROR,
  CREATE_ORG,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_ERROR,
  UPDATE_ORG,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_WEBSITES,
  UPDATE_ORG_WEBSITES_SUCCESS,
  UPDATE_ORG_WEBSITES_ERROR,
  REMOVE_ORG_WEBSITES,
  REMOVE_ORG_WEBSITES_SUCCESS,
  REMOVE_ORG_WEBSITES_ERROR,
  REPORT,
  REPORT_SUCCESS,
  REPORT_ERROR,
  PAGE_REPORT,
  PAGE_REPORT_SUCCESS,
  PAGE_REPORT_ERROR,
  AUDIT,
  AUDIT_SUCCESS,
  AUDIT_ERROR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  TOGGLE_SIDEBAR,
  TOGGLE_HAMBURGER,
  CLOSE_HAMBURGER,
  MAP_USER_WEBSITES,
  MAP_USER_WEBSITES_SUCCESS,
  MAP_USER_WEBSITES_ERROR,
  GET_ELEMENTS_LIST,
  GET_ELEMENTS_LIST_SUCCESS,
  GET_ELEMENTS_LIST_ERROR,
  GET_ELEMENT_OCCURRENCE,
  GET_ELEMENT_OCCURRENCE_SUCCESS,
  GET_ELEMENT_OCCURRENCE_ERROR,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_SUCCESS,
  UPDATE_ELEMENT_ERROR,
  GET_DASHOARD_DATA,
  GET_DASHOARD_DATA_SUCCESS,
  GET_DASHOARD_DATA_ERROR,
  GET_SITE_ANALYTICS_DATA,
  GET_SITE_ANALYTICS_DATA_SUCCESS,
  GET_SITE_ANALYTICS_DATA_ERROR,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  GET_WEBSITE_SETTINGS,
  GET_WEBSITE_SETTINGS_SUCCESS,
  GET_WEBSITE_SETTINGS_ERROR,
  EDIT_WEBSITE_SETTINGS,
  EDIT_WEBSITE_SETTINGS_SUCCESS,
  EDIT_WEBSITE_SETTINGS_ERROR,
  RESTORE_WEBSITE,
  RESTORE_WEBSITE_SUCCESS,
  RESTORE_WEBSITE_ERROR,
  GET_AUDIT_DATA,
  GET_AUDIT_DATA_SUCCESS,
  GET_AUDIT_DATA_ERROR,
  AUDIT_STAGE,
  GET_AUDIT_STAGE,
  GET_AUDIT_STAGE_SUCCESS,
  GET_AUDIT_STAGE_ERROR,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_SUCCESS,
  GET_ACTIVE_SUBSCRIPTION_ERROR,
  GET_WORKSPACE_SITES,
  GET_WORKSPACE_SITES_SUCCESS,
  GET_WORKSPACE_SITES_ERROR,
  GET_SITE_DATA,
  GET_SITE_DATA_SUCCESS,
  GET_SITE_DATA_ERROR,
  EDIT_WORKSPACE,
  EDIT_WORKSPACE_SUCCESS,
  EDIT_WORKSPACE_ERROR,
  GET_WORKSPACE_SITE_DATA,
  GET_WORKSPACE_SITE_DATA_SUCCESS,
  GET_WORKSPACE_SITE_DATA_ERROR,
  GET_WORKSPACE_USERS,
  GET_WORKSPACE_USERS_SUCCESS,
  GET_WORKSPACE_USERS_ERROR,
  SHARE_REPORT,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_ERROR,
  GET_AUDIT_SCORE,
  GET_AUDIT_SCORE_SUCCESS,
  GET_AUDIT_SCORE_ERROR,
  GET_AUDIT_RULE,
  GET_AUDIT_RULE_SUCCESS,
  GET_AUDIT_RULE_ERROR,
  GET_SIGNATURE,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_ERROR
} = actionTypes.dashboard;

const { LOGOUT } = actionTypes.auth;

const initialState = {
  loading: false,
  editUserLoading: false,
  editOrgLoading: false,
  getUsersLoading: false,
  updateElementLoading: false,
  data: null,
  dashboardData: null,
  selectedOrganization: null,
  userProfile: null,
  currentOrgUsers: [],
  sidebarCollapsed: true,
  hamburgerCollapsed: true,
  verifiedUser: false,
  getWebsiteSettingsLoading: false,
  currentWebsite: {},
  auditStage: [],
  auditStatus: {},
  activeSubscription: {},
  scfCount: null,
  workSpaceSitesCount: null,
  currentWorkspaceUsersCount: null,
  orgUpdated: false,
  selectedOrganizationByUser: null,
  occurrenceLoading: false,
  occurrenceData: null
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT: {
      // wipe out all the data
      return { ...initialState };
    }
    case DASHBOARD: {
      // if it is api call / request, then fetch it as action.payload
      return { ...state, loading: true };
    }
    case DASHBOARD_SUCCESS: {
      const orgUpdatedByuser = state.orgUpdated;
      const organizationSelectedByUser = state.selectedOrganizationByUser;
      const orgsList =
        action?.response?.dashboardResponse?.data?.message?.organizations || [];
      // if it is api call success, then fetch it as action.response
      return {
        ...state,
        loading: false,
        data: action?.response?.dashboardResponse?.data?.message || null,
        selectedOrganization: orgUpdatedByuser ? organizationSelectedByUser : (orgsList?.length > 0 ? orgsList[0]?.id : null),
        userProfile:
          action?.response?.getUserProfileResponse?.data?.message || null,
      };
    }

    case DASHBOARD_ERROR: {
      // if it is api call error, then fetch it as action.error
      return {
        ...state,
        loading: false,
        data: action.error,
      };
    }

    case CREATE_WEBSITE: {
      return { ...state, loading: true };
    }

    case CREATE_WEBSITE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null
      };
    }

    case CREATE_WEBSITE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_ORG: {
      return { ...state, loading: true };
    }

    case CREATE_ORG_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case CREATE_ORG_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ORG: {
      return { ...state, editOrgLoading: true };
    }

    case UPDATE_ORG_SUCCESS: {
      return {
        ...state,
        editOrgLoading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case UPDATE_ORG_ERROR: {
      return {
        ...state,
        editOrgLoading: false,
      };
    }

    case REPORT: {
      // if it is api call / request, then fetch it as action.payload
      return { ...state, loading: true };
    }
    case REPORT_SUCCESS: {
      // if it is api call success, then fetch it as action.response
      return {
        ...state,
        loading: false,
      };
    }

    case REPORT_ERROR: {
      // if it is api call error, then fetch it as action.error
      return {
        ...state,
        loading: false,
      };
    }

    case PAGE_REPORT: {
      // if it is api call / request, then fetch it as action.payload
      return { ...state, loading: true };
    }
    case PAGE_REPORT_SUCCESS: {
      // if it is api call success, then fetch it as action.response
      return {
        ...state,
        loading: false,
      };
    }

    case PAGE_REPORT_ERROR: {
      // if it is api call error, then fetch it as action.error
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ELEMENTS_LIST: {
      return { ...state, loading: true };
    }
    case GET_ELEMENTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ELEMENTS_LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ELEMENT_OCCURRENCE: {
      return { ...state, occurrenceData: action.occurrenceData, occurrenceLoading: true };
    }

    case GET_ELEMENT_OCCURRENCE_SUCCESS: {
      return {
        ...state,
        occurrenceData: action.occurrenceData,
        occurrenceLoading: false,
      };
    }

    case GET_ELEMENT_OCCURRENCE_ERROR: {
      return {
        ...state,
        occurrenceData: null,
        occurrenceLoading: false,
      };
    }

    case UPDATE_ELEMENT: {
      return { ...state, updateElementLoading: true };
    }
    case UPDATE_ELEMENT_SUCCESS: {
      return {
        ...state,
        updateElementLoading: false,
      };
    }

    case UPDATE_ELEMENT_ERROR: {
      return {
        ...state,
        updateElementLoading: false,
      };
    }

    case AUDIT: {
      return { ...state, loading: true };
    }

    case AUDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case AUDIT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_USER: {
      return { ...state, loading: true };
    }

    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case CREATE_USER_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case MAP_USER_WEBSITES: {
      return { ...state, loading: true };
    }

    case MAP_USER_WEBSITES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case MAP_USER_WEBSITES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case SELECT_ORG: {
      return { ...state, selectedOrganization: action.payload.orgid, selectedOrganizationByUser: action.payload.orgid, orgUpdated: true };
    }
    case GET_USERS: {
      return { ...state, getUsersLoading: true };
    }

    case GET_USERS_SUCCESS: {
      return {
        ...state,
        getUsersLoading: false,
        currentOrgUsers: action.response?.data?.message,
      };
    }

    case GET_USERS_ERROR: {
      return {
        ...state,
        getUsersLoading: false,
      };
    }

    case EDIT_USER: {
      return { ...state, editUserLoading: true };
    }

    case EDIT_USER_SUCCESS: {
      return {
        ...state,
        editUserLoading: false,
        currentOrgUsers: action.response[0]?.data?.message,
        userProfile: action.response[1]?.data?.message,
      };
    }

    case EDIT_USER_ERROR: {
      return {
        ...state,
        editUserLoading: false,
      };
    }

    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };
    }

    case TOGGLE_HAMBURGER: {
      return {
        ...state,
        hamburgerCollapsed: !state.hamburgerCollapsed,
      };
    }

    case CLOSE_HAMBURGER: {
      return {
        ...state,
        hamburgerCollapsed: true
      };
    }

    case UPDATE_ORG_WEBSITES: {
      return { ...state, loading:false };
    }

    case UPDATE_ORG_WEBSITES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case UPDATE_ORG_WEBSITES_ERROR: {
      return { ...state, loading:false };
    }

    case REMOVE_ORG_WEBSITES: {
      return { ...state, loading: true };
    }

    case REMOVE_ORG_WEBSITES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case REMOVE_ORG_WEBSITES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DASHOARD_DATA: {
      return { ...state, getUsersLoading: true };
    }

    case GET_DASHOARD_DATA_SUCCESS: {
      return {
        ...state,
        getUsersLoading: false,
        dashboardData: action.response?.data,
      };
    }

    case GET_DASHOARD_DATA_ERROR: {
      return {
        ...state,
        getUsersLoading: false,
        dashboardData: null,
      };
    }

    case GET_SITE_ANALYTICS_DATA: {
      return { ...state, loading: true };
    }

    case GET_SITE_ANALYTICS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SITE_ANALYTICS_DATA_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case VERIFY_USER: {
      return { ...state, getUsersLoading: true };
    }

    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        getUsersLoading: false,
        data: action?.response?.data?.message || null,
      };
    }

    case VERIFY_USER_ERROR: {
      return {
        ...state,
        getUsersLoading: false,
      };
    }

    case GET_WEBSITE_SETTINGS: {
      return { ...state, getWebsiteSettingsLoading: true };
    }

    case GET_WEBSITE_SETTINGS_SUCCESS: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case GET_WEBSITE_SETTINGS_ERROR: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case EDIT_WEBSITE_SETTINGS: {
      return { ...state, getWebsiteSettingsLoading: true };
    }

    case EDIT_WEBSITE_SETTINGS_SUCCESS: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
        data: action?.response?.data?.message
      };
    }

    case EDIT_WEBSITE_SETTINGS_ERROR: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case RESTORE_WEBSITE: {
      return { ...state, getWebsiteSettingsLoading: true };
    }

    case RESTORE_WEBSITE_SUCCESS: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
        data: action?.response?.data?.message
      };
    }

    case RESTORE_WEBSITE_ERROR: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case GET_AUDIT_DATA: {
      return { ...state, getWebsiteSettingsLoading: true };
    }

    case GET_AUDIT_DATA_SUCCESS: {
      return {
        ...state,
        getWebsiteSettingsLoading: false
      };
    }

    case GET_AUDIT_DATA_ERROR: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case GET_AUDIT_SCORE: {
      return { ...state, getWebsiteSettingsLoading: true };
    }

    case GET_AUDIT_SCORE_SUCCESS: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case GET_AUDIT_SCORE_ERROR: {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
      };
    }

    case GET_AUDIT_RULE: {
      return { ...state };
    }

    case GET_AUDIT_RULE_SUCCESS: {
      return { ...state };
    }

    case GET_AUDIT_RULE_ERROR: {
      return { ...state };
    }

    case AUDIT_STAGE: {
      return {
        ...state,
        auditStage: action.payload.payload,
      };
    }

    case GET_AUDIT_STAGE: {
      return { ...state, stageInfo: null };
    }

    case GET_AUDIT_STAGE_SUCCESS: {
      return {
        ...state,
        auditStatus: action.response
      };
    }

    case GET_AUDIT_STAGE_ERROR: {
      return {
        ...state,
      };
    }

    case GET_ACTIVE_SUBSCRIPTION: {
      return { ...state, stageInfo: null };
    }

    case GET_ACTIVE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        activeSubscription: action?.response?.data?.data?.plan
      };
    }

    case GET_ACTIVE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
      };
    }

    case GET_WORKSPACE_SITES: {
      return { ...state };
    }

    case GET_WORKSPACE_SITES_SUCCESS: {
      return {
        ...state,
        workSpaceSitesCount: action?.response?.data?.data
      };
    }

    case GET_WORKSPACE_SITES_ERROR: {
      return {
        ...state,
      };
    }

    case GET_SITE_DATA: {
      return { ...state };
    }

    case GET_SITE_DATA_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_SITE_DATA_ERROR: {
      return {
        ...state,
      };
    }

    case EDIT_WORKSPACE: {
      return { ...state, editUserLoading: true };
    }

    case EDIT_WORKSPACE_SUCCESS: {
      return {
        ...state,
        editUserLoading: false,
        data: action?.response?.data?.message
      };
    }

    case EDIT_WORKSPACE_ERROR: {
      return {
        ...state,
        editUserLoading: false,
      };
    }

    case GET_WORKSPACE_SITE_DATA: {
      return { ...state };
    }

    case GET_WORKSPACE_SITE_DATA_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_WORKSPACE_SITE_DATA_ERROR: {
      return {
        ...state,
      };
    }

    case GET_WORKSPACE_USERS: {
      return { ...state };
    }

    case GET_WORKSPACE_USERS_SUCCESS: {
      return {
        ...state,
        currentWorkspaceUsersCount: action?.response?.data?.data?.count[0]?.count
      };
    }

    case GET_WORKSPACE_USERS_ERROR: {
      return {
        ...state,
      };
    }

    case SHARE_REPORT: {
      return { ...state, loading: true };
    }

    case SHARE_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SHARE_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SIGNATURE: {
      return { ...state };
    }

    case GET_SIGNATURE_SUCCESS: {
      return { ...state };
    }

    case GET_SIGNATURE_ERROR: {
      return { ...state };
    }

    default:
      return { ...state };
  }
};

// exports
export default dashboardReducer;
