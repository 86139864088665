// library imports
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import LocalStorageHandler from "./utils/StateCacheStorage";
import { connect } from "react-redux";
import posthog from 'posthog-js'

// components
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import ForgotPassword from "./pages/ForgotPassword";
import EnterOtp from "./pages/EnterOtp";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import OrganizationSetup from "./pages/organizationSetup";
import Organization from "./pages/organization/organizationList";
import ProtectedRoute from "./components/protectedRoute";
import Users from "./pages/users/usersList";
import UsersDetail from "./pages/users/usersDetail";
import WorkspaceDetail from "./pages/workspace/workspaceDetail";
import Websites from "./pages/websites/websitesList";
import ViewWebsite from "./pages/websites/viewWebsite";
import ViewOrganization from "./pages/organization/viewOrganization";
import AddOrganization from "./pages/organization/addOrganization";
import EditOrganization from "./pages/organization/editOrganization/index";
import Reports from "./pages/websites/reports";
import ViewAnalytics from "./pages/websites/viewAnalytics";
import ViewFullReport from "./pages/websites/viewFullReport";
import ViewReport from "./pages/websites/viewReport";
import Register from "./pages/register";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import InternetDisconnected from "./pages/InternetDisconnected";
import Settings from "./pages/websites/settings";
import VerifyUser from "./pages/verifyUserEmail";
import Profile from "./pages/profile";
// constants
import { RoutingConstants } from "./constants";
import { useSelector } from "react-redux";


import { auditStage } from "./store/actions/dashboard";

// destructuring
const {
  organization,
  dashboard,
  login,
  root,
  users,
  workspace,
  websites,
  register,
  forgotPassword,
  enterOtp,
  resetPassword,
  changePassword,
  verifyUserEmail,
  profile
} = RoutingConstants;

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    // remove when unmounted
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
const useViewport = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })

const App = (props) => {
  const {triggerAuditStage, auditStage} = props
  const [isOnline, setIsOnline] = React.useState(true);
  const handleNetworkStatus = (e) => setIsOnline(e.target.navigator.onLine);

  React.useEffect(() => {
    window.addEventListener("offline", handleNetworkStatus);
    window.addEventListener("online", handleNetworkStatus);

    // remove when unmounted
    return () => {
      window.removeEventListener("online", handleNetworkStatus);
      window.removeEventListener("offline", handleNetworkStatus);
    };
  }, []);

  const isLoggedIn = useSelector((store) => store.auth.loggedIn);
  const SERVER = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const socket = io(SERVER, {
      transports: ["websocket"],
    });

    isLoggedIn && socket.on("connect");

    socket.emit("authorize", { accessToken: LocalStorageHandler.get("ACCESS_TOKEN") });
    socket.on("authorized");
    const auditLevel = auditStage.length > 0 ? JSON.parse(auditStage) : []
    socket.on("auditReport", (socket) => {
      const filteredArray = auditLevel.filter((i) => {return i.jobId !== socket.jobId })
      filteredArray.push(socket)
      let auditStage = JSON.stringify(filteredArray)
      triggerAuditStage({ 
        payload: auditStage
      });
    });

    socket.on("disconnect");

    socket.on("connect_error");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn]);

  return (
    <ViewportProvider>
      <BrowserRouter>
        {!isOnline ? (
          <Routes>
            <Route path="*" element={<InternetDisconnected />} />
          </Routes>
        ) : (
          <Routes>
            {/* public routes can be added at top level */}
            <Route exact path={register} element={<Register />}></Route>
            <Route exact path={login} element={<Login />}></Route>
            <Route
              exact
              path={forgotPassword}
              element={<ForgotPassword />}
            ></Route>
            <Route exact path={enterOtp} element={<EnterOtp />}></Route>
            <Route
              exact
              path={resetPassword}
              element={<ResetPassword />}
            ></Route>
            <Route exact path={changePassword} element={<ChangePassword />} />
            <Route exact path={verifyUserEmail} element={<VerifyUser />} />
            {/* if logged in, show dashboard, else show login screen -- on user requesting "/" */}
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={root} element={<Dashboard />} />
            </Route>

            {/* protected routes can be added at one level nested to / - root */}
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={organization.setup}
                element={<OrganizationSetup />}
              />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={dashboard} element={<Dashboard />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={organization.orgRoot}
                element={<Organization />}
              />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={organization.view}
                element={<ViewOrganization />}
              />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={organization.add}
                element={<AddOrganization />}
              />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={organization.edit}
                element={<EditOrganization />}
              />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={users.usersList} element={<Users />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={users.detail} element={<UsersDetail />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={workspace.workspacedetail} element={<WorkspaceDetail />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={websites.webRoot} element={<Websites />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={websites.view} element={<ViewWebsite />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={websites.reports} element={<Reports />} />
            </Route>
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route exact path={websites.settings} element={<Settings />} />
            </Route>

            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={websites.view_analytics}
                element={<ViewAnalytics />}
              />
            </Route>
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={websites.view_report}
                element={<ViewFullReport />}
              />
            </Route>
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={websites.view_report_with_rules}
                element={<ViewFullReport />}
              />
            </Route>
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={websites.view_report_interim}
                element={<ViewReport />}
              />
            </Route>
            <Route exact path={root} element={<ProtectedRoute />}>
              <Route
                exact
                path={profile}
                element={<Profile />}
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
        <ToastContainer
          position="bottom-right"
          theme="colored"
          style={{
            width: 480,
          }}
          autoClose={2500}
          pauseOnHover={false}
          newestOnTop={true}
          bodyClassName={"text-sm"}
          limit={1}
        />
      </BrowserRouter>
    </ViewportProvider>
  );
};

export { useViewport };
// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    auditStage: state.dashboard.auditStage
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerAuditStage: auditStage,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(App);
