// user imports
import authActions from "./auth";
import dashboardActions from "./dashboard";

// combined action types
const actionTypes = {
  auth: authActions,
  dashboard: dashboardActions,
};

// exports
export default actionTypes;
