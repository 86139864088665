/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from "@mui/material/Stack";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import SectionLoader from "../SectionLoader";

// components
import AddWebsite from "../../components/websites/addWebsite";
import UpgradePlan from "../plan/upgradePlan";

// Actions
import { editUser, mapUserWebsites, getWorkspaceSiteData, restoreWebsite } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import PieLoader from "../PieLoader";

/**
 * @function websites
 * @returns {JSX}
 */
const ManageSites = (props) => {
  const { selectedOrgId, selectedOrg, triggerSiteData, orgsList, triggerRestoreWebsite, roleId } = props;
  const [totalSites, setTotalSites] = useState(null)
  const [websites, setWebsites] = useState(null)
  const { triggerLogout } = props;
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [elementsLimit, setElementsLimit] = useState(25)
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const [loadingId, setLoadingId] = useState(null);
  const [apiRes, setApiRes] = useState(null)

  useEffect(() => {
    triggerSiteData({
      payload: {
        id: selectedOrgId,
        limit: elementsLimit,
        page: page
      },
      onSuccess: (res) => {
        setTotalSites(res?.count[0]?.count)
        setWebsites(res?.websites)
        setPageLimit(
          res?.count[0]?.count % elementsLimit === 0
            ? Math.floor(res?.count[0]?.count / elementsLimit)
            : Math.floor(res?.count[0]?.count / elementsLimit + 1) || 1
        );
      },
      onError: (errorMsg) => {
        setErrorFromApi({
          type: "error",
          msg: errorMsg || "Something went wrong!!!",
        });
        setTimeout(() => {
          setErrorFromApi(null);
        }, 3000);
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  },[orgsList, elementsLimit, selectedOrgId, page, triggerSiteData, triggerLogout])

  const restoreSite = (e, val) => {
    e.preventDefault();
    setLoadingId(val.id);
    setApiRes(null);
    const payload = {
      id: val.id
    }
    triggerRestoreWebsite({ 
      payload, 
      onSuccess: (res) => {
        setApiRes({
          type: "success",
          msg: res?.data.message
        });
        setLoadingId(null);
      }, 
      onError: (error) => {
        setApiRes({
          type: "error",
          msg: error
        });
        setLoadingId(null);
      } 
    });
  };

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="inset-0 z-50 outline-none focus:outline-none">
          <div className="relative mb-80">
            <div>
              {apiRes !== null && (
                <div
                  className={`${
                    apiRes?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 my-4 flex items-center flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        apiRes?.type === "error"
                          ? "#FF5353"
                          : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${apiRes?.msg}`}</p>
                </div>
              )}
              <div className="flex flex-wrap items-center justify-between border-b border-grayLight pb-3 md:pb-5 font-semibold">
                <h3 className="flex items-center text-sm md:text-lg">
                  Manage sites
                </h3>
                {roleId === 0 && <div className="ml-auto">
                  <AddWebsite icon org_id={selectedOrg?.id} truncate={true} />
                </div>}
              </div>
              <section className="h-[60vh] overflow-auto">
              {websites?.length > 0 ?
                websites.map((val, index) => {
                  return (
                    <div
                      className="group text-xs md:text-sm border-b border-grayBorder py-2 grid grid-cols-9 md:grid-cols-12 items-center"
                      key={index}
                    >
                      <p className="font-medium col-span-2">{val.name}</p>
                      <p className="w-full truncate col-span-3 sm:col-span-4">{val.url}</p>
                      <div className="col-span-3 flex items-center gap-6">
                      <p className={` ${val.plan_name && 'border'} px-1 py-2 w-[105px] h-auto text-xs leading-[10px] uppercase text-center`}>{val.plan_name}</p>
                      {val.is_deleted && <div className={`${val.is_deleted && 'border'} cursor-pointer px-1 py-2 w-[105px] h-auto text-xs leading-[10px] uppercase text-center ${loadingId === val.id ? 'text-red-500 border-red-500': ''}`} onClick={(e) => restoreSite(e, val)}>
                        {loadingId === val.id ? (
                          <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={true}
                              size={5}
                            />
                            <span className="ml-2">Loading</span>
                          </div>
                        ) : (
                          "Restore"
                        )}
                      </div>}
                      </div>
                      {roleId === 0 && 
                        <div
                          className="bg-white flex text-xs font-normal w-max ml-auto col-span-3"
                          style={{ transition: "all 500ms ease" }}
                        >
                          {!val.is_deleted &&
                          <>
                            <Link to={"/website/" + val.id + "/settings"} key={index} className="group">
                              <p style={{transition: "all 500ms ease"}} className="w-0 group-hover:w-12 cursor-pointer bg-primary text-white text-1xs flex items-center justify-center font-bold md:py-1 overflow-hidden mr-1">EDIT</p>
                            </Link>
                            <div style={{transition: "all 500ms ease"}} className="w-0 group-hover:w-16 cursor-pointer bg-white text-primary text-1xs flex items-center justify-center overflow-hidden group-hover:border border-primary"> <UpgradePlan content={`UPGRADE`}/></div>                  
                          </>
                          }
                        </div>
                      }
                    </div>
                  );
                }) :
                <div className="p-6 py-4 text-center flex items-center justify-center h-full">
                  <SectionLoader
                    content={websites === null?[ "Fetching User Data"]: websites?.length === 0? ["No data to display"]: ["we couldn't find any results"]}
                    isNoData={websites === null ? false : true}
                  /> 
                </div>
                }
                {totalSites > 0 && <div className="flex w-full items-center justify-between absolute border border-grayLight bottom-[-70px] bg-white flex-col sm:flex-row">
                  <div className="flex w-full w-fit justify-center pageCount border-b border-grayLight border-0 font-medium">
                    <p className="h-10 px-4 py-2 border-r border-grayLight flex items-center border-l md:border-l-0 justify-center">Show</p>
                    <div 
                      className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalSites <= 0 && 'text-gray pointer-events-none'} ${elementsLimit === 25 ? 'bg-primary text-white pointer-events-none' : ''}`}
                      onClick={() => {setElementsLimit(25); setPage(1)}}
                    >
                      25
                    </div>
                    <div 
                      className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalSites <= 25 && 'text-gray pointer-events-none'} ${elementsLimit === 50 ? 'bg-primary text-white pointer-events-none' : ''}`}
                      onClick={() => {setElementsLimit(50); setPage(1)}}
                    >
                      50
                    </div>
                    <div 
                      className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalSites <= 50 && 'text-gray pointer-events-none'} ${elementsLimit === 100 ? 'bg-primary text-white pointer-events-none' : ''}`}
                      onClick={() => {setElementsLimit(100); setPage(1)}}
                    >
                      100
                    </div>
                  </div>
                  <Stack spacing={2}>
                    <Pagination
                      size="small"
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                      renderItem={(item) => (
                        <PaginationItem
                          components={{ previous: HiOutlineArrowLeft, next: HiOutlineArrowRight }}
                          {...item}
                        />
                      )}
                      count={pageLimit}
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
                </div>}
              </section>
              {errorFromApi !== null && (
                <div
                  className={`${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 my-8 flex items-center flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    selectedOrgId: state.dashboard.selectedOrganization,
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
    orgsList: state.dashboard.data?.organizations || [],
    currentOrgUsers: state.dashboard.currentOrgUsers,
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    roleId: state.auth.roleId
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
  mapUserWebsites,
  triggerSiteData: getWorkspaceSiteData,
  triggerRestoreWebsite: restoreWebsite,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ManageSites);
