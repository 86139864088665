// library imports
import React from "react";
import { BsHourglassSplit } from "react-icons/bs";
import ScreenIsMobile from "../utils/ScreenIsMobile";

/**
 * @function Dialog
 * @returns {JSX}
 */
const Dialog = ({ title, subtitle, onYesClick, onNoClick }) => {
  const isMobile = ScreenIsMobile();
  return (
    <div id="modal" className="text-black">
      <div className="overflow-x-hidden overflow-y-auto mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed inset-0 z-50 outline-none focus:outline-none flex items-center justify-center pb-14 sm:pb-0">
        <div className="relative mx-auto w-10/12 lg:w-2/6 flex">
          <div className="lg:shadow-lg relative w-full bg-white">
            <div className="relative">
              <div className="lg:p-10">
                <BsHourglassSplit className="text-3xl text-orange-500 m-auto" />
                <h3 className="text-xl font-bold my-5 text-center text-primary">
                  {title}
                </h3>
                <p className="max-w-sm m-auto text-center text-primary">
                  {subtitle}
                </p>
              </div>
              <div className={`grid grid-cols-6 mt-5 bg-white ${isMobile && "fixed bottom-0 w-full left-0"}`}>
                <button
                  onClick={onNoClick}
                  className="col-span-2 py-4 font-bold lg:text-xl text-grayNobel border-t border-grayBorder"
                >
                  {"No"}
                </button>
                <button
                  onClick={onYesClick}
                  className={`col-span-4 font-bold lg:text-xl text-white py-4 tracking-small bg-primary border-cyanBlue`}
                  type="button"
                >
                  {"Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

// default exports
export default Dialog;
