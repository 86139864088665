// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// constants
import { Strings } from "../constants";

// Actions
import { createUser, verifyUser, getSignature } from "../store/actions/dashboard";
import { logOut } from "../store/actions/auth";

// icons
import PieLoader from "../components/PieLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// token expired handler
import TokenExpired from "../utils/TokenExpired";

/**
 * @function EmbedScript
 * @returns {JSX}
 */
const EmbedScript = (props) => {
//   const { triggerCreateUser, triggerLogout } = props;
  // state variables
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState('')
  const { id } = useParams();

  const {
    triggerLogout,
    selectedOrgId,
    selectedWebsites,
    verifyUser,
    triggerGetSignature,
    setShowEmbedScript
  } = props;

  useEffect(() => {
    triggerGetSignature({
      payload: {
        orgId: selectedOrgId,
      },
      onSuccess: (res) => {
        setSignature(res?.script)
        setErrorFromApi(null)
      },
      onError: (errorMsg) => {
        setLoading(false);
        setErrorFromApi(errorMsg)
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  }, [selectedOrgId, triggerGetSignature, triggerLogout])

  const selectedWebsite = selectedWebsites.find(web => web.id === parseInt(id))

  const onSuccess = () => {
    setLoading(false);
    setErrorFromApi(null)
    setShowEmbedScript(false)
  }
  const onError = (errorMsg) => {
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Please embed the Wally Script and Verify Installation",
    });
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  }
  const verifyInstallation = (e) => {
    e.preventDefault();
    setErrorFromApi(null)
    setLoading(true);
    const verifyObj = {
      url : selectedWebsite.url
    }
    const auditObj = {
      url : selectedWebsite.url,
      orgId: selectedOrgId,
      siteId: selectedWebsite.id
    }
    verifyUser({ verifyObj, auditObj, onSuccess, onError });
  };

  return (
    <div id="modal" className="text-black">
      <div className="overflow-x-hidden overflow-y-auto mt-14 lg:mt-0 bg-white lg:bg-primary/75 absolute mx-auto inset-0 z-50 outline-none focus:outline-none flex items-center justify-center pb-14 sm:pb-0">
        <div className="relative mx-auto w-10/12 lg:w-1/2 flex">
          <div className="lg:shadow-lg relative w-full bg-white">
            <div className="relative text-left">
              <h3 className="text-2xl text-primary font-bold lg:px-10 py-6">Embed Wally Script</h3>
              <form className="w-full">
                <div className="lg:px-10">
                  <fieldset className="w-full m-auto text-sm sm:mb-32">
                    <div className="text-lg font-bold mb-2">
                      Add this code in to all of your site’s pages &lt;head&gt;&lt;/head&gt;
                    </div>
                    <div className="`w-full border border-gray bg-tileGray outline-none focus:border-primary py-3 px-4 font-medium">
                      {signature}
                    </div>
                  </fieldset>
                  {errorFromApi !== null && (
                    <div
                      className={`${
                        errorFromApi?.type === "error"
                          ? "bg-floralWhite "
                          : "bg-aliceBlue"
                      } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto`}
                    >
                      <div className="mr-2">
                        <BsFillExclamationSquareFill
                          color={
                            errorFromApi?.type === "error"
                              ? "#FF5353"
                              : "#000000"
                          }
                          className="text-2xl"
                        />
                      </div>
                      <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0">
                  <button
                    className="col-span-2 py-4 font-bold text-sm text-grayNobel border-t border-grayBorder"
                    type="button"
                    onClick={() => {
                      setErrorFromApi(null)
                      setLoading(false);
                      setShowEmbedScript(false);
                    }}
                  >
                    {"CANCEL"}
                  </button>
                  <button
                    type="button"
                    className={`${loading && "pointer-events-none"} col-span-4 font-bold text-sm text-white py-4 tracking-small bg-cyanBlue border-cyanBlue `}
                    onClick={verifyInstallation}
                  >
                    {loading ? (
                      <div className="flex flex-row justify-center items-center">
                        <PieLoader
                          color={"white"}
                          loading={loading}
                          size={5}
                        />
                        <span className="ml-2">{Strings.loading}</span>
                      </div>
                    ) : 
                      'Verify Installation'
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
    selectedWebsites: state.dashboard.data?.organizations.find(org => org.id === state.dashboard.selectedOrganization).websites || ''
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateUser: createUser,
  triggerLogout: logOut,
  verifyUser,
  triggerGetSignature: getSignature
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EmbedScript);
