/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import posthog from 'posthog-js'

// Actions
import { createWebsite, getWorkspaceSites, shareReport } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import { useParams } from "react-router-dom";
import PieLoader from "../PieLoader";

/**
 * @function ShareReport
 * @returns {JSX}
 */
const ShareReport = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const { jobId } = useParams();
  const { triggerLogout, orgsList, selectedOrgId, triggerShareReport } = props;
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const isCurrentWebsiteActive = orgsList.find(i => i.id === selectedOrgId).isActive

  const {
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: 'onChange',
  });
  const [inputList, setInputList] = useState([{ name: "", email: "" }]);
  const [formError, setFormError] = useState([]);

  const handleShareReport = (e) => {
    e.preventDefault();
    if(isCurrentWebsiteActive) {setShowModal(true);}
  };

  const handleCancel = () => {
    setShowModal(false);
    setInputList([{ name: "", email: "" }])
    setFormError([])
  }

  const isValid = (email) =>{
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  const onSuccess = () => {
    posthog.capture('Shared report successfully', { property: 'value' })
    setErrorFromApi({
      type: "success",
      msg: "Report shared successfully",
    });
    setLoading(false);
    setTimeout(() => {
      setShowModal(false);
      setErrorFromApi(null);
      setInputList([{ name: "", email: "" }])
      setFormError([])
    }, 3000);
  };

  const onError = (errorMsg) => {
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setLoading(false);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", email: "" }]);
  };
  var isError = false;

  const submit = () => {
    const errorMsg = inputList.map((list, key) => {
      let error = {};
      if (!list.name) {
        error.errorname = "Name is required";
        isError = true;
      } else {
        error.errorname = "";
      }

      if (!list.email) {
        error.erroremail = "Email is required";
        isError = true;
      } else if (!isValid(list.email)) {
        error.erroremail = "Please Enter valid email address";
        isError = true;
      } else {
        error.erroremail = "";
      }
      return error;
    });
    setFormError(errorMsg);
    if (!isError) {
      setLoading(true);
      const payload = {
        jobId : jobId,
        contacts : inputList
      }
      triggerShareReport({ payload, onSuccess, onError });
    }
  };

  return (
    <section>
      <div className="cursor-pointer border py-1 px-3 flex items-center gap-2" onClick={(e) => {handleShareReport(e)}}>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
        </svg>
        Share
      </div>
      {showModal === true && (
        <div id="modal" className="text-black">
          <div className="mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed mx-auto inset-0 z-50 outline-none focus:outline-none lg:h-full pb-14 sm:pb-0">
            <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
              <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                <div className="relative">
                  <div className="flex justify-between items-center border-b py-4 px-6">
                    <h3 className="text-2xl font-bold">Share Report</h3>
                    <button className="py-2 px-4 font-bold text-sm border flex items-center gap-2" type="button" onClick={handleCancel} >
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.09756 1.34146L1.09756 8.84146M8.90244 8.84146L0.902439 1.34146" stroke="black" strokeWidth="2"/>
                      </svg>
                      {"Close"}
                    </button>
                  </div>
                  <form onSubmit={handleSubmit(submit)} className="w-full my-4">
                    <div className="lg:px-6 lg:mb-10 pt-6">
                      <fieldset className="w-full m-auto text-sm mb-4">
                        <div className="text-left text-sm font-semibold">
                          Report Link
                        </div>
                        <div className="border-b border-grayBorder py-2">
                          <a href={`https://reports.wallyax.com/view/${jobId}`} target="_blank" rel="noreferrer">{`https://reports.wallyax.com/view/${jobId}`}</a>
                        </div>
                      </fieldset>
                      <fieldset>
                        {inputList.map((x, i) => {
                          return (
                            <div className="flex gap-5 mb-4" key={i}>
                              <div className="flex-grow">
                                <label className="text-left text-sm font-semibold">Name</label>
                                <input
                                  type="text"
                                  className="w-full overflow-x-auto text-sm text-black border-b border-grayBorder outline-none focus:border-primary py-2 font-medium"
                                  name="name"
                                  value={x.name}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                                {x.name === "" && <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                  {formError[i]?.errorname}
                                </div>}
                              </div>
                              <div className="flex-grow">
                                <label className="text-left text-sm font-semibold">Email</label>
                                <input 
                                  className="w-full overflow-x-auto text-sm text-black border-b border-grayBorder outline-none focus:border-primary py-2 font-medium" 
                                  type="text" 
                                  name="email" 
                                  value={x.email}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                                {(x.email === "" || !isValid(x.email)) && <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                  {formError[i]?.erroremail}
                                </div>}
                              </div>
                              <div className="w-14 mt-7">
                                <div className="flex items-center gap-2">
                                  {inputList.length !== 1 && (
                                    <button className="border-2 border-[#F44336] p-[1px] rounded-full button remove" onClick={() => handleRemoveClick(i)}>
                                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="15px" height="15px"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"/><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"/></svg>
                                    </button>
                                  )}
                                  {inputList.length - 1 === i && inputList.length < 10 && (
                                    <button  className="button add" onClick={handleAddClick}>
                                      <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z"/></svg>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </fieldset>
                      <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0 ">
                        <button className={`font-bold text-sm text-white py-3 px-6 tracking-small ${(inputList?.length <1 || loading) ? 'bg-gray border-gray ' : 'bg-cyanBlue border-cyanBlue' }`} type="submit">
                          {loading ? (
                            <div className="flex flex-row justify-center items-center">
                              <span className="mx-2 ">Loading</span>
                              <PieLoader
                                color={"white"}
                                loading={loading}
                                size={5}
                              />
                            </div>
                          ) : (
                            <div className="inline-flex items-center gap-3 text-base">
                              Send
                              <svg width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.979492 8H14.0407" stroke="white"/>
                                <path d="M9.63257 3.56104L14.3673 7.99981L9.63257 12.4386" stroke="white"/>
                              </svg>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                    {errorFromApi !== null && (
                      <div className="w-full lg:px-6">
                        <div
                          className={`${
                            errorFromApi?.type === "error"
                              ? "bg-floralWhite "
                              : "bg-aliceBlue"
                          } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto w-full`}
                        >
                          <div className="mr-2">
                            <BsFillExclamationSquareFill
                              color={
                                errorFromApi?.type === "error"
                                  ? "#FF5353"
                                  : "#000000"
                              }
                              className="text-2xl"
                            />
                          </div>
                          <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
    workSpaceSitesCount: state.dashboard.workSpaceSitesCount,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateWebsite: createWebsite,
  triggerLogout: logOut,
  triggerWorkspaceSites: getWorkspaceSites,
  triggerShareReport: shareReport,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ShareReport);
