/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../../../components/PieLoader";
import SectionLoader from "../../../components/SectionLoader";

// constants
import { RoutingConstants, Strings } from "../../../constants";
import ScreenIsMobile from "../../../utils/ScreenIsMobile";

// Actions
import { updateOrgwebsites, removeOrgwebsites } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";

// token expired handler
import TokenExpired from "../../../utils/TokenExpired";

const EditOrganization = (props) => {
  // destructure props
  const isMobile = ScreenIsMobile();
  const {
    triggerUpdateOrgWebsites,
    triggerLogout,
    orgsList,
    isTempPassword,
    triggerRemoveOrgwebsites,
  } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const currentOrgData = orgsList.find((org) => org.id === parseInt(id));
  const displayWebsites = currentOrgData?.websites
    ?.filter((web) => !web.isDeleted)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .map((i) => i.url);

  const renderWebsites = currentOrgData?.websites
    ?.filter((web) => !web.isDeleted)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  const [showModal, setShowModal] = useState(false);


  const [newlyAddingUrl, setNewlyAddingUrl] = useState("");
  const [newlyAddingDomainName, setNewlyAddingDomainName] = useState("");
  const [showAddUrlError, setShowAddUrlError] = useState({
    msg: "",
    show: false,
  });
  const [showAddDomainNameError, setShowAddDomainNameError] = useState({
    msg: "",
    show: false,
  });
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [webremoveLoading, setWebRemoveLoading] = useState(null);

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  const checkForExistingWebsite = (newlyAddedSite) => {
    // check if URL exists already
    let websitesFromCurrentLogin = [];
    if (orgsList?.length > 0) {
      orgsList?.map((org) => {
        if (org?.websites?.length > 0) {
          org?.websites?.map((website) => {
            if (website?.url?.length > 0) {
              websitesFromCurrentLogin.push(website?.url);
            }
          });
        }
      });
    }
    const existsAlready = websitesFromCurrentLogin.includes(newlyAddedSite);
    return existsAlready;
  };

  const webSiteRemoveSuccess = (sucessMsg) => {
    setErrorFromApi({
      type: "success",
      msg: sucessMsg || "Site removed successfully",
    });
    setWebRemoveLoading(null);
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };
  const webSiteRemoveError = (errorMsg) => {
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    setWebRemoveLoading(null);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };
  const removeWeb = (domainName) => {
    setWebRemoveLoading(domainName);
    const webId = [...currentOrgData.websites].find(
      (web) => web.url === domainName
    );
    triggerRemoveOrgwebsites({
      id: webId.id,
      webSiteRemoveSuccess,
      webSiteRemoveError,
    });
  };

  const onSuccess = (successMsg) => {



    setNewlyAddingUrl("");
    setNewlyAddingDomainName("");
    reset(
      {
        domain: "",
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
    setErrorFromApi({
      type: "success",
      msg: "Site added successfully",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    setNewlyAddingUrl("");
    setNewlyAddingDomainName("");
    reset(
      {
        domain: "",
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const submit = (e) => {
    if (newlyAddingUrl.trim().length === 0 && !errors.domain) {
      setShowAddUrlError({
        show: true,
        msg: "* Please enter site URL",
      });
      return;
    }

    // check if URL exists already
    if (displayWebsites?.includes(newlyAddingUrl.trim())) {
      setShowAddUrlError({
        show: true,
        msg: "* URL exists already",
      });
      return;
    }
    const isExisting = checkForExistingWebsite(newlyAddingUrl.trim());
    if (isExisting) {
      setShowAddUrlError({
        show: true,
        msg: "* URL exists already",
      });
      return;
    }
    if (errors.domain && errors.domainName) {
      return;
    }
    setShowModal(false);
    setLoading(true);
    const payload = {
      url: newlyAddingUrl,
      name: newlyAddingDomainName,
      organization: currentOrgData.id,
    };
    triggerUpdateOrgWebsites({
      websiteObj: payload,
      onSuccess,
      onError,
    });
  };
  const inputsValidated =
    newlyAddingUrl?.length >= 10 &&
    newlyAddingDomainName?.length > 4 &&
    !errors.domainName &&
    !errors.domain;

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="inset-0 z-50 outline-none focus:outline-none">
          <div className="sm:px-4">
            <p className="text-black text-lg font-bold pb-4 border-b border-grayBorder ">Manage Sites</p>
          </div>
          <div className="relative sm:px-4">
            <div className="w-full">
              {/* If no websites to display */}
              {renderWebsites?.length === 0 ? (
                <div className="w-full text-center">
                  <SectionLoader
                  content={["No data to display."]}
                  isNoData={true}
                />
                <p className="max-w-sm m-auto text-gray">
                  {"Please add the valid website url below"}
                </p>
                </div>
              ) : (
                // If websites are available to display
                <div className="w-full">
                  <div>
                    {renderWebsites?.map((val, index) => {
                      return (
                        <div
                          className="text-sm border-b border-grayBorder group grid grid-cols-8 items-center py-2"
                          key={index.toString()}
                        >
                          <p className="font-medium col-span-2">{val.name}</p>
                          <p className="w-full truncate font-light col-span-4 sm:col-span-5"> {val.url} </p>
                          <button
                            onClick={() => removeWeb(val.url)}
                            style={{ transition: "all 500ms ease" }}
                            type="button"
                            disabled={webremoveLoading === val.url}
                            className={`${
                              webremoveLoading === val.url
                                ? "bg-red-500 text-white opacity-100"
                                : "text-red-500 border-red-500 bg-white opacity-100 md:opacity-0"
                            } group-hover:opacity-100 border px-2 py-1 text-xs font-normal mb-2 md:mb-0 w-max ml-auto col-span-2 sm:col-span-1`}
                          >
                            {webremoveLoading === val.url ? (
                              <div className="flex flex-row justify-center items-center">
                                <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                                <span className="ml-1">{Strings.loading}</span>
                              </div>
                            ) : (
                              `Remove`
                            )}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {showModal === true && (
                <div id="modal" className="text-black">
                  <div className="mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed mx-auto inset-0 z-50 outline-none focus:outline-none flex items-center justify-center pb-14 sm:pb-0">
                    <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
                      <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                        <div className="relative">
                          <h3 className="text-2xl text-primary font-bold lg:px-10 py-6">
                            {"Add Website"}
                          </h3>
                          <form onSubmit={handleSubmit(submit)}>
                            <div className="lg:px-10 lg:mb-10">
                              <fieldset className="w-full m-auto text-sm mb-4">
                                <div className="text-sm text-grayDeep font-semibold mb-2">
                                  <span>{"Website url "}</span>
                                  <span className="text-lg text-red-500 font-bold">{"*"}</span>
                                </div>
                                <input
                                  value={newlyAddingUrl.toLowerCase()}
                                  type="text"
                                  className={`w-full border text-sm border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                                    errors.domain && "border-red-500"
                                  }`}
                                  id="domain"
                                  name="domain"
                                  placeholder="https://www.example.com"
                                  {...register("domain", {
                                    required: {
                                      value: true,
                                      message: "* Please enter site URL",
                                    },
                                    maxLength: {
                                      value: 60,
                                      message:
                                        "* Maximum 60 characters allowed",
                                    },
                                    minLength: {
                                      value: 10,
                                      message:
                                        "* Minimum 10 characters required",
                                    },
                                    pattern: {
                                      value:
                                        /(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                                      message:
                                        "* Please enter valid URL",
                                    },
                                  })}
                                  onChange={(e) => {
                                    setShowAddUrlError({
                                      show: false,
                                      msg: "",
                                    });
                                    setNewlyAddingUrl(e.target.value.trim());
                                  }}
                                />
                                {showAddUrlError?.show && !errors.domain && (
                                  <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {showAddUrlError?.msg}
                                  </span>
                                )}
                                {errors.domain && (
                                  <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {errors.domain.message}
                                  </span>
                                )}
                              </fieldset>
                              <fieldset className="w-full m-auto text-sm mb-4">
                                <div className="text-sm text-grayDeep font-semibold mb-2">
                                  <span>{"Website Name "}</span>
                                  <span className="text-lg text-red-500 font-bold">{"*"}</span>
                                </div>
                                <input
                                  value={newlyAddingDomainName}
                                  type="text"
                                  className={`w-full border text-sm border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                                    errors.domainName && "border-red-500"
                                  }`}
                                  id="domainName"
                                  name="domainName"
                                  placeholder={
                                    isMobile
                                      ? "Short identification name"
                                      : "Short identification name for this website"
                                  }
                                  {...register("domainName", {
                                    required: {
                                      value: true,
                                      message: "* Please enter site name",
                                    },
                                    maxLength: {
                                      value: 40,
                                      message:
                                        "* Maximum 40 characters allowed",
                                    },
                                    minLength: {
                                      value: 4,
                                      message:
                                        "* Minimum 4 characters required",
                                    },
                                    pattern: {
                                      value: /^[A-Za-z0-9 ]+$/,
                                      message: "* Only alphanumeric characters allowed",
                                    },
                                  })}
                                  onChange={(e) => {
                                    setShowAddDomainNameError({
                                      show: false,
                                      msg: "",
                                    });
                                    setNewlyAddingDomainName(
                                      e.target.value.trim()
                                    );
                                  }}
                                />
                                {showAddDomainNameError?.show &&
                                  !errors.domain && (
                                    <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                      {showAddDomainNameError?.msg}
                                    </span>
                                  )}
                                {errors.domainName && (
                                  <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {errors.domainName.message}
                                  </span>
                                )}
                              </fieldset>
                            </div>
                            <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0">
                              <button
                                className="col-span-2 py-4 font-bold text-sm text-grayNobel border-t border-grayBorder"
                                type="button"
                                onClick={() => {
                                  setNewlyAddingUrl("");
                                  setNewlyAddingDomainName("");
                                  setShowAddUrlError({
                                    show: false,
                                    msg: "",
                                  });
                                  setShowAddDomainNameError({
                                    show: false,
                                    msg: "",
                                  });
                                  reset(
                                    {
                                      domain: "",
                                    },
                                    {
                                      keepErrors: false,
                                      keepDirty: true,
                                      keepIsSubmitted: false,
                                      keepTouched: false,
                                      keepIsValid: false,
                                      keepSubmitCount: false,
                                    }
                                  );
                                  setShowModal(false);
                                }}
                              >
                                {'Cancel'}
                              </button>
                              <button
                                className={`${
                                  inputsValidated
                                    ? `bg-cyanBlue border-cyanBlue `
                                    : `bg-gray border-gray`
                                } col-span-4 font-bold text-sm text-white py-4 tracking-small`}
                                type="submit"
                              >
                                {"Submit"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
                </div>
              )}
              {errorFromApi !== null && (
                <div
                  className={`${
                    errorFromApi?.type === "error"
                      ? "bg-floralWhite "
                      : "bg-aliceBlue"
                  } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto`}
                >
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color={
                        errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                      }
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                </div>
              )}
              <div className="text-right w-full mr-10">
                <button
                  className={`${
                    loading
                      ? "bg-gray pointer-events-none"
                      : "bg-primary"
                  } text-sm cursor-pointer font-bold p-3 px-6 my-8 mr-8 tracking-small w-full text-white`}
                  type="button"
                  onClick={() => {
                    setNewlyAddingUrl("");
                    reset(
                      {
                        domain: "",
                      },
                      {
                        keepErrors: false,
                        keepDirty: true,
                        keepIsSubmitted: false,
                        keepTouched: false,
                        keepIsValid: false,
                        keepSubmitCount: false,
                      }
                    );
                    setShowModal(true);
                  }}
                >
                  {loading ? (
                    <div className="flex flex-row justify-center items-center font-bold">
                      <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                      <span className="ml-4">Loading</span>
                    </div>
                  ) : (
                    'Add Site'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    editOrgLoading: state.dashboard.editOrgLoading,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || [],
    isTempPassword: state.auth.isTempPassword,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateOrgWebsites: updateOrgwebsites,
  triggerLogout: logOut,
  triggerRemoveOrgwebsites: removeOrgwebsites,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);
