/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import no_data from "../../assets/images/no_data.png";

// components
import DefaultPage from "../../components/layout/defaultPage";
import AddUser from "../../components/users/addUser";
import { Titles } from "../../constants";
import MobileHeader from "../../components/MobileHeader";
import SectionLoader from "../../components/SectionLoader";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";

// svg
import { useEffect } from "react";

// actions
import { getUsers, editUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function UsersList
 * @param {Object} props
 * @returns {JSX}
 */
const Users = (props) => {
  const isMobile = ScreenIsMobile();
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const {
    currentOrgObject,
    triggerGetUsers,
    currentOrgUsers,
    selectedOrgId,
    triggerLogout,
    userProfile,
    orgsList
  } = props;

  // did mount - whenever the screen is mounted, make an API call to fetch users data
  useEffect(() => {
    setFetchingUserData(true);
    triggerGetUsers({ onSuccess: () => {
      setFetchingUserData(false);
    }, onError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetUsers, selectedOrgId, currentOrgObject]);

  // to avoid dupes, put it in a set
  let users = new Set();

  // convert set to array
  users = [...currentOrgUsers];

  const onError = (errorMsg) => {
    setErrorFromApi(errorMsg || "Something went wrong!!!");
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  useEffect(() => {
    if (showSearchBox) {
      document.getElementById("searchbox").focus();
    }
    else{
      setSearchTerm("");
    }
  }, [showSearchBox]);

  const handleSearchIconClick = () => {
    setShowSearchBox(!showSearchBox);
  };

  const filteredUser = [...users]?.filter((val) => {
    if (searchTerm === "") {
        return val;
    } else if (val?.email.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else if (val?.firstName.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else if (val?.lastName.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
    } else return "";
  })

  if (!Boolean(orgsList.length)) {
    return (
      <DefaultPage userProfile={userProfile} showSidebar={true}>
        <SectionLoader content={['No data to display']} isNoData={true} />
      </DefaultPage>
    )
  }

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      {isMobile ?
        <MobileHeader
          title={Titles.users}
          websites={currentOrgObject?.websites} 
          existingUsers={users}
          onSearchIconClick={() => handleSearchIconClick()}
        />
      :
        <section className="flex flex-wrap items-center justify-between border-b border-grayLight pl-6 font-semibold">
          <div className="flex items-center text-xl">
            {Titles.users}
          </div>
          <div className="flex items-center">
              <div onClick={() => handleSearchIconClick()} className="p-6 border-l border-grayLight cursor-pointer">
                  <BiSearch className="text-2xl" />
              </div>
              {process.env.REACT_APP_DISABLE_ADD_USER === "false" && <AddUser websites={currentOrgObject?.websites} existingUsers={users} />}
          </div>
        </section>
      }
      {showSearchBox && (
        <section className="flex flex-1 items-center pl-7 pr-2 py-3 bg-tileGray border-b border-grayLight">
          <input
            id="searchbox"
            name="searchbox"
            type="text"
            className="flex w-full focus:outline-none text-xl text-black bg-tileGray"
            placeholder="Type here to start searching"
            onChange={(e) => setSearchTerm(e.target.value.trim())}
          />
          <MdClose
            className="text-2xl cursor-pointer"
            onClick={() => {
              setShowSearchBox(false);
              setSearchTerm("");
            }}
          />
        </section>
      )}
      {errorFromApi !== null ?
        <div className="p-6 py-4 text-center flex items-center justify-center h-80">
            <div>
                <img src={no_data} className="m-auto" alt="no_data" />
                <p className="text-lg font-medium">{errorFromApi}</p>
            </div>
        </div>
      :
      <section className={`overflow-y-auto ${showSearchBox ? 'h-80' : 'h-91'}`}>
        {filteredUser?.length > 0 ?
            filteredUser.map((val, index) => {
                return (
                    <Link to={"/users/" + val.id} key={index} className="group">
                        <div className="flex justify-between border-b border-grayLight">
                            <div className="px-6 py-2 w-5/12">
                                <p className="text-lg font-bold capitalize">
                                  {val.firstName + ' ' + val.lastName}
                                </p>
                                <p className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
                                    {val.email || "-"}
                                </p>
                            </div>
                            <div className="flex items-center"><p className="col-span-2 border px-1 py-2 w-[90px] h-auto text-xs leading-[10px] uppercase text-center">{val.roleId === 0 ? 'Admin' : 'Manager'}</p></div>
                            <div className="flex items-center justify-end w-3/12">
                                <div className="px-8"><div className={`w-3 h-3 rounded-full m-auto ${val.isActive === true ? "bg-bluishGreen" : "bg-gray"}`}></div></div>
                                <p style={{transition: "all 500ms ease"}} className="w-0 group-hover:w-16 bg-primary text-white h-full flex items-center justify-center"><BsArrowRight className="text-2xl" /></p>
                            </div>
                        </div>
                    </Link>
                );
            })
            :
            <SectionLoader
              content={fetchingUserData? ["Fetching User Data"]: users?.length === 0? ["No data to display"]: ["we couldn't find any results"]} 
              isNoData={fetchingUserData? false : true}
            />
        }
      </section>
      }
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    currentOrgUsers: state.dashboard.currentOrgUsers,
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || []
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerGetUsers: getUsers,
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Users);
