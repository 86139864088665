/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import posthog from 'posthog-js'

// icons
import { BsFillExclamationSquareFill } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";


// constants
import { Strings } from "../../constants";

// Actions
import { createWebsite, getWorkspaceSites, fetchDashboard } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";

// icons
//import { css } from "@emotion/react";
import PieLoader from "../PieLoader";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import UpgradePlan from "..//plan/upgradePlan";

// Can be a string as well. Need to ensure each key-value pair ends with ;


/**
 * @function AddWebsite
 * @returns {JSX}
 */
const AddWebsite = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const isMobile = ScreenIsMobile();
  const { triggerCreateWebsite, triggerLogout, orgsList, selectedOrgId, triggerWorkspaceSites, workSpaceSitesCount, truncate } = props;
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteDetail, setWebsiteDetail] = useState({
    url: "",
    name: "",
  });
  const isCurrentWebsiteActive = orgsList?.find(i => i.id === selectedOrgId)?.isActive

  const handleAddWebsite = (e) => {
    e.preventDefault();
    if(isCurrentWebsiteActive) {setShowModal(true);}
  };

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const firstField = document.getElementById("name");
    if (firstField) {
      firstField.focus();
    }
  }, [showModal]);

  const onChangeHandler = (e) => {
    setErrorFromApi(null);
    setWebsiteDetail({
      ...websiteDetail,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onSuccess = () => {
    posthog.capture('Added website successfully', { property: 'value' })
    setErrorFromApi({
      type: "success",
      msg: "Website added successfully",
    });
    setLoading(false);
    setTimeout(() => {
      setShowModal(false);
      setErrorFromApi(null)
      setWebsiteDetail({ url: "" });
      reset(
        {
          url: "",
        },
        {
          keepErrors: false,
          keepDirty: true,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        }
      );
    }, 3000);
  };

  const onError = (errorMsg) => {
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong",
    });
    errorMsg?.toLowerCase() === 'invalid website.' && document.getElementById("url").focus();
    setLoading(false);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const submit = () => {
    // check if URL exists
    let websitesFromCurrentLogin = [];
    if (orgsList?.length > 0) {
      orgsList?.map((org) => {
        if (org?.websites?.length > 0) {
          org?.websites?.map((website) => {
            if (website?.url?.length > 0) {
              websitesFromCurrentLogin.push(website?.url);
            }
          });
        }
      });
    }
    const existsAlready = websitesFromCurrentLogin.includes(
      websiteDetail.url.trim()
    );
    if (existsAlready) {
      setError("url", {
        type: "manual",
        message: "* URL exists already",
      });
      return;
    }
    setLoading(true);
    const postObj = {
      url: websiteDetail.url.trim(),
      name: websiteDetail?.name?.trim(),
      organization: props.org_id,
      onSuccess,
      onError,
    };
    triggerCreateWebsite(postObj);
  };

  const inputsValidated =
    websiteDetail.url?.length >= 3 &&
    websiteDetail.name?.length >= 2 &&
    websiteDetail.name?.length <= 40 &&
    !errors.url &&
    !errors.name;

  useEffect(() => {
    triggerWorkspaceSites({
      payload: {
        orgId: selectedOrgId,
      },
      onSuccess: () => { },
      onError: (errorMsg) => {
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  }, [selectedOrgId, triggerLogout, triggerWorkspaceSites, orgsList])

  return (
    <section>
      {workSpaceSitesCount?.unlimited || workSpaceSitesCount?.pending > 0 ?
        <>
          {isMobile ? (
          <button
            className={`p-2 bg-primary ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}`}
            onClick={(e) => {handleAddWebsite(e)}}
          >
            <HiPlus className="text-white" />
          </button>
          ) : (
            <button
              className={`bg-primary cursor-pointer py-1 px-3 flex items-center justify-between border border-black gap-1 ml-0 ${ truncate ? 'px-8 py-2' : 'px-4 py-6' } text-white font-semibold ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}`}
              onClick={(e) => {handleAddWebsite(e)}}
            >
              { truncate ? Strings.addSite : Strings.addNewSite }
              <HiPlus className={`ml-3 text-xl ${ truncate ? 'hidden' : ' ' }`} />
            </button>
          )}
        </>
        :
        <>
        {isMobile ? (
          <UpgradePlan content={`
            <button className="md:p-4 p-2 bg-primary ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}">
              <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" class="md:ml-3 text-white md:text-primary text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </button>
          `} />
          ) : (
          <UpgradePlan content={`
            <button className="bg-primary ${ truncate ? 'px-8 py-2' : 'px-4 py-6' } text-white flex w-max items-center justify-center font-semibold ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}">
              ${ truncate ? Strings.addSite : Strings.addNewSite }
              <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" class="ml-3 text-xl ${ truncate ?'hidden' : ' '}" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </button>
          `} />
          )}
        </>
      }
      {showModal === true && (
        <div id="modal">
          <div
            className="mt-14 lg:mt-0 bg-white lg:bg-black/[0.85] fixed mx-auto inset-0 z-50 outline-none focus:outline-none flex items-center justify-center h-sm lg:h-full pb-14 sm:pb-0"
          >
            <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
              <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                <div className="relative">
                  <div className="flex justify-between items-center border-b py-4 px-6">
                    <h3 className="text-2xl font-bold">
                      {"Add New Website"}
                    </h3>
                    <button
                      onClick={() => {
                        setWebsiteDetail({
                          url: "",
                          name: "",
                        });
                        setErrorFromApi(null);
                        reset(
                          {
                            url: "",
                          },
                          {
                            keepErrors: false,
                            keepDirty: true,
                            keepIsSubmitted: false,
                            keepTouched: false,
                            keepIsValid: false,
                            keepSubmitCount: false,
                          }
                        );
                        setShowModal(false);
                      }}
                      className="py-2 px-4 font-bold text-sm border flex items-center gap-2"
                    >
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.09756 1.34146L1.09756 8.84146M8.90244 8.84146L0.902439 1.34146" stroke="black" strokeWidth="2"/>
                      </svg>
                      {"Close"}
                    </button>
                  </div>
                  <form onSubmit={handleSubmit(submit)} className="w-full">
                    <div className="lg:px-6 lg:mb-10 pt-6">
                    <fieldset className="text-sm text-center flex flex-col justify-center mb-4">
                      <div className="text-left text-base font-bold">
                        <span>{"Website Name "}</span>
                        {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
                      </div>
                      <input
                        type="text"
                        className={`w-full overflow-x-auto text-sm text-black border-b border-grayBorder outline-none focus:border-primary py-2 font-medium ${
                          errors.name
                            ? `border-red-500`
                            : `border-gray`
                        }`}
                        id="name"
                        name="name"
                        placeholder="Enter the website name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "* Please enter site name",
                          },
                          maxLength: {
                            value: 40,
                            message: "* Maximum 40 characters allowed",
                          },
                          minLength: {
                            value: 2,
                            message: "* Minimum 2 characters required",
                          },
                          pattern: {
                            value: /^[A-Za-z0-9 ]+$/,
                            message: "* Only alphanumeric characters allowed",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.name && (
                        <div className="text-left text-xs text-red-500 font-semibold">
                          {errors.name.message}
                        </div>
                      )}
                    </fieldset>
                    <fieldset className="text-sm text-center flex flex-col justify-center mb-4">
                      <div className="text-left text-base font-bold">
                        <span>{"Website URL "}</span>
                        {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
                      </div>
                      <input
                        type="text"
                        className={`w-full overflow-x-auto text-sm border-b border-grayBorder outline-none focus:border-primary py-2 font-medium ${
                          errors.url || errorFromApi?.msg?.toLowerCase() === 'invalid website.'
                            ? `border-red-500`
                            : `border-gray`
                        }`}
                        id="url"
                        name="url"
                        placeholder="www.domain.com"
                        value={websiteDetail.url.toLowerCase()}
                        {...register("url", {
                          required: {
                            value: true,
                            message: "* Please enter site URL",
                          },
                          pattern: {
                            value:
                              /(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                            message:
                              "* Please enter valid URL",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.url && (
                        <div className="text-left text-xs text-red-500 font-semibold">
                          {errors.url.message}
                        </div>
                      )}
                    </fieldset>
                    <div
                      className={`mt-5 bg-white ${
                        isMobile && "fixed bottom-0 w-full left-0"
                      }`}
                    >
                      <button
                        disabled={loading}
                        className={`font-bold text-base text-white py-2.5 px-3.5 tracking-small ${
                          errorFromApi || loading
                            ? ` pointer-events-none bg-gray `
                            : inputsValidated && !errorFromApi
                            ? ` bg-cyanBlue border-cyanBlue `
                            : `bg-gray border-gray`
                        }`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="flex justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                            <span className="ml-2">{Strings.loading}</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-3 text-base">
                            Submit
                            <svg className={`${loading ? 'hidden' : ''}`} width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.775513 10H24.449" stroke="white" strokeWidth="3"/>
                <path d="M16.4592 1.41846L25.0408 10.0001L16.4592 18.5817" stroke="white" strokeWidth="3"/>
                </svg>
                          </div>
                        )}
                      </button>
                    </div>
                    </div>
                    {errorFromApi !== null && (
                      <div className="lg:px-10 lg:mb-10">
                        <div
                          className={`${
                            errorFromApi?.type === "error"
                              ? "bg-floralWhite "
                              : "bg-aliceBlue"
                          } border border-grayLight p-3 my-8 flex items-center flex-row overflow-x-auto`}
                        >
                          <div className="mr-2">
                            <BsFillExclamationSquareFill
                              color={
                                errorFromApi?.type === "error"
                                  ? "#FF5353"
                                  : "#000000"
                              }
                              className="text-2xl"
                            />
                          </div>
                          <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
    workSpaceSitesCount: state.dashboard.workSpaceSitesCount,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateWebsite: createWebsite,
  triggerLogout: logOut,
  triggerWorkspaceSites: getWorkspaceSites,
  fetchDashboard
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(AddWebsite);
