/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';

// icons
import { MdClose } from "react-icons/md";

// components
import DefaultPage from "../../components/layout/defaultPage";
import AddWebsite from "../../components/websites/addWebsite";
import { RoutingConstants, Titles } from "../../constants";
import WebsiteCard from "./WebsiteCard";
import MobileHeader from "../../components/MobileHeader";
import SectionLoader from "../../components/SectionLoader";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";
import Checkbox from "../../components/Checkbox";

/**
 * @function Websites
 * @param {Object} props
 * @returns {JSX}
 */
const Websites = (props) => {
  const isMobile = ScreenIsMobile();
  const navigate = useNavigate();
  // destructure props;
  const {
    selectedOrgId,
    orgsList,
    isCurrentOrgActive,
    userProfile,
    roleId,
    sidebarCollapsed,
    isTempPassword,
    filterDataDefault
  } = props;

  let selectedOrg = "";
  if (orgsList?.length > 0) {
    selectedOrg = orgsList?.filter((o) => o.id === selectedOrgId)[0];
  }

  // state variables
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [sortOptions, showSortOptions] = useState(false);
  const [filterOptions, showFilterOptions] = useState(false);
  const [websitesListFiltered, setWebsitesListFiltered] = useState([]);
  const [sortByValue, setSortByValue] = useState("updatedAt");
  const [sortOrderValue, setSortOrderValue] = useState("asc");
  const [filterBy, setFilterBy] = useState([]);
  const [resetFilter, setResetFilter] = useState();
  const [inSort, setInSort] = useState(false);
  const [inFilter, setInFilter] = useState(false);
  const [sortData, setSortData] = useState([
    {
      cardName: 'Sort by', options: [{ radioLabel: 'Last audited', radioName: 'updatedAt', selected: true },
      {  radioLabel: 'Date created', radioName: 'createdAt', selected: false },
      {  radioLabel: 'Compliance', radioName: 'compliant_status', selected: false },
      {  radioLabel: 'Status', radioName: 'reportStatus', selected: false },
      {  radioLabel: 'Name', radioName: 'name', selected: false }]
    },
    {
      cardName: 'Sort order', options: [{  radioLabel: 'Ascending', radioName: 'asc', selected: true },
      { radioLabel: 'Descending', radioName: 'desc', selected: false }]
    }
  ]);
  // const filterDataDefaultVal = filterDataDefault;
  const [filterData, setFilterData] = useState(filterDataDefault);

  const filterRef = useRef();
  const sortRef = useRef();

  // If search box input is opened, set focus to it
  useEffect(() => {
    if (showSearchBox) {
      document.getElementById("searchbox").focus();
    }
  }, [showSearchBox]);

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  useEffect(() => {
    /* if(filterOptions) {
      console.log("filterData useEffect handler: ", filterData, filterOptions, inFilter, filterBy);
      const nexState = _.map(filterBy, (val, i) => {
       console.log("find key: ", val,i, filterData, filterData[0].options[i].radioName === val);
      });
    } */
    let handler = (event) => {
      if(!filterRef?.current?.contains(event.target)) {
        showFilterOptions(false)
      };
      if(!sortRef?.current?.contains(event.target)) {
        showSortOptions(false)
      };
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    }
  }, [filterOptions])

  
  useEffect(() => {
    const filteredData = selectedOrg?.websites
    // eslint-disable-next-line array-callback-return
    ?.filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (val.url.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      }
    });
    setWebsitesListFiltered(filteredData);
  }, [selectedOrg])

  const handleSearchIconClick = () => {
    setSearchTerm("");
    setShowSearchBox(!showSearchBox);
  };

  const onSort = ({ target }) => {
    if(target.name === "Sort by") setSortByValue(target.value);
    if(target.name === "Sort order") setSortOrderValue(target.value);
target.value !== "updatedAt" ? setInSort(true) : setInSort(false);
    const nexState = sortData.map(card => {
      if (card.cardName !== target.name) return card;
      return {
        ...card,
        options: card.options.map(opt => {
          const checked = opt.radioName === target.value;
          return {
            ...opt,
            selected: checked
          }
        })
      }
    });
    setSortData(nexState);
  }
  
  useEffect(()=> {
    // let data = _.sortBy(websitesListFiltered, [sortByValue], [sortOrderValue]) ;
    setWebsitesListFiltered(data => _.orderBy(data, [sortByValue], [sortOrderValue]) );
  },[sortByValue,sortOrderValue])
  
  const onFilter = ({ target}, checked) => {
    setResetFilter(false);

    if(checked) {
      let _arr = [ ...filterBy, target.value ];
      setFilterBy(_arr);
    }else{
      const index = _.indexOf(filterBy, target.value);
      let _arr = [...filterBy.slice(0,index), ...filterBy.slice(index+1)];
      setFilterBy(_arr);
    }

    const nexState = filterData.map(card => {
      if (card.cardName !== target.name) return card;
      return {
        ...card,
        options: card.options.map(opt => {
          const checked = opt.radioName === target.value;
          return {
            ...opt,
            selected: checked
          }
        })
      }
    });
    setFilterData(nexState);
  }


  const onApplyFilter = () => {
    let _arr = [];
    const getData = !websitesListFiltered.length ? selectedOrg?.websites : websitesListFiltered;
    _.map(filterBy, function(o, i) {
      const findFilter =  _.filter(getData, function(val) { 
        if(o === "active") {
          return val.isActive === true;
        }
        if(o === "inactive") {
          return val.isActive === false;
        }
        if(o === "compliant_status") {
          return val.compliant_status === "Non-Compliant";
        }
        return val[o] === "compliant";

       })
       _arr.push(...findFilter);
       _arr = _.uniqBy(_arr, 'id');
    });
  

    filterBy.length ? setInFilter(true) : setInFilter(false);
    setWebsitesListFiltered(_arr);
    // !filterBy.length ? setWebsitesListFiltered(selectedOrg?.websites) : setWebsitesListFiltered(filteredData);
  }
    
  const onResetFilter = () => {
    const nexState = filterData.map(card => {
      return {
        ...card,
        options: card.options.map(opt => {
          return {
            ...opt,
            selected: false
          }
        })
      }
    });
    setFilterData(nexState);
    setWebsitesListFiltered(selectedOrg?.websites);
    setInFilter(false);
    setFilterBy([]);
    setResetFilter(true);
    // setFilterData(filterDataDefault);
    // setFilterData([]);
    // setTimeout(() => {
    //   setFilterData(filterDataDefaultVal);
    // }, 500);
  }

  if (!Boolean(orgsList.length)) {
    return (
      <DefaultPage userProfile={userProfile} showSidebar={true}>
        <SectionLoader content={['No data to display']} isNoData={true} />
      </DefaultPage>
    )
  }

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      {/* Header section */}
      {/* Show Header only on mobile & tablets */}
      {isMobile && (
        <MobileHeader
          title={Titles.websites}
          org_id={selectedOrgId}
          onSearchIconClick={() => handleSearchIconClick()}
        />
      )}
      {!isMobile && (
        <section
          className={`flex flex-wrap items-center justify-between font-semibold w-full ${
            !showSearchBox && "border-b border-grayLight"
          }`}
        >
          <div className="flex items-center text-2xl font-bold ml-7">
            {"Websites"}
            
          </div>

          {/* container for Search icon and Add website button */}
          <div
            className={`flex items-center ${showSearchBox && "bg-tileGray"} m-5`}
          >
            <div className="flex items-center font-bold mr-4 relative" ref={sortRef}>
              <div className="cursor-pointer py-1 px-3 flex items-center justify-between gap-3 border relative" onClick={(e) => {e.preventDefault(); showSortOptions((prev) => !prev)}}>
              {inSort && <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-4 h-4 text-xs font-bold text-white bg-yellow rounded-full border-2 border-white dark:border-gray-900"></div>}
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
                </svg>
                <p>Sort</p>
              </div>
              {sortOptions && <div className="inline-block absolute top-10 w-64 z-10 text-sm bg-white border border-black">
                  <div>
                    {
                      sortData.length < 1 ? "Loading..." :
                        <div className='grid grid-cols-2 m-2'>
                          {sortData.map((sortDataOptions, index) => {
                            return (
                              <div key={index}>
                                <h3 className="font-bold">{sortDataOptions.cardName}</h3>
                                {
                                  sortDataOptions.options.map((option, index) => (
                                    <div key={index}>
                                      <input className="w-3 h-3 checked:rounded-full checked:appearance-none checked:bg-black checked:border-black" type="radio" id={`${option.radioName}_${index}`} name={sortDataOptions.cardName} value={option.radioName} checked={option.selected} onChange={(e) => onSort(e)} />
                                      <label for={`${option.radioName}_${index}`} className="cursor-pointer font-medium pl-1.5">{option.radioLabel}</label>
                                    </div>
                                  ))
                                }
                              </div>
                          )})}
                        </div>
                    }
                  </div>
              </div>}
            </div>
            <div className="flex items-center font-bold mr-4 relative" ref={filterRef}>
              <div className="cursor-pointer px-3 py-1 flex items-center justify-between gap-3 border relative" onClick={(e)=> {e.preventDefault(); showFilterOptions((prev) => !prev); }}>
              {inFilter && <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-4 h-4 text-xs font-bold text-white bg-yellow rounded-full border-2 border-white dark:border-gray-900"></div>}
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00023 10.925C8.0269 11.1125 7.96023 11.3125 7.8069 11.4437C7.5469 11.6875 7.1269 11.6875 6.8669 11.4437L4.19356 8.9375C4.04023 8.79375 3.97356 8.6 4.00023 8.41875V5.21875L0.806897 1.3875C0.580231 1.11875 0.633564 0.725 0.920231 0.5125C1.0469 0.425 1.1869 0.375 1.33356 0.375H10.6669C10.8136 0.375 10.9536 0.425 11.0802 0.5125C11.3669 0.725 11.4202 1.11875 11.1936 1.3875L8.00023 5.21875V10.925ZM2.69356 1.625L5.33356 4.7875V8.2375L6.6669 9.4875V4.78125L9.3069 1.625H2.69356Z" fill="black"/>
                </svg>
                <p className="capitalize w-full overflow-hidden break-all h-6">
                  Filter
                </p>
              </div>
              {filterOptions && <div className="inline-block absolute top-10 w-64 z-10 text-sm bg-white border border-black p-2">
                  <div>
                    {
                      filterData.length < 1 ? "Loading..." :
                        <div className='flex flex-col'>
                          {filterData.map((filterDataOptions, index) => {
                            return (
                              <div key={index} className='pb-3'>
                                <h3 className="font-bold">{filterDataOptions.cardName}</h3>
                                {
                                  filterDataOptions.options.map((option, index) => (
                                    <Checkbox parentCallback={onFilter} reset={resetFilter} optionData={option} key={index} data={filterDataOptions} />
                                  ))
                                }
                              </div>
                          )})}
                        </div>
                    }
                    <div className="mt-1">
                      <button onClick={onApplyFilter} className="bg-primary py-2 px-4 mr-3 text-white">Apply</button>
                      <button onClick={onResetFilter} className="underline decoration-1">Reset</button>
                    </div>
                  </div>
              </div>}
            </div>
            
            {/* Show add website button only if the user is an admin */}
            {roleId === 0 && <AddWebsite org_id={selectedOrgId} />}
          </div>
        </section>
      )}

      {/* Body section */}
      {showSearchBox && (
        <div className="flex flex-1 items-center pl-7 pr-2 py-3 bg-tileGray border-t border-b border-grayLight">
          <input
            id="searchbox"
            name="searchbox"
            type="text"
            className="flex w-full focus:outline-none text-xl text-black bg-tileGray"
            placeholder="Type here to start searching"
            onChange={(e) => setSearchTerm(e.target.value.trim())}
          />
          <MdClose
            className="text-2xl cursor-pointer"
            onClick={() => {
              setShowSearchBox(false);
              setSearchTerm("");
            }}
          />
        </div>
      )}
      <section className={`overflow-y-auto pb-10 ${showSearchBox ? 'h-80' : 'h-91'}`}>
        {/* Show no websites available */}
        {websitesListFiltered?.length === 0 && (
          <SectionLoader 
            content= {searchTerm !== "" ? ["We couldn't find any results."] : ["No data to display."]} 
            isNoData={true}
          />
        )}

        {/* Websites section */}
        <div
          className={`mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0 mx-6`}
        >
          {websitesListFiltered?.length > 0 &&
            websitesListFiltered
              // .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .map((i, index) => {
                const reports = [...i.reports];
                reports.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );

                const handleCardClick = (data) => {
                  if (
                    data?.id &&
                    (reports?.length === 0 ||
                      (reports.length > 0 && !reports[0]?.jobId))
                  ) {
                    navigate(
                      `${RoutingConstants.websites.webRoot}/${data?.id}`,
                      {
                        state: {
                          isCurrentWebsiteActive:
                            isCurrentOrgActive && data?.isActive,
                        },
                      }
                    );
                    return;
                  }
                  if (
                    data?.id &&
                    reports?.length > 0 &&
                    reports[0]?.jobId !== null
                  ) {
                    navigate(
                      `/website/${data?.id}/reports/${reports[0]?.jobId}`,
                      {
                        state: {
                          isCurrentWebsiteActive:
                            isCurrentOrgActive && data?.isActive,
                        },
                      }
                    );
                  }
                };

                /**
                 * render all the cards from filteredWebsites using map()
                 */
                return (
                  <WebsiteCard
                    key={String(index)}
                    sidebarCollapsed={sidebarCollapsed}
                    data={i}
                    index={index}
                    onCardClick={handleCardClick}
                  />
                );
              })}
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    sidebarCollapsed: state.dashboard.sidebarCollapsed,
    isTempPassword: state.auth.isTempPassword,
    roleId: state.auth.roleId,
    userProfile: state.dashboard.userProfile,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
    isCurrentOrgActive:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          ).isActive
        : false,
    filterDataDefault: [
      {
        cardName: 'Status', options: [{  radioLabel: 'Active', radioName: 'active', selected: false },
        { radioLabel: 'Inactive', radioName: 'inactive', selected: false }]
      },
      {
        cardName: 'Compliance', options: [{ radioLabel: 'Level A Complaint', radioName: 'levelACompliance', selected: false },
        {  radioLabel: 'Level AA Complaint', radioName: 'levelAACompliance', selected: false },
        {  radioLabel: 'Level AAA Complaint', radioName: 'levelAAACompliance', selected: false },
        {  radioLabel: 'Non-Complaint', radioName: 'compliant_status', selected: false }]
      }
    ] 
  };
};

// exports
export default connect(mapStateToProps, {})(Websites);