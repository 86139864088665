// library imports
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

// constants
import { Strings } from "../../constants";

// Actions
import { createUser } from "../../store/actions/dashboard";
import { updatePassword, logOut } from "../../store/actions/auth";
import PieLoader from "../../components/PieLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";

/**
 * @function Security
 * @returns {JSX}
 */
const Security = (props) => {
  const {
    triggerLogout,
    triggerUpdatePassword
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notEmpty, setNotEmpty] = useState(false);
  const [isValidPassword, setisValidPassword] = useState('');
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });
  const watchAllFields = watch();
  useEffect(() => {
    if(Object.keys(watchAllFields).length > 0) {
        setNotEmpty(watchAllFields.currentPassword !== '' && watchAllFields.password !== '' && watchAllFields.confirmPassword !== '')
        if (watchAllFields.currentPassword !== '' && watchAllFields.password !== '' && watchAllFields.currentPassword === watchAllFields.password) {
            setisValidPassword('invalid');
        } else {
            setisValidPassword('valid');
        }
    }
  }, [watchAllFields])
  const submit = (data) => {
    setErrorFromApi(null)
    setLoading(true)
    const payload = {
        apiPayload: {
            password: data.currentPassword,
            newPassword: data.password
        },
        onSuccess: (msg) => {
          setErrorFromApi({
            type: "success",
            msg: msg.message || "Successful",
          });
          setTimeout(() => {
            triggerLogout();
          }, 4000);
          setLoading(false)
        },
        onError: (msg) => {
          setErrorFromApi({
            type: "error",
            msg: msg || "Something went wrong!!!",
          });
          setLoading(false)
        },
      };
    triggerUpdatePassword(payload);
  };
  const showPopup = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <section>
        <button className="w-full bg-cyanBlue p-3 text-sm text-white font-bold uppercase text-center" onClick={(e) => showPopup(e)}>
            UPDATE PASSWORD
        </button>
        {showModal === true && (
            <div id="modal" className="text-black">
            <div className="mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed mx-auto inset-0 z-50 outline-none focus:outline-none lg:h-full pb-14 sm:pb-0">
                <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
                <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                    <div className="relative">
                    <h3 className="text-2xl font-bold lg:px-10 py-6">Update Password</h3>
                    <form onSubmit={handleSubmit(submit)} className="w-full">
                        <div className="lg:px-10 lg:mb-10">
                            <fieldset className="w-full m-auto text-sm mb-4">
                                <div className="text-base text-black font-bold mb-2">
                                    Current Password
                                </div>
                                <input
                                    type="password"
                                    className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                                    errors.currentPassword && "border-red-500"
                                    }`}
                                    id="currentPassword"
                                    name="currentPassword"
                                    placeholder="Enter the current password"
                                    {...register("currentPassword", {
                                    required: {
                                        value: true,
                                        message: "* Current password is mandatory",
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: "* Maximum 60 characters allowed",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "* Minimum 8 characters required",
                                    },
                                    })}
                                />
                                {errors.currentPassword && (
                                    <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {errors.currentPassword.message}
                                    </div>
                                )}
                            </fieldset>
                            <fieldset className="w-full m-auto text-sm mb-4">
                                <div className="text-base text-black font-bold mb-2">
                                    New Password
                                </div>
                                <input
                                    type="password"
                                    className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                                    errors.password && "border-red-500"
                                    }`}
                                    id="password"
                                    name="password"
                                    placeholder="Enter the new password"
                                    {...register("password", {
                                    required: {
                                        value: true,
                                        message: "* New Password is mandatory",
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: "* Maximum 60 characters allowed",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "* Minimum 8 characters required",
                                    },
                                    })}
                                />
                                {errors.password && (
                                    <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {errors.password.message}
                                    </div>
                                )}
                                {isValidPassword === 'invalid' && (
                                <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    New password should not be same as old password
                                </div>
                                )}
                            </fieldset>
                            <fieldset className="w-full m-auto text-sm mb-4">
                                <div className="text-base text-black font-bold mb-2">
                                    Confirm New Password
                                </div>
                                <input
                                    type="password"
                                    className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] text-sm focus:border-primary py-2 px-3 font-medium ${
                                    errors.confirmPassword && "border-red-500"
                                    }`}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="Confirm the new password"
                                    {...register("confirmPassword", {
                                    required: {
                                        value: true,
                                        message: "* Confirm New Password is mandatory",
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: "* Maximum 60 characters allowed",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "* Minimum 8 characters required",
                                    },
                                    validate: (value) =>
                                        value === watch("password") ||
                                        "* Confirm password should be same as new password",
                                    })}
                                />
                                {errors.confirmPassword && (
                                    <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                                    {errors.confirmPassword.message}
                                    </div>
                                )}
                            </fieldset>
                        </div>
                        {errorFromApi !== null && (
                        <div className="w-full lg:px-10">
                            <div
                            className={`${
                                errorFromApi?.type === "error"
                                ? "bg-floralWhite "
                                : "bg-aliceBlue"
                            } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto w-full`}
                            >
                            <div className="mr-2">
                                <BsFillExclamationSquareFill
                                color={
                                    errorFromApi?.type === "error"
                                    ? "#FF5353"
                                    : "#000000"
                                }
                                className="text-2xl"
                                />
                            </div>
                            <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                            </div>
                        </div>
                        )}
                        <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0 ">
                        <button
                            className="col-span-2 py-4 font-bold text-sm text-grayNobel border-t"
                            type="button"
                            onClick={() => {
                            setShowModal(false);
                            reset(
                                {
                                    currentPassword: "",
                                    password: "",
                                    confirmPassword: "",
                                },
                                {
                                    keepErrors: false,
                                    keepDirty: true,
                                    keepIsSubmitted: false,
                                    keepTouched: false,
                                    keepIsValid: false,
                                    keepSubmitCount: false,
                                }
                              );
                            }}
                        >
                            {"Cancel"}
                        </button>
                        <button
                            className={`${
                            !notEmpty || (isValidPassword === 'invalid') || Object.keys(errors).length > 0 || loading
                                ? `bg-gray border-gray `
                                : `bg-cyanBlue border-cyanBlue`
                            } ${ (!notEmpty || (isValidPassword === 'invalid') || Object.keys(errors).length > 0 || loading) && "pointer-events-none"} col-span-4 font-bold text-sm text-white py-4 tracking-small`}
                            type="submit"
                        >
                            {loading ? (
                            <div className="flex flex-row justify-center items-center">
                                <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                                <span className="ml-2">{Strings.loading}</span>
                            </div>
                            ) : (
                            Strings.submit
                            )}
                        </button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
            </div>
        )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdatePassword: updatePassword,
  triggerCreateUser: createUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(Security);
