// user imports
import actionTypes from "../types";

// destructuring
const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VALIDATE_OTP,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  VERIFY_USER_EMAIL,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR
} = actionTypes.auth;

const { CREATE_ORG_SUCCESS } = actionTypes.dashboard;

const initialState = {
  loggedIn: false,
  loading: false,
  accessToken: null,
  isOrganizationPresent: false,
  roleId: null,
  isTempPassword: false,
  passwordRetrievalEmail: null,
  userEmail: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      // if it is api call / request, then fetch it as action.payload
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      // if it is api call success, then fetch it as action.response
      return {
        ...state,
        loading: false,
        loggedIn: true,
        accessToken: action?.response?.response?.data?.message?.accessToken || null,
        isOrganizationPresent:
          action?.response?.response?.data?.message?.isOrganizationPresent,
        roleId: action?.response?.response?.data?.message?.roleId,
        isTempPassword:
          action?.response?.response?.data?.message?.isTempPassword || false,
        userEmail: action.response?.userProfileRes?.data?.message?.email
      };
    case LOGIN_ERROR:
      // if it is api call error, then fetch it as action.error
      return { ...initialState };
    case LOGOUT:
      // wipe out all data, back to init state
      return { ...initialState };
    case REGISTER:
      return { ...state, loading: true };
    case REGISTER_SUCCESS:
      return { ...state, loading: false };
    case REGISTER_ERROR:
      return { ...state, loading: false };

    case SEND_OTP: {
      return { ...state, loading: true };
    }
    case SEND_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordRetrievalEmail: action?.response?.email,
      };
    }
    case SEND_OTP_ERROR: {
      return { ...state, loading: false, passwordRetrievalEmail: null };
    }
    case VALIDATE_OTP: {
      return { ...state, loading: true };
    }
    case VALIDATE_OTP_SUCCESS: {
      return { ...state, loading: false };
    }
    case VALIDATE_OTP_ERROR: {
      return { ...state, loading: false };
    }
    case RESET_PASSWORD: {
      return { ...state, loading: true };
    }
    case RESET_PASSWORD_SUCCESS: {
      return { ...state, loading: false, passwordRetrievalEmail: null };
    }
    case RESET_PASSWORD_ERROR: {
      return { ...state, loading: false };
    }

    case CHANGE_PASSWORD: {
      return { ...state, loading: true };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return { ...state, loading: false, isTempPassword: false };
    }
    case CHANGE_PASSWORD_ERROR: {
      return { ...state, loading: false };
    }

    case VERIFY_USER_EMAIL: {
      return { ...state, loading: true };
    }
    case VERIFY_USER_EMAIL_SUCCESS: {
      return { ...state, loading: false, isTempPassword: false };
    }
    case VERIFY_USER_EMAIL_ERROR: {
      return { ...state, loading: false };
    }

    case UPDATE_PASSWORD: {
      return { ...state, loading: true };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return { ...state, loading: false, isTempPassword: false };
    }
    case UPDATE_PASSWORD_ERROR: {
      return { ...state, loading: false };
    }

    // Illegal task, but as a work around, we do this. Later we shall ask BE to send isOrganizationPresent prop in dashboard also
    case CREATE_ORG_SUCCESS: {
      return { ...state, isOrganizationPresent: true };
    }
    default:
      return { ...state };
  }
};

// exports
export default authReducer;
