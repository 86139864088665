const authActions = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  SEND_OTP: "SEND_OTP",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_ERROR: "SEND_OTP_ERROR",

  VALIDATE_OTP: "VALIDATE_OTP",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_ERROR: "VALIDATE_OTP_ERROR",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",

  VERIFY_USER_EMAIL: "VERIFY_USER_EMAIL",
  VERIFY_USER_EMAIL_SUCCESS: "VERIFY_USER_EMAIL_SUCCESS",
  VERIFY_USER_EMAIL_ERROR: "VERIFY_USER_EMAIL_ERROR",

  LOGOUT: "LOGOUT",
};

// exports
export default authActions;
