/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import posthog from 'posthog-js'

// assets
import RenderSvg, { svgNames } from "../assets/svg";
import PieLoader from "../components/PieLoader";


// constants
import { RoutingConstants, Strings } from "../constants";

// actions
import { logIn } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// local storage handler
import LocalStorageHandler from "../utils/StateCacheStorage";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   border-color: "white";
// `;

/**
 * @function Login
 * @returns {JSX}
 */
const Login = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // destructure the props
  const {
    triggerLogin,
    loggedIn,
    loading,
    accessToken,
    isOrganizationPresent,
    isTempPassword
  } = props;
  const [autofilled, setAutofilled] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  // state variables
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [tokenError, setTokenError] = useState(null);

  // show focus on email field on load
  useEffect(() => {
    if(LocalStorageHandler.get("logoutData") && LocalStorageHandler.get("logoutData")?.lastPage && LocalStorageHandler.get("logoutData")?.message){
      setTokenError(LocalStorageHandler.get("logoutData")?.message);
      setTimeout(() => {
        setTokenError(null);
      },3000);
    }
    const emailField = document.getElementById("username");
    emailField.focus();
  }, []);

  // whenever these variables from the store changes, this useeffect will be triggered
  useEffect(() => {
    if (loggedIn && !loading && accessToken) {
      if(LocalStorageHandler.get("logoutData") && LocalStorageHandler.get("logoutData")?.lastPage){
        LocalStorageHandler.clear("logoutData");
        navigate(RoutingConstants.dashboard);
      }
      else{
        if (isTempPassword) {
          navigate(RoutingConstants.changePassword);
        } else {
          if (!isOrganizationPresent) {
            // navigate to org setup
            navigate(`${RoutingConstants.organization.setup}?addedOrg=false`);
          } else if (isOrganizationPresent) {
            // navigate to dashboard
            navigate(RoutingConstants.dashboard);
          }
        }
      }
    }
  }, [loading, loggedIn, accessToken, isOrganizationPresent, isTempPassword]);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username and password textinputs
   */
  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("username");
    const inputTagElement_2 = document.getElementById("password");
    // If the user edits the input boxes, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const inputsValidated =
    userData.username?.length >= 6 &&
    userData.username?.length <= 100 &&
    !errors.username &&
    userData.password?.length >= 8 &&
    userData.password?.length <= 30 &&
    !errors.password;

  const submit = () => {
    if (!inputsValidated) {
      return;
    }
    const payload = {
      email: userData.username,
      password: userData.password,
      onSuccess: (res) => {
        posthog.identify(res.email, {
          email: res.email,
          role: res?.roleId === 0 ? 'Admin' : 'Manager',
          $set: { firstName: res?.firstName, lastName: res?.lastName, email: res?.email, role: res?.roleId === 0 ? 'Admin' : 'Manager' }
        });
        posthog.capture('Login Successfully', { Role: res?.roleId === 0 ? 'Admin' : 'Manager' })
      },
      onError: (msg) => {
        setErrorFromApi(msg || "Something went wrong!!!");
      },
    };
    // trigger login action
    triggerLogin(payload);
  };

  useEffect(() => {
    const email = document.querySelector('#username')
    const password = document.querySelector('#password')
    setTimeout(() => {
      const emailAutofilled = getComputedStyle(email).backgroundColor === 'rgb(232, 240, 254)';
      const passwordAutofilled = getComputedStyle(password).backgroundColor === 'rgb(232, 240, 254)';
      if(errors !== {}) {
        setAutofilled(emailAutofilled && passwordAutofilled)
      } else {
        setAutofilled(false)
      }
    },1000);   
  }, [errors, autofilled])

  return (
    <div className={`max-w-full overflow-hidden`}>
      <div className="grid lg:grid-cols-2">
        <div className="px-8 md:px-12 lg:px-20">
          <div className={`w-full flex mt-20 items-center`}>
            {/* <img src={logo} alt="Logo" className="max-w-150px" /> */}
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          <div className="w-full pt-16 overflow-auto">
            <form onSubmit={handleSubmit(submit)} className="w-full">
              <h2 className="font-bold text-black text-2xl mb-10">
                {Strings.wallySignIn}
              </h2>
              <fieldset className="mb-2">
                <div className={`w-full font-bold mb-2 ${errors.username ? 'text-black' : 'text-grayDeep' } text-base`}>
                  {`Email  `}
                  {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
                </div>
                <input
                  type="email"
                  className={`placeholder-[#7E7E7E] placeholder:font-semibold w-full focus:border-primary outline-none border-b-2 border-[#000000] py-2 text-sm font-normal overflow-x-auto ${
                    errors.username && "border-red-500"
                  }`}
                  placeholder={Strings.enterYourEmail}
                  id="username"
                  name="username"
                  {...register("username", {
                    required: {
                      value: true,
                      message: "* E-mail is mandatory",
                    },
                    maxLength: {
                      value: 300,
                      message: "* Invalid Email Id",
                    },
                    minLength: {
                      value: 6,
                      message: "* Invalid Email Id",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "* Invalid email format",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
                {errors.username && (
                  <span className="text-xs text-red-500 font-semibold">
                    {errors.username.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-2">
                <div className={`w-full font-bold mb-2 ${errors.password ? 'text-black' : 'text-grayDeep' } text-base`}>
                  {`${Strings.password}  `}
                  {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
                </div>
                <input
                  type="password"
                  className={`placeholder-[#7E7E7E] placeholder:font-semibold w-full focus:border-primary outline-none border-b-2 border-[#000000] py-2 text-sm font-normal overflow-x-auto ${
                    errors.password && "border-red-500"
                  }`}
                  placeholder={Strings.enterPassword}
                  id="password"
                  name="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "* Password is mandatory",
                    },
                    maxLength: {
                      value: 50,
                      message: "* Maximum 50 characters allowed",
                    },
                    minLength: {
                      value: 8,
                      message: "* Minimum 8 characters required",
                    },
                  })}
                  onChange={(e) => onChangeHandler(e)}
                />
                {errors.password && (
                  <span className="text-xs text-red-500 font-semibold">
                    {errors.password.message}
                  </span>
                )}
              </fieldset>
              {errorFromApi !== null || tokenError !== null? (
                <div className="w-full mt-5 -mb-5">
                  <div className="bg-floralWhite border border-grayLight p-3 flex items-center">
                    <div className="mr-2">
                      <BsFillExclamationSquareFill
                        color="#FF5353"
                        className="text-2xl"
                      />
                    </div>
                    <p className="text-sm text-left text-black font-light">{`${errorFromApi? errorFromApi: tokenError}`}</p>
                  </div>
                </div>
              ): ""}
              <button
                onClick={() => {
                  const autosavedUsername =
                    document.getElementById("username").value;
                  const autosavedPassword =
                    document.getElementById("password").value;
                  reset(
                    {
                      username: autosavedUsername,
                      password: autosavedPassword,
                    },
                    {
                      keepErrors: false,
                      keepDirty: true,
                      keepIsSubmitted: false,
                      keepTouched: false,
                      keepIsValid: false,
                      keepSubmitCount: false,
                    }
                  );
                  setUserData({
                    username: autosavedUsername,
                    password: autosavedPassword,
                  });
                }}
                disabled={errorFromApi || loading}
                type="submit"
                className={`cursor-pointer text-white font-bold w-max py-2 px-3 flex justify-center items-center text-xl mt-10 ${
                  errorFromApi || loading
                    ? `bg-gray pointer-events-none`
                    : inputsValidated && !errorFromApi
                    ? ` bg-primary`
                    : autofilled ? 'bg-cyanBlue' 
                    : `bg-gray`
                }`}
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <span className="mx-2 ">{Strings.loading}</span>
                    <PieLoader
                      color={"white"}
                      loading={loading}
                      size={5}
                    />
                  </div>
                ) : (
                  "Sign In"
                )} 
                &nbsp;&nbsp;
                <svg className={`${loading ? 'hidden' : ''}`} width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.775513 10H24.449" stroke="white" strokeWidth="3"/>
                <path d="M16.4592 1.41846L25.0408 10.0001L16.4592 18.5817" stroke="white" strokeWidth="3"/>
                </svg>
                
              </button>
              <div className="w-full my-4 text-left">
                <Link
                  to={RoutingConstants.forgotPassword}
                  className="text-base text-grayDeep"
                >
                  {Strings.forgotPassword}
                </Link>
              </div>
            </form>
          </div>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    accessToken: state.auth.accessToken,
    isOrganizationPresent: state.auth.isOrganizationPresent,
    isTempPassword: state.auth.isTempPassword
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogin: logIn,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
