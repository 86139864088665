/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import posthog from 'posthog-js'

// components
import { RoutingConstants, Titles } from "../../constants";

// Actions
import { createOrg, fetchDashboard } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";

// icons
import PieLoader from "../../components/PieLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function AddOrganization
 * @param {Object} props
 * @returns {JSX}
 */
const AddOrganization = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();

  // state variables
  const {
    triggerCreateOrg,
    triggerLogout,
    isTempPassword,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [organizationDetail, setOrganizationDetail] = useState({
    orgName: "",
    orgType: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
  });

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  const onChangeHandler = (e) => {
    setOrganizationDetail({
      ...organizationDetail,
      [e.target.name]: e.target.value,
    });
    const inputTagElement_1 = document.getElementById("orgName");
    const inputTagElement_2 = document.getElementById("orgType");
    const inputTagElement_3 = document.getElementById("country");
    // If the user edits the input boxes, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2 ||
      document.activeElement === inputTagElement_3
    ) {
      setErrorFromApi(null);
    }
  };

  const onSuccess = () => {
    posthog.capture('Added workspace successfully', { property: 'value' })
    setErrorFromApi({
      type: "success",
      msg: "Congratulations!!! New workspace has been added successfully",
    });
    setLoading(false);
    setTimeout(() => {
      setShowModal(false);
      setOrganizationDetail({
        orgName: "",
        orgType: ""
      });
      reset(
        {
          orgType: "",
          orgName: "",
        },
        {
          keepErrors: false,
          keepDirty: true,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        }
      );
      setErrorFromApi(null);
    }, 2000);
    
    
  };

  const onError = (errorMsg) => {
    let errorFromApi = errorMsg
    if (errorMsg && errorMsg.includes("Org creation failed")) {
      errorFromApi = errorFromApi.replace('Org creation failed: ', '');
    }
    setErrorFromApi({
      type: "error",
      msg: errorFromApi || "Something went wrong!!!",
    });
    setOrganizationDetail({
      orgName: "",
      orgType: "",
    });
    reset(
      {
        orgType: "",
        orgName: "",
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };
  const submit = (e) => {
    setLoading(true);
    const payload_1 = {
      name: organizationDetail.orgName,
    };
    triggerCreateOrg({
      orgObj: payload_1,
      onSuccess,
      onError,
    });
  };
  const handleAddOrg = (e) => {
    e.preventDefault();
    setShowModal(true);
    document.getElementById('mobileMenu')?.classList.add('h-sm')
  };

  const firstStepValidationPassed = organizationDetail.orgName.length > 0 && !errors.orgName
    return (
      <section>
        {isMobile ? (
          props?.displayType? (<button
            className={`${
              props?.displayType
                ? "font-semibold hover:text-secondary text-blue p-2"
                : "bg-primary p-6 text-white ml-auto"
            } flex w-max items-center font-semibold uppercase`}
            onClick={(e) => handleAddOrg(e)}
          >
            {props?.displayType? (
              <>
              <AiOutlinePlusCircle className="mr-3 text-sm" />
                {Titles.addNewWorkspace}
              </>
            ) : (
              <>
                {Titles.addNewWorkspace}
              </>
            )}
          </button>) :(
            <button className="p-2 bg-primary" onClick={(e) => handleAddOrg(e)}>
              <HiOutlinePlusCircle className="text-2xl text-white" />
            </button>
          )
        ) : (
          <button
            className={`${
              props?.displayType
                ? "font-semibold hover:text-secondary text-blue p-2"
                : "bg-primary p-6 text-white ml-auto"
            } flex w-max items-center font-semibold uppercase`}
            onClick={(e) => handleAddOrg(e)}
          >
            {props?.displayType? (
              <>
              <AiOutlinePlusCircle className="mr-3 text-sm" />
                {Titles.addNewWorkspace}
              </>
            ) : (
              <>
                {Titles.addNewWorkspace}
              </>
            )}
          </button>
        )}
        {showModal === true && (
          <>
            <div className={`${!props?.displayType && 'mt-14' } lg:mt-0 bg-white lg:bg-primary/75 fixed inset-0 z-50 outline-none focus:outline-none flex items-center justify-center h-sm lg:h-full pb-14 sm:pb-0`}>
              <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
                <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                  <div className="relative">
                    <div className="flex justify-between items-center border-b py-4 px-6">
                      <h3 className="text-2xl font-bold">Add Workspace</h3>
                      <button
                        className={`py-2 px-4 font-bold text-sm border flex items-center gap-2`}
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          setLoading(false);
                          setErrorFromApi(null);
                          reset(
                            {
                              orgName: "",
                              orgType: "",
                              country: ""
                            },
                            {
                              keepErrors: false,
                              keepDirty: true,
                              keepIsSubmitted: false,
                              keepTouched: false,
                              keepIsValid: false,
                              keepSubmitCount: false,
                            }
                          );
                          document.getElementById('mobileMenu')?.classList.remove('h-sm')
                        }}
                      >
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.09756 1.34146L1.09756 8.84146M8.90244 8.84146L0.902439 1.34146" stroke="black" strokeWidth="2"/>
                        </svg>
                        {"Close"}
                      </button>
                    </div>
                    <section>
                      <form onSubmit={handleSubmit(submit)} className="text-black mt-4">
                        <div className="lg:px-6 lg:mb-10">
                          <fieldset className="w-full m-auto text-sm mb-4">
                            <div className="text-left text-sm font-semibold">
                              {`Workspace Name`}
                              <span className="text-lg text-red-500 font-bold">
                                {"*"}
                              </span>
                            </div>
                            <input
                              type="text"
                              className={`w-full overflow-x-auto text-sm text-black border-b border-grayBorder outline-none focus:border-primary py-2 font-medium ${
                                errors.orgName && `border-red-500`
                              }`}
                              id="orgName"
                              name="orgName"
                              {...register("orgName", {
                                required: {
                                  value: true,
                                  message: "* Please enter workspace name",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "* Maximum 40 characters allowed",
                                },
                                minLength: {
                                  value: 2,
                                  message: "* Minimum 2 characters required",
                                },
                                pattern: {
                                  value: /^(?![0-9]+$)[a-zA-Z0-9-_ ]{2,}$/,
                                  message: "* Only alphanumeric characters allowed",
                                },
                              })}
                              placeholder="Enter your workspace name"
                              onChange={(e) => onChangeHandler(e)}
                            />
                            {errors.orgName && (
                              <span className="text-xs text-red-500 font-semibold">
                                {errors.orgName.message}
                              </span>
                            )}
                          </fieldset>
                          {errorFromApi !== null && (
                            <div
                              className={`${
                                errorFromApi?.type === "error"
                                  ? "bg-floralWhite "
                                  : "bg-aliceBlue"
                              } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto`}
                            >
                              <div className="mr-2">
                                <BsFillExclamationSquareFill
                                  color={
                                    errorFromApi?.type === "error"
                                      ? "#FF5353"
                                      : "#000000"
                                  }
                                  className="text-2xl"
                                />
                              </div>
                              <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                            </div>
                          )}
                          <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0">
                            <button
                              disabled={errorFromApi || loading}
                              type="submit"
                              className={` ${
                                firstStepValidationPassed
                                  ? `bg-cyanBlue border-cyanBlue `
                                  : `bg-gray border-gray`
                              } ${
                                loading && "pointer-events-none bg-gray border-gray"
                              } font-bold text-sm text-white py-3 px-6 tracking-small`}
                            >
                              {loading ? (
                              <div className="flex flex-row justify-center items-center">
                                <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                                <span className="ml-2">Loading</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className={`opacity-75 fixed inset-0 z-40 bg-primary h-sm lg:h-full ${props?.displayType && '-mt-14 sm:mt-0' }`}></div>
          </>
        )}
      </section>
    );
};

const mapStateToProps = (state) => {
  return {
    roleId: state.auth.roleId,
    orgsList: state.dashboard.data?.organizations || [],
    userProfile: state.dashboard.userProfile,
    isTempPassword: state.auth.isTempPassword,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateOrg: createOrg,
  triggerLogout: logOut,
  fetchDashboard,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);
