// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// assets
import RenderSvg, { svgNames } from "../assets/svg";

// constants
import { RoutingConstants, Strings, Titles } from "../constants";

// actions
import { resetPassword } from "../store/actions/auth";

// utils
import ScreenIsMobile from "../utils/ScreenIsMobile";

// icons
import PieLoader from "../components/PieLoader";
//import { css } from "@emotion/react";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   border-color: "white";
// `;

/**
 * @function ResetPassword
 * @returns {JSX}
 */
const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [errorFromApi, setErrorFromApi] = useState(null);
  const isMobile = ScreenIsMobile();
  // destructure the props
  const {
    triggerResetPassword,
    loggedIn,
    loading,
    accessToken,
    isOrganizationPresent,
    passwordRetrievalEmail,
  } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  // state variables
  const initState = {
    password: "",
    cpassword: "",
  };
  const [userData, setUserData] = useState(initState);
  const [disable, setDisable] = useState(false);

  // show focus on load
  useEffect(() => {
    const firstField = document.getElementById("password");
    firstField.focus();
  }, []);

  // whenever these variables from the store changes, this useeffect will be triggered
  useEffect(() => {
    if (loggedIn && !loading && accessToken) {
      if (!isOrganizationPresent) {
        // navigate to org setup
        navigate(`${RoutingConstants.organization.setup}?addedOrg=false`);
      } else if (isOrganizationPresent) {
        // navigate to dashboard
        navigate(RoutingConstants.dashboard);
      }
    }
  }, [loading, loggedIn, accessToken, isOrganizationPresent, navigate]);

  /**
   * @function onChangeHandler
   * @param {any} e
   * @description handles the username textinput
   */
  const onChangeHandler = (e) => {
    const inputTagElement_1 = document.getElementById("password");
    const inputTagElement_2 = document.getElementById("cpassword");
    // If the user edits the input box, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2
    ) {
      setErrorFromApi(null);
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const inputsValidated =
    !errors?.cpassword?.message &&
    userData.password.length > 0 &&
    userData.cpassword.length > 0 &&
    userData.password === userData.cpassword;

  const submit = () => {
    if (!inputsValidated) {
      return;
    }
    setDisable(true);
    const payload = {
      apiPayload: {
        email: passwordRetrievalEmail,
        password: userData.password,
      },
      onSuccess: (msg) => {
        setErrorFromApi({
          type: "success",
          msg: msg || "Success",
        });
        setTimeout(() => {
          window.location.replace(RoutingConstants.login);
        }, 3000);
      },
      onError: (msg) => {
        setErrorFromApi({
          type: "error",
          msg: msg || "Something went wrong!!!",
        });
        setTimeout(() => {
          setDisable(false);
        }, 1000);
      },
    };
    triggerResetPassword(payload);
  };

  return (
    <div className="max-w-full">
      <div className="grid lg:grid-cols-2">
        <div className="flex flex-wrap flex-col justify-between min-h-screen">
          <div className={`w-full flex px-8 md:px-12 lg:px-20 mt-20  my-10 items-center`}>
            {RenderSvg(svgNames.Wally_Logo)}
          </div>
          {/* Form data */}
          <form
            onSubmit={handleSubmit(submit)}
            className="flex flex-1 flex-col justify-center mb-16"
          >
            <h2 className="font-bold text-black text-2xl px-8 md:px-12 lg:px-20 mb-6 w-full">
              {Titles.enterNewPassword}
            </h2>
            {/* password field */}
            <fieldset className="mb-2 px-8 md:px-12 lg:px-20 ">
              <div className="w-full mb-2 text-base text-black font-bold">
                {`${Strings.password}  `}
                {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
              </div>
              <input
                type="password"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold border-b-2 border-[#000000] focus:border-primary outline-none py-2 px-3 text-sm font-normal overflow-x-auto ${
                  errors.password && "border-red-500"
                }`}
                placeholder={Strings.enterPassword}
                id="password"
                name="password"
                value={userData.password}
                {...register("password", {
                  required: {
                    value: true,
                    message: "* Password is mandatory",
                  },
                  maxLength: {
                    value: 60,
                    message: "* Maximum 60 characters allowed",
                  },
                  minLength: {
                    value: 8,
                    message: "* Minimum 8 characters required",
                  },
                })}
                onChange={(e) => onChangeHandler(e)}
              />
              {errors.password && (
                <span className="text-xs text-red-500 font-semibold">
                  {errors.password.message}
                </span>
              )}
            </fieldset>
            {/* confirm password field */}
            <fieldset className="mb-2 px-8 md:px-12 lg:px-20 ">
              <div className="w-full mb-2 text-base text-black font-bold">
                {`Confirm Password  `}
                {/* <span className="text-lg text-red-500 font-bold">{"*"}</span> */}
              </div>
              <input
                type="password"
                className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold border-b-2 border-[#000000] focus:border-primary outline-none py-2 px-3 text-sm font-normal overflow-x-auto ${
                  errors.cpassword && "border-red-500"
                }`}
                placeholder={"Enter password again"}
                id="cpassword"
                name="cpassword"
                {...register("cpassword", {
                  required: {
                    value: true,
                    message: "* Please confirm password",
                  },
                  maxLength: {
                    value: 60,
                    message: "* Maximum 60 characters allowed",
                  },
                  minLength: {
                    value: 8,
                    message: "* Minimum 8 characters required",
                  },
                })}
                onChange={(e) => onChangeHandler(e)}
              />
              {!errors?.cpassword?.message &&
                userData.password.length > 0 &&
                userData.cpassword.length > 0 &&
                userData.password !== userData.cpassword && (
                  <span className="text-xs text-red-500 font-semibold">
                    {"* Passwords do not match"}
                  </span>
                )}
              {errors.cpassword && (
                <span className="text-xs text-red-500 font-semibold">
                  {errors.cpassword.message}
                </span>
              )}
            </fieldset>
            {errorFromApi !== null && (
              <div
                className={`${
                  errorFromApi?.type === "error"
                    ? "bg-floralWhite "
                    : "bg-aliceBlue"
                } border border-grayLight mx-8 md:mx-12 lg:mx-20 p-3 mt-4 flex flex-row overflow-x-auto`}
              >
                <div className="mr-2">
                  <BsFillExclamationSquareFill
                    color={
                      errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                    }
                    className="text-2xl"
                  />
                </div>
                <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
              </div>
            )}

            <button
              disabled={disable || errorFromApi || loading}
              type="submit"
              className={`cursor-pointer text-white font-bold w-full lg:w-1/2 text-sm tracking-small p-4 bottom-0 fixed ${
                (loading || disable || errorFromApi) &&
                "pointer-events-none bg-gray"
              } ${
                loading
                  ? "bg-gray pointer-events-none"
                  : inputsValidated &&
                    !errorFromApi
                    ? "bg-black"
                    : "bg-gray"
              }`}
            >
              {loading ? (
                <div className="flex flex-row justify-center items-center">
                  <span className="mx-2">{Strings.loading}</span>
                  <PieLoader
                    color={"white"}
                    loading={loading}
                    size={5}
                  />
                </div>
              ) : (
                Strings.submit
              )}
            </button>
          </form>
        </div>
        {!isMobile && (
          <div className="max-w-full h-screen flex justify-center overflow-hidden login-bg">
            {RenderSvg(svgNames.login)}
          </div>
        )}
      </div>
    </div>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    accessToken: state.auth.accessToken,
    isOrganizationPresent: state.auth.isOrganizationPresent,
    passwordRetrievalEmail: state.auth.passwordRetrievalEmail,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerResetPassword: resetPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
