/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import UpgradePlan from "../plan/upgradePlan";
import moment from 'moment-timezone';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const ReportHeader = (props) => {
    const { id, jobId } = useParams();
    const navigate = useNavigate();
    const { selectedOrg, activeSubscription} = props;
    const currentSiteData = selectedOrg?.websites?.find((w) => w.id.toString() === id);
    const isArchive = selectedOrg?.websites?.find(i => i?.id === Number(id))?.isDeleted
    const reports = selectedOrg?.websites?.find((w) => w.id.toString() === id)?.reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (val, index) => {
        if(props?.pageRoute === 'fullReport') {
            navigate(`/website/${id}/reports/fullReport/${val.jobId}`);
        } else {
            navigate(`/website/${id}/reports/${val.jobId}`);
        }
        setSelectedIndex(index);
        setAnchorEl(null);
    };
    const handleBack = () => {
        if(props?.pageRoute === 'fullReport') {
            navigate(`/website/${id}/reports/${jobId}`);
        } else {
            navigate(`/websites`);
        }
    };
    let reportNames = [];
    reports?.length > 0 && reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice().map((val, index) => {
        const auditName = `Audit #${reports.length - index}`;
        reportNames.push(auditName);
    });

    useEffect(() => {
        setSelectedIndex(reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).findIndex(i => i.jobId === jobId))
    }, [jobId, reports]);
    
    return (
        <section className="bg-white flex border-b border-grayLight items-center w-full">
            <div className="flex items-center z-40 p-2 sm:p-3">
                <div className='flex items-center gap-2 border py-1 px-2 cursor-pointer' onClick={() => handleBack()}>
                    <MdOutlineKeyboardBackspace className="text-base sm:text-xl md:text-2xl" />
                    <span className='md:block hidden'>Back</span>
                </div>
                <p className={`text-sm md:text-xl lg:text-2xl font-semibold overflow-x-auto overflow-y-hidden whitespace-nowrap ml-4`}>
                {currentSiteData?.name || "NA"}
                </p>
            </div>
            <div className="flex flex-row justify-center px-5 ml-auto">
                {activeSubscription?.config?.audit_analytics ?
                <Link className="flex justify-center items-center" to={`/website/${id}/analytics/${jobId}`}>
                    <div className="border px-3 py-2 flex items-center gap-2 font-semibold" >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
                        </svg>
                        <span className='md:block hidden'>Analytics</span>
                    </div>
                </Link>
                :
                <UpgradePlan
                    content={`<div className="flex justify-center items-center">
                    <div className="border px-3 md:py-2 py-2.5 flex items-center gap-2 font-semibold" >
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.625H8V3.375H0M0 0.25V1.5H12V0.25M0 7.75H4V6.5H0V7.75Z" fill="black"/>
                        </svg>
                        <span className='md:block hidden'>Analytics</span>
                    </div>
                </div>`}
                />
                }
                {!isArchive && <div className={`p-2 md:p-3 lg:p-4 flex justify-center items-center`}>
                <Link className='flex justify-center items-center' to={`/website/${id}/settings`}>
                    <div className="border px-3 py-2 flex items-center gap-2 font-semibold" >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99989 9.1875C6.38105 9.1875 5.78756 8.95703 5.34998 8.5468C4.91239 8.13656 4.66656 7.58016 4.66656 7C4.66656 6.41984 4.91239 5.86344 5.34998 5.4532C5.78756 5.04297 6.38105 4.8125 6.99989 4.8125C7.61873 4.8125 8.21222 5.04297 8.64981 5.4532C9.08739 5.86344 9.33323 6.41984 9.33323 7C9.33323 7.58016 9.08739 8.13656 8.64981 8.5468C8.21222 8.95703 7.61873 9.1875 6.99989 9.1875ZM11.9532 7.60625C11.9799 7.40625 11.9999 7.20625 11.9999 7C11.9999 6.79375 11.9799 6.5875 11.9532 6.375L13.3599 5.35625C13.4866 5.2625 13.5199 5.09375 13.4399 4.95625L12.1066 2.79375C12.0266 2.65625 11.8466 2.6 11.6999 2.65625L10.0399 3.28125C9.69323 3.0375 9.33323 2.825 8.91323 2.66875L8.66656 1.0125C8.63989 0.8625 8.49989 0.75 8.33323 0.75H5.66656C5.49989 0.75 5.35989 0.8625 5.33323 1.0125L5.08656 2.66875C4.66656 2.825 4.30656 3.0375 3.95989 3.28125L2.29989 2.65625C2.15323 2.6 1.97323 2.65625 1.89323 2.79375L0.559893 4.95625C0.473226 5.09375 0.513226 5.2625 0.639893 5.35625L2.04656 6.375C2.01989 6.5875 1.99989 6.79375 1.99989 7C1.99989 7.20625 2.01989 7.40625 2.04656 7.60625L0.639893 8.64375C0.513226 8.7375 0.473226 8.90625 0.559893 9.04375L1.89323 11.2062C1.97323 11.3438 2.15323 11.3938 2.29989 11.3438L3.95989 10.7125C4.30656 10.9625 4.66656 11.175 5.08656 11.3313L5.33323 12.9875C5.35989 13.1375 5.49989 13.25 5.66656 13.25H8.33323C8.49989 13.25 8.63989 13.1375 8.66656 12.9875L8.91323 11.3313C9.33323 11.1687 9.69323 10.9625 10.0399 10.7125L11.6999 11.3438C11.8466 11.3938 12.0266 11.3438 12.1066 11.2062L13.4399 9.04375C13.5199 8.90625 13.4866 8.7375 13.3599 8.64375L11.9532 7.60625Z" fill="black"/>
                        </svg>
                        <span className='md:block hidden'>Settings</span>
                    </div>
                </Link>
                </div>}
                <div className={`flex ${reports && reports?.length > 0 && "cursor-pointer"}`}>
                {reports && reports?.length > 0 && (
                    <div className="flex flex-row items-center font-mukta" onClick={handleClickListItem}>
                        <div className="px-4 py-2 flex items-center gap-3 font-semibold bg-black text-white">
                            <div className="flex flex-col">
                                <span className="font-semibold text-sm sm:text-lg md:block hidden">
                                {reportNames[selectedIndex]}
                                </span>
                                <span className="font-semibold text-sm sm:text-lg md:hidden block">
                                {reportNames[selectedIndex].substring(5)}
                                </span>
                            </div>
                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.94 0.862305L4 3.73105L7.06 0.862305L8 1.7498L4 5.4998L0 1.7498L0.94 0.862305Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                )}
                <Menu
                    className="mt-2 audit_list"
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                    }}
                >
                    {reports
                    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .slice()
                    .map((val, index) => (
                        <MenuItem
                        style={{ borderTop: "1px solid #DADADA" }}
                        key={val.jobId}
                        selected={index === selectedIndex}
                        onClick={() => {
                            handleMenuItemClick(val, index);
                        }}
                        >
                        <div className="flex flex-col">
                            <span style={{fontFamily: `Raleway, sans-serif`,}}
                            className="font-medium text-sm smm:text-lg hover:text-grayDark">
                            {`Audit #${reports.length - index}`}
                            </span>
                            <span style={{fontSize: 10, fontFamily: `Raleway, sans-serif`}}className="overflow-x-auto">
                            {`${moment(val.createdAt + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}
                            </span>
                        </div>
                        </MenuItem>
                    ))}
                </Menu>
                </div>
            </div>
        </section>
    );
};
const mapStateToProps = (state) => {
    return {
      selectedOrg: state?.dashboard?.data?.organizations?.find(
        (o) => o.id === state.dashboard.selectedOrganization
      ),
      userProfile: state.dashboard.userProfile,
      updateElementLoading: state.dashboard.updateElementLoading,
      auditStatus: state.dashboard.auditStatus,
      scfCount: state.dashboard.scfCount
    };
};

export default connect(mapStateToProps)(ReportHeader);
