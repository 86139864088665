// library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import posthog from 'posthog-js'

// constants
import { Strings } from "../../constants";

// Actions
import { createUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";

// icons
import { HiOutlinePlusCircle } from "react-icons/hi";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import PieLoader from "../PieLoader";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";

/**
 * @function AddUser
 * @returns {JSX}
 */
const AddUser = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const { triggerCreateUser, triggerLogout, existingUsers, truncate, orgsList, selectedOrgId } = props;
  // state variables
  const [showModal, setShowModal] = useState(false);
  const isMobile = ScreenIsMobile();
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const isCurrentWebsiteActive = orgsList.find(i => i.id === selectedOrgId).isActive

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    const firstField = document.getElementById("firstName");
    if (firstField) {
      firstField.focus();
    }
  }, [showModal]);

  const initState = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    site_name: [],
  };
  const [userDetail, setUserDetail] = useState({ ...initState });

  const onChangeHandler = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });

    const inputTagElement_1 = document.getElementById("firstName");
    const inputTagElement_2 = document.getElementById("lastName");
    const inputTagElement_3 = document.getElementById("password");
    const inputTagElement_4 = document.getElementById("confirmpPassword");
    const inputTagElement_5 = document.getElementById("email");
    // If the user edits the input boxes, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2 ||
      document.activeElement === inputTagElement_3 ||
      document.activeElement === inputTagElement_4 ||
      document.activeElement === inputTagElement_5
    ) {
      setErrorFromApi(null);
    }
  };

  const onSuccess = () => {
    posthog.capture('Added user successfully', { property: 'value' })
    setErrorFromApi({
      type: "success",
      msg: "Congratulations!!! New User has been added successfully",
    });
    setLoading(false);
    setTimeout(() => {
      setShowModal(false);
      setUserDetail({ ...initState });
      reset(
        {
          firstName: "",
          lastName: "",
          password: "",
          email: "",
          site_name: [],
        },
        {
          keepErrors: false,
          keepDirty: true,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        }
      );
      setErrorFromApi(null);
    }, 3000);
  };

  const onError = (errorMsg) => {
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setUserDetail({ ...initState });
    reset(
      {
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        site_name: [],
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
  };

  const submit = (e) => {
    setLoading(true);
    if (userDetail?.firstName?.length === 0) {
      setError("firstName", {
        type: "manual",
        message: "* First name is mandatory",
      });
    }
    if (userDetail?.lastName?.length === 0) {
      setError("lastName", {
        type: "manual",
        message: "* Last name is mandatory",
      });
    }
    if (userDetail?.password?.length === 0) {
      setError("password", {
        type: "manual",
        message: "* Password is mandatory",
      });
    }
    if (userDetail?.confirmPassword?.length === 0) {
      setError("confirmPassword", {
        type: "manual",
        message: "* Confirm Password is mandatory",
      });
    }
    if (userDetail?.email?.length === 0) {
      setError("email", {
        type: "manual",
        message: "* E-mail is mandatory",
      });
    }
    if (existingUsers?.map((i) => i.email).includes(userDetail.email.trim())) {
      setError("email", {
        type: "manual",
        message: "* E-mail exists already",
      });
      setLoading(false);
    } else {
      const payload_1 = {
        firstName: userDetail.firstName.trim(),
        lastName: userDetail.lastName.trim(),
        password: userDetail.password.trim(),
        roleId: 1,
        email: userDetail.email.trim(),
      };
      triggerCreateUser({ payload_1, onSuccess, onError });
    }
  };

  const firstStepValidationPassed =
    userDetail.email.length > 0 &&
    !errors?.email &&
    userDetail.firstName.length > 0 &&
    !errors?.firstName &&
    userDetail.lastName.length > 0 &&
    !errors?.lastName &&
    userDetail.password.length > 0 &&
    !errors?.password &&
    userDetail.confirmPassword.length > 0 &&
    userDetail.confirmPassword === userDetail.password &&
    !errors?.confirmPassword;

  const handleAddUser = (e) => {
    e.preventDefault();
    if(isCurrentWebsiteActive) {setShowModal(true);}
  };

  return (
    <section>
      {isMobile ? (
        <button className={`p-2 bg-primary ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}`} onClick={(e) => handleAddUser(e)}>
          <HiOutlinePlusCircle className="text-white" />
        </button>
      ) : (
        <button
          className={`${
            props.icon
              ? "font-semibold hover:text-blue"
              : `bg-primary ${ truncate ? 'px-8 py-2' : 'px-4 py-6' } text-white ml-auto`
          } flex w-max items-center font-semibold uppercase ${!isCurrentWebsiteActive && 'bg-gray pointer-events-none'}`}
          onClick={(e) => handleAddUser(e)}
        >
          {props.icon || truncate ? (
            <>
              {truncate? Strings.addUser : Strings.addNewUser}
            </>
          ) : (
            <>
              {truncate? Strings.addUser : Strings.addNewUser}
              <HiOutlinePlusCircle className="text-xl text-white ml-3" />
            </>
          )}
        </button>
      )}
      {showModal === true && (
        <div id="modal" className="text-black">
          <div className="mt-14 lg:mt-0 bg-white lg:bg-primary/75 fixed mx-auto inset-0 z-50 outline-none focus:outline-none lg:h-full pb-14 sm:pb-0">
            <div className="relative mx-auto w-full lg:w-1/2 flex items-center h-full ">
              <div className="lg:shadow-lg relative w-full bg-white overflow-x-hidden overflow-y-auto px-8 lg:px-0" style={{maxHeight: "90%"}}>
                <div className="relative">
                  <h3 className="text-2xl font-bold lg:px-10 py-6">Add User</h3>
                  <form onSubmit={handleSubmit(submit)} className="w-full">
                    <div className="lg:px-10 lg:mb-10">
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold font-semibold mb-2">
                        First Name
                      </div>
                      <input
                        type="text"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] outline-none text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.firstName && "border-red-500"
                        }`}
                        id="firstName"
                        name="firstName"
                        placeholder="Enter user’s first name"
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "* First name is mandatory",
                          },
                          maxLength: {
                            value: 40,
                            message: "* Maximum 40 characters allowed",
                          },
                          pattern: {
                            value: /^(?![0-9]+$)[a-zA-Z0-9 ]{1,}$/,
                            message:
                              "* Should be alpha numerical",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.firstName && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.firstName.message}
                        </div>
                      )}
                    </fieldset>
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold font-semibold mb-2">
                        Last Name
                      </div>
                      <input
                        type="text"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] outline-none text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.lastName && "border-red-500"
                        }`}
                        id="lastName"
                        name="lastName"
                        placeholder="Enter user’s last name"
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "* Last name is mandatory",
                          },
                          maxLength: {
                            value: 40,
                            message: "* Maximum 40 characters allowed",
                          },
                          pattern: {
                            value: /^(?![0-9]+$)[a-zA-Z0-9 ]{1,}$/,
                            message:
                              "* Should be alpha numerical",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.lastName && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.lastName.message}
                        </div>
                      )}
                    </fieldset>
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold font-semibold mb-2">
                        Email ID
                      </div>
                      <input
                        type="text"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] outline-none text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.email && "border-red-500"
                        }`}
                        id="email"
                        name="email"
                        placeholder="Enter user’s email id"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "* E-mail is mandatory",
                          },
                          maxLength: {
                            value: 300,
                            message: "* Invalid Email Id",
                          },
                          minLength: {
                            value: 6,
                            message: "* Invalid Email Id",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "* Invalid email format",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.email && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.email.message}
                        </div>
                      )}
                    </fieldset>
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold mb-2">
                        Password
                      </div>
                      <input
                        type="password"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] outline-none text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.password && "border-red-500"
                        }`}
                        id="password"
                        name="password"
                        placeholder="Enter user’s password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "* Password is mandatory",
                          },
                          maxLength: {
                            value: 60,
                            message: "* Maximum 60 characters allowed",
                          },
                          minLength: {
                            value: 8,
                            message: "* Minimum 8 characters required",
                          },
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.password && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.password.message}
                        </div>
                      )}
                    </fieldset>
                    <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold mb-2">
                        Confirm Password
                      </div>
                      <input
                        type="password"
                        className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] outline-none text-sm focus:border-primary py-2 px-3 font-medium ${
                          errors.confirmPassword && "border-red-500"
                        }`}
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Re-enter user’s password"
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "* Confirm Password is mandatory",
                          },
                          maxLength: {
                            value: 60,
                            message: "* Maximum 60 characters allowed",
                          },
                          minLength: {
                            value: 8,
                            message: "* Minimum 8 characters required",
                          },
                          validate: (value) =>
                            value === watch("password") ||
                            "* Confirm Password should be same as Password",
                        })}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      {errors.confirmPassword && (
                        <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                          {errors.confirmPassword.message}
                        </div>
                      )}
                    </fieldset>
                    </div>
                    {errorFromApi !== null && (
                      <div className="w-full lg:px-10">
                        <div
                          className={`${
                            errorFromApi?.type === "error"
                              ? "bg-floralWhite "
                              : "bg-aliceBlue"
                          } border border-grayLight p-3 my-8 flex flex-row overflow-x-auto w-full`}
                        >
                          <div className="mr-2">
                            <BsFillExclamationSquareFill
                              color={
                                errorFromApi?.type === "error"
                                  ? "#FF5353"
                                  : "#000000"
                              }
                              className="text-2xl"
                            />
                          </div>
                          <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-6 mt-5 bg-white lg:static fixed bottom-0 w-full left-0 ">
                      <button
                        className="col-span-2 py-4 font-bold text-sm text-grayNobel border-t border-grayBorder"
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          setUserDetail({ ...initState });
                          reset(
                            {
                              firstName: "",
                              lastName: "",
                              password: "",
                              email: "",
                              role: "",
                              site_name: [],
                            },
                            {
                              keepErrors: false,
                              keepDirty: true,
                              keepIsSubmitted: false,
                              keepTouched: false,
                              keepIsValid: false,
                              keepSubmitCount: false,
                            }
                          );
                        }}
                      >
                        {"Cancel"}
                      </button>
                      <button
                        disabled={errorFromApi || loading}
                        className={`${
                          firstStepValidationPassed
                            ? `bg-cyanBlue border-cyanBlue `
                            : `bg-gray border-gray`
                        } ${
                          loading && "pointer-events-none"
                        } col-span-4 font-bold text-sm text-white py-4 tracking-small`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="flex flex-row justify-center items-center">
                            <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                            <span className="ml-2">{Strings.loading}</span>
                          </div>
                        ) : (
                          Strings.submit
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrgId: state.dashboard.selectedOrganization,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerCreateUser: createUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
