// library imports
import { combineReducers } from "redux";

// user imports
import authReducer from "./auth";
import dashboardReducer from "./dashboard";

/**
 * @function rootReducer
 */
const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
});

// exports
export default rootReducer;
