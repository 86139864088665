/* eslint-disable array-callback-return */
// library imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SectionLoader from "../SectionLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from "@mui/material/Stack";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";


// components
import AddUser from "../../components/users/addUser";

// svg
import { useEffect } from "react";

// actions
import { getWorkspaceUsers } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function UsersList
 * @param {Object} props
 * @returns {JSX}
 */
const ManageUsers = (props) => {
  const [errorFromApi, setErrorFromApi] = useState(null);
  const {
    currentOrgObject,
    selectedOrgId,
    triggerLogout,
    triggerSiteData,
    currentOrgUsers,
    roleId,
    userProfileId
  } = props;

  const [totalUsers, setTotalUsers] = useState(null)
  const [users, setUsers] = useState(null)
  const [elementsLimit, setElementsLimit] = useState(25)
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);

  useEffect(() => {
    triggerSiteData({
      payload: {
        id: selectedOrgId,
        limit: elementsLimit,
        page: page
      },
      onSuccess: (res) => {
        setTotalUsers(res?.data?.data?.count[0]?.count)
        setUsers(res?.data?.data?.users)
        setPageLimit(
          res?.data?.data?.count[0]?.count % elementsLimit === 0
            ? Math.floor(res?.data?.data?.count[0]?.count / elementsLimit)
            : Math.floor(res?.data?.data?.count[0]?.count / elementsLimit + 1) || 1
        );
      },
      onError: (errorMsg) => {
        setErrorFromApi({
          type: "error",
          msg: errorMsg || "Something went wrong!!!",
        });
        setTimeout(() => {
          setErrorFromApi(null);
        }, 3000);
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  },[currentOrgUsers, elementsLimit, selectedOrgId, page, triggerSiteData, triggerLogout])
  return (
    <section>
    <div id="modal" className="text-black">
      <div className="inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mb-80">
          <div>
            <div className="flex flex-wrap items-center justify-between border-b border-grayLight pb-3 md:pb-5 font-semibold">
              <h3 className="flex items-center text-sm md:text-lg">
                Manage users
              </h3>
              {roleId === 0 && <div className="ml-auto">
                <AddUser websites={currentOrgObject?.websites} existingUsers={users} truncate={true} />
              </div>}
            </div>
            <section className="h-[60vh] overflow-auto">
            {users?.length > 0 ?
              users.map((val, index) => {
                return (
                  <div
                    className="group text-xs md:text-sm py-2 grid grid-cols-6 border-b border-grayLight"
                    key={index}
                  >
                    <p className="overflow-x-auto text-xs md:text-sm col-span-3 capitalize">
                        {val.firstName + ' ' + val.lastName}
                    </p>
                    <p className="col-span-2 border px-1 py-2 w-[90px] h-auto text-xs leading-[10px] uppercase text-center">{val.roleId === 0 ? 'Admin' : 'Manager'}</p>
                    {(roleId === 0 && val.id !== userProfileId) && <div
                      className="bg-white flex text-xs font-normal"
                      style={{ transition: "all 500ms ease" }}
                    >
                      <Link to={"/users/" + val.id} key={index} className="flex items-center justify-end ml-auto">
                        <div className="group-hover:hidden"><div className={`w-2 h-2 rounded-full m-auto ${val.isActive === true ? "bg-bluishGreen" : "bg-gray"}`}></div></div>
                        <p style={{transition: "all 500ms ease"}} className="w-0 ml-2 group-hover:w-12 cursor-pointer bg-primary text-white text-1xs flex items-center justify-center font-bold md:py-1 overflow-hidden mr-1">EDIT</p>
                      </Link>                
                    </div>}
                  </div>
                );
              }) :
              <div className="p-6 py-4 text-center flex items-center justify-center h-full">
                <SectionLoader
                  content={users === null ? ["Fetching User Data"]: users?.length === 0? ["No data to display"]: ["we couldn't find any results"]}
                  isNoData={users === null ? false : true}
                />
              </div>
              }
              {totalUsers > 0 && <div className="flex w-full items-center justify-between absolute border border-grayLight bottom-[-70px] bg-white flex-col sm:flex-row">
              <div className="flex w-full w-fit justify-center pageCount border-b border-grayLight border-0 font-medium">
                    <p className="h-10 px-4 py-2 border-r border-grayLight flex items-center border-l md:border-l-0 justify-center">Show</p>
                  <div 
                    className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalUsers <= 0 && 'text-gray pointer-events-none'} ${elementsLimit === 25 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(25); setPage(1)}}
                  >
                    25
                  </div>
                  <div 
                    className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalUsers <= 25 && 'text-gray pointer-events-none'} ${elementsLimit === 50 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(50); setPage(1)}}
                  >
                    50
                  </div>
                  <div 
                    className={`h-10 px-4 py-2 border-r border-grayLight flex items-center justify-center cursor-pointer ${totalUsers <= 50 && 'text-gray pointer-events-none'} ${elementsLimit === 100 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(100); setPage(1)}}
                  >
                    100
                  </div>
                </div>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{ previous: HiOutlineArrowLeft, next: HiOutlineArrowRight }}
                        {...item}
                      />
                    )}
                    count={pageLimit}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>}
            </section>
            {errorFromApi !== null && (
              <div
                className={`${
                  errorFromApi?.type === "error"
                    ? "bg-floralWhite "
                    : "bg-aliceBlue"
                } border border-grayLight p-3 my-8 flex items-center flex-row overflow-x-auto`}
              >
                <div className="mr-2">
                  <BsFillExclamationSquareFill
                    color={
                      errorFromApi?.type === "error" ? "#FF5353" : "#000000"
                    }
                    className="text-2xl"
                  />
                </div>
                <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    currentOrgUsers: state.dashboard.currentOrgUsers,
    selectedOrgId: state.dashboard.selectedOrganization,
    roleId: state.auth.roleId,
    userProfileId: state.dashboard.userProfile?.id || 0
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  triggerSiteData: getWorkspaceUsers,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
