// library imports
import React from "react";
import { connect } from "react-redux";
import { FiEye } from "react-icons/fi";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useParams, Link } from "react-router-dom";
import moment from 'moment-timezone';

// components
import { Strings } from "../../constants";
import DefaultPage from "../../components/layout/defaultPage";

/**
 * @function ViewWebsite
 * @returns {JSX}
 */
const Reports = (props) => {
  // destructure props;
  const { id } = useParams();
  const { selectedOrg, userProfile } = props;

  let reports = [...selectedOrg.websites].find(
    (w) => w.id.toString() === id
  ).reports;

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section className="grid grid-cols-1 sm:grid-cols-2 flex-wrap items-center border-b border-grayLight p-6 py-4 font-semibold">
        <div className="flex items-center">
          {/* if no reports, go to view website*/}
          {reports.length === 0 && (
            <Link to={`/website/${id}`}>
              <MdOutlineKeyboardBackspace className="text-2xl mr-2 cursor-pointer hover:text-blue" />
            </Link>
          )}
          {/* if reports present, goto first report page */}
          {reports.length > 0 && (
            <Link
              className={"text-xl ml-5 col-span-2"}
              to={`/website/${id}/reports/${reports[0]?.jobId}`}
            >
              <MdOutlineKeyboardBackspace className="text-2xl mr-2 cursor-pointer hover:text-blue" />
            </Link>
          )}
          {Strings.reportHistory}
        </div>
      </section>
      <section style={{ maxHeight: "95vh" }} className="overflow-y-auto">
        <div className="min-h-70 m-6 border border-grayLight">
          <div className="grid grid-cols-1 sm:grid-cols-4 p-6 py-4 font-medium">
            <p className="text-center">{Strings.srNo}</p>
            <p className="text-center">{Strings.reportName}</p>
            <p className="text-center">{"DATE & TIME"}</p>
            <p className="text-center">{Strings.action}</p>
          </div>
          {reports.length &&
            reports
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((i, index) => {
                return (
                  <div
                    className="grid grid-cols-1 sm:grid-cols-4 p-6 py-4 even:bg-grayLight even:bg-opacity-30 text-sm"
                    key={index}
                  >
                    <p className="text-center">{index + 1}</p>
                    <p className="text-center">{`${"Report"} ${
                      reports.length - index
                    }`}</p>
                    <p className="text-center">
                      {`${moment(i.createdAt + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}
                    </p>
                    <p className="flex justify-center">
                      <Link to={`/website/${id}/reports/${i.jobId}`}>
                        <FiEye className="text-xl cursor-pointer hover:text-blue" />
                      </Link>
                    </p>
                  </div>
                );
              })}
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    userProfile: state.dashboard.userProfile,
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
  };
};

// exports
export default connect(mapStateToProps, {})(Reports);
