import React from 'react';

const AccordionLayout = ({ title, description, count, level, category, severity, children, index, activeIndex, setActiveIndex, groupValue }) => {
    const handleSetIndex = (index) => (activeIndex !== index) && setActiveIndex(index);

    return (
        <div className='elements-list w-full'>
            <div onClick={() => handleSetIndex(index)} className={`cursor-pointer grid sm:grid-cols-8 w-full py-3 ${activeIndex !== index && 'border-b'}`}>
                <div className='flex items-center col-span-2'>
                    <div className="pr-3 h-[18px]"><input type="checkbox" /></div>
                    {
                        (activeIndex === index)
                        ?
                        <p className='font-bold text-3xl leading-none pr-3'>
                            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.00498 6.99572L0.000204611 0.995719L12.0098 0.99572L6.00498 6.99572Z" fill="#000000"/>
                            </svg>
                        </p>
                        : 
                        <p className='font-bold text-3xl leading-none pr-3'>
                            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.99572 6.9999L0.99572 13.0047L0.99572 0.995117L6.99572 6.9999Z" fill="#000000"/>
                            </svg>
                        </p>
                    }
                    <div className='font-bold'>{title}</div>
                </div>
                <p className='col-span-3'>{description}</p>
                <div className='col-span-3 flex items-center sm:justify-end gap-2'>
                    <div className='border border-yellow bg-yellow py-1 px-2 leading-none'>{count}</div>
                    {groupValue === 'rules' &&
                        <>
                            <p className='bg-black border text-white py-1 px-2 leading-none'>Level {level}</p>
                            <p className='border py-1 px-2 leading-none'>{category}</p>
                            <p className={`py-1 px-2 leading-none ${severity?.includes('error') ? 'bg-sunsetRed text-white' : severity?.includes('notice') ? 'bg-salmon text-white' : 'bg-yellow text-white'}`}>
                                {severity?.includes('error') ? 'Failure' :severity?.includes('notice') ? 'Needs Attention' : 'Recommendation'}
                            </p>
                        </>
                    }
                </div>
            </div>

            {(activeIndex === index) && (
                <div className='content'> 
                    {children}
                </div>
            )}
        </div>
    );
};

export default AccordionLayout;
