/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Link, useNavigate, useParams,
} from "react-router-dom";
import "chart.js/auto";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Bar, Chart } from "react-chartjs-2";
import moment from 'moment-timezone';

// icons
import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

//constants
import { RoutingConstants } from "../../constants";
import SectionLoader from "../../components/SectionLoader";

import DefaultPage from "../../components/layout/defaultPage";
// actions
import { logOut } from "../../store/actions/auth";
import { getSiteAnalyticsData, getActiveSubscription } from "../../store/actions/dashboard";
// token expired handler
import TokenExpired from "../../utils/TokenExpired";
import UpgradePlan from "../../components/plan/upgradePlan";
/**
 * @function ViewReport
 * @returns {JSX}
 */
const ViewAnalytics = (props) => {
  const { id, jobId } = useParams();
  const navigate = useNavigate();
  const { selectedOrg, triggerLogout, auditStatus, loggedIn,
    accessToken,
    getSiteAnalyticsData,
    selectedOrgId,
    triggerActiveSubscription
  } = props;
  const [activeSubscription, setActiveSubscription] = useState({})
  const currentSiteData = selectedOrg?.websites?.find((w) => w.id.toString() === id);
  const reports = selectedOrg?.websites?.find((w) => w.id.toString() === id)?.reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const isArchive = selectedOrg?.websites?.find(i => i?.id === Number(id))?.isDeleted
  const [siteAnlyticsData, setSiteAnlyticsData] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(true)
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let reportDate = "-";
  let reportNames = [];
  reports?.length > 0 && reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice().map((val, index) => {
    const auditName = `Audit #${reports.length - index}`;
    reportNames.push(auditName);
    if (jobId === val.jobId) {
      reportDate = `${val.createdAt}`;
    }
  });

  const handleMenuItemClick = (val, index) => {
    navigate(`/website/${id}/reports/fullReport/${val.jobId}`);
    setSelectedIndex(index);
    setAnchorEl(null);
  };
   /**
   * @function handleLogout
   */
    const handleLogout = () => {
      triggerLogout();
      if (!loggedIn || !accessToken) {
        // navigate to login
        navigate(RoutingConstants.login);
      }
    };

    useEffect(() => {
      triggerActiveSubscription({
        payload: {
          websiteId: id,
        },
        onSuccess: (res) => { 
          setActiveSubscription(res)
        },
        onError: (errorMsg) => {
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              triggerLogout();
            }, 1500);
          }
        }
      });
    }, [])

useEffect(() => {
  if (selectedOrgId) {
    const payload = {
      orgId: selectedOrgId,
      siteId: id
    }
    getSiteAnalyticsData({
      payload,
      onSuccess: (res) => {
        setLoader(false)
        setSiteAnlyticsData(res)
      },
      onError: (errorMsg) => {
        setLoader(false)
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            handleLogout();
          }, 1500);
        }
      }
    });
  }
  window.scrollTo(0, 0);
}, [selectedOrgId]);

  const horizontalBaroptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItem) {
            const str = tooltipItem[0]?.dataset?.originalLabel[tooltipItem[0].dataIndex];
            const maxwidth=45;
            var returnedArray = [];
            var splittedString = str.split(" ");
            var temp = "";
            splittedString.forEach(function(item, index){
              if(temp.length > 0){
                var concat = temp + ' ' + item;
                if(concat.length > maxwidth){
                  returnedArray.push(temp);
                  temp = "";
                }
                else{
                  if(index === (splittedString.length-1)) {
                    returnedArray.push(concat);
                    return;
                  }
                  else {
                    temp = concat;
                    return;
                  }
                }
              }
          
              if(index === (splittedString.length-1)) {
                returnedArray.push(item);
                return;
              }
          
              if(item.length < maxwidth) {
                temp = item;
              }
              else {
                returnedArray.push(item);
              }
          
            });
            return returnedArray;
          }
        }
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false
        },
      },
      y: {
        barPercentage: 0.4,
        grid: {
          display: false
        },
      }
    }
  };
  
  const horizontalBarRule = {
    labels: siteAnlyticsData?.top_rule_failures?.map((i) => i?.key?.length > 20? i?.key?.substring(0, 17) + "...": i?.key) || [],
    datasets: [
      {
        data: siteAnlyticsData?.top_rule_failures?.map((i) => i?.sum) || [],
        backgroundColor: "#000000",
        borderColor: "#fff",
        barThickness: 20,
        minBarLength: 2,
        originalLabel:siteAnlyticsData?.top_rule_failures?.map((i) => i?.key) || [],
      }
    ],
  };
  
  const horizontalBarPage = {
    labels: siteAnlyticsData?.top_affected_pages.map((i) => i?.title?.length > 20? i?.title?.substring(0, 17) + "...": i?.title) || [],
    datasets: [
      {
        data: siteAnlyticsData?.top_affected_pages.map((i) => i?.scf) || [],
        backgroundColor: "#000000",
        borderColor: "#fff",
        barThickness: 20,
        minBarLength: 2,
        originalLabel:siteAnlyticsData?.top_affected_pages.map((i) => i?.title) || [],
      }
    ],
  };
  
  const fixesRatio = {
    labels: siteAnlyticsData?.no_of_scf_vs_fixes.map((i) => i?.audit_num?.length > 20? i?.audit_num?.substring(0, 17) + "...": i?.audit_num) || [],
    datasets: [
      {
        grouped: true,
        categoryPercentage: 0.35,
        barPercentage: 1,
        data: siteAnlyticsData?.no_of_scf_vs_fixes.map((i) => i?.total_scf) || [],
        label: "No. of success criterion failures",
        borderColor: "#000000",
        backgroundColor: "#000000",
        barThickness: 20,
        fill: true
      },
      {
        grouped: true,
        categoryPercentage: 0.35,
        barPercentage: 1,
        data: siteAnlyticsData?.no_of_scf_vs_fixes.map((i) => i?.total_autofix) || [],
        label: "No. of fixes generated",
        borderColor: "#FC8763",
        backgroundColor: "#FC8763",
        barThickness: 20,
        fill: true
      }
    ]
  };
  
  const fixesRatioOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    layout: {
      padding: {
        left: 75,
        right: 75,
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false
        },
      },
      x: {
        grid: {
          display: false
        },
      },
    }
  };
  const axScoreData = {
    labels: siteAnlyticsData?.score_diff.map((i) => i?.month?.length > 20? i?.month?.substring(0, 17) + "...": i?.month) || [],
    datasets: [
      {
        type: 'line',
        borderJoinStyle: 'bevel',
        borderColor: '#000000',
        borderWidth: 1.5,
        pointStyle:'rect',
        backgroundColor: '#000000',
        data: siteAnlyticsData?.score_diff.map((i) => i?.with_ax_score) || [],
        label: "With Wally",
      },
      {
        type: 'line',
        pointStyle:'rect',
        borderJoinStyle: 'bevel',
        borderColor: '#FC8763',
        borderWidth: 1.5,
        backgroundColor: '#FC8763',
        data: siteAnlyticsData?.score_diff.map((i) => i?.without_ax_score) || [],
        label: "Without Wally",
      },
    ],
  };
  
  const auditData = {
    labels: siteAnlyticsData?.no_of_audited_pages.map((i) => i?.Month?.length > 20? i?.Month?.substring(0, 17) + "...": i?.Month) || [],
    datasets: [
      {
        type: 'bar',
        fill: true,
        barPercentage: 0.35,
        barThickness: 20,
        backgroundColor: '#000000',
        data: siteAnlyticsData?.no_of_audited_pages.map((i) => i?.audit_count) || [],
        label: "No. of audits",
      },
      {
        type: 'line',
        pointStyle:'rect',
        borderJoinStyle: 'bevel',
        borderColor: '#FC8763',
        borderWidth: 1.5,
        backgroundColor: '#FC8763',
        data: siteAnlyticsData?.no_of_audited_pages.map((i) => i?.page_count) || [],
        label: "Pages Audited",
      },
    ],
  };

  return (
    <DefaultPage handleLogout={handleLogout} showSidebar={true}>
      <section className="flex border-b border-grayLight items-center justify-between w-full">
        <div className="flex items-center z-40 p-2 sm:p-3">
          <Link to={`/website/${id}/reports/${jobId}`}>
            <MdOutlineKeyboardBackspace className="text-base sm:text-xl md:text-2xl lg:text-3xl mr-1 sm:mr-2 cursor-pointer" />
          </Link>
          <p className={`text-sm md:text-xl lg:text-2xl font-semibold whitespace-nowrap`}>
            {currentSiteData?.name || "NA"}
          </p>
        </div>

        <div className="flex flex-row justify-center">
          <div  className={`bg-primary pointer-events-none p-2 md:p-3 lg:p-4 border-l border-grayLight flex justify-center items-center`}>
            <Link to={`/website/${id}/analytics/${jobId}`}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 20.0833H5.91667V10.1667H8.75V20.0833ZM14.4167 20.0833H11.5833V5.91667H14.4167V20.0833ZM20.0833 20.0833H17.25V14.4167H20.0833V20.0833ZM22.9167 22.9167H3.08333V3.08333H22.9167V23.0583M22.9167 0.25H3.08333C1.525 0.25 0.25 1.525 0.25 3.08333V22.9167C0.25 24.475 1.525 25.75 3.08333 25.75H22.9167C24.475 25.75 25.75 24.475 25.75 22.9167V3.08333C25.75 1.525 24.475 0.25 22.9167 0.25Z" fill="#E5E5E5"/>
            </svg>
            </Link>
          </div>
          {!isArchive &&<div className={`p-2 md:p-3 lg:p-4 border-x border-grayLight flex justify-center items-center`}>
            <Link to={`/website/${id}/settings`}>
              <svg className="w-5 h-5 sm:w-8 sm:h-8" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27.7579 10.366L26.7579 8.634C26.2059 7.677 24.9829 7.35 24.0259 7.902L23.4999 8.206C21.4999 9.36 18.9999 7.917 18.9999 5.608V5C18.9999 3.895 18.1049 3 16.9999 3H14.9999C13.8949 3 12.9999 3.895 12.9999 5V5.608C12.9999 7.917 10.4999 9.361 8.49989 8.206L7.97389 7.902C7.01689 7.35 5.79389 7.677 5.24189 8.634L4.24189 10.366C3.68989 11.323 4.01689 12.546 4.97389 13.098L5.49989 13.402C7.49989 14.557 7.49989 17.443 5.49989 18.598L4.97389 18.902C4.01689 19.454 3.68989 20.677 4.24189 21.634L5.24189 23.366C5.79389 24.323 7.01689 24.65 7.97389 24.098L8.49989 23.794C10.4999 22.639 12.9999 24.083 12.9999 26.392V27C12.9999 28.105 13.8949 29 14.9999 29H16.9999C18.1049 29 18.9999 28.105 18.9999 27V26.392C18.9999 24.083 21.4999 22.639 23.4999 23.794L24.0259 24.098C24.9829 24.65 26.2059 24.323 26.7579 23.366L27.7579 21.634C28.3099 20.677 27.9829 19.454 27.0259 18.902L26.4999 18.598C24.4999 17.443 24.4999 14.557 26.4999 13.402L27.0259 13.098C27.9829 12.546 28.3109 11.323 27.7579 10.366Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          </div>}
          <div className={`flex ${reports && reports?.length > 0 && "cursor-pointer"}`}>
            {reports && reports?.length > 0 && (
              <div className="flex flex-row items-center" onClick={handleClickListItem}>
                <div className="px-2 sm:px-5 flex flex-col">
                  <span className="font-semibold text-black hover:text-grayDark text-sm sm:text-lg">
                    {reportNames[selectedIndex]}
                  </span>
                  <span className="font-medium text-black text-xs">
                    {moment(reportDate + '+00:00').local().format('MMMM DD, YYYY h:mmA')}
                  </span>
                </div>
                <MdOutlineArrowDropDown className="text-2xl text-black font-semibold" />
              </div>
            )}
            <Menu
              className="mt-2"
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
            >
              {reports
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice()
                .map((val, index) => (
                  <MenuItem
                    style={{ borderTop: "1px solid #DADADA" }}
                    key={val.jobId}
                    selected={index === selectedIndex}
                    onClick={() => {
                      handleMenuItemClick(val, index);
                    }}
                  >
                    <div className="px-5 flex flex-col">
                      <span style={{fontFamily: `Raleway, sans-serif`,}}
                        className="font-medium text-sm smm:text-lg hover:text-grayDark">
                        {`Audit #${reports.length - index}`}
                      </span>
                      <span style={{fontSize: 10, fontFamily: `Raleway, sans-serif`}}>
                        {`${moment(val.createdAt + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}
                      </span>
                    </div>
                  </MenuItem>
                ))}
            </Menu>
          </div>
          <div className="border-l border-grayLight p-2 px-2 sm:px-5 text-center">
            <svg className="mx-auto w-5 h-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14.5L14 10L8 5.5V14.5ZM20 10C20 4.46 15.54 0 10 0C8.83 0 7.7 0.19 6.62 0.56L7.32 2.5C8.17 2.16 9.06 1.97 10 1.97C14.41 1.97 18.03 5.59 18.03 10C18.03 14.41 14.41 18.03 10 18.03C5.59 18.03 1.97 14.41 1.97 10C1.97 9.06 2.16 8.12 2.5 7.28L0.56 6.62C0.19 7.7 0 8.83 0 10C0 15.54 4.46 20 10 20C15.54 20 20 15.54 20 10ZM3.47 1.97C4.32 1.97 5 2.68 5 3.47C5 4.32 4.32 5 3.47 5C2.68 5 1.97 4.32 1.97 3.47C1.97 2.68 2.68 1.97 3.47 1.97Z" fill="black" />
            </svg>
            <div className="mt-2 hidden sm:block">
              <p className="text-xs font-bold">Next audit schedule</p>
              <p className="text-xs">{auditStatus?.next_audit_trigger_time && moment(auditStatus?.next_audit_trigger_time + '+00:00').local().format('MMMM DD, YYYY h:mmA')}</p>
            </div>
          </div>
          {activeSubscription?.config?.detailed_audit_summary ?
            <Link to={`/website/${id}/reports/fullReport/${jobId}`}>
              <div className="border-l border-grayLight p-2 px-2 sm:px-5 text-center">
                <svg className="mx-auto" width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 13L17.5 18L14 14.5L15.5 13L17.5 15L21 11.5L22.5 13ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.75 8.65 21.44 9.26 21.08 9.85C20.5 9.5 19.86 9.25 19.18 9.12L19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8C3.83 11.36 7.24 13.5 11 13.5L12.21 13.43C12.07 13.93 12 14.46 12 15V15.46L11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5Z" fill={'black'} />
                </svg>
                <div className="mt-2 hidden sm:block">
                  <p className="text-xs font-bold">View</p>
                  <p className="text-xs">Full audit report</p>
                </div>
              </div>
            </Link>
          :
            <UpgradePlan
              content={`<div>
              <div className="border-l border-grayLight p-2 px-2 sm:px-5 text-center">
                <svg className="mx-auto" width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 13L17.5 18L14 14.5L15.5 13L17.5 15L21 11.5L22.5 13ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.75 8.65 21.44 9.26 21.08 9.85C20.5 9.5 19.86 9.25 19.18 9.12L19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8C3.83 11.36 7.24 13.5 11 13.5L12.21 13.43C12.07 13.93 12 14.46 12 15V15.46L11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5Z" fill="black" />
                </svg>
                <div className="mt-2 hidden sm:block">
                  <p className="text-xs font-bold">View</p>
                  <p className="text-xs">Full audit report</p>
                </div>
              </div>
            </div>`}
            />
          }
        </div>
      </section>
        <section className={`overflow-y-auto h-91 ${loader ? 'flex justify-center' : ''}`}>
          {loader ?
            <SectionLoader content={['We are crunching a bunch of numbers.',' We will notify you once it is ready']}/>
          : 
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
              <div className="bg-aliceBlue p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className="mb-6 text-6xl">
                    {siteAnlyticsData?.no_of_audits[0]?.count || 0}
                  </h2>
                  <div className="bg-primary py-1 px-2 text-white text-sm lg:text-sm inline-block">AUDITS</div>
                </div>
              </div>

              <div className="bg-aliceBlue p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {siteAnlyticsData?.audit_duration[0].sum || 0}
                  </h2>
                  <div className="bg-primary py-1 px-2 text-white text-sm lg:text-sm">
                    AUDIT MINUTES
                  </div>
                </div>
              </div>

              <div className="bg-floralWhite p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {siteAnlyticsData?.total_scf_count[0]?.sum || 0}
                  </h2>
                  <div className="border border-salmon text-salmon py-1 px-2 text-sm lg:text-sm">
                    SUCCESS CRITERION FAILURES
                  </div>
                </div>
              </div>

              <div className="bg-greenLight p-6 sm:min-h-14 flex items-center justify-center border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className={`mb-6 text-6xl`}>
                    {siteAnlyticsData?.auto_fix_count[0]?.sum || 0}
                  </h2>
                  <div className="border border-greenSucceed text-greenSucceed py-1 px-2 text-sm lg:text-sm">
                    AUTO FIXES GENERATED
                  </div>
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-8 text-center">
            <div className="bg-white col-span-2 p-6 sm:min-h-14 flex items-center justify-center sm:border-r border-b border-grayLight">
                <div className="font-semibold text-center">
                  <h2 className="mb-6 text-6xl">
                    { parseFloat(siteAnlyticsData?.wally_score[0]?.page_score).toFixed(1) || 0}
                  </h2>
                  <div className="bg-primary py-1 px-2 text-white text-sm lg:text-sm inline-block">ax SCORE</div>
                </div>
              </div>
              <div className="px-6 col-span-3 py-7 sm:border-r border-b border-grayLight">
                <h3 className="inline-block p-2 leading-none mb-3 text-cyanBlue border border-cyanBlue font-semibold text-center">TOP {siteAnlyticsData?.top_rule_failures?.length} RULES FAILURES</h3>
                <Bar className="max-h-44" options={horizontalBaroptions} data={horizontalBarRule} />
              </div>
              <div className="px-6 col-span-3 py-7 sm:border-r border-b border-grayLight">
                <h3 className="inline-block p-2 leading-none mb-3 text-cyanBlue border border-cyanBlue font-semibold text-center">TOP {siteAnlyticsData?.top_affected_pages.length} AFFECTED PAGES</h3>
                <Bar className="max-h-44" options={horizontalBaroptions} data={horizontalBarPage} />
              </div>
            </div>
            <div className="grid grid-rows-2 grid-cols-1 sm:grid-cols-2 text-center">
              <div className="row-span-2 text-center px-6 py-6 sm:border-r border-b border-grayLight">
                <div className="flex justify-center items-center gap-4">
                  <h3 className="inline-block p-2 leading-none text-cyanBlue border border-cyanBlue font-semibold text-center">ax SCORE</h3>
                  <div className="flex items-center text-sm"><div className="bg-primary w-3 h-3 mr-2"></div>With Wally</div>
                  <div className="flex items-center text-sm"><div className="bg-salmon w-3 h-3 mr-2"></div>Without Wally</div>
                </div>
                <Chart className="h-full" height={264} options={fixesRatioOptions} data={axScoreData} />
              </div>
              <div className="text-center px-6 py-6 sm:border-r border-b border-grayLight">
                <div className="flex justify-center items-center gap-4">
                  <h3 className="inline-block p-2 leading-none text-cyanBlue border border-cyanBlue font-semibold text-center">AUDITS RAN</h3>
                  <div className="flex items-center text-sm"><div className="bg-primary w-3 h-3 mr-2"></div>No. of audits</div>
                  <div className="flex items-center text-sm"><div className="bg-salmon w-3 h-3 mr-2"></div>Pages Audited</div>
                </div>
                <Chart className="h-64" height={112} options={fixesRatioOptions} data={auditData} />
              </div>
              <div className="text-center px-6 py-6 sm:border-r border-b border-grayLight">
                <div className="flex justify-center items-center gap-8">
                  <h3 className="inline-block p-2 leading-none text-cyanBlue border border-cyanBlue font-semibold text-center">Fixes ratio</h3>
                  <div className="flex items-center text-sm"><div className="bg-primary w-3 h-3 mr-2"></div>No. of success criterion failures</div>
                  <div className="flex items-center text-sm"><div className="bg-salmon w-3 h-3 mr-2"></div>No. of fixes generated</div>
                </div>
                <Bar className="h-64 w-32" height={112} options={fixesRatioOptions}  data={fixesRatio} />
              </div>
            </div>
          </>
          }
        </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    selectedOrg: state?.dashboard?.data?.organizations?.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ),
    auditStatus: state.dashboard.auditStatus,
    loggedIn: state.auth.loggedIn,
    accessToken: state.auth.accessToken,
    selectedOrgId: state.dashboard.selectedOrganization,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  getSiteAnalyticsData,
  triggerActiveSubscription: getActiveSubscription,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ViewAnalytics);
