// library imports
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// user imports
import rootReducer from "../store/reducers";
import rootSaga from "./sagas/rootSaga";
import StateCacheStorage from "../utils/StateCacheStorage";

// for redux dev tools
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;

const sagaMiddleware = createSagaMiddleware();

// populate any middleware used here
const middleware = [sagaMiddleware];

// rehydrate the app state from local storage
const initialState = StateCacheStorage.get("APP_STATE");

// build the redux store with all these
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), reduxDevTools)
);

store.subscribe(() => {
  StateCacheStorage.set("APP_STATE", store.getState());
});

sagaMiddleware.run(rootSaga);

// exports
export default store;
