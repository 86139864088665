// library imports
import React from "react";
import RenderSvg, { svgNames } from "../../assets/svg";

/**
 * @function WebsiteCard
 * @param {JSON} props
 * @returns {JSX}
 */
const WebsiteCard = (props) => {
  let { data, onCardClick, index } = props;
  let reportStatus = '';
  if (data?.reportStatus) {
    reportStatus = data?.reportStatus === 2 ? 'Audit in progress' : data?.reportStatus === 3 ? 'Audit got interrupted' : 'Completed';
  }
  if (data?.compliant_status && data?.reportStatus === 1) {
    reportStatus = ''
  }
  return (
    <div onClick={() => onCardClick(data)}
    key={String(index)} className={`group relative outline outline-1 outline-black ${
      data?.id && "cursor-pointer"
    }`}>
      <div className="relative h-80 w-full overflow-hidden bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1  border-b border-black">
        <img src={
        data?.image?.length > 0 && data?.image?.includes("https")
          ? data?.image
          : "https://wallyassets.s3.us-east-2.amazonaws.com/Placeholder_image.png"
        } alt={data?.url}
        className="h-full w-full object-cover object-center" />
      </div>
      <h3 className="mt-6 font-bold text-black text-lg pl-6 ">{data?.name || "-"}</h3>
      <p className="w-9/12 truncate text-base text-gray-700 pl-6">{data?.url || "-"}</p>
      <div className="mt-5 text-sm text-gray-500 pl-6 mb-5 flex">
        { data?.score && !reportStatus ? 
          <span className="text-xs inline-block p-1.5 leading-none text-center whitespace-nowrap align-baseline font-medium bg-yellow mr-2">{parseFloat(data?.score).toFixed(1)}</span>
          : null
        }
        {
          reportStatus ?
          <span className={`inline-block align-baseline font-bold w-min ${data?.reportStatus === 2 ? 'bg-pizzaz text-black' : data?.reportStatus === 3 ? 'bg-gray text-black' : 'bg-greenSucceed text-white'}`}>
            <p className="text-xs p-1.5 leading-none text-center whitespace-nowrap font-bold">{reportStatus}</p>
          </span> :
          <span className={`text-xs inline-block ${data?.compliant_status ? 'px-1.5' : ''} py-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white flex w-min ${data?.compliant_status?.toLowerCase() === 'non-compliant' ? 'bg-[#E00000]' : data?.compliant_status?.toLowerCase() === 'level aaa compliant' ? 'bg-greenSucceed' : !data?.compliant_status ? '' : 'bg-pizzaz'}`}>
            {data?.compliant_status ? data?.compliant_status?.toLowerCase() !== 'level aaa compliant' ? RenderSvg(svgNames.alert) : RenderSvg(svgNames.check) : null }
            {data?.compliant_status || <p className="bg-gray text-xs p-1.5 text-black leading-none text-center whitespace-nowrap font-bold">Initial audit is in progress</p>}
          </span>
        }
      </div>
    </div>
  );
};

export default WebsiteCard;
