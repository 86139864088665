// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RiFileListLine } from "react-icons/ri";
import { BsHourglassSplit } from "react-icons/bs";
import { FiSettings, FiPlay } from "react-icons/fi";
import { RiVipCrownLine } from "react-icons/ri";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from 'moment-timezone';

// components
import AddWebsite from "../../components/websites/addWebsite";
import { RoutingConstants, Strings } from "../../constants";
import DefaultPage from "../../components/layout/defaultPage";

// Actions
import { runAudit } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function ViewWebsite
 * @returns {JSX}
 */
const ViewWebsite = (props) => {
  // destructure props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedOrg, triggerRunAudit, triggerLogout, userProfile } = props;
  const isCurrentWebsiteActive = selectedOrg?.isActive;
  let reports = selectedOrg.websites.find(
    (w) => w.id.toString() === id
  ).reports;
  let website = selectedOrg.websites.find((w) => w.id.toString() === id);

  // Whenever there comes atleast 1 report, navigate to view report.
  // This is applicable when: Upon add website, run audit is auto triggered,
  // and immediately when the user comes to view website (newly added site) the "Report 1" will be visible after few seconds
  // So once a report comes in app state (job ID) for that particular site, goto the report page
  useEffect(() => {
    if (reports?.length === 1) {
      navigate(`/website/${id}/reports/${reports[0]?.jobId}`);
    }
  }, [id, navigate, reports]);

  // state variables
  const [showReport, setShowReport] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    setLoading(false);
    toast.success("Audit initiated Successfully!!!");
  };

  const onError = (errorMsg) => {
    setLoading(false);
    toast.error(errorMsg || "Something went wrong!!!");
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const RunAudit = () => {
    if (!isCurrentWebsiteActive) {
      toast.warn("Cannot run audit from an archived organization!!!");
      return;
    }
    const postObj = {
      orgId: selectedOrg?.id,
      site: website?.url,
      siteId: website?.id,
      onSuccess,
      onError,
    };
    setLoading(true);
    setShowReport(true);
    triggerRunAudit(postObj);
  };
  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section className="grid grid-cols-1 sm:grid-cols-2 flex flex-wrap items-center border-b border-grayLight p-6 py-4 font-semibold">
        <div className="flex items-center">
          <Link to={RoutingConstants.websites.webRoot}>
            <MdOutlineKeyboardBackspace className="text-2xl mr-2 cursor-pointer" />
          </Link>
          {selectedOrg?.websites?.find((w) => w.id.toString() === id).url}
        </div>
      </section>
      <section className="max-h-80 overflow-y-auto">
        <div className="m-6 border border-grayLight">
          <div className="grid grid-cols-10">
            <div
              className={`col-span-8 text-center ${
                showReport === false &&
                "min-h-screen flex items-center justify-center"
              }`}
            >
              {showReport === false ? (
                <div>
                  <h3 className="mb-10 font-medium text-2xl">
                    To check your website with <b>WRCHAF</b> compliances
                    <br />
                    Please click on <b>Run Audit Button</b>.
                  </h3>
                  <button
                    className={`${
                      isCurrentWebsiteActive
                        ? "bg-primary"
                        : "bg-secondary bg-opacity-70"
                    }  text-white py-3 px-10 rounded m-auto font-bold`}
                    onClick={RunAudit}
                  >
                    {Strings.runAudit}
                  </button>
                </div>
              ) : (
                <div>
                  {loading && (
                    <div id="modal" className="text-black">
                      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-sm sm:max-w-sm -translate-y-2/4 top-2/4	">
                          <div className="shadow-lg relative w-full bg-white">
                            <div className="pt-4 px-4">
                              <div
                                className="text-xl w-5 h-5 border rounded-full font-semibold cursor-pointer text-center ml-auto"
                                style={{ lineHeight: "15px" }}
                                onClick={() => setLoading(false)}
                              >
                                ×
                              </div>
                            </div>
                            <div className="relative p-4 pb-12">
                              <BsHourglassSplit className="text-3xl text-orange-500 m-auto" />
                              <h3 className="text-2xl font-bold my-5 text-center">
                                {Strings.auditRunning}
                              </h3>
                              <p className="max-w-sm m-auto text-center">
                                {Strings.auditInProgress}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-span-2 border-l border-grayLight min-h-screen flex flex-wrap content-between font-lato">
              <div className="w-full">
                {reports.length > 0 &&
                  reports
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .slice(0, 3)
                    .slice()
                    .map((val, index) => {
                      return (
                        val.jobId && (
                          <Link
                            className="p-3 flex cursor-pointer border-l-2 border-transparent hover:bg-gray hover:bg-opacity-20 hover:border-primary font-lato"
                            key={index}
                            to={`/website/${id}/reports/${val.jobId}`}
                          >
                            <RiFileListLine className="mt-1 mr-2" />
                            <div>
                              <p className="text-sm">
                                {`${"Report"} ${reports.length - index}`}
                              </p>
                              <p className="text-gray text-xs">
                                {`On `}
                                {`${moment(val.createdAt + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}
                              </p>
                            </div>
                          </Link>
                        )
                      );
                    })}
                {reports.length > 0 && reports[0].jobId && (
                  <Link
                    state={{
                      isCurrentWebsiteActive,
                    }}
                    to={`/website/${id}/reports`}
                    className="w-full p-3 flex items-center font-medium text-blue border-l-2 border-transparent hover:bg-gray hover:bg-opacity-20 hover:border-primary"
                  >
                    <RiFileListLine className="mr-3 text-lg" />
                    {Strings.viewAllReports}
                  </Link>
                )}
              </div>
              <div className="w-full m-3">
                <div className="my-4">
                  <Link
                    className={
                      "flex items-center pointer-events-none hover:text-blue"
                    }
                    to={""}
                  >
                    <FiSettings className="mr-3 text-lg" />
                    {Strings.settings}
                  </Link>
                </div>
                <div className="my-4">
                  <AddWebsite icon org_id={selectedOrg?.id} />
                </div>
                <div className="my-4">
                  <button
                    className="flex items-center hover:text-blue"
                    onClick={RunAudit}
                  >
                    <FiPlay className="mr-3" />
                    {Strings.runAuditAgain}
                  </button>
                </div>
                <div className="my-4">
                  <Link
                    className={
                      "flex items-center pointer-events-none hover:text-blue"
                    }
                    to={""}
                  >
                    <RiVipCrownLine className="mr-3" />
                    {Strings.upgradePlan}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    userProfile: state.dashboard.userProfile,
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerRunAudit: runAudit,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ViewWebsite);
