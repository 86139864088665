const dashboardActions = {
  DASHBOARD: "DASHBOARD",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
  DASHBOARD_ERROR: "DASHBOARD_ERROR",

  SELECT_ORG: "SELECT_ORG",

  CREATE_WEBSITE: "CREATE_WEBSITE",
  CREATE_WEBSITE_SUCCESS: "CREATE_WEBSITE_SUCCESS",
  CREATE_WEBSITE_ERROR: "CREATE_WEBSITE_ERROR",

  CREATE_ORG: "CREATE_ORG",
  CREATE_ORG_SUCCESS: "CREATE_ORG_SUCCESS",
  CREATE_ORG_ERROR: "CREATE_ORG_ERROR",

  UPDATE_ORG: "UPDATE_ORG",
  UPDATE_ORG_SUCCESS: "UPDATE_ORG_SUCCESS",
  UPDATE_ORG_ERROR: "UPDATE_ORG_ERROR",

  UPDATE_ORG_WEBSITES: "UPDATE_ORG_WEBSITES",
  UPDATE_ORG_WEBSITES_SUCCESS: "UPDATE_ORG_WEBSITES_SUCCESS",
  UPDATE_ORG_WEBSITES_ERROR: "UPDATE_ORG_WEBSITES_ERROR",

  REMOVE_ORG_WEBSITES: "REMOVE_ORG_WEBSITES",
  REMOVE_ORG_WEBSITES_SUCCESS: "REMOVE_ORG_WEBSITES_SUCCESS",
  REMOVE_ORG_WEBSITES_ERROR: "REMOVE_ORG_WEBSITES_ERROR",
  
  REPORT: "REPORT",
  REPORT_SUCCESS: "REPORT_SUCCESS",
  REPORT_ERROR: "REPORT_ERROR",

  PAGE_REPORT: "PAGE_REPORT",
  PAGE_REPORT_SUCCESS: "PAGE_REPORT_SUCCESS",
  PAGE_REPORT_ERROR: "PAGE_REPORT_ERROR",

  AUDIT: "AUDIT",
  AUDIT_SUCCESS: "AUDIT_SUCCESS",
  AUDIT_ERROR: "AUDIT_ERROR",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",

  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_ERROR: "GET_USER_PROFILE_ERROR",

  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  EDIT_USER: "EDIT_USER",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "EDIT_USER_ERROR",

  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",

  TOGGLE_HAMBURGER: "TOGGLE_HAMBURGER",

  CLOSE_HAMBURGER: "CLOSE_HAMBURGER",

  MAP_USER_WEBSITES: "MAP_USER_WEBSITES",
  MAP_USER_WEBSITES_SUCCESS: "MAP_USER_WEBSITES_SUCCESS",
  MAP_USER_WEBSITES_ERROR: "MAP_USER_WEBSITES_ERROR",

  GET_ELEMENTS_LIST: "GET_ELEMENTS_LIST",
  GET_ELEMENTS_LIST_SUCCESS: "GET_ELEMENTS_LIST_SUCCESS",
  GET_ELEMENTS_LIST_ERROR: "GET_ELEMENTS_LIST_ERROR",

  GET_ELEMENT_OCCURRENCE: "GET_ELEMENT_OCCURRENCE",
  GET_ELEMENT_OCCURRENCE_SUCCESS: "GET_ELEMENT_OCCURRENCE_SUCCESS",
  GET_ELEMENT_OCCURRENCE_ERROR: "GET_ELEMENT_OCCURRENCE_ERROR",

  UPDATE_ELEMENT: "UPDATE_ELEMENT",
  UPDATE_ELEMENT_SUCCESS: "UPDATE_ELEMENT_SUCCESS",
  UPDATE_ELEMENT_ERROR: "UPDATE_ELEMENT_ERROR",

  GET_DASHOARD_DATA: "GET_DASHOARD_DATA",
  GET_DASHOARD_DATA_SUCCESS: "GET_DASHOARD_DATA_SUCCESS",
  GET_DASHOARD_DATA_ERROR: "GET_DASHOARD_DATA_ERROR",

  GET_SITE_ANALYTICS_DATA: "GET_SITE_ANALYTICS_DATA",
  GET_SITE_ANALYTICS_DATA_SUCCESS: "GET_SITE_ANALYTICS_DATA_SUCCESS",
  GET_SITE_ANALYTICS_DATA_ERROR: "GET_SITE_ANALYTICS_DATA_ERROR",

  VERIFY_USER: "VERIFY_USER",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_ERROR: "VERIFY_USER_ERROR",

  GET_WEBSITE_SETTINGS: "GET_WEBSITE_SETTINGS",
  GET_WEBSITE_SETTINGS_SUCCESS: "GET_WEBSITE_SETTINGS_SUCCESS",
  GET_WEBSITE_SETTINGS_ERROR: "GET_WEBSITE_SETTINGS_ERROR",

  EDIT_WEBSITE_SETTINGS: "EDIT_WEBSITE_SETTINGS",
  EDIT_WEBSITE_SETTINGS_SUCCESS: "EDIT_WEBSITE_SETTINGS_SUCCESS",
  EDIT_WEBSITE_SETTINGS_ERROR: "EDIT_WEBSITE_SETTINGS_ERROR",

  RESTORE_WEBSITE: "RESTORE_WEBSITE",
  RESTORE_WEBSITE_SUCCESS: "RESTORE_WEBSITE_SUCCESS",
  RESTORE_WEBSITE_ERROR: "RESTORE_WEBSITE_ERROR",

  GET_AUDIT_DATA: "GET_AUDIT_DATA",
  GET_AUDIT_DATA_SUCCESS: "GET_AUDIT_DATA_SUCCESS",
  GET_AUDIT_DATA_ERROR: "GET_AUDIT_DATA_ERROR",

  GET_AUDIT_SCORE: "GET_AUDIT_SCORE",
  GET_AUDIT_SCORE_SUCCESS: "GET_AUDIT_SCORE_SUCCESS",
  GET_AUDIT_SCORE_ERROR: "GET_AUDIT_SCORE_ERROR",

  GET_AUDIT_RULE: "GET_AUDIT_RULE",
  GET_AUDIT_RULE_SUCCESS: "GET_AUDIT_RULE_SUCCESS",
  GET_AUDIT_RULE_ERROR: "GET_AUDIT_RULE_ERROR",

  AUDIT_STAGE: "AUDIT_STAGE",

  GET_AUDIT_STAGE: "GET_AUDIT_STAGE",
  GET_AUDIT_STAGE_SUCCESS: "GET_AUDIT_STAGE_SUCCESS",
  GET_AUDIT_STAGE_ERROR: "GET_AUDIT_STAGE_ERROR",

  GET_ACTIVE_SUBSCRIPTION: "GET_ACTIVE_SUBSCRIPTION",
  GET_ACTIVE_SUBSCRIPTION_SUCCESS: "GET_ACTIVE_SUBSCRIPTION_SUCCESS",
  GET_ACTIVE_SUBSCRIPTION_ERROR: "GET_ACTIVE_SUBSCRIPTION_ERROR",

  GET_WORKSPACE_SITES: "GET_WORKSPACE_SITES",
  GET_WORKSPACE_SITES_SUCCESS: "GET_WORKSPACE_SITES_SUCCESS",
  GET_WORKSPACE_SITES_ERROR: "GET_WORKSPACE_SITES_ERROR",

  GET_SITE_DATA: "GET_SITE_DATA",
  GET_SITE_DATA_SUCCESS: "GET_SITE_DATA_SUCCESS",
  GET_SITE_DATA_ERROR: "GET_SITE_DATA_ERROR",

  EDIT_WORKSPACE: "EDIT_WORKSPACE",
  EDIT_WORKSPACE_SUCCESS: "EDIT_WORKSPACE_SUCCESS",
  EDIT_WORKSPACE_ERROR: "EDIT_WORKSPACE_ERROR",

  GET_WORKSPACE_SITE_DATA: "GET_WORKSPACE_SITE_DATA",
  GET_WORKSPACE_SITE_DATA_SUCCESS: "GET_WORKSPACE_SITE_DATA_SUCCESS",
  GET_WORKSPACE_SITE_DATA_ERROR: "GET_WORKSPACE_SITE_DATA_ERROR",

  GET_WORKSPACE_USERS: "GET_WORKSPACE_USERS",
  GET_WORKSPACE_USERS_SUCCESS: "GET_WORKSPACE_USERS_SUCCESS",
  GET_WORKSPACE_USERS_ERROR: "GET_WORKSPACE_USERS_ERROR",

  SHARE_REPORT: "SHARE_REPORT",
  SHARE_REPORT_SUCCESS: "SHARE_REPORT_SUCCESS",
  SHARE_REPORT_ERROR: "SHARE_REPORT_ERROR",

  GET_SIGNATURE: "GET_SIGNATURE",
  GET_SIGNATURE_SUCCESS: "GET_SIGNATURE_SUCCESS",
  GET_SIGNATURE_ERROR: "GET_SIGNATURE_ERROR",
  
};

// exports
export default dashboardActions;
