/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// library imports
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PieLoader from "../../../components/PieLoader";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// constants
import { RoutingConstants, Strings } from "../../../constants";


// Actions
import { updateOrg } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";

// token expired handler
import TokenExpired from "../../../utils/TokenExpired";

const EditBasicDetails = (props) => {
  // destructure props
  const {
    triggerUpdateOrg,
    triggerLogout,
    orgsList,
    isTempPassword,
  } = props;
  const navigate = useNavigate();

  const { id } = useParams();
  const currentOrgData = orgsList.find((org) => org.id === parseInt(id));

  const [errorFromApi, setErrorFromApi] = useState(null);
  const [organizationDetail, setOrganizationDetail] = useState({
    email_id: currentOrgData.email || "",
    orgName: currentOrgData.name || "",
    orgType: currentOrgData.type || "",
    business_no: "",
    isActive: currentOrgData.isActive || false,
    domain: currentOrgData?.websites?.map((i) => i.url),
  });

  const [webStatus, setWebStatus] = useState(currentOrgData.isActive || false);
  const [country, setCountry] = useState({
    country: currentOrgData.country || "",
  });
  const [state, setState] = useState({
    state: currentOrgData.state || "",
  });

  const [countryError, setCountryError] = useState(false);
  const [loading, setLoading] = useState(false);

  const orgNames = [
    "Select your organization type",
    "Information Technology",
    "Healthcare",
    "Education",
    "FMCG",
    "Others",
  ];

  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: "all",
  });

  const onChangeHandler = (e) => {
    setOrganizationDetail({
      ...organizationDetail,
      [e.target.name]: e.target.value,
    });
    
    const inputTagElement_1 = document.getElementById("email_id");
    const inputTagElement_2 = document.getElementById("orgName");
    const inputTagElement_3 = document.getElementById("orgType");
    const inputTagElement_4 = document.getElementById("business_no");
    const inputTagElement_5 = document.getElementById("country");
    const inputTagElement_6 = document.getElementById("state");
    // If the user edits the input boxes, disable any error msgs if present
    if (
      document.activeElement === inputTagElement_1 ||
      document.activeElement === inputTagElement_2 ||
      document.activeElement === inputTagElement_3 ||
      document.activeElement === inputTagElement_4 ||
      document.activeElement === inputTagElement_5 ||
      document.activeElement === inputTagElement_6
    ) {
      setErrorFromApi(null);
    }
  };

  const onSuccess = (successMsg) => {
    reset(
      {
        domain: "",
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
    setErrorFromApi({
      type: "success",
      msg: successMsg || "Organization Details Updated Successfully",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 2000);
  };

  const onError = (errorMsg) => {
    reset(
      {
        domain: "",
      },
      {
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
  };

  const submit = () => {
    if (country?.country?.length > 0) {
      setCountryError(false);
    }
    if (firstStepValidationPassed) {
      setCountryError(false);
      setError(null);
    } else {
      // set or unset country error
      setCountryError(country?.country?.length === 0);
      if (organizationDetail?.orgName?.length === 0) {
        setError("orgName", {
          type: "manual",
          message: "* Please enter organization name",
        });
      }
      if (organizationDetail?.orgType?.length === 0) {
        setError("orgType", {
          type: "manual",
          message: "* Please choose organization type",
        });
      }
    }
    setLoading(true);
    const payload1 = {
      email: organizationDetail.email_id.trim(),
      name: organizationDetail.orgName.trim(),
      type: organizationDetail.orgType,
      number: organizationDetail.business_no.trim(),
      country: country.country,
      state: state.state,
      isActive: webStatus,
    };
    const payload2 = {
      organization: currentOrgData.id,
    };
    triggerUpdateOrg({
      orgObj: payload1,
      websiteObj: payload2,
      onSuccess,
      onError,
    });
  };

  const firstStepValidationPassed =
    organizationDetail.orgName.length > 0 &&
    !errors.orgName &&
    organizationDetail.orgType.length > 0 &&
    !errors.orgType &&
    !errors.business_no &&
    country.country.length > 0 &&
    !errors.email_id;

  const isOrgUpdated =
    !!organizationDetail?.email_id !== !!currentOrgData.email ||
    organizationDetail?.orgName !== currentOrgData?.name ||
    organizationDetail?.orgType !== currentOrgData?.type ||
    country.country !== currentOrgData.country ||
    state.state !== currentOrgData.state ||
    organizationDetail?.organizationDetail?.length > 0 ||
    webStatus !== currentOrgData?.isActive ||
    !!state?.state !== !!currentOrgData.state

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="inset-0 z-50 outline-none focus:outline-none">
          <div className="relative sm:p-4">
            <div className="w-full sm:max-w-md">
              <form onSubmit={handleSubmit(submit)}>
                <>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {Strings.emailId}
                    </div>
                    <input
                      value={organizationDetail.email_id}
                      type="email"
                      className={`w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                        errors.email_id && "border-red-500"
                      }`}
                      id="email_id"
                      name="email_id"
                      placeholder="Enter your Email ID"
                      {...register("email_id", {
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "* Invalid email format",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.email_id && (
                      <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {errors.email_id.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {`${Strings.orgName} `}
                      <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span>
                    </div>
                    <input
                      value={organizationDetail.orgName}
                      type="text"
                      className={`w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                        errors.orgName && `border-red-500`
                      }`}
                      id="orgName"
                      name="orgName"
                      placeholder="Enter your Organization Name"
                      {...register("orgName", {
                        required: {
                          value: true,
                          message: "* Please enter organization name",
                        },
                        maxLength: {
                          value: 40,
                          message: "* Maximum 40 characters allowed",
                        },
                        minLength: {
                          value: 2,
                          message: "* Minimum 2 characters required",
                        },
                        pattern: {
                          value: /^(?![0-9]+$)[a-zA-Z0-9-_ ]{2,}$/,
                          message: "* Only alphanumeric characters allowed",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.orgName && (
                      <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {errors.orgName.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {`${Strings.orgType} `}
                      <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span>
                    </div>
                    <select
                      defaultValue={organizationDetail.orgType}
                      className={`w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium`}
                      id="orgType"
                      name="orgType"
                      {...register("orgType", {
                        required: {
                          value: true,
                          message: "* Please select organization type",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    >
                      {orgNames.map((org, index) => {
                        return (
                          <option
                            key={`organization_type${index}`}
                            value={`${org === "Select your organization type" ? "" : org}`}
                          >
                            {org}
                          </option>
                        );
                      })}
                    </select>
                    {errors.orgType && (
                      <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {errors.orgType.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {Strings.businessNo}
                    </div>
                    <input
                      value={organizationDetail.business_no}
                      type="text"
                      className={`w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                        errors.business_no && `border-red-500`
                      }`}
                      id="business_no"
                      name="business_no"
                      {...register("business_no", {
                        maxLength: {
                          value: 40,
                          message: "* Maximum 40 characters allowed",
                        },
                        minLength: {
                          value: 4,
                          message: "* Minimum 4 characters required",
                        },
                        pattern: {
                          value: /[a-zA-Z_][0-9]*/,
                          message: "* Only alphanumeric characters allowed",
                        },
                      })}
                      placeholder="Enter your Business No"
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.business_no && (
                      <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {errors.business_no.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {`${Strings.country} `}
                      <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span>
                    </div>
                    <CountryDropdown
                      className={`w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium ${
                        countryError && "border-red-500"
                      }`}
                      id="country"
                      name="country"
                      value={country.country}
                      onChange={(e) => {
                        setCountry({ country: e });
                        e?.length > 0
                          ? setCountryError(false)
                          : setCountryError(true);
                        setErrorFromApi(null);
                      }}
                    />
                    {countryError && (
                      <span className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {"* Please select country"}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      {Strings.state}
                    </div>
                    <RegionDropdown
                      className="w-full border border-gray outline-none focus:border-primary py-2 px-3 font-medium"
                      id="state"
                      name="state"
                      country={country.country}
                      value={state.state}
                      onChange={(e) => {
                        setState({ state: e });
                        setErrorFromApi(null);
                    }}
                    />
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-grayDeep font-semibold mb-2">
                      Status
                    </div>
                    <div className="flex items-center">
                      <div
                        className={`h-6 w-12 p-1 cursor-pointer border ${
                          webStatus
                            ? "border-primary bg-aliceBlue"
                            : "border-grayNobel bg-white"
                        }`}
                        onClick={() => {
                          setWebStatus(!webStatus);
                          setErrorFromApi(null);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <div
                          className={`h-4 w-4 transform duration-300 ease-in-out ${
                            webStatus
                              ? "bg-primary transform translate-x-6"
                              : "bg-grayNobel "
                          } `}
                        ></div>
                      </div>
                      <p className={`font-normal pl-2 ${!webStatus && 'text-grayDeep'}`}>
                        {webStatus ? "Active" : "Inactive"}
                      </p>
                    </div>
                  </fieldset>
                  {errorFromApi !== null && (
                    <div
                      className={`${
                        errorFromApi?.type === "error"
                          ? "bg-floralWhite "
                          : "bg-aliceBlue"
                      } border border-grayLight p-3 my-6 flex flex-row overflow-x-auto`}
                    >
                      <div className="mr-2">
                        <BsFillExclamationSquareFill
                          color={
                            errorFromApi?.type === "error"
                              ? "#FF5353"
                              : "#000000"
                          }
                          className="text-2xl"
                        />
                      </div>
                      <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                    </div>
                  )}
                  <div className="flex my-6">
                    <button
                      type="submit"
                      className={` ${
                        firstStepValidationPassed && isOrgUpdated
                          ? `bg-cyanBlue border-cyanBlue`
                          : `bg-gray border-gray pointer-events-none`
                      } text-sm sm:w-4/6 border cursor-pointer text-white font-bold py-3 px-8 tracking-small ${
                        loading && "bg-gray border-gray pointer-events-none"
                      }`}
                    >
                      {loading ? (
                        <div className="flex flex-row justify-center items-center">
                          <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                          <span className="ml-2">Loading</span>
                        </div>
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    editOrgLoading: state.dashboard.editOrgLoading,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || [],
    isTempPassword: state.auth.isTempPassword,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateOrg: updateOrg,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EditBasicDetails);
