// library imports
import { takeLatest, call, put, all } from "redux-saga/effects";

// user imports
import {
  loginSuccess,
  loginError,
  registerSuccess,
  registerError,
  sendOtpSuccess,
  sendOtpError,
  validateOtpSuccess,
  validateOtpError,
  resetPasswordSuccess,
  resetPasswordError,
  changePasswordSuccess,
  changePasswordError,
  verifyUserEmailSuccess,
  verifyUserEmailError,
  updatePasswordSuccess,
  updatePasswordError
} from "../actions/auth";

// axios api call
import {
  login,
  register,
  sendOtp,
  validateOtp,
  resetPassword,
  changePassword as changePasswordApi,
  verifyUserEmailApi,
  updatePassword as updatePasswordApi
} from "../../api/auth";
import {
  userProfile
} from "../../api/dashboard";

// action types
import actionTypes from "../types";

// local storage handler
import LocalStorageHandler from "../../utils/StateCacheStorage";

// destructuring
const {
  LOGIN,
  LOGOUT,
  REGISTER,
  SEND_OTP,
  VALIDATE_OTP,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VERIFY_USER_EMAIL,
  UPDATE_PASSWORD
} = actionTypes.auth;

export function* fetchLogin(action) {
  const { payload } = action;
  const { onSuccess, onError, ...rest } = payload;
  try {
    // if you wanna pass payload to login() axios api call, do not pass it as ==> login(payload)
    // pass it as ==> yield call(login, payload)
    const response = yield call(login, rest);
    if (response.code !== 200) {
      const errorMsg = response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    yield LocalStorageHandler.set(
      "ACCESS_TOKEN",
      response?.data?.message?.accessToken || null
    );

    const userProfileRes = yield call(userProfile);
    if (userProfileRes.code !== 200) {
      const errorMsg = userProfileRes?.response?.status === 401? userProfileRes?.response?.data?.message: userProfileRes?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }

    yield put(loginSuccess({response, userProfileRes}));
    onSuccess(userProfileRes?.data?.message);
  } catch (error) {
    onError(error.message);
    yield put(loginError(error));
    yield LocalStorageHandler.clear("APP_STATE");
    yield LocalStorageHandler.clear("ACCESS_TOKEN");
  }
}

export function* triggerLogout(action) {
  // clear local storage
  yield LocalStorageHandler.clear("APP_STATE");
  yield LocalStorageHandler.clear("ACCESS_TOKEN");
}

export function* fetchRegister(action) {
  const { payload } = action;
  const { onSuccess, onError, ...requestObj } = payload;
  try {
    const response = yield call(register, requestObj);
    if (response?.data?.code !== 200) {
      const errorMsg = response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    onSuccess(response);
    yield put(registerSuccess(response));
  } catch (error) {
    yield put(registerError(error));
    onError(error.message);
  }
}

export function* triggerSendOtp(action) {
  const { payload } = action;
  const { onSuccess, onError, apiPayload } = payload;
  try {
    const response = yield call(sendOtp, apiPayload);
    if (response.code !== 200) {
      const errorMsg = response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(sendOtpSuccess({ response, email: apiPayload?.email || "" }));
    onSuccess(response?.data?.message);
  } catch (error) {
    onError(error.message);
    yield put(sendOtpError(error));
  }
}

export function* triggerValidateOtp(action) {
  const { payload } = action;
  const { onSuccess, onError, apiPayload } = payload;
  try {
    const response = yield call(validateOtp, apiPayload);
    if (response.code !== 200) {
      const errorMsg = response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(validateOtpSuccess(response));
    onSuccess(response?.data?.message);
  } catch (error) {
    onError(error.message);
    yield put(validateOtpError(error));
  }
}

export function* triggerResetPassword(action) {
  const { payload } = action;
  const { onSuccess, onError, apiPayload } = payload;
  try {
    const response = yield call(resetPassword, apiPayload);
    if (response.code !== 200) {
      const errorMsg = response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(resetPasswordSuccess(response));
    onSuccess(response?.data?.message);
  } catch (error) {
    onError(error.message);
    yield put(resetPasswordError(error));
  }
}

export function* fetchChangePassword(action) {
  const { onSuccess, onError, apiPayload } = action.payload;
  try {
    const response = yield call(changePasswordApi, apiPayload);
    if (response.code !== 200) {
      const errorMsg = response?.response?.data?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(changePasswordSuccess(response));
    onSuccess(response?.data?.message);
  } catch (error) {
    onError && onError(error.message);
    yield put(changePasswordError(error));
  }
}

export function* fetchVerifyUserEMail(action) {
  const { onSuccess, onError, payload } = action.payload;
  try {
    const response = yield call(verifyUserEmailApi, payload);
    if (response.code !== 200) {
      const errorMsg = response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(verifyUserEmailSuccess(response));
    onSuccess(response);
  } catch (error) {
    onError && onError(error.message);
    yield put(verifyUserEmailError(error));
  }
}

export function* fetchUpdatePassword(action) {
  const { onSuccess, onError, apiPayload } = action.payload;
  try {
    const response = yield call(updatePasswordApi, apiPayload);
    if (response.code !== 200) {
      const errorMsg = response?.data?.message;
      throw new Error(
        typeof errorMsg === "string" ? errorMsg : "Something went wrong"
      );
    }
    yield put(updatePasswordSuccess(response));
    onSuccess(response?.data);
  } catch (error) {
    onError && onError(error.message);
    yield put(updatePasswordError(error));
  }
}

export function* saga() {
  yield takeLatest(LOGIN, fetchLogin);
  yield takeLatest(LOGOUT, triggerLogout);
  yield takeLatest(REGISTER, fetchRegister);
  yield takeLatest(SEND_OTP, triggerSendOtp);
  yield takeLatest(VALIDATE_OTP, triggerValidateOtp);
  yield takeLatest(RESET_PASSWORD, triggerResetPassword);
  yield takeLatest(CHANGE_PASSWORD, fetchChangePassword);
  yield takeLatest(VERIFY_USER_EMAIL, fetchVerifyUserEMail);
  yield takeLatest(UPDATE_PASSWORD, fetchUpdatePassword);

}

export function* authSagas() {
  yield all([call(saga)]);
}
