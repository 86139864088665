/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import "chart.js/auto";
import { useForm } from "react-hook-form";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from "@mui/material/Stack";
import AccordionLayout from './accordionLayout';

// icons
import { ClipLoader } from "react-spinners";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import PieLoader from "../PieLoader";

// components
import { RoutingConstants, Strings } from "../../constants";
import SectionLoader from "../SectionLoader";

// actions
import { logOut } from "../../store/actions/auth";
import { getElementsList, updateElement, getElementOccurrence } from "../../store/actions/dashboard";

// image asets
import RenderSvg, { svgNames } from "../../assets/svg";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function AuditReport
 * @returns {JSX}
 */
const AuditReport = (props) => {
  const { id, jobId } = useParams();
  const navigate = useNavigate();
  const { selectedOrg, selectedOrgId, triggerLogout, isTempPassword, fetchElementsList, fetchElementOccurrence, updateElementLoading, triggerUpdateElement } = props;
  const [accessorData, setAccessorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openIssuePopup, setOpenIssuePopup] = useState(null);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [updatingElement, setUpdatingElement] = useState(null);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const [elementsLimit, setElementsLimit] = useState(25)
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterOptions, showFilterOptions] = useState(false)
  const [groupOptions, showGroupOptions] = useState(false)
  const [groupValue, setGroupValue] = useState('rules')
  const [filterValues, setFilterValues] = useState(null)
  const [filterLevel, setFilterLevel] = useState(null)
  const [filterCategory, setFilterCategory] = useState(null)
  const [filterSeverity, setFilterSeverity] = useState(null)
  const [filterFix, setFilterFix] = useState(null)
  const [groupList, setGroupList] = useState([])
  const [activeIndex, setActiveIndex] = useState(null);
  const [rulesList, setRulesList] = useState('');
  const [firstOccurrence, setFirstOccurrence] = useState('0');
  const [lastOccurrence, setLastOccurrence] = useState('0');
  const [getOccurrence, setGetOccurrence] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const filterRef = useRef();
  const groupRef = useRef();
  const parser = new DOMParser();
  const isCurrentWebsiteActive = selectedOrg.isActive

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
    reset,
    watch
  } = useForm({
    mode: "all",
  });
  const watchFields = watch();
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  const typeDispenser = (obj = null) => {
    let result = "";
    if (obj?.suggested_fix?.switch === "on") {
      result = obj?.suggested_fix?.type;
    } else if (obj?.your_fix?.switch === "on") {
      result = obj?.your_fix?.type === "manualFix" ? 'Manual Fix' : obj?.your_fix?.type;
    }
    return result;
  };

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    setRulesList(urlParams.get('rules_list'));
    const payload = {
      onSuccess: (data) => {
        setLoading(false);
        setGroupList(data?.group);
        setAccessorData(data?.accessorData || []);
        setPageLimit(
          data?.count % elementsLimit === 0
            ? Math.floor(data?.count / elementsLimit)
            : Math.floor(data?.count / elementsLimit + 1) || 1
        );
        const elementId = searchParams.get("elementId");
        const link = searchParams.get("link");
        if (data?.accessorData?.length > 0) {
          let isElementPresent = false;
          isElementPresent = data?.accessorData?.find(
            (i) => i?.element_id === elementId && i?.link === link
          );
          if (isElementPresent) {
            setOpenIssuePopup({ ...isElementPresent });
          } else {
            searchParams.delete("elementId");
            searchParams.delete("link");
            setSearchParams(searchParams);
          }
        }
      },
      onError: (errorMsg) => {
        setLoading(false);
        setErrorFromApi({
          type: "error",
          msg: errorMsg || "Something went wrong!!!",
        });
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      },
      requestObj: { 
        page: page.toString(), 
        orgId: selectedOrgId, 
        jobId, 
        limit: elementsLimit, 
        level: filterLevel,
        category: filterCategory,
        severity: filterSeverity,
        fix: filterFix,
        group: groupValue,
        rulesList: rulesList,
        group_selector: activeIndex
      },
    };
    fetchElementsList(payload);
  }, [jobId, page, elementsLimit, filterValues, groupValue, activeIndex, rulesList]);

  useEffect(() => {
    const group_selector = groupList[0]?.id
    if(activeIndex === null || activeIndex === undefined) {
      setTimeout(() => {
        setActiveIndex(group_selector)
      }, 100);
    }
  }, [groupList])

  let currentSite =
    selectedOrg &&
    selectedOrg?.websites?.find((w) => w.id.toString() === id)?.url;
  if (currentSite?.includes("https")) {
    currentSite = currentSite.slice().slice(8);
  } else if (currentSite?.includes("http")) {
    currentSite = currentSite.slice().slice(7);
  }

  if (currentSite?.length > 0 && currentSite[currentSite.length - 1] === "/") {
    currentSite = currentSite.slice(0, currentSite.length - 1);
  }
  const validationPassed = () => {
    if (submitData?.your_fix?.switch === "on" && openIssuePopup && Object.keys(openIssuePopup).length && openIssuePopup?.editedContextInJson && Object.keys(openIssuePopup?.editedContextInJson).length <= 0) {
      if (submitData?.your_fix?.text?.length > 0 && !errors?.your_fix) {
        return true;
      }
    } else {
      return true;
    }
  };

  const changesMade =
    openIssuePopup?.switch !== submitData?.switch ||
    JSON.stringify(openIssuePopup?.suggested_fix) !==
    JSON.stringify(submitData?.suggested_fix) ||
    JSON.stringify(openIssuePopup?.your_fix) !==
    JSON.stringify(submitData?.your_fix);
  const submit = () => {
    if(isCurrentWebsiteActive){
    setLoading(true);
    if (!validationPassed()) {
      setError("your_fix", {
        type: "manual",
        message: "* Kindly enter your fix",
      });
      setLoading(false);
      return;
    }
    if (!changesMade) {
      setErrorFromApi({
        type: "error",
        msg: "No changes made",
      });
      setLoading(false);
      setTimeout(() => {
        setErrorFromApi(null);
      }, 3000);
      return;
    }
    const payload_1 = {
      elementId: openIssuePopup?.element_id,
      jobId,
      primaryId: Number(openIssuePopup?.primaryid),
      orgId: selectedOrgId,
      switch: submitData?.switch,
      suggested_fix: submitData?.suggested_fix,
      your_fix: submitData?.your_fix,
      link: openIssuePopup?.link,
      linkId: openIssuePopup?.linkId,
      element_source: openIssuePopup?.element_source,
      existing_action: openIssuePopup?.existing_action,
      file_name: openIssuePopup?.file_name,
      editedContextInJson: submitData?.editedContextInJson
    };
    const payload_2 = {
      page: page.toString(), 
      orgId: selectedOrgId, 
      jobId, 
      limit: elementsLimit, 
      level: filterLevel,
      category: filterCategory,
      severity: filterSeverity,
      fix: filterFix,
      group: groupValue,
      group_selector: activeIndex,
    };
    const onSuccess = (data) => {
      setLoading(false);
      setErrorFromApi({
        type: "success",
        msg: "User Context Details Updated Successfully",
      });
      setAccessorData(data?.accessorData || []);
      setTimeout(() => {
        setUpdatingElement(null);
        setOpenIssuePopup(null);
        setErrorFromApi(null);
        setLoading(false);
      }, [3000]);
    };
    const onError = (errorMsg) => {
      setLoading(false);
      setErrorFromApi({
        type: "error",
        msg: errorMsg || "Something went wrong!!!",
      });
      setUpdatingElement(null);
      if (errorMsg === "Token expired") {
        TokenExpired();
        setTimeout(() => {
          triggerLogout();
        }, 1500);
      }
    };
    triggerUpdateElement({
      payload_1,
      payload_2,
      onSuccess,
      onError,
    });
    }
  };

  const handleClear = () => {
    reset({
      A: false,
      AA: false,
      AAA: false,
      Perceivable: false,
      Operable: false,
      Robust: false,
      Understandable: false,
      high: false,
      moderate: false,
      low: false,
      autoFix: false,
      manualFix: false
    })
    setFilterLevel(null)
    setFilterCategory(null)
    setFilterSeverity(null)
    setFilterFix(null)
    setFilterValues(null)
    setPage(1)
    setTimeout(() => {
      showFilterOptions(false)
    },100);
  };

  Object.filter = (obj, predicate) => 
    Object.keys(obj)
      .filter( key => predicate(obj[key]) )
      // eslint-disable-next-line no-sequences
      .reduce( (res, key) => (res[key] = obj[key], res), {} );

  const onSubmit = data => {
    const selectedValuesArr = Object.keys(Object.filter(data, item => item === true))
    setFilterLevel(selectedValuesArr.filter(i => i === 'A' || i === 'AA' || i === 'AAA').join(','))
    setFilterCategory(selectedValuesArr.filter(i => i === 'Perceivable' || i === 'Operable' || i === 'Robust' || i === 'Understandable').join(','))
    setFilterSeverity(selectedValuesArr.filter(i => i === 'high' || i === 'moderate' || i === 'low').join(','))
    setFilterFix(selectedValuesArr.filter(i => i === 'autoFix' || i === 'manualFix' || i === 'needsAttention').join(','))
    setFilterValues(selectedValuesArr)
    setPage(1)
    showFilterOptions(false)
  };

  useEffect(() => {
    let handler = (event) => {
      if(!filterRef?.current?.contains(event.target)) {
        showFilterOptions(false)
      };
      if(!groupRef?.current?.contains(event.target)) {
        showGroupOptions(false)
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    }
  }, [])

  const handleGroup = (e) => {
    setGroupValue(e.target.value)
    setGroupList([])
    setActiveIndex(null)
    setPage(1)
    showGroupOptions(false)
  };

  useEffect(() => {
    if(submitData?.your_fix?.switch === "on") {
      const field1 = document.getElementById("yourFixTextArea")
      const field2 = document.getElementById("yourFixTextArea")
      const end1 = field1?.value?.length;
      const end2 = field2?.value?.length;
      field1?.setSelectionRange(end1, end1);
      field2?.setSelectionRange(end2, end2);
      field1?.focus()
      field2?.focus()
    }
  }, [submitData?.your_fix?.switch])

  useEffect(() => {
    setLoading(true);
    if (!currentElement?.element_id) {
      return
    }
    const payload = {
      onSuccess: async (data) => {
        await setFirstOccurrence(data.firstOccurrence);
        await setLastOccurrence(data.lastOccurrence);
        setErrorFromApi(null);
        setSearchParams({
          elementId: currentElement?.element_id,
          link: currentElement?.link,
        });
        setSubmitData({
          jobId,
          switch: currentElement?.switch,
          suggested_fix: currentElement?.suggested_fix,
          your_fix: currentElement?.your_fix,
          element_id: currentElement?.element_id,
        });
        setGetOccurrence(false);
        setOpenIssuePopup({ ...currentElement });
        setLoading(false);
      },
      onError: (errorMsg) => {
        setLoading(false);
        setGetOccurrence(false);
        setErrorFromApi({
          type: "error",
          msg: errorMsg || "Something went wrong!!!",
        });
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      },
      requestObj: { 
        jobId, 
        eleId: currentElement?.element_id
      },
    };
    fetchElementOccurrence(payload);
  }, [getOccurrence]);

  const showJobElementDetails = () => {
    if (updateElementLoading) {
      return;
    }
    setGetOccurrence(true);
  }

  const getFixStatus = (reportData) => {
    const fixStatus = reportData?.fix_status;
    let status = 'Fix this';
    if (!fixStatus) return status;
    if (fixStatus === 1) {
      status = 'Fix Applied';
    } else if (fixStatus === 2) {
      status = 'Fix Available';
    }
    return status;
  }

  const getFixBg = (reportData) => {
    const fixStatus = reportData?.fix_status;
    let fixBg = 'bg-yellow border-yellow';
    if (!fixStatus) return fixBg;
    if (fixStatus === 1) {
      fixBg = 'bg-limeGreen border-limeGreen';
    } else if (fixStatus === 2) {
      fixBg = 'bg-pizzaz border-pizzaz';
    }
    return fixBg;
  }
  
  return (
    <>
      <section>
        <div className="flex items-center justify-between gap-3 border-b pb-4">
          <div className="text-grayNobel flex items-center">
            <input type='checkbox' className="mr-2"/>
            0 items selected
          </div>
          <div className="flex gap-3">
            <div className="group flex items-center border-b md:border-0">
              <div className="relative" ref={groupRef}>
                <div className="cursor-pointer py-1 px-3 flex items-center justify-between capitalize gap-3 border" onClick={(e)=> {e.preventDefault(); showGroupOptions(!groupOptions) }}>
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.667 8.125L12.3337 11.25L10.0003 9.0625L11.0003 8.125L12.3337 9.375L14.667 7.1875L15.667 8.125ZM8.00033 3.125C8.53076 3.125 9.03947 3.32254 9.41454 3.67417C9.78961 4.02581 10.0003 4.50272 10.0003 5C10.0003 5.49728 9.78961 5.97419 9.41454 6.32583C9.03947 6.67746 8.53076 6.875 8.00033 6.875C7.46989 6.875 6.96118 6.67746 6.58611 6.32583C6.21104 5.97419 6.00033 5.49728 6.00033 5C6.00033 4.50272 6.21104 4.02581 6.58611 3.67417C6.96118 3.32254 7.46989 3.125 8.00033 3.125ZM8.00033 0.3125C11.3337 0.3125 14.1803 2.25625 15.3337 5C15.167 5.40625 14.9603 5.7875 14.7203 6.15625C14.3337 5.9375 13.907 5.78125 13.4537 5.7L13.8803 5C12.7803 2.9 10.507 1.5625 8.00033 1.5625C5.49366 1.5625 3.22033 2.9 2.12033 5C3.22033 7.1 5.49366 8.4375 8.00033 8.4375L8.80699 8.39375C8.71366 8.70625 8.66699 9.0375 8.66699 9.375V9.6625L8.00033 9.6875C4.66699 9.6875 1.82033 7.74375 0.666992 5C1.82033 2.25625 4.66699 0.3125 8.00033 0.3125Z" fill="black"/>
                  </svg>
                  View
                </div>
                {groupOptions && 
                  <div className="absolute py-2 bg-white border mt-3 min-w-full z-10">
                  <div className="w-max max-w-full min-w-full" onClick={handleGroup}>
                    <input type="text" className={`py-1 px-2 cursor-pointer hover:bg-aliceBlue bg-white block capitalize ${groupValue === 'pages' ? 'bg-aliceBlue' : ''}`} value="pages" disabled />
                    <input type="text" className={`py-1 px-2 cursor-pointer hover:bg-aliceBlue bg-white block capitalize ${groupValue === 'rules' ? 'bg-aliceBlue' : ''}`} value="rules" disabled />
                    <input type="text" className={`py-1 px-2 cursor-pointer hover:bg-aliceBlue bg-white block capitalize ${groupValue === 'category' ? 'bg-aliceBlue' : ''}`} value="category" disabled />
                  </div>
                </div>
                }
              </div>
            </div>
            <div className="filter flex items-center border-b md:border-0">
              <div className="relative" ref={filterRef}>
                <div className="cursor-pointer px-3 py-1 flex items-center justify-between gap-3 border" onClick={(e)=> {e.preventDefault(); showFilterOptions(!filterOptions) }}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00023 10.925C8.0269 11.1125 7.96023 11.3125 7.8069 11.4437C7.5469 11.6875 7.1269 11.6875 6.8669 11.4437L4.19356 8.9375C4.04023 8.79375 3.97356 8.6 4.00023 8.41875V5.21875L0.806897 1.3875C0.580231 1.11875 0.633564 0.725 0.920231 0.5125C1.0469 0.425 1.1869 0.375 1.33356 0.375H10.6669C10.8136 0.375 10.9536 0.425 11.0802 0.5125C11.3669 0.725 11.4202 1.11875 11.1936 1.3875L8.00023 5.21875V10.925ZM2.69356 1.625L5.33356 4.7875V8.2375L6.6669 9.4875V4.78125L9.3069 1.625H2.69356Z" fill="black"/>
                  </svg>
                  <p className="capitalize w-full overflow-hidden break-all h-6">
                    {(filterValues && filterValues.length > 0) ? filterValues.join(',') : 'Filter'}
                  </p>
                </div>
                {filterOptions && 
                  <div className="absolute right-0 p-3 bg-white border mt-3 z-10">
                    <form className="w-max max-w-full" onSubmit={handleSubmit(onSubmit)}>
                      <div className=" grid grid-cols-2 sm:grid-cols-4 gap-6">
                        <div>
                          <h3 className="font-bold">Level</h3>
                          <div>
                            <div>
                              <input type="checkbox" id="A" name="A" {...register('A', {})} checked={watchFields.A !== undefined ? watchFields.A : filterValues?.find(i => i === 'A') ? true : false}/>
                              <label htmlFor="A" className="cursor-pointer"> Level A </label>
                            </div>
                            <div>
                              <input type="checkbox" id="AA" name="AA" {...register('AA', {})} checked={watchFields.AA !== undefined ? watchFields.AA : filterValues?.find(i => i === 'AA') ? true : false}/>
                              <label htmlFor="AA" className="cursor-pointer"> Level AA </label>
                            </div>
                            <div>
                              <input type="checkbox" id="AAA" name="AAA" {...register('AAA', {})} checked={watchFields.AAA !== undefined ? watchFields.AAA : filterValues?.find(i => i === 'AAA') ? true : false}/>
                              <label htmlFor="AAA" className="cursor-pointer"> Level AAA </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-bold">Category</h3>
                          <div>
                            <div>
                              <input type="checkbox" id="Perceivable" name="Perceivable" {...register('Perceivable', {})} checked={watchFields.Perceivable !== undefined ? watchFields.Perceivable : filterValues?.find(i => i === 'Perceivable') ? true : false}/>
                              <label htmlFor="Perceivable" className="cursor-pointer"> Perceivable </label>
                            </div>
                            <div>
                              <input type="checkbox" id="Operable" name="Operable" {...register('Operable', {})} checked={watchFields.Operable !== undefined ? watchFields.Operable : filterValues?.find(i => i === 'Operable') ? true : false}/>
                              <label htmlFor="Operable" className="cursor-pointer"> Operable </label>
                            </div>
                            <div>
                              <input type="checkbox" id="Robust" name="Robust" {...register('Robust', {})} checked={watchFields.Robust !== undefined ? watchFields.Robust : filterValues?.find(i => i === 'Robust') ? true : false}/>
                              <label htmlFor="Robust" className="cursor-pointer"> Robust </label>
                            </div>
                            <div>
                              <input type="checkbox" id="Understandable" name="Understandable" {...register('Understandable', {})} checked={watchFields.Understandable !== undefined ? watchFields.Understandable : filterValues?.find(i => i === 'Understandable') ? true : false}/>
                              <label htmlFor="Understandable" className="cursor-pointer"> Understandable </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-bold">Severity</h3>
                          <div>
                            <div>
                              <input type="checkbox" id="high" name="high" {...register('high', {})} checked={watchFields.high !== undefined ? watchFields.high : filterValues?.find(i => i === 'high') ? true : false}/>
                              <label htmlFor="high" className="cursor-pointer"> High </label>
                            </div>
                            <div>
                              <input type="checkbox" id="moderate" name="moderate" {...register('moderate', {})} checked={watchFields.moderate !== undefined ? watchFields.moderate : filterValues?.find(i => i === 'moderate') ? true : false}/>
                              <label htmlFor="moderate" className="cursor-pointer"> Moderate </label>
                            </div>
                            <div>
                              <input type="checkbox" id="low" name="low" {...register('low', {})} checked={watchFields.low !== undefined ? watchFields.low : filterValues?.find(i => i === 'low') ? true : false}/>
                              <label htmlFor="low" className="cursor-pointer"> Low </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-bold">Available Fix</h3>
                          <div>
                            <div>
                              <input type="checkbox" id="autoFix" name="autoFix" {...register('autoFix', {})} checked={watchFields.autoFix !== undefined ? watchFields.autoFix : filterValues?.find(i => i === 'autoFix') ? true : false}/>
                              <label htmlFor="autoFix" className="cursor-pointer"> Auto Fix </label>
                            </div>
                            <div>
                              <input type="checkbox" id="manualFix" name="manualFix" {...register('manualFix', {})} checked={watchFields.manualFix !== undefined ? watchFields.manualFix : filterValues?.find(i => i === 'manualFix') ? true : false}/>
                              <label htmlFor="manualFix" className="cursor-pointer"> Manual Fix </label>
                            </div>
                            <div>
                              <input type="checkbox" id="needsAttention" name="needsAttention" {...register('needsAttention', {})} checked={watchFields.needsAttention !== undefined ? watchFields.needsAttention : filterValues?.find(i => i === 'needsAttention') ? true : false}/>
                              <label htmlFor="needsAttention" className="cursor-pointer"> Needs Attention </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        <button type="button" className="mr-4 px-5 py-1 bg-grayLight text-black" onClick={handleClear}>Clear</button>
                        <button type="submit" className="mr-4 px-5 py-1 bg-primary text-white">Filter</button>
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-y-auto h-lg">
          {groupValue === "none" ?
            <div className="text-left text-sm">
              {loading ?
                <SectionLoader content={['We are crunching a bunch of numbers.',' We will notify you once it is ready']}/>
                :
                <>
                  {accessorData?.length > 0 ?
                    accessorData
                      ?.sort((a, b) =>
                        a.typeDetermined > b.typeDetermined
                          ? 1
                          : b.typeDetermined > a.typeDetermined
                            ? -1
                            : 0
                      )
                      .map((i, index) => {
                        return (
                          <div key={index.toString()} className={`${i?.element_id === updatingElement?.element_id && `bg-aliceBlue`} border-b px-3 py-4 justify-between items-center hover:bg-aliceBlue`}>
                            <div className={`col-span-8 grid sm:grid-cols-12 items-center ${updateElementLoading && `pointer-events-none`}`} >
                              <div 
                                className="cursor-pointer md:col-span-4 xl:col-span-5 col-span-12 md:pr-8 flex items-center"
                                onClick={() => {
                                  if (updateElementLoading) {
                                    return;
                                  }
                                  setErrorFromApi(null);
                                  setSearchParams({
                                    elementId: i?.element_id,
                                    link: i?.link,
                                  });
                                  setOpenIssuePopup({ ...i });
                                  setSubmitData({
                                    jobId,
                                    switch: i?.switch,
                                    suggested_fix: i?.suggested_fix,
                                    your_fix: i?.your_fix,
                                    element_id: i?.element_id,
                                    editedContextInJson: i?.editedContextInJson
                                  });
                                }}
                              >
                                <div className="pr-3"><input type="checkbox" /></div>
                                <div className="overflow-hidden">
                                  <p className={`font-bold text-sm sm:text-base overflow-x-auto flex-wrap`} style={{ wordBreak: 'break-word' }}>
                                    {parser.parseFromString(`<!doctype html><body>${i?.selector}`, 'text/html').body.textContent || "html"}
                                  </p>
                                  <p className="font-light text-xs sm:text-sm overflow-x-auto flex flex-wrap max-w-xs sm:max-w-md">
                                    {i?.link || "-"}
                                  </p>
                                </div>
                              </div>
                              <div className="md:col-span-8 xl:col-span-7 col-span-12 flex flex-wrap lg:flex-nowrap my-2 md:my-0 items-center">
                                <p style={{minWidth: "46px"}} className={`w-12 font-bold flex justify-center text-center px-1 mr-4 h-max border border-grayBorder ${i?.rule === "A" ? "bg-salmon text-white" : i?.rule === "AA" ? "bg-supernova text-white" : i?.rule === "AAA" ? "bg-grayLight" : "" }`}>
                                  {i?.rule}
                                </p>
                                {i?.category?.length > 0 && (
                                  <p className="text-center flex justify-center font-bold px-1 mr-4 h-max border border-grayBorder w-32" style={{minWidth: "128px"}}>
                                    {i?.category}
                                  </p>
                                )}
                                <p className="font-bold px-2 mr-4 my-2 lg:my-0 overflow-y-auto border border-grayBorder bg-floralWhite md:w-2/6 w-1/2" style={{minWidth: '155px', maxHeight: '2.75rem'}}>
                                  {i?.short_description ? parser.parseFromString(`<!doctype html><body>${i.short_description}`, 'text/html').body.textContent : '-'}
                                </p>
                                <p className="xl:whitespace-nowrap flex justify-center text-center font-bold px-1 mr-4 h-max bg-salmon text-white capitalize w-36">
                                  {i.severity} Severity
                                </p>
                                {typeDispenser(i)?.length > 0 && (
                                  <p className={`xl:min-w-119 min-w-88 xl:whitespace-nowrap w-max flex justify-center text-center font-bold px-1 mr-4 h-max border border-grayBorder ${typeDispenser(i) === "Auto Fix" ? 'bg-aliceBlue' : 'bg-floralWhite'}`}>
                                    {typeDispenser(i)}
                                  </p>
                                )}
                                {typeDispenser(i) !=='Needs Attention' ?
                                <>
                                  {i?.element_id !== updatingElement?.element_id ? (
                                    <div
                                      className={`cursor-pointer flex justify-center items-center ${!props?.autoApplyFixes && 'pointer-events-none opacity-25' }`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if(props?.autoApplyFixes) {
                                        setUpdatingElement(i);
                                        let payload_1 = {};
                                        if (i?.switch === "on") {
                                          payload_1 = {
                                            file_name: i?.file_name,
                                            editedContextInJson: i?.editedContextInJson,
                                            elementId: Number(i?.element_id),
                                            primaryId: Number(i?.primaryid),
                                            orgId: selectedOrgId,
                                            jobId,
                                            switch: "off",
                                            suggested_fix: {
                                              ...i?.suggested_fix,
                                            },
                                            your_fix: {
                                              ...i?.your_fix,
                                            },
                                            link: i?.link,
                                            linkId: i?.linkId,
                                            element_source: i?.element_source,
                                            existing_action: i?.existing_action
                                          };
                                        } else if (i?.switch === "off") {
                                          payload_1 = {
                                            file_name: i?.file_name,
                                            editedContextInJson: i?.editedContextInJson,
                                            elementId: Number(i?.element_id),
                                            primaryId: Number(i?.primaryid),
                                            orgId: selectedOrgId,
                                            jobId,
                                            switch: "on",
                                            suggested_fix: {
                                              ...i?.suggested_fix,
                                            },
                                            your_fix: {
                                              ...i?.your_fix,
                                            },
                                            link: i?.link,
                                            linkId: i?.linkId,
                                            element_source: i?.element_source,
                                            existing_action: i?.existing_action
                                          };
                                        }

                                        const payload_2 = {
                                          page: page.toString(), 
                                          orgId: selectedOrgId, 
                                          jobId, 
                                          limit: elementsLimit, 
                                          level: filterLevel,
                                          category: filterCategory,
                                          severity: filterSeverity,
                                          fix: filterFix,
                                          group: groupValue,
                                          group_selector: activeIndex,
                                        };
                                        const onSuccess = (data) => {
                                          setUpdatingElement(null);
                                          setAccessorData(
                                            data?.accessorData || []
                                          );
                                        };
                                        const onError = (errorMsg) => {
                                          setUpdatingElement(null);
                                          if (errorMsg === "Token expired") {
                                            TokenExpired();
                                            setTimeout(() => {
                                              triggerLogout();
                                            }, 1500);
                                          }
                                        };
                                        
                                        triggerUpdateElement({
                                          payload_1,
                                          payload_2,
                                          onSuccess,
                                          onError,
                                        });
                                      }}}
                                    >
                                      {i?.switch === "on" ? (
                                        RenderSvg(svgNames.switchOn)
                                      ) : i?.switch === "off" ? (
                                        RenderSvg(svgNames.switchOff)
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="pointer-events-none flex justify-center items-center"
                                    >
                                      <ClipLoader
                                        color={"#000000"}
                                        loading={updateElementLoading}
                                        size={14}
                                      />
                                    </div>
                                  )}
                                </>
                                :
                                <div className="opacity-0 cursor-pointer flex justify-center items-center"><svg width="38" height="18" viewBox="0 0 38 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="37" height="17" fill="#F5FBFF" stroke="#1F3B6F"></rect><rect x="22.1001" y="2.43945" width="13" height="13.1212" fill="#000000" stroke="#1F3B6F"></rect></svg></div>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : (
                      <SectionLoader
                        content={["No data to display"]} 
                        isNoData={true}
                      />
                    )
                  }
                </>
              }
              <div id="footer" className="flex h-10"></div>
              <div className="flex w-full items-center justify-between absolute border-t bottom-0 bg-white flex-col sm:flex-row">
                <div className="flex pageCount font-medium">
                  <p className="h-10 px-4 py-2 border-r flex items-center justify-center">Show</p>
                  <div 
                    className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 25 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(25); setPage(1)}}
                  >
                    25
                  </div>
                  <div 
                    className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 50 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(50); setPage(1)}}
                  >
                    50
                  </div>
                  <div 
                    className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 100 ? 'bg-primary text-white pointer-events-none' : ''}`}
                    onClick={() => {setElementsLimit(100); setPage(1)}}
                  >
                    100
                  </div>
                </div>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{ previous: HiOutlineArrowLeft, next: HiOutlineArrowRight }}
                        {...item}
                      />
                    )}
                    count={pageLimit}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
            : 
            <div className='flex flex-col justify-center items-center'>
              {groupList?.length > 0 && groupList?.map((val, index)=> {
                return (
                  <AccordionLayout 
                    key={index}
                    title={val?.title}
                    description={val?.short_description}
                    count={val?.count}
                    level={val?.level}
                    category={val?.category}
                    severity={val?.severity}
                    index={val?.id}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    groupValue={groupValue}
                  >
                    <div className="text-left text-sm relative">
                      {loading ?
                        <SectionLoader content={['We are crunching a bunch of numbers.',' We will notify you once it is ready']}/>
                      :
                        <div className="overflow-y-auto pb-10" style={{ height: "50vh" }}>
                          {accessorData?.length > 0 ?
                            accessorData
                              ?.sort((a, b) =>
                                a.typeDetermined > b.typeDetermined
                                  ? 1
                                  : b.typeDetermined > a.typeDetermined
                                    ? -1
                                    : 0
                              )
                              .map((i, index) => {
                                const fixStatus = getFixStatus(i);
                                const fixBg = getFixBg(i);
                                return (
                                  <div key={index.toString()} className={`${i?.element_id === updatingElement?.element_id && `bg-aliceBlue`} border-b ml-7 py-4 justify-between items-center`}>
                                    <div className={`col-span-8 grid sm:grid-cols-12 items-center ${updateElementLoading && `pointer-events-none`}`} >
                                      <div className="md:col-span-3 col-span-12 md:pr-8 flex">
                                        <div className="pr-3 h-[15px] mt-[5px]">
                                        <input 
                                          className="cursor-pointer"
                                          type="checkbox" 
                                          onClick={(e) => {
                                            if(e.target.checked) {
                                              setCurrentElement(i)
                                              showJobElementDetails()
                                            }
                                          }}
                                        />
                                        </div>
                                        <div className="overflow-hidden">
                                          <p className={`flex items-start gap-2 font-bold text-sm sm:text-base`} style={{ wordBreak: 'break-word' }}>
                                            <svg className="mt-[5px]" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fillRule="evenodd" clipRule="evenodd" d="M6.99572 6.9999L0.99572 13.0047L0.99572 0.995117L6.99572 6.9999Z" fill="#000000"/>
                                            </svg>
                                            #{parser.parseFromString(`<!doctype html><body>${i?.element_id}`, 'text/html').body.textContent || "html"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="md:col-span-6 col-span-12 md:px-8 flex">
                                        <div className="overflow-hidden">
                                          <p className="font-light text-xs sm:text-sm max-w-xs sm:max-w-md">
                                            <span className="font-semibold mr-1 ">{i?.page_title} - </span> {i?.link}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="md:col-span-3 flex justify-end gap-2 flex-wrap lg:flex-nowrap my-2 md:my-0">
                                        <p className={`flex items-center gap-2 justify-center text-center font-bold px-2 h-max border capitalize ${fixBg}`}>
                                          {i?.fix_status === 3 &&
                                          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.25107 0.537109H9.74882C10.0708 0.537109 10.3796 0.665009 10.6072 0.892671C10.8349 1.12033 10.9628 1.42911 10.9628 1.75107V10.2488C10.9628 10.5708 10.8349 10.8796 10.6072 11.1072C10.3796 11.3349 10.0708 11.4628 9.74882 11.4628H1.25107C0.92911 11.4628 0.620334 11.3349 0.392671 11.1072C0.165009 10.8796 0.0371094 10.5708 0.0371094 10.2488V1.75107C0.0371094 1.42911 0.165009 1.12033 0.392671 0.892671C0.620334 0.665009 0.92911 0.537109 1.25107 0.537109ZM6.10693 6.60693V2.96504H4.89297V6.60693H6.10693ZM6.10693 9.03486V7.82089H4.89297V9.03486H6.10693Z" fill="black"/>
                                          </svg>}
                                          {fixStatus}
                                        </p>
                                        <p
                                          className="cursor-pointer xl:whitespace-nowrap flex justify-center text-center font-bold px-2 h-max border capitalize" 
                                          onClick={() => {
                                            setCurrentElement(i);
                                            showJobElementDetails();
                                          }}
                                        >
                                          Know More
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : (
                              <SectionLoader
                                content={["No data to display"]} 
                                isNoData={true}
                              />
                            )
                          }
                        </div>
                      }
                      <div className="flex w-full items-center justify-between absolute bottom-0 border-t bg-[#f2f2f2] flex-col sm:flex-row border-b">
                        <div className="flex pageCount font-medium">
                          <p className="h-10 px-4 py-2 border-r flex items-center justify-center">Show</p>
                          <div 
                            className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 25 ? 'bg-primary text-white pointer-events-none' : ''}`}
                            onClick={() => {setElementsLimit(25); setPage(1)}}
                          >
                            25
                          </div>
                          <div 
                            className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 50 ? 'bg-primary text-white pointer-events-none' : ''}`}
                            onClick={() => {setElementsLimit(50); setPage(1)}}
                          >
                            50
                          </div>
                          <div 
                            className={`h-10 px-4 py-2 border-r flex items-center justify-center cursor-pointer ${elementsLimit === 100 ? 'bg-primary text-white pointer-events-none' : ''}`}
                            onClick={() => {setElementsLimit(100); setPage(1)}}
                          >
                            100
                          </div>
                        </div>
                        <Stack spacing={2}>
                          <Pagination
                            size="small"
                            page={page}
                            onChange={(event, value) => {
                              setPage(value);
                            }}
                            renderItem={(item) => (
                              <PaginationItem
                                components={{ previous: HiOutlineArrowLeft, next: HiOutlineArrowRight }}
                                {...item}
                              />
                            )}
                            count={pageLimit}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Stack>
                      </div>
                    </div>
                  </AccordionLayout>
                )
              })}
            </div>
          }
        </div>
      </section>
      {openIssuePopup && (
        <div id="modal" className="text-black">
          <div className="overflow-auto mt-14 lg:mt-0 fixed inset-0 z-50 outline-none focus:outline-none bg-white lg:bg-transparent pb-14 sm:pb-0 flex items-center">
            <div className="relative mx-auto my-5 w-full lg:w-10/12 max-h-[95vh] overflow-y-auto">
              <div className="lg:shadow-lg relative w-full bg-white">
                <div className="relative">
                  <div className={`md:flex justify-between mb-6 p-6 border-b py-4`}>
                    <div className="flex items-center">
                      <h3 className={`text-lg text-black font-bold mr-4`}>
                        #{openIssuePopup?.element_id}
                      </h3>
                      <p className="font-bold">
                        <span className="bg-yellow border border-yellow px-2 font-medium">Last occurrence</span>
                        <span className="mx-3">Audit #{lastOccurrence}</span>
                      </p>
                      <p className="font-bold">
                        <span className="border px-2 font-medium">First Occurrence</span>
                        <span className="mx-3">Audit #{firstOccurrence}</span></p>
                    </div>
                    <div className="flex items-center gap-3">
                      {typeDispenser(openIssuePopup) !== 'Needs Attention' &&
                        <div
                          className={`ml-4 cursor-pointer ${(!isCurrentWebsiteActive || !props?.autoApplyFixes) && 'opacity-25 pointer-events-none'}`}
                          onClick={() => {
                            setOpenIssuePopup({ ...openIssuePopup });
                            if (submitData?.switch === "on") {
                              setSubmitData({
                                editedContextInJson: submitData?.editedContextInJson ? submitData?.editedContextInJson : openIssuePopup?.editedContextInJson,
                                jobId,
                                switch: "off",
                                suggested_fix: {
                                  ...openIssuePopup?.suggested_fix,
                                  switch: "off",
                                },
                                your_fix: {
                                  ...openIssuePopup?.your_fix,
                                  switch: "off",
                                },
                                element_id: openIssuePopup?.element_id,
                              });
                            } else if (submitData?.switch === "off") {
                              setSubmitData({
                                editedContextInJson: submitData?.editedContextInJson ? submitData?.editedContextInJson : openIssuePopup?.editedContextInJson,
                                jobId,
                                switch: "on",
                                suggested_fix: {
                                  ...openIssuePopup?.suggested_fix,
                                  switch: "on",
                                },
                                your_fix: { ...openIssuePopup?.your_fix },
                                element_id: openIssuePopup?.element_id,
                              });
                            }
                          }}
                        >
                          {submitData?.switch === "on" ? (
                            RenderSvg(svgNames.switchOn)
                          ) : submitData?.switch === "off" ? (
                            RenderSvg(svgNames.switchOff)
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      <button
                        disabled={loading}
                        className="py-2 px-4 font-bold text-sm border flex items-center gap-2"
                        type="button"
                        onClick={() => {
                          setOpenIssuePopup(null);
                          setErrorFromApi(null);
                          setFirstOccurrence('');
                          setLastOccurrence('');
                          // Delete search param when CLOSE button is clicked
                          searchParams.delete("elementId");
                          searchParams.delete("link");
                          setSearchParams(searchParams);

                          reset(
                            {
                              firstName: "",
                              lastName: "",
                              password: "",
                              email: "",
                              role: "",
                              site_name: [],
                            },
                            {
                              keepErrors: false,
                              keepDirty: true,
                              keepIsSubmitted: false,
                              keepTouched: false,
                              keepIsValid: false,
                              keepSubmitCount: false,
                            }
                          );
                        }}
                      >
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.09756 1.34146L1.09756 8.84146M8.90244 8.84146L0.902439 1.34146" stroke="black" strokeWidth="2"/>
                          </svg>
                        {"Close"}
                      </button>
                    </div>
                  </div>
                  <form
                    style={{ overflowWrap: "anywhere" }}
                    onSubmit={handleSubmit(submit)}
                    className=""
                  >
                    <div className="px-6 pb-8">
                      <div className="grid grid-cols-6">
                        <div className="col-span-2">
                          <img src={openIssuePopup?.screenshot} alt="" className="w-full" />
                        </div>
                        <div className="col-span-4 pl-8">
                          <fieldset className="m-auto text-sm mb-4">
                            <p className="text-sm font-semibold mb-1">
                              {"Element"}
                            </p>
                            <p style={{wordBreak: 'break-word'}}>
                              {openIssuePopup?.element_source ? parser.parseFromString(`<!doctype html><body>${openIssuePopup.element_source}`, 'text/html').body.textContent : "-"}
                            </p>
                          </fieldset>
                          <fieldset className="m-auto text-sm mb-4 flex flex-col flex-wrap relative">
                            <p className="text-sm font-semibold mb-1">xpath</p>
                            <p style={{wordBreak: 'break-word'}}>
                              {openIssuePopup?.selector ? parser.parseFromString(`<!doctype html><body>${openIssuePopup.selector}`, 'text/html').body.textContent : "-"}
                            </p>
                          </fieldset>
                          <fieldset className="m-auto text-sm mb-4">
                            <p className="text-sm font-semibold mb-1">
                              {openIssuePopup?.page_title}
                            </p>
                            <p style={{wordBreak: 'break-word'}}>
                              {openIssuePopup?.link || "-"}
                            </p>
                          </fieldset>
                        </div>
                      </div>
                      <fieldset className="m-auto text-sm my-4 flex flex-col flex-wrap relative">
                        <div className="flex items-center gap-4">
                          <div className="text-lg text-black font-semibold mb-2">
                            {openIssuePopup?.wcag_rule ? parser.parseFromString(`<!doctype html><body>${openIssuePopup.wcag_rule}`, 'text/html').body.textContent : "-"}
                          </div>
                          <div className="bg-black border text-white font-medium  py-1 px-2 leading-none">Level {openIssuePopup?.rule}</div>
                          <div className="border font-medium  py-1 px-2 leading-none">{openIssuePopup?.category}</div>
                          <div className={`border font-medium  py-1 px-2 leading-none ${openIssuePopup?.severity === 'medium' ? 'bg-salmon text-white' :openIssuePopup?.severity === 'low' ? 'bg-yellow text-white' : 'bg-sunsetRed text-white'}`}>{openIssuePopup?.severity === 'medium' ? 'Needs Attention' :openIssuePopup?.severity === 'low' ? 'Recommendation' : 'Failure' }</div>
                        </div>
                        <p className="max-h-20 overflow-y-auto bg-[#F2F2F2] p-3 min-h-4" style={{wordBreak: 'break-word'}}>
                          {openIssuePopup?.rule_text ? parser.parseFromString(`<!doctype html><body>${openIssuePopup.rule_text}`, 'text/html').body.textContent : "-"}
                        </p>
                      </fieldset>
                      {typeDispenser(openIssuePopup) !== 'Needs Attention' &&
                      <>
                        <fieldset className="m-auto text-sm mb-4">
                          <div className="flex justify-between">
                            <p className="text-sm text-black font-semibold my-4 leading-none">
                              <span className="inline-block bg-yellow px-2 py-2 mr-2">Wally Ai</span>
                              {"Suggested Fix"}
                            </p>
                            <div
                              className={`cursor-pointer ${(!isCurrentWebsiteActive || !props?.autoApplyFixes) && 'opacity-25 pointer-events-none'}`}
                              onClick={() => {
                                if (submitData?.switch === "off") { return; }
                                if(isCurrentWebsiteActive || !props?.autoApplyFixes) {
                                  setSubmitData({
                                    ...submitData,
                                    suggested_fix: {
                                      ...submitData?.suggested_fix,
                                      switch:
                                        submitData?.suggested_fix?.switch === "on"
                                          ? "off"
                                          : "on",
                                    },
                                    your_fix: {
                                      ...submitData?.your_fix,
                                      switch:
                                        submitData?.suggested_fix?.switch === "on"
                                          ? "on"
                                          : "off",
                                    },
                                  });
                                }
                              }}
                            >
                              {submitData?.suggested_fix?.switch === "on" ? (
                                RenderSvg(svgNames.switchOn)
                              ) : submitData?.suggested_fix?.switch === "off" ? (
                                RenderSvg(svgNames.switchOff)
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <p className="max-h-20 overflow-y-auto p-3 bg-tileGray min-h-4 " style={{wordBreak: 'break-word'}}>
                            {submitData?.suggested_fix?.text ? parser.parseFromString(`<!doctype html><body>${submitData.suggested_fix.text}`, 'text/html').body.textContent : "-"}
                          </p>
                        </fieldset>
                        <fieldset className="m-auto text-sm mb-4">
                          <div className="flex justify-between">
                            <p className="text-sm text-black font-semibold mb-1">
                              {"Your Fix"}
                            </p>
                            <div
                              className={`cursor-pointer ${(!isCurrentWebsiteActive || !props?.autoApplyFixes) && 'opacity-25 pointer-events-none'}`}
                              onClick={() => {
                                if (submitData?.switch === "off") { return; }
                                setSubmitData({
                                  ...submitData,
                                  suggested_fix: {
                                    ...submitData?.suggested_fix,
                                    switch:
                                      submitData?.your_fix?.switch === "on"
                                        ? "on"
                                        : "off",
                                  },
                                  your_fix: {
                                    ...submitData?.your_fix,
                                    switch:
                                      submitData?.your_fix?.switch === "on"
                                        ? "off"
                                        : "on",
                                  },
                                });
                              }}
                            >
                              {submitData?.your_fix?.switch === "on" ? (
                                RenderSvg(svgNames.switchOn)
                              ) : submitData?.your_fix?.switch === "off" ? (
                                RenderSvg(svgNames.switchOff)
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {openIssuePopup?.editedContextInJson?.type === 'modify_atr' ?
                            <div className="grid grid-cols-12">
                              <label className="col-span-3 font-bold p-3 border border-r-0 border-gray bg-tileGray">{Object.keys(openIssuePopup?.editedContextInJson?.data.atr)[0]}</label>
                              <textarea
                                id="yourFixTextArea"
                                defaultValue={openIssuePopup?.your_fix?.text !== "" ? openIssuePopup?.your_fix?.text : openIssuePopup?.editedContextInJson?.data?.atr?.[Object.keys(openIssuePopup?.editedContextInJson?.data.atr)[0]] || ""}
                                className={`${!isCurrentWebsiteActive && 'pointer-events-none'} col-span-9 placeholder-gray min-h-4 grow border flex flex-wrap border-gray focus:border-primary outline-none p-3 font-medium ${errors.your_fix && "border-red-500"
                                  }`}
                                onChange={(e) => {
                                  setSubmitData({
                                    ...submitData,
                                    editedContextInJson: {
                                      "type": "modify_atr",
                                      "data": {
                                          "selector": openIssuePopup?.editedContextInJson?.data?.selector,
                                          "atr": {
                                              [Object.keys(openIssuePopup?.editedContextInJson?.data.atr)[0]]: e.target.value,
                                          }
                                      }
                                    },
                                    your_fix: {
                                      type: "manualFix",
                                      text: e.target.value,
                                      switch: "on",
                                    },
                                    suggested_fix: {
                                      ...submitData?.suggested_fix,
                                      switch: "off",
                                    },
                                  });
                                }}
                              />
                            </div>
                          : openIssuePopup?.editedContextInJson?.type === 'add_atr' ?
                            <div>
                              {Object.keys(openIssuePopup?.editedContextInJson?.data.atr)?.map((label, index) => {
                                return (
                                  <div className="grid grid-cols-12" key={index}>
                                    <label className="col-span-3 font-bold p-3 border border-r-0 border-gray bg-tileGray">{label}</label>
                                    <textarea
                                      id="yourFixTextArea"
                                      defaultValue={openIssuePopup?.your_fix?.text !== "" ? openIssuePopup?.your_fix?.text : openIssuePopup?.editedContextInJson?.data?.atr[label] || ""}
                                      className={`${!isCurrentWebsiteActive && 'pointer-events-none'} col-span-9 placeholder-gray min-h-4 grow border flex flex-wrap border-gray focus:border-primary outline-none p-3 font-medium ${errors.your_fix && "border-red-500"
                                        }`}
                                      onChange={(e) => {
                                        setSubmitData({
                                          ...submitData,
                                          editedContextInJson: {
                                            "type": "add_atr",
                                            "data": {
                                              "selector": openIssuePopup?.editedContextInJson?.data?.selector,
                                              "atr": {
                                                [label]: e.target.value
                                              }
                                            }
                                          },
                                          switch: "on",
                                          your_fix: {
                                            type: "manualFix",
                                            text: e.target.value,
                                            switch: "on",
                                          },
                                          suggested_fix: {
                                            ...submitData?.suggested_fix,
                                            switch: "off",
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          : openIssuePopup?.editedContextInJson?.type === 'modify_tag' ?
                            <div className="grid grid-cols-12">
                              <label className="col-span-3 font-bold p-3 border border-r-0 border-gray bg-tileGray">Tag</label>
                              <textarea
                                id="yourFixTextArea"
                                defaultValue={openIssuePopup?.your_fix?.text !== "" ? openIssuePopup?.your_fix?.text : openIssuePopup?.editedContextInJson?.data?.tag || ""}
                                className={`${!isCurrentWebsiteActive && 'pointer-events-none'} col-span-9 placeholder-gray min-h-4 grow border border-gray focus:border-primary outline-none p-3 font-medium ${errors.your_fix && "border-red-500"
                                  }`}
                                onChange={(e) => {
                                  setSubmitData({
                                    ...submitData,
                                    editedContextInJson: {
                                      "type": "modify_tag",
                                      "data": {
                                        "selector": openIssuePopup?.editedContextInJson?.data?.selector,
                                        "tag": e.target.value,
                                      }
                                    },
                                    switch: "on",
                                    your_fix: {
                                      type: "manualFix",
                                      text: e.target.value,
                                      switch: "on",
                                    },
                                    suggested_fix: {
                                      ...submitData?.suggested_fix,
                                      switch: "off",
                                    },
                                  });
                                }}
                              />
                            </div>
                          : openIssuePopup?.editedContextInJson?.type === 'modify_value' ?
                            <div className="grid grid-cols-12">
                              <label className="col-span-3 font-bold p-3 border border-r-0 border-gray bg-tileGray">Value</label>
                              <textarea
                                id="yourFixTextArea"
                                defaultValue={openIssuePopup?.your_fix?.text !== "" ? openIssuePopup?.your_fix?.text : openIssuePopup?.editedContextInJson?.data?.value || ""}
                                className={`${!isCurrentWebsiteActive && 'pointer-events-none'} col-span-9 placeholder-gray min-h-4 grow border border-gray focus:border-primary outline-none p-3 font-medium ${errors.your_fix && "border-red-500"
                                  }`}
                                onChange={(e) => {
                                  setSubmitData({
                                    ...submitData,
                                    editedContextInJson: {
                                      "type": "modify_value",
                                      "data": {
                                        "selector": openIssuePopup?.editedContextInJson?.data?.selector,
                                        "value": e.target.value,
                                      }
                                    },
                                    switch: "on",
                                    your_fix: {
                                      type: "manualFix",
                                      text: e.target.value,
                                      switch: "on",
                                    },
                                    suggested_fix: {
                                      ...submitData?.suggested_fix,
                                      switch: "off",
                                    },
                                  });
                                }}
                              />
                            </div>
                          :
                            <textarea
                              id="your_fix"
                              name="your_fix"
                              value={submitData?.your_fix?.text || ""}
                              className={`${!isCurrentWebsiteActive && 'pointer-events-none'} placeholder-gray min-h-4 w-full border flex flex-wrap border-gray outline-none focus:border-primary py-2 px-4 font-medium ${errors.your_fix && "border-red-500"
                                }`}
                              {...register("your_fix", {
                                maxLength: {
                                  value: 1000,
                                  message: "* Maximum 1000 characters allowed",
                                },
                                minLength: {
                                  value: 1,
                                  message: "* Minimum 1 character required",
                                },
                              })}
                              onChange={(e) => {
                                // turn ON overall switch, your_fix switch, and turn OFF suggested_fix
                                // because the user types-in the your_fix textarea box now
                                setSubmitData({
                                  ...submitData,
                                  editedContextInJson: {},
                                  switch: "on",
                                  your_fix: {
                                    type: "manualFix",
                                    text: e.target.value,
                                    switch: "on",
                                  },
                                  suggested_fix: {
                                    ...submitData?.suggested_fix,
                                    switch: "off",
                                  },
                                });
                              }}
                            />
                          }
                          {errors.your_fix && (
                            <span className="text-xs text-red-500 font-semibold">
                              {errors.your_fix.message}
                            </span>
                          )}
                        </fieldset>
                        {errorFromApi !== null && (
                          <div
                            className={`${errorFromApi?.type === "error"
                                ? "bg-floralWhite "
                                : "bg-aliceBlue"
                              } border p-3 my-8 flex items-center flex-row overflow-x-auto`}
                          >
                            <div className="mr-2">
                              <BsFillExclamationSquareFill
                                color={
                                  errorFromApi?.type === "error"
                                    ? "#FF5353"
                                    : "#000000"
                                }
                                className="text-2xl"
                              />
                            </div>
                            <p className="text-sm text-left text-black font-light">
                              {`${errorFromApi?.msg}`}
                            </p>
                          </div>
                        )}
                      </>
                      }
                      {typeDispenser(openIssuePopup) === 'Needs Attention' ?
                          <button className="flex items-center gap-3 bg-gray border-gray false font-bold text-sm text-white py-2 px-4 tracking-small cursor-default	" type="submit">
                            Help me fix this
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.979492 8H14.0407" stroke="white"/>
                              <path d="M9.63281 3.56152L14.3675 8.0003L9.63281 12.4391" stroke="white"/>
                            </svg>
                          </button>
                        :
                        <button
                          disabled={loading}
                          className={`${validationPassed() && changesMade
                              ? `bg-black border-black`
                              : `bg-gray border-gray`
                            } ${(updateElementLoading || !isCurrentWebsiteActive) && "pointer-events-none"
                            } font-bold text-sm text-white py-2 px-4 tracking-small flex items-center gap-3`}
                          type="submit"
                        >
                          {loading ? (
                            <div className="flex flex-row justify-center items-center">
                              <PieLoader
                                color={"white"}
                                loading={loading}
                                size={5}
                              />
                              <span className="ml-2">{Strings.loading}</span>
                            </div>
                          ) : (
                            <>
                              Fix this now
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.979492 8H14.0407" stroke="white"/>
                                <path d="M9.63281 3.56152L14.3675 8.0003L9.63281 12.4391" stroke="white"/>
                              </svg>
                            </>
                          )}
                        </button>
                      } 
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-primary"></div>
        </div>
      )}
    </>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    selectedOrg: state?.dashboard?.data?.organizations?.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ),
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
    isTempPassword: state.auth.isTempPassword,
    updateElementLoading: state.dashboard.updateElementLoading,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
  fetchElementsList: getElementsList,
  fetchElementOccurrence: getElementOccurrence,
  triggerUpdateElement: updateElement,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(AuditReport);
