// library imports
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect, useSelector } from "react-redux";

// constants
import { RoutingConstants } from "../constants";

// actions
import { logOut } from "../store/actions/auth";

/**
 * @function ProtectedRoute
 * @param {Object} props
 * @returns {JSX}
 */
const ProtectedRoute = (props) => {
  const { triggerLogout } = props;
  const isLoggedIn = useSelector((store) => store.auth.loggedIn);
  const token = useSelector((store) => store.auth.accessToken);
  if (isLoggedIn && !token) {
    triggerLogout();
  }
  // If logged in and accessToken is present, return an outlet that will render child elements in App.js
  // If not logged in and accessToken is not present, return element that will navigate to login page
  return isLoggedIn && token ? (
    <Outlet />
  ) : (
    <Navigate to={RoutingConstants.login} />
  );
};

/**
 * @function mapStateToProps
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
