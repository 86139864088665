/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// library imports
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useParams, useNavigate} from "react-router-dom";
import "chart.js/auto";
import { useTable, useSortBy } from 'react-table';
import { Chart } from "react-chartjs-2";

// icons
import { MdCheckCircleOutline } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import moment from 'moment-timezone';

// icons
import { ClipLoader } from "react-spinners";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import ReportHeader from "../../components/reports/reportHeader";

// components
import { RoutingConstants } from "../../constants";
import DefaultPage from "../../components/layout/defaultPage";
import UpgradePlan from "../../components/plan/upgradePlan";
import ScoreChart from "../../components/reports/scoreChart";
import SectionLoader from "../../components/SectionLoader";

// actions
import { fetchReport, fetchPageReport, getAuditScore, getAuditRule, getAuditStage, getActiveSubscription, getWebsiteSettings, editWebsiteSettings } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import { getElementsList, updateElement } from "../../store/actions/dashboard";

// token expired handler
import TokenExpired from "../../utils/TokenExpired";
/**
 * @function ViewReport
 * @returns {JSX}
 */

const toHoursAndMinutes = (totalMinutes) => {
  if (!totalMinutes) {
    return '1 min'
  }
  if (totalMinutes < 60) {
    return `${totalMinutes} mins`;
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (!minutes) {
    return `${hours} hr`;
  }
  if (hours === 1) {
    return `${hours} hr ${minutes} mins`;
  }
  return `${hours} hrs ${minutes} mins`;
}

const ViewReport = (props) => {
  const { id, jobId } = useParams();
  const navigate = useNavigate();
  const {
    selectedOrg,
    triggerLogout,
    userProfile,
    isTempPassword,
    triggerAuditData,
    triggerAuditRule,
    triggerAuditStage,
    auditStage,
    auditStatus,
    triggerActiveSubscription,
    triggerGetWebsiteSettings
  } = props;
  
  const fixesRatioOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    layout: {
      padding: {
        left: 75,
        right: 75,
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false
        },
      },
      x: {
        grid: {
          display: false
        },
      }
    }
  };

  let reports = selectedOrg?.websites?.find(
    (w) => w.id.toString() === id
  )?.reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // state variables
  const [autoApplyFixes, setAutoApplyFixes] = useState(false)
  const [filterLevel, setFilterLevel] = useState('All')
  const [loading, setLoading] = useState(true);
  const auditStageArray = auditStage.length > 0 ? JSON.parse(auditStage) : []
  const auditStageData = auditStageArray?.find(i => i.jobId === jobId)
  const auditLevel = auditStageData?.stageId && auditStageData?.stageId > auditStatus?.stageInfo ?  auditStageData?.stageId : auditStatus?.stageInfo
  const [scoreData, setScoreData] = useState({})
  const [comparativeData, setComparativeData] = useState({})

  const auditData = {
    labels: scoreData?.trend?.map(item => { return 'Audit #'+item?.auditNumber}) ||[],
    datasets: [
      {
        type: 'line',
        pointStyle:'rect',
        borderWidth: 1,
        borderColor: '#213251',
        backgroundColor: '#213251',
        data: scoreData?.trend?.map(item => { return item?.comparative_analysis_data?.new_rule_violations?.count || 0}) || [],
        label: "New Rule Violations",
        borderDash: [8,5]
      },
      {
        type: 'line',
        pointStyle:'rect',
        borderJoinStyle: 'bevel',
        borderWidth: 1,
        borderColor: '#213251',
        backgroundColor: '#213251',
        data: scoreData?.trend?.map(item => { return item?.comparative_analysis_data?.stopped_rule_violations?.count || 0}) || [],
        label: "Stopped Rule Violations",
      },
    ],
  };

  const[ruleData, setRuleData] = useState({
    "rules": [
      {
        "rule": "",
        "rule_level": "",
        "category": "",
        "inaccessibility": 0,
        "affected_documents": 0
      }
    ]
  })
  const [statusFromApi, setStatusFromApi] = useState(null);
  const [triggerAuditStatus, setTriggerAuditStatus] = useState(false)
  const [activeSubscription, setActiveSubscription] = useState({})
  const processingStatus = auditStageData?.status || auditStatus?.status
  const completedPercentage = auditStageData?.completedPercentage || 0

  useEffect(() => {
    if (triggerAuditStatus && auditLevel >= 3) {
      triggerAuditRule({
        payload: {
          jobId: jobId,
          level: filterLevel
        },
        onSuccess: (res) => {
          setLoading(false)
          setRuleData(res);
          setStatusFromApi(null)
        },
        onError: (errorMsg) => {
          setLoading(false)
          setStatusFromApi(errorMsg)
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              triggerLogout();
            }, 1500);
          }
        }
      });
    }
  }, [filterLevel, triggerAuditStatus, auditLevel])

  useEffect(() => {
    triggerActiveSubscription({
      payload: {
        websiteId: id,
      },
      onSuccess: (res) => {
        setActiveSubscription(res)
      },
      onError: (errorMsg) => {
        if (errorMsg === "Token expired") {
          TokenExpired();
          setTimeout(() => {
            triggerLogout();
          }, 1500);
        }
      }
    });
  }, [])

  useEffect(() => {
    setTriggerAuditStatus(false)
      triggerAuditStage({
        payload: {
          jobId: jobId,
        },
        onSuccess: (res) => { 
          if(res?.stageInfo < 3) {
            setLoading(false)
          }
          setStatusFromApi(null)
          setTimeout(() => {
            setTriggerAuditStatus(true)
          }, 1000);
        },
        onError: (errorMsg) => {
          setStatusFromApi(errorMsg)
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              triggerLogout();
            }, 1500);
          }
        }
      });
  }, [jobId])
  useEffect(() => {
    if (triggerAuditStatus && auditLevel >= 3) {
      triggerAuditData({
        payload: {
          jobId: jobId,
        },
        onSuccess: (res) => {
          setScoreData(res);
          setComparativeData(res?.trend?.find(job => job?.currentJob === true))
          setStatusFromApi(null)
        },
        onError: (errorMsg) => {
          setStatusFromApi(errorMsg)
          if (errorMsg === "Token expired") {
            TokenExpired();
            setTimeout(() => {
              triggerLogout();
            }, 1500);
          }
        }
      });
    }
  }, [triggerAuditStatus, auditLevel, completedPercentage])


  // navigate to password change if this flag is true
  useEffect(() => {
    if (isTempPassword) {
      navigate(RoutingConstants.changePassword);
    }
  }, [isTempPassword]);

  // chuck off the first 8 chars that has "https://"
  let currentSite =
    selectedOrg &&
    selectedOrg?.websites?.find((w) => w.id.toString() === id)?.url;
  if (currentSite?.includes("https")) {
    currentSite = currentSite.slice().slice(8);
  } else if (currentSite?.includes("http")) {
    currentSite = currentSite.slice().slice(7);
  }

  // if the last character of website is "/", chuck it off
  if (currentSite?.length > 0 && currentSite[currentSite.length - 1] === "/") {
    currentSite = currentSite.slice(0, currentSite.length - 1);
  }

  let reportNames = [];
  reports?.length > 0 &&
    reports
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice()
      .map((val, index) => {
        const auditName = `Audit #${reports.length - index}`;
        reportNames.push(auditName);
      });
  useEffect(() => {
    triggerGetWebsiteSettings({ 
      payload: {
        id: id,
      },
      onSuccess: (res) => {
        setAutoApplyFixes(res?.auto_apply_fixes)
      }, 
      onError: () => {} 
    });
  }, [id]);

  const maxScore = Math.max(...Object.values({actual_ax_score: scoreData?.actual_ax_score, current_ax_score:scoreData?.current_ax_score}))
  const data = ruleData?.rules
  const inaccessibilityTotal = Math.max(...new Set(ruleData?.rules?.map((i, index) => {return i?.inaccessibility  })));
  const affectedPagesTotal = Math.max(...new Set(ruleData?.rules?.map((i, index) => {return i?.affected_documents})));
  const columns = useMemo((val) =>
    [
      {
        Header: `Instances of Inaccessibility (${ruleData?.total_scf})`,
        accessor: 'inaccessibility',
        Cell: ({ cell: { row, value } }) => {
          return (
            <div className="flex items-center justify-end w-full">
              <label className="text-xs font-bold mr-2">{value}</label>
              <div className="bar_bg min-w-10 h-5" style={{width: `${((value/ inaccessibilityTotal) * 100)}%`}}></div>
            </div>
          )
        },
      },
      {
        Header: 'WCAG Rule',
        accessor: 'rule',
      },
      {
        Header: 'Rule Level',
        accessor: 'rule_level',
      },
      {
        Header: 'Impact Category',
        accessor: 'category',
      },
      {
        Header: `Affected Page Documents (Out of ${ruleData?.total_pages})`,
        accessor: 'affected_documents',
        Cell: ({ cell: { row, value } }) => {
          return(
            <div className="flex items-center w-full">
              <div className="w-full flex">
                <div className="bg-warning min-w-10 h-5" style={{width: `${((value/ affectedPagesTotal) * 100)}%`}}>
                </div>
                {100 -((value/ affectedPagesTotal) * 100) > 0 && <div className="bg-grayLight p-2" style={{width: `${100 -((value/ affectedPagesTotal) * 100)}%`}}></div>}
              </div>
              <label className="text-xs font-bold ml-2">{value}</label>
            </div>
          )
        }
      },
    ],
    [inaccessibilityTotal, affectedPagesTotal, ruleData]
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  const levelWiseFilter = (val) => {
    if(filterLevel !== val) {
      setLoading(true)
    } 
    setFilterLevel(val)
  };

  const checkIfAuditInterrupted = (auditStatus && auditStatus?.isAuditInterrupted);
  const checkIfLongRunningAudit = (auditStatus && auditStatus?.longRunningAudits);
  const planId = (auditStatus && auditStatus?.plan_id) || 5;
  const freeForeverAuditCompleted = planId === 1 && auditLevel === 3;

  const sectionLoaderContent = () => {
    let content = ['We are crunching a bunch of numbers.',' We will notify you once it is ready']
    if (auditStatus?.isAuditInterrupted) {
      content = ['OOPS! Something went wrong!','Please try again.']
    } else if (auditStatus?.longRunningAudits) {
      content = ['It is taking more time than we expected.','We will notify you once it is ready']
    } else if (auditLevel >=0 && auditLevel <= 2) {
      content = ['Loading... Please wait']
    }
    return content
  }

  if (checkIfAuditInterrupted && !loading) {
    return (
      <DefaultPage userProfile={userProfile} showSidebar={true}>
      <ReportHeader activeSubscription={activeSubscription} />
        <SectionLoader content={sectionLoaderContent()} showWarningIcon={true} />
      </DefaultPage>
    )
  }
  
  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <ReportHeader activeSubscription={activeSubscription} />
      {loading ?
        <SectionLoader content={sectionLoaderContent()} />
        :
        <>
          {!statusFromApi ?
            <>
            {triggerAuditStatus &&
            <section style={{ height: "calc(100vh - 80px)" }} className="overflow-y-auto bg-[#F2F2F2]">
              {!autoApplyFixes &&
                <div className="bg-floralWhite border border-grayLight p-4 flex flex-row overflow-x-auto">
                  <div className="mr-2">
                    <BsFillExclamationSquareFill
                      color="#FF5353"
                      className="text-2xl"
                    />
                  </div>
                  <p className="text-base text-left text-black font-light">Any changes you make to the fixes will not reflect as the Apply fixes  settings is turned OFF for this site.</p>
                </div>
              }
              {(auditLevel >=0 && auditLevel <= 2) &&
                <div>
                  <div className="p-4">
                    <div className="flex items-center justify-between mb-1">
                      <p>Audit Started at {`${moment(auditStatus?.dateTime).local().format('MMMM DD, YYYY h:mmA')}`}</p>
                    </div>
                    <div className="progress bg-grayBorder">
                      <div className={`bar bg-primary ${auditLevel === 0 ? 'w-[30px]' : 'w-1/5'} h-[5px]`}></div>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="inline-flex items-center gap-2 bg-yellow border border-yellow py-1 px-3 leading-none mt-3">
                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.25113 0.537109H9.74888C10.0708 0.537109 10.3796 0.665009 10.6073 0.892671C10.8349 1.12033 10.9628 1.42911 10.9628 1.75107V10.2488C10.9628 10.5708 10.8349 10.8796 10.6073 11.1072C10.3796 11.3349 10.0708 11.4628 9.74888 11.4628H1.25113C0.929171 11.4628 0.620395 11.3349 0.392732 11.1072C0.16507 10.8796 0.0371704 10.5708 0.0371704 10.2488V1.75107C0.0371704 1.42911 0.16507 1.12033 0.392732 0.892671C0.620395 0.665009 0.929171 0.537109 1.25113 0.537109ZM6.10699 6.60693V2.96504H4.89303V6.60693H6.10699ZM6.10699 9.03486V7.82089H4.89303V9.03486H6.10699Z" fill="black"/>
                        </svg>
                        Initiated
                      </div>
                      <div className="inline-flex items-center gap-2 py-1 px-3 border leading-none mt-3">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.00002 10.6667C8.56669 10.6667 10.6667 8.56666 10.6667 5.99999C10.6667 3.43332 8.56669 1.33332 6.00002 1.33332C3.43335 1.33332 1.33335 3.43332 1.33335 5.99999C1.33335 8.56666 3.43335 10.6667 6.00002 10.6667ZM6.00002 0.166656C9.20835 0.166656 11.8334 2.79166 11.8334 5.99999C11.8334 9.20832 9.20835 11.8333 6.00002 11.8333C2.79169 11.8333 0.166687 9.20832 0.166687 5.99999C0.166687 2.79166 2.79169 0.166656 6.00002 0.166656ZM8.91669 7.10832L8.50835 7.86666L5.41669 6.17499V3.08332H6.29169V5.64999L8.91669 7.10832Z" fill="black" />
                        </svg>
                        {auditStageData?.eta || auditStatus?.eta}
                      </div>
                    </div>
                  </div>
                  <SectionLoader content={sectionLoaderContent()} />
                </div>
              }
              {auditLevel >= 3 && checkIfLongRunningAudit &&
                <>
                  <div className="flex flex-col flex-wrap xl:flex-nowrap items-center justify-center gap-4 sm:gap-10 py-4 sm:py-12 px-4 md:px-16">
                    <div className="w-full mb-12 text-lg">
                      <div className="flex items-center justify-between mb-1">
                        <p>Audit Started at {`${moment(auditStatus?.dateTime).local().format('MMMM DD, YYYY h:mmA')}`}</p>
                      </div>
                      <div className="progress bg-grayBorder">
                        <div className={`bar bg-primary ${auditLevel === 5 ? 'w-full' : 'w-3/5'} h-[5px] `}></div>
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <div className="flex items-center gap-3">
                          {auditLevel === 5 ?
                            <div className="inline-flex items-center gap-2 bg-greenSucceed text-white text-sm border border-greenSucceed py-1 px-3 leading-none mt-3">
                              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.49984 0.416992C2.979 0.416992 0.916504 2.47949 0.916504 5.00033C0.916504 7.52116 2.979 9.58366 5.49984 9.58366C8.02067 9.58366 10.0832 7.52116 10.0832 5.00033C10.0832 2.47949 8.02067 0.416992 5.49984 0.416992ZM5.49984 8.66699C3.47859 8.66699 1.83317 7.02157 1.83317 5.00033C1.83317 2.97908 3.47859 1.33366 5.49984 1.33366C7.52109 1.33366 9.1665 2.97908 9.1665 5.00033C9.1665 7.02157 7.52109 8.66699 5.49984 8.66699ZM7.60359 2.97449L4.58317 5.99491L3.39609 4.81241L2.74984 5.45866L4.58317 7.29199L8.24984 3.62533L7.60359 2.97449Z" fill="white"/>
                              </svg>
                              Completed in {toHoursAndMinutes(auditStatus?.elapsedTime)}
                            </div>
                            :
                            <div className="inline-flex items-center gap-2 bg-yellow text-sm border border-yellow py-1 px-3 leading-none mt-3">
                              <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.25113 0.537109H9.74888C10.0708 0.537109 10.3796 0.665009 10.6073 0.892671C10.8349 1.12033 10.9628 1.42911 10.9628 1.75107V10.2488C10.9628 10.5708 10.8349 10.8796 10.6073 11.1072C10.3796 11.3349 10.0708 11.4628 9.74888 11.4628H1.25113C0.929171 11.4628 0.620395 11.3349 0.392732 11.1072C0.16507 10.8796 0.0371704 10.5708 0.0371704 10.2488V1.75107C0.0371704 1.42911 0.16507 1.12033 0.392732 0.892671C0.620395 0.665009 0.929171 0.537109 1.25113 0.537109ZM6.10699 6.60693V2.96504H4.89303V6.60693H6.10699ZM6.10699 9.03486V7.82089H4.89303V9.03486H6.10699Z" fill="black"/>
                              </svg>
                              In Progress
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <SectionLoader content={sectionLoaderContent()} showWarningIcon={auditStatus && auditStatus?.isAuditInterrupted} />
                </>
              }
              {auditLevel >= 3 && !checkIfLongRunningAudit &&
              <>
                {!statusFromApi ?
                  <div className="min-h-full bg-[#F2F2F2]">
                    <div className="flex flex-col flex-wrap xl:flex-nowrap items-center justify-center gap-4 sm:gap-10 py-4 sm:py-12 px-4 md:px-16">
                      <div className="w-full mb-12 text-lg">
                        <div className="flex items-center justify-between mb-1">
                          <p>Audit Started at {`${moment(auditStatus?.dateTime).local().format('MMMM DD, YYYY h:mmA')}`}</p>
                          <div>
                            <span className="mr-5"><b className="mr-1.5">{scoreData?.reportSummery?.rule_failures}</b> Rule Violations</span>
                            <span className=""><b className="mr-1.5">{scoreData?.reportSummery?.inaccessibility_instances}</b> Instances of Inaccessiblity</span>
                          </div>
                        </div>
                        <div className="progress bg-grayBorder">
                          <div className={`bar bg-primary ${(auditLevel === 5 || freeForeverAuditCompleted) ? 'w-full' : 'w-3/5'} h-[5px] `}></div>
                        </div>
                        <div className="flex items-center justify-between gap-3">
                          <div className="flex items-center gap-3">
                            {(auditLevel === 5 || freeForeverAuditCompleted) ?
                              <div className="inline-flex items-center gap-2 bg-greenSucceed text-white text-sm border border-greenSucceed py-1 px-3 leading-none mt-3">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.49984 0.416992C2.979 0.416992 0.916504 2.47949 0.916504 5.00033C0.916504 7.52116 2.979 9.58366 5.49984 9.58366C8.02067 9.58366 10.0832 7.52116 10.0832 5.00033C10.0832 2.47949 8.02067 0.416992 5.49984 0.416992ZM5.49984 8.66699C3.47859 8.66699 1.83317 7.02157 1.83317 5.00033C1.83317 2.97908 3.47859 1.33366 5.49984 1.33366C7.52109 1.33366 9.1665 2.97908 9.1665 5.00033C9.1665 7.02157 7.52109 8.66699 5.49984 8.66699ZM7.60359 2.97449L4.58317 5.99491L3.39609 4.81241L2.74984 5.45866L4.58317 7.29199L8.24984 3.62533L7.60359 2.97449Z" fill="white"/>
                                </svg>
                                Completed in {toHoursAndMinutes(auditStatus?.elapsedTime)}
                              </div>
                              :
                              <div className="inline-flex items-center gap-2 bg-yellow text-sm border border-yellow py-1 px-3 leading-none mt-3">
                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.25113 0.537109H9.74888C10.0708 0.537109 10.3796 0.665009 10.6073 0.892671C10.8349 1.12033 10.9628 1.42911 10.9628 1.75107V10.2488C10.9628 10.5708 10.8349 10.8796 10.6073 11.1072C10.3796 11.3349 10.0708 11.4628 9.74888 11.4628H1.25113C0.929171 11.4628 0.620395 11.3349 0.392732 11.1072C0.16507 10.8796 0.0371704 10.5708 0.0371704 10.2488V1.75107C0.0371704 1.42911 0.16507 1.12033 0.392732 0.892671C0.620395 0.665009 0.929171 0.537109 1.25113 0.537109ZM6.10699 6.60693V2.96504H4.89303V6.60693H6.10699ZM6.10699 9.03486V7.82089H4.89303V9.03486H6.10699Z" fill="black"/>
                                </svg>
                                In Progress
                              </div>
                            }
                            {(auditLevel < 5) && !freeForeverAuditCompleted &&  
                              <div className="inline-flex items-center gap-2 py-1 px-3 border text-sm leading-none mt-3">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.00002 10.6667C8.56669 10.6667 10.6667 8.56666 10.6667 5.99999C10.6667 3.43332 8.56669 1.33332 6.00002 1.33332C3.43335 1.33332 1.33335 3.43332 1.33335 5.99999C1.33335 8.56666 3.43335 10.6667 6.00002 10.6667ZM6.00002 0.166656C9.20835 0.166656 11.8334 2.79166 11.8334 5.99999C11.8334 9.20832 9.20835 11.8333 6.00002 11.8333C2.79169 11.8333 0.166687 9.20832 0.166687 5.99999C0.166687 2.79166 2.79169 0.166656 6.00002 0.166656ZM8.91669 7.10832L8.50835 7.86666L5.41669 6.17499V3.08332H6.29169V5.64999L8.91669 7.10832Z" fill="black" />
                                </svg>
                                {auditStageData?.eta || auditStatus?.eta}
                              </div>
                            }
                          </div>
                          {auditLevel === 5 ?
                            <>
                              {activeSubscription?.config?.detailed_audit_summary ?
                                <Link to={`/website/${id}/reports/fullReport/${jobId}`} className={`${auditLevel !== 5 && ' pointer-events-none'}`}>
                                  <div className="flex items-center gap-2">
                                    <svg className="mx-auto" width="20" height="18" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M22.5 13L17.5 18L14 14.5L15.5 13L17.5 15L21 11.5L22.5 13ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.75 8.65 21.44 9.26 21.08 9.85C20.5 9.5 19.86 9.25 19.18 9.12L19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8C3.83 11.36 7.24 13.5 11 13.5L12.21 13.43C12.07 13.93 12 14.46 12 15V15.46L11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5Z" fill='black' />
                                    </svg>
                                    <div className="hidden sm:block">
                                      <p className="text-xs leading-[12px] font-bold">View Full Audit Report</p>
                                    </div>
                                  </div>
                                </Link>
                              :
                                <UpgradePlan
                                  content={`<div className="flex items-center gap-2">
                                      <svg className="mx-auto" width="20" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.5 13L17.5 18L14 14.5L15.5 13L17.5 15L21 11.5L22.5 13ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.75 8.65 21.44 9.26 21.08 9.85C20.5 9.5 19.86 9.25 19.18 9.12L19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8C3.83 11.36 7.24 13.5 11 13.5L12.21 13.43C12.07 13.93 12 14.46 12 15V15.46L11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5Z" fill='black' />
                                      </svg>
                                      <div className="hidden sm:block">
                                        <p className="text-xs leading-[12px] font-bold">View Full Audit Report</p>
                                      </div>
                                    </div>`}
                                />
                              }
                            </>
                          :
                          <>
                            {!freeForeverAuditCompleted && processingStatus === "processing" &&
                              <div className="border flex items-center gap-1 px-1 mt-3">
                                <div className="bg-sunsetRed w-4 h-4 text-sm leading-none flex items-center justify-center text-white font-bold rounded-sm">!</div>
                                This is an interim audit findings of only the top level pages
                              </div>
                            }
                          </>
                          }
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col items-center justify-center w-full mb-14 md:ml-14">
                      <div className={`transition ease-in-out lg:mr-10 md:mr-8 md:mb-0 mb-8 duration-[1200ms] bg-white cursor-pointer text-white p-4 pt-2 lg:min-w-[15%] md:min-w-[25%] border-grayBorder ${filterLevel === 'All' ? 'md:scale-[1.3] scale-[1.2] scale-[1.2] bg-[#FED600]' : ''}`} onClick={() => {levelWiseFilter('All')}}>
                        <ScoreChart 
                          colors={['#FC8763', '#fff', '#FC8763']}
                          className="axScore"
                          formatValues={(values) => `${(values)?.toFixed(1)}` }
                          data={[
                            {
                              value: scoreData?.actual_ax_score,
                            },
                            {
                              value: 0.07,
                            },
                            {
                              value: scoreData?.current_ax_score === 0 ? 0 : (10 - scoreData?.actual_ax_score),
                            },
                            {
                              value: (10 - maxScore),
                              isEmpty: true,
                            },
                          ]}
                          score={{
                            label: 'AX Score',
                            value: scoreData?.current_ax_score !== 0 ? scoreData?.current_ax_score?.toFixed(1) : scoreData?.actual_ax_score?.toFixed(1)
                          }}
                          status={scoreData?.status}
                          ruleFailures=''
                        />
                      </div>
                      <div className={`transition ease-in-out lg:mr-10 md:mr-8 md:mb-0 mb-8 duration-[1200ms] bg-white cursor-pointer border p-4 pt-2 lg:min-w-[15%] md:min-w-[25%] border-grayBorder ${filterLevel === 'A' ? 'md:scale-[1.3] scale-[1.2] bg-[#FED600]' : ''}`} onClick={() => {levelWiseFilter('A')}}>
                        <ScoreChart
                          colors={['#029B14']}
                          className="axScore"
                          data={[
                            {
                              value: scoreData?.donut?.A?.score,
                            },
                            {
                              label: '',
                              value: (10 - scoreData?.donut?.A?.score),
                              isEmpty: true,
                            },
                          ]}
                          score={{
                            label: 'Level A',
                            value: scoreData?.donut?.A?.score?.toFixed(1),
                          }}
                          status={scoreData?.donut?.A?.status}
                          ruleFailures={scoreData?.donut?.A?.rule_failures}
                        />
                      </div>
                      <div className={`transition ease-in-out lg:mr-10 md:mr-8 md:mb-0 mb-8 duration-[1200ms] bg-white cursor-pointer border p-4 pt-2 lg:min-w-[15%] md:min-w-[25%] border-grayBorder ${filterLevel === 'AA' ? 'md:scale-[1.3] scale-[1.2] bg-[#FED600]' : ''}`} onClick={() => {levelWiseFilter('AA')}}>
                        <ScoreChart 
                          colors={['#D61B1B']}
                          className="axScore"
                          data={[
                            {
                              value: scoreData?.donut?.AA?.score,
                            },
                            {
                              label: '',
                              value: (10 - scoreData?.donut?.AA?.score),
                              isEmpty: true,
                            },
                          ]}
                          score={{
                            label: 'Level AA',
                            value: scoreData?.donut?.AA?.score?.toFixed(1),
                          }}
                          status={scoreData?.donut?.AA?.status}
                          ruleFailures={scoreData?.donut?.AA?.rule_failures}
                        />
                      </div>
                      <div className={`transition ease-in-out lg:mr-10 md:mr-8 md:mb-0 mb-8 duration-[1200ms] bg-white cursor-pointer border p-4 pt-2 lg:min-w-[15%] md:min-w-[25%] border-grayBorder ${filterLevel === 'AAA' ? 'md:scale-[1.3] scale-[1.2] bg-[#FED600]' : ''}`} onClick={() => {levelWiseFilter('AAA')}}>
                        <ScoreChart 
                          colors={['#FC8763']}
                          className="axScore"
                          data={[
                            {
                              value: scoreData?.donut?.AAA?.score,
                            },
                            {
                              label: '',
                              value: (10 - scoreData?.donut?.AAA?.score),
                              isEmpty: true,
                            },
                          ]}
                          score={{
                            label: 'Level AAA',
                            value: scoreData?.donut?.AAA?.score?.toFixed(1),
                          }}
                          status={scoreData?.donut?.AAA?.status}
                          ruleFailures={scoreData?.donut?.AAA?.rule_failures}
                        />
                      </div>
                      </div>
                    </div>
                    {ruleData?.rules?.length > 0 &&
                    <div className="px-4 md:px-16 w-full overflow-x-auto pb-20">
                      <table className="w-[1100px] lg:w-full block table-scroll" {...getTableProps()}>
                        <thead className="text-left overflow-y-auto w-full block">
                        {headerGroups.map(headerGroup => (
                          <tr className="w-full flex" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th className="block basis-full text-sm capitalize px-4 py-1 pb-2" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <div className="flex items-center gap-3">
                                  {column.render('Header')}
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? 
                                      <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 6L13 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    :
                                      <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 6L7 1L13 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                  : 
                                    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M1 6L7 1L13 6" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  }
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                        </thead>
                        <tbody className="block relative overflow-y-auto overflow-visible w-full block max-h-[65vh]" {...getTableBodyProps()}>
                        {loading ?
                          <tr className="mt-16 flex flex-row justify-center items-center">
                            <td>
                              <ClipLoader
                                color={"#000000"}
                                loading={loading}
                                size={24}
                              />
                            </td>
                          </tr>
                        :
                          <>
                            {rows.map(row => {
                              prepareRow(row)
                              return (
                                <tr className="w-full flex" {...row.getRowProps()}>
                                  {row.cells.map(cell => {
                                    return (
                                      <td
                                        {...cell.getCellProps()}
                                        className={`flex items-center basis-full text-sm capitalize px-4 py-1 ${cell?.row?.cells[0]?.row?.values?.rule_level === 'Level A' ? 'levelA' :cell?.row?.cells[0]?.row?.values?.rule_level === 'Level AA' ?  'levelAA' : 'levelAAA'}`}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })}
                          </>
                        }
                        </tbody>
                      </table>
                    </div>
                    }
                  </div>
                  :
                  <SectionLoader 
                    content= {[statusFromApi]} 
                    isNoData={true}
                  />
                }
                { scoreData?.trend?.length > 0 &&
                  <>
                    <div className="flex items-center border border-grayLight bg-white">
                      <div className="text-2xl font-semibold px-6"> Trend </div>
                      <div className="flex items-center ml-auto">
                        <div className="mr-6 font-semibold text-sm text-right leading-[14px]">
                          <p>In comparision to </p>
                          <p>Audit #{comparativeData?.auditNumber}</p>
                        </div>
                        <div className="flex items-center border-l border-grayLight py-3 pr-7">
                          <svg className="ml-7 mr-2.5 w-7 h-7"  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 14.5L14 10L8 5.5V14.5ZM20 10C20 4.46 15.54 0 10 0C8.83 0 7.7 0.19 6.62 0.56L7.32 2.5C8.17 2.16 9.06 1.97 10 1.97C14.41 1.97 18.03 5.59 18.03 10C18.03 14.41 14.41 18.03 10 18.03C5.59 18.03 1.97 14.41 1.97 10C1.97 9.06 2.16 8.12 2.5 7.28L0.56 6.62C0.19 7.7 0 8.83 0 10C0 15.54 4.46 20 10 20C15.54 20 20 15.54 20 10ZM3.47 1.97C4.32 1.97 5 2.68 5 3.47C5 4.32 4.32 5 3.47 5C2.68 5 1.97 4.32 1.97 3.47C1.97 2.68 2.68 1.97 3.47 1.97Z" fill="black" />
                          </svg>
                          <div className="mt-2 hidden sm:block">
                            <p className="text-xs font-bold">Next audit schedule</p>
                            <p className="text-xs">{`${auditStatus?.next_audit_trigger_time && moment(auditStatus?.next_audit_trigger_time + '+00:00').local().format('MMMM DD, YYYY h:mmA')}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-6 grid-row-6 md:gap-x-7 gap-y-3 justify-center mt-[80px] mx-[80px] mb-10 text-xs text-center">
                      <div className="border border-grayLight px-4 py-6 bg-white">
                        <p className="font-medium"> New Rule <br/> Violations </p> 
                        <div className="flex items-center w-20 h-20 bg-salmon rounded-full mx-auto my-3 rotate-180">
                          <MdInfoOutline className="text-6xl m-auto text-white font-semibold" />
                        </div>
                        <span className="text-2xl font-bold text-cyanBlue">{comparativeData?.comparative_analysis_data?.new_rule_violations?.count || 0}</span>
                        <p>({ parseInt(comparativeData?.comparative_analysis_data?.new_rule_violations?.ratio) || 0}%)</p>
                        <Link to={`/website/${id}/reports/fullReport/${comparativeData?.jobId}/${comparativeData?.comparative_analysis_data?.new_rule_violations?.rules}`}>
                          <div className="border border-grayLight font-semibold cursor-pointer mt-2"> Know More </div>
                        </Link>
                      </div>
                      <div className="border border-grayLight px-4 py-6 bg-white">
                        <p className="font-medium"> Stopped Rule <br/> Violations </p> 
                        <div className="flex items-center w-20 h-20 bg-limeGreen rounded-full mx-auto my-3">
                          <MdCheckCircleOutline className="text-6xl m-auto text-white font-semibold" />
                        </div>
                        <span className="text-2xl font-bold text-cyanBlue">{comparativeData?.comparative_analysis_data?.stopped_rule_violations?.count || 0}</span>
                        <p>({parseInt(comparativeData?.comparative_analysis_data?.stopped_rule_violations?.ratio) || 0}%)</p>
                        <Link to={`/website/${id}/reports/fullReport/${comparativeData?.jobId}/${comparativeData?.comparative_analysis_data?.stopped_rule_violations?.rules}`}>
                          <div className="border border-grayLight font-semibold cursor-pointer mt-2"> Know More </div>
                        </Link>
                        </div>
                      <div className="md:col-span-4 border border-grayLight pt-10 bg-white">
                        <Chart className="max-h-56" options={fixesRatioOptions} data={auditData} />
                      </div>
                    </div>
                  </> 
                }
              </>
              }
            </section>
            }
            </>
          :
          <SectionLoader 
            content= {[statusFromApi]} 
            isNoData={true}
          />
          }
        </>
      }
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    pageLoader: state?.dashboard?.loading,
    selectedOrg: state?.dashboard?.data?.organizations?.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ),
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
    isTempPassword: state.auth.isTempPassword,
    updateElementLoading: state.dashboard.updateElementLoading,
    auditStage: state.dashboard.auditStage,
    auditStatus: state.dashboard.auditStatus,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  fetchReport,
  fetchPageReport,
  triggerLogout: logOut,
  fetchElementsList: getElementsList,
  triggerUpdateElement: updateElement,
  triggerAuditData: getAuditScore,
  triggerAuditRule: getAuditRule,
  triggerAuditStage: getAuditStage,
  triggerActiveSubscription: getActiveSubscription,
  triggerGetWebsiteSettings: getWebsiteSettings,
  triggerAutoApplyUpdates: editWebsiteSettings,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
