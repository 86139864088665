// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import posthog from 'posthog-js'

// components
import DefaultPage from "../../../components/layout/defaultPage";
import GeneralInfo from "./generalInfo";
import Users from "./users";
import Audits from "./audits";
// utils
import ScreenIsMobile from "../../../utils/ScreenIsMobile";

// actions
import MobileHeader from "../../../components/MobileHeader";

const Settings = (props) => {
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  const { userProfile, selectedOrg, roleId } = props;
  const { id } = useParams();

  const [openTab, setOpenTab] = useState(1);
  const [currentAuditJobId, setCurrentAuditJobId] = useState('')

  // chuck off the first 8 chars that has "https://"
  let currentSiteName = selectedOrg && selectedOrg?.websites?.find((w) => w.id.toString() === id)?.name;
  let currentSite =
    selectedOrg &&
    selectedOrg?.websites?.find((w) => w.id.toString() === id)?.url;
  if (currentSite?.includes("https")) {
    currentSite = currentSite.slice().slice(8);
  } else if (currentSite?.includes("http")) {
    currentSite = currentSite.slice().slice(7);
  }

  // if the last character of website is "/", chuck it off
  if (currentSite?.length > 0 && currentSite[currentSite.length - 1] === "/") {
    currentSite = currentSite.slice(0, currentSite.length - 1);
  }
  useEffect(() => {
    posthog.capture('Clicked settings', { property: 'value' })
  }, [])

  const navigateBack = () => {
    if (currentAuditJobId) {
      return navigate(`/website/${id}/reports/${currentAuditJobId}`);
    }
    navigate(-1);
  }

  const newRunAudit = (jobId) => {
    setCurrentAuditJobId(jobId)
  }
  
  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section>
      {isMobile ?
          <>
            <MobileHeader
              title={currentSiteName}
              backUrl={true}
            />
          </>
          :
          <div className="border-b border-grayLight px-6 py-4 font-semibold flex items-center text-xl">
            <button onClick={() => navigateBack()}><BsArrowLeft className="mr-4" /></button> {currentSiteName || "N/A"}
          </div>
        }
        <div className="flex flex-wrap">
          <div className="w-full sm:flex h-91">
            <div className="sm:w-80 sm:block sm:border-b-0 sm:border-r border-b flex justify-center border-grayLight" role="tablist">
              <div className={"text-center"+ (openTab === 1? "text-white bg-primary": "text-blockbg-white")}>
                <a
                  className={
                    "group flex items-center justify-between text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white "+ (openTab === 1? "text-white": "text-block bg-white")
                }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#generalInfo"
                  role="tablist"
                >
                  General Info{" "}
                  <BsArrowRight
                    className={`text-xl md:group-hover:block ${
                      openTab === 1 ? "md:block hidden" : "hidden"
                    } `}
                  />
                </a>
              </div>
              {roleId === 0 &&
              <div className={"text-center" + (openTab === 2 ? "text-white bg-primary" : "bg-white")}>
                <a
                  className={
                    "group flex items-center justify-between text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " + (openTab === 2? "text-white": "text-block bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#users"
                  role="tablist"
                >
                  Users{" "}
                  <BsArrowRight
                    className={`text-xl md:group-hover:block ${
                      openTab === 2 ? "md:block hidden" : "hidden"
                    } `}
                  />
                </a>
              </div>
              }
              <div className={"text-center" +
                    (openTab === 3 ? "text-white bg-primary" : "bg-white")}>
                <a
                  className={
                    "group flex items-center justify-between text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white "+ (openTab === 3? "text-white": "text-block bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#audits"
                  role="tablist"
                >
                  Audits{" "}
                  <BsArrowRight
                    className={`text-xl md:group-hover:block ${
                      openTab === 3 ? "md:block hidden" : "hidden"
                    } `}
                  />
                </a>
              </div>
            </div>
            <div className="break-words bg-white w-full mb-6 rounded">
              <div className="py-8 sm:py-5">
                <div className="tab-content tab-space overflow-x-hidden overflow-y-auto h-80">
                  <div className={`${openTab === 1 ? "block" : "hidden"} px-4`} id="generalInfo">
                    <GeneralInfo/>
                  </div>
                  <div className={`${openTab === 2 ? "block" : "hidden"} px-4`} id="users">
                    <Users/>
                  </div>
                  <div className={`${openTab === 3 ? "block" : "hidden"} px-4 sm:px-0`} id="audits">
                    <Audits newRunAudit={newRunAudit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state, props) => {
  return {
    userProfile: state.dashboard.userProfile,
    selectedOrg: state.dashboard.data?.organizations.find(
      (o) => o.id === state.dashboard.selectedOrganization
    ) || '',
    selectedOrgId: state.dashboard.selectedOrganization,
    roleId: state.auth.roleId,
  };
};

// exports
export default connect(mapStateToProps, {})(Settings);
