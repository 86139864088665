/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// library imports
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

// components
import DefaultPage from "../../../components/layout/defaultPage";
import BasicDetails from "./basicDetails";
import Websites from "./websites";

// utils
import ScreenIsMobile from "../../../utils/ScreenIsMobile";

// Actions
import { updateOrg } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";
import MobileHeader from "../../../components/MobileHeader";

const EditOrganization = (props) => {
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  // destructure props
  const { userProfile, orgsList } = props;

  const { id } = useParams();
  const [openTab, setOpenTab] = React.useState(1);
  const currentOrgData = orgsList.find(org => org.id === parseInt(id));

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section>
        {isMobile ?
          <>
            <MobileHeader
              title={currentOrgData?.name}
              backUrl={true}
            />
          </>
          :
          <div className="border-b border-grayLight px-6 py-4 font-semibold flex items-center text-xl">
            <button onClick={() => navigate(-1)}><BsArrowLeft className="mr-4" /></button> 
            {currentOrgData.name}
          </div>
        }
        <div className="flex flex-wrap">
          <div className="w-full sm:flex h-91">
            <div className="sm:w-80 flex justify-center sm:block border-b sm:border-b-0 sm:border-r border-grayLight" role="tablist" >
              <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                    (openTab === 1 ? "text-white bg-primary" : "text-blockbg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(1);}}
                  data-toggle="tab"
                  href="#basicDetails"
                  role="tablist"
                >
                  {'General Info'}<BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 1 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
              <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                    (openTab === 2 ? "text-white bg-primary" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(2); }}
                  data-toggle="tab"
                  href="#websites"
                  role="tablist"
                >
                  {'Sites'}<BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 2 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
            </div>
            <div className="break-words bg-white w-full mb-6 rounded">
              <div className="tab-content tab-space overflow-x-hidden overflow-y-auto h-80 sm:h-91 px-4 py-8 sm:py-5">
                  <div className={openTab === 1 ? "block" : "hidden"} id="basicDetails">
                      <BasicDetails
                      currentOrgData = {currentOrgData}
                      />
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="basicDetails">
                      <Websites
                      currentOrgData = {currentOrgData}
                      />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultPage>
  );
};

const mapStateToProps = (state) => {
  return {
    editOrgLoading: state.dashboard.editOrgLoading,
    userProfile: state.dashboard.userProfile,
    orgsList: state.dashboard.data?.organizations || [],
    selectedOrganization: state.dashboard?.data?.selectedOrganization
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateOrg: updateOrg,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);
