/* eslint-disable array-callback-return */
// library imports
import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";


// components
import DefaultPage from "../../components/layout/defaultPage";

// utils
import ScreenIsMobile from "../../utils/ScreenIsMobile";
// svg

// actions
import { getUsers, editUser } from "../../store/actions/dashboard";
import { logOut } from "../../store/actions/auth";
import EditUser from "../../components/users/EditUser";
import Security from "../../components/users/security";
import SitePermissions from "../../components/users/sitePermissions";
import MobileHeader from "../../components/MobileHeader";

/**
 * @function UserDetails
 * @param {Object} props
 * @returns {JSX}
 */
const UsersDetail = (props) => {
  const navigate = useNavigate();
  const isMobile = ScreenIsMobile();
  const {
    currentOrgUsers,
    userProfile,
    currentOrgObject
  } = props;

  const { id } = useParams();
  const openTabInitialValue = Number(id) === userProfile.id ? 3 : 1
  const [openTab, setOpenTab] = React.useState(openTabInitialValue);
  const userData = currentOrgUsers.find(val => val.id === Number(id))
  const loggedInUser = Number(id) === userProfile.id

  return (
    <DefaultPage userProfile={userProfile} showSidebar={true}>
      <section>
        {isMobile ?
          <>
            <MobileHeader
              title={userData.firstName + ' ' + userData.lastName}
              backUrl={true}
            />
          </>
          :
          <div className="border-b border-grayLight px-6 py-4 font-semibold flex items-center text-xl">
            <button onClick={() => navigate(-1)}><BsArrowLeft className="mr-4" /></button>
            {userData.firstName + ' ' + userData.lastName}
          </div>
        }
        <div className="flex flex-wrap">
          <div className="w-full sm:flex h-91">
            <div className="sm:w-80 flex justify-center sm:block border-b sm:border-b-0 sm:border-r border-grayLight" role="tablist" >
              {!loggedInUser && <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                    (openTab === 1 ? "text-white bg-primary" : "text-blockbg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(1);}}
                  data-toggle="tab"
                  href="#generalInfo"
                  role="tablist"
                >
                  General Info <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 1 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>}
              <div className="text-center">
                <a
                  className={
                    "group flex items-center justify-between text-xs sm:text-base font-bold uppercase px-5 py-3 leading-normal hover:bg-primary hover:text-white " +
                    (openTab === 3 ? "text-white bg-primary" : "bg-white")
                  }
                  onClick={e => { e.preventDefault(); setOpenTab(3); }}
                  data-toggle="tab"
                  href="#sitePermissions"
                  role="tablist"
                >
                  Site Permissions <BsArrowRight className={`text-xl hidden sm:group-hover:block ${openTab === 3 ? 'sm:block' : 'hidden'} `} />
                </a>
              </div>
            </div>
            <div className="break-words bg-white w-full mb-6 rounded">
              <div className="py-8 sm:py-5">
                <div className="tab-content tab-space overflow-x-hidden overflow-y-auto h-80 sm:h-91 px-4">
                  {!loggedInUser && <div className={openTab === 1 ? "block" : "hidden"} id="generalInfo">
                    <EditUser
                      userData={userData}
                      websites={currentOrgObject?.websites}
                    />
                  </div>}
                  <div className={openTab === 2 ? "block" : "hidden"} id="security">
                    <Security
                      userData={userData}
                      websites={currentOrgObject?.websites}
                    />
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="sitePermissions">
                    <SitePermissions isTabVisible = {openTab === 3 || loggedInUser} userData={userData}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultPage>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    currentOrgObject:
      state?.dashboard?.data?.organizations?.length > 0
        ? [...state.dashboard.data?.organizations].find(
            (o) => o.id === state.dashboard.selectedOrganization
          )
        : null,
    currentOrgUsers: state.dashboard.currentOrgUsers,
    selectedOrgId: state.dashboard.selectedOrganization,
    userProfile: state.dashboard.userProfile,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerGetUsers: getUsers,
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(UsersDetail);
