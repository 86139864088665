// user imports
import actionTypes from "../types";

// destructuring
const {
  DASHBOARD,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  SELECT_ORG,
  CREATE_WEBSITE,
  CREATE_WEBSITE_SUCCESS,
  CREATE_WEBSITE_ERROR,
  CREATE_ORG,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_ERROR,
  UPDATE_ORG,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_WEBSITES,
  UPDATE_ORG_WEBSITES_SUCCESS,
  UPDATE_ORG_WEBSITES_ERROR,
  REMOVE_ORG_WEBSITES,
  REMOVE_ORG_WEBSITES_SUCCESS,
  REMOVE_ORG_WEBSITES_ERROR,
  REPORT,
  REPORT_SUCCESS,
  REPORT_ERROR,
  PAGE_REPORT,
  PAGE_REPORT_SUCCESS,
  PAGE_REPORT_ERROR,
  AUDIT,
  AUDIT_SUCCESS,
  AUDIT_ERROR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  TOGGLE_SIDEBAR,
  TOGGLE_HAMBURGER,
  CLOSE_HAMBURGER,
  MAP_USER_WEBSITES,
  MAP_USER_WEBSITES_SUCCESS,
  MAP_USER_WEBSITES_ERROR,
  GET_ELEMENTS_LIST,
  GET_ELEMENTS_LIST_SUCCESS,
  GET_ELEMENTS_LIST_ERROR,
  GET_ELEMENT_OCCURRENCE,
  GET_ELEMENT_OCCURRENCE_SUCCESS,
  GET_ELEMENT_OCCURRENCE_ERROR,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_SUCCESS,
  UPDATE_ELEMENT_ERROR,
  GET_DASHOARD_DATA,
  GET_DASHOARD_DATA_SUCCESS,
  GET_DASHOARD_DATA_ERROR,
  GET_SITE_ANALYTICS_DATA,
  GET_SITE_ANALYTICS_DATA_ERROR,
  GET_SITE_ANALYTICS_DATA_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  GET_WEBSITE_SETTINGS,
  GET_WEBSITE_SETTINGS_SUCCESS,
  GET_WEBSITE_SETTINGS_ERROR,
  EDIT_WEBSITE_SETTINGS,
  EDIT_WEBSITE_SETTINGS_SUCCESS,
  EDIT_WEBSITE_SETTINGS_ERROR,
  RESTORE_WEBSITE,
  RESTORE_WEBSITE_SUCCESS,
  RESTORE_WEBSITE_ERROR,
  GET_AUDIT_DATA,
  GET_AUDIT_DATA_SUCCESS,
  GET_AUDIT_DATA_ERROR,
  AUDIT_STAGE,
  GET_AUDIT_STAGE,
  GET_AUDIT_STAGE_SUCCESS,
  GET_AUDIT_STAGE_ERROR,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_SUCCESS,
  GET_ACTIVE_SUBSCRIPTION_ERROR,
  GET_WORKSPACE_SITES,
  GET_WORKSPACE_SITES_SUCCESS,
  GET_WORKSPACE_SITES_ERROR,
  GET_SITE_DATA,
  GET_SITE_DATA_SUCCESS,
  GET_SITE_DATA_ERROR,
  EDIT_WORKSPACE,
  EDIT_WORKSPACE_SUCCESS,
  EDIT_WORKSPACE_ERROR,
  GET_WORKSPACE_SITE_DATA,
  GET_WORKSPACE_SITE_DATA_SUCCESS,
  GET_WORKSPACE_SITE_DATA_ERROR,
  GET_WORKSPACE_USERS,
  GET_WORKSPACE_USERS_SUCCESS,
  GET_WORKSPACE_USERS_ERROR,
  SHARE_REPORT,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_ERROR,
  GET_AUDIT_SCORE,
  GET_AUDIT_SCORE_SUCCESS,
  GET_AUDIT_SCORE_ERROR,
  GET_AUDIT_RULE,
  GET_AUDIT_RULE_SUCCESS,
  GET_AUDIT_RULE_ERROR,
  GET_SIGNATURE,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_ERROR
} = actionTypes.dashboard;

export const fetchDashboard = (payload) => {
  return {
    type: DASHBOARD,
    payload,
  };
};

export const fetchDashboardSuccess = (response) => {
  return {
    type: DASHBOARD_SUCCESS,
    response,
  };
};

export const fetchDashboardError = (error) => {
  return {
    type: DASHBOARD_ERROR,
    error,
  };
};

export const selectOrganization = (payload) => {
  return {
    type: SELECT_ORG,
    payload,
  };
};

export const createWebsite = (payload) => {
  return {
    type: CREATE_WEBSITE,
    payload,
  };
};

export const createWebsiteSuccess = (response) => {
  return {
    type: CREATE_WEBSITE_SUCCESS,
    response,
  };
};

export const createWebsiteError = (error) => {
  return {
    type: CREATE_WEBSITE_ERROR,
    error,
  };
};

export const createOrg = (payload) => {
  return {
    type: CREATE_ORG,
    payload,
  };
};

export const createOrgSuccess = (response) => {
  return {
    type: CREATE_ORG_SUCCESS,
    response,
  };
};

export const createOrgError = (error) => {
  return {
    type: CREATE_ORG_ERROR,
    error,
  };
};

export const updateOrg = (payload) => {
  return {
    type: UPDATE_ORG,
    payload,
  };
};

export const updateOrgSuccess = (response) => {
  return {
    type: UPDATE_ORG_SUCCESS,
    response,
  };
};

export const updateOrgError = (error) => {
  return {
    type: UPDATE_ORG_ERROR,
    error,
  };
};

export const updateOrgwebsites = (payload) => {
  return {
    type: UPDATE_ORG_WEBSITES,
    payload,
  };
};

export const updateOrgWebsitesSuccess = (response) => {
  return {
    type: UPDATE_ORG_WEBSITES_SUCCESS,
    response,
  };
};

export const updateOrgWebsitesError = (error) => {
  return {
    type: UPDATE_ORG_WEBSITES_ERROR,
    error,
  };
};

export const removeOrgwebsites = (payload) => {
  return {
    type: REMOVE_ORG_WEBSITES,
    payload,
  };
};

export const removeOrgWebsitesSuccess = (response) => {
  return {
    type: REMOVE_ORG_WEBSITES_SUCCESS,
    response,
  };
};

export const removeOrgWebsitesError = (error) => {
  return {
    type: REMOVE_ORG_WEBSITES_ERROR,
    error,
  };
};

export const fetchReport = (payload) => {
  return {
    type: REPORT,
    payload,
  };
};

export const fetchReportSuccess = (response) => {
  return {
    type: REPORT_SUCCESS,
    response,
  };
};

export const fetchReportError = (error) => {
  return {
    type: REPORT_ERROR,
    error,
  };
};

export const fetchPageReport = (payload) => {
  return {
    type: PAGE_REPORT,
    payload,
  };
};

export const fetchPageReportSuccess = (response) => {
  return {
    type: PAGE_REPORT_SUCCESS,
    response,
  };
};

export const fetchPageReportError = (error) => {
  return {
    type: PAGE_REPORT_ERROR,
    error,
  };
};

export const runAudit = (payload) => {
  return {
    type: AUDIT,
    payload,
  };
};

export const runAuditSuccess = (response) => {
  return {
    type: AUDIT_SUCCESS,
    response,
  };
};

export const runAuditError = (error) => {
  return {
    type: AUDIT_ERROR,
    error,
  };
};

export const createUser = (payload) => {
  return {
    type: CREATE_USER,
    payload,
  };
};

export const createUserSuccess = (response) => {
  return {
    type: CREATE_USER_SUCCESS,
    response,
  };
};

export const createUserError = (error) => {
  return {
    type: CREATE_USER_ERROR,
    error,
  };
};

export const getUserProfile = (payload) => {
  return {
    type: GET_USER_PROFILE,
    payload,
  };
};

export const getUserProfileSuccess = (response) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    response,
  };
};

export const getUserProfileError = (error) => {
  return {
    type: GET_USER_PROFILE_ERROR,
    error,
  };
};

export const getUsers = (payload) => {
  return {
    type: GET_USERS,
    payload,
  };
};

export const getUsersSuccess = (response) => {
  return {
    type: GET_USERS_SUCCESS,
    response,
  };
};

export const getUsersError = (error) => {
  return {
    type: GET_USERS_ERROR,
    error,
  };
};

export const editUser = (payload) => {
  return {
    type: EDIT_USER,
    payload,
  };
};

export const editUserSuccess = (response) => {
  return {
    type: EDIT_USER_SUCCESS,
    response,
  };
};

export const editUserError = (error) => {
  return {
    type: EDIT_USER_ERROR,
    error,
  };
};

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
  };
};

export const toggleHamburger = () => {
  return {
    type: TOGGLE_HAMBURGER,
  };
};

export const closeHamburger = () => {
  return {
    type: CLOSE_HAMBURGER,
  };
};

export const mapUserWebsites = (payload) => {
  return {
    type: MAP_USER_WEBSITES,
    payload,
  };
};

export const mapUserWebsitesSuccess = (response) => {
  return {
    type: MAP_USER_WEBSITES_SUCCESS,
    response,
  };
};

export const mapUserWebsitesError = (error) => {
  return {
    type: MAP_USER_WEBSITES_ERROR,
    error,
  };
};

export const getElementsList = (payload) => {
  return {
    type: GET_ELEMENTS_LIST,
    payload,
  };
};

export const getElementsListSuccess = (response) => {
  return {
    type: GET_ELEMENTS_LIST_SUCCESS,
    response,
  };
};

export const getElementsListError = (error) => {
  return {
    type: GET_ELEMENTS_LIST_ERROR,
    error,
  };
};

export const getElementOccurrence = (payload) => {
  return {
    type: GET_ELEMENT_OCCURRENCE,
    payload,
  };
};

export const getElementOccurrenceSuccess = (response) => {
  return {
    type: GET_ELEMENT_OCCURRENCE_SUCCESS,
    response,
  };
};

export const getElementOccurrenceError = (error) => {
  return {
    type: GET_ELEMENT_OCCURRENCE_ERROR,
    error,
  };
};

export const updateElement = (payload) => {
  return {
    type: UPDATE_ELEMENT,
    payload,
  };
};

export const updateElementSuccess = (response) => {
  return {
    type: UPDATE_ELEMENT_SUCCESS,
    response,
  };
};

export const updateElementError = (error) => {
  return {
    type: UPDATE_ELEMENT_ERROR,
    error,
  };
};

export const getDashboardData = (payload) => {
  return {
    type: GET_DASHOARD_DATA,
    payload,
  };
};

export const getDashboardDataSuccess = (response) => {
  return {
    type: GET_DASHOARD_DATA_SUCCESS,
    response,
  };
};

export const getDashboardDataError = (error) => {
  return {
    type: GET_DASHOARD_DATA_ERROR,
    error,
  };
};

export const getSiteAnalyticsData = (payload) => {
  return {
    type: GET_SITE_ANALYTICS_DATA,
    payload,
  };
};

export const getSiteAnalyticsDataSuccess = (response) => {
  return {
    type: GET_SITE_ANALYTICS_DATA_SUCCESS,
    response,
  };
};

export const getSiteAnalyticsDataError = (error) => {
  return {
    type: GET_SITE_ANALYTICS_DATA_ERROR,
    error,
  };
};

export const verifyUser = (payload) => {
  return {
    type: VERIFY_USER,
    payload,
  };
};

export const verifyUserSuccess = (response) => {
  return {
    type: VERIFY_USER_SUCCESS,
    response,
  };
};

export const verifyUserError = (error) => {
  return {
    type: VERIFY_USER_ERROR,
    error,
  };
};

export const getWebsiteSettings = (payload) => {
  return {
    type: GET_WEBSITE_SETTINGS,
    payload,
  };
};

export const getWebsiteSettingsSuccess = (response) => {
  return {
    type: GET_WEBSITE_SETTINGS_SUCCESS,
    response,
  };
};

export const getWebsiteSettingsError = (error) => {
  return {
    type: GET_WEBSITE_SETTINGS_ERROR,
    error,
  };
};

export const editWebsiteSettings = (payload) => {
  return {
    type: EDIT_WEBSITE_SETTINGS,
    payload,
  };
};

export const editWebsiteSettingsSuccess = (response) => {
  return {
    type: EDIT_WEBSITE_SETTINGS_SUCCESS,
    response,
  };
};

export const editWebsiteSettingsError = (error) => {
  return {
    type: EDIT_WEBSITE_SETTINGS_ERROR,
    error,
  };
};

export const restoreWebsite = (payload) => {
  return {
    type: RESTORE_WEBSITE,
    payload,
  };
};

export const restoreWebsiteSuccess = (response) => {
  return {
    type: RESTORE_WEBSITE_SUCCESS,
    response,
  };
};

export const restoreWebsiteError = (error) => {
  return {
    type: RESTORE_WEBSITE_ERROR,
    error,
  };
};

export const getAuditData = (payload) => {
  return {
    type: GET_AUDIT_DATA,
    payload,
  };
};

export const getAuditDataSuccess = (response) => {
  return {
    type: GET_AUDIT_DATA_SUCCESS,
    response,
  };
};

export const getAuditDataError = (error) => {
  return {
    type: GET_AUDIT_DATA_ERROR,
    error,
  };
};

export const getAuditScore = (payload) => {
  return {
    type: GET_AUDIT_SCORE,
    payload,
  };
};

export const getAuditScoreSuccess = (response) => {
  return {
    type: GET_AUDIT_SCORE_SUCCESS,
    response,
  };
};

export const getAuditScoreError = (error) => {
  return {
    type: GET_AUDIT_SCORE_ERROR,
    error,
  };
};

export const getAuditRule = (payload) => {
  return {
    type: GET_AUDIT_RULE,
    payload,
  };
};

export const getAuditRuleSuccess = (response) => {
  return {
    type: GET_AUDIT_RULE_SUCCESS,
    response,
  };
};

export const getAuditRuleError = (error) => {
  return {
    type: GET_AUDIT_RULE_ERROR,
    error,
  };
};

export const auditStage = (payload) => {
  return {
    type: AUDIT_STAGE,
    payload
  };
};

export const getAuditStage = (payload) => {
  return {
    type: GET_AUDIT_STAGE,
    payload,
  };
};

export const getAuditStageSuccess = (response) => {
  return {
    type: GET_AUDIT_STAGE_SUCCESS,
    response,
  };
};

export const getAuditStageError = (error) => {
  return {
    type: GET_AUDIT_STAGE_ERROR,
    error,
  };
};

export const getActiveSubscription = (payload) => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION,
    payload,
  };
};

export const getActiveSubscriptionSuccess = (response) => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION_SUCCESS,
    response,
  };
};

export const getActiveSubscriptionError = (error) => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION_ERROR,
    error,
  };
};

export const getWorkspaceSites = (payload) => {
  return {
    type: GET_WORKSPACE_SITES,
    payload,
  };
};

export const getWorkspaceSitesSuccess = (response) => {
  return {
    type: GET_WORKSPACE_SITES_SUCCESS,
    response,
  };
};

export const getWorkspaceSitesError = (error) => {
  return {
    type: GET_WORKSPACE_SITES_ERROR,
    error,
  };
};

export const getSiteData = (payload) => {
  return {
    type: GET_SITE_DATA,
    payload,
  };
};

export const getSiteDataSuccess = (response) => {
  return {
    type: GET_SITE_DATA_SUCCESS,
    response,
  };
};

export const getSiteDataError = (error) => {
  return {
    type: GET_SITE_DATA_ERROR,
    error,
  };
};

export const editWorkspace = (payload) => {
  return {
    type: EDIT_WORKSPACE,
    payload,
  };
};

export const editWorkspaceSuccess = (response) => {
  return {
    type: EDIT_WORKSPACE_SUCCESS,
    response,
  };
};

export const editWorkspaceError = (error) => {
  return {
    type: EDIT_WORKSPACE_ERROR,
    error,
  };
};

export const getWorkspaceSiteData = (payload) => {
  return {
    type: GET_WORKSPACE_SITE_DATA,
    payload,
  };
};

export const getWorkspaceSiteDataSuccess = (response) => {
  return {
    type: GET_WORKSPACE_SITE_DATA_SUCCESS,
    response,
  };
};

export const getWorkspaceSiteDataError = (error) => {
  return {
    type: GET_WORKSPACE_SITE_DATA_ERROR,
    error,
  };
};

export const getWorkspaceUsers = (payload) => {
  return {
    type: GET_WORKSPACE_USERS,
    payload,
  };
};

export const getWorkspaceUsersSuccess = (response) => {
  return {
    type: GET_WORKSPACE_USERS_SUCCESS,
    response,
  };
};

export const getWorkspaceUsersError = (error) => {
  return {
    type: GET_WORKSPACE_USERS_ERROR,
    error,
  };
};

export const shareReport = (payload) => {
  return {
    type: SHARE_REPORT,
    payload,
  };
};

export const shareReportSuccess = (response) => {
  return {
    type: SHARE_REPORT_SUCCESS,
    response,
  };
};

export const shareReportError = (error) => {
  return {
    type: SHARE_REPORT_ERROR,
    error,
  };
};

export const getSignature = (payload) => {
  return {
    type: GET_SIGNATURE,
    payload,
  };
};

export const getSignatureSuccess = (response) => {
  return {
    type: GET_SIGNATURE_SUCCESS,
    response,
  };
};

export const getSignatureError = (error) => {
  return {
    type: GET_SIGNATURE_ERROR,
    error,
  };
};
