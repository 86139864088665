// library imports
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// user imports
import store from "./store/store";

// user imports
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

/**
 * @function render
 * @description Top level method where the entire react app is injected inside the div with id 'root' in index.html
 */
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
