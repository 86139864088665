const colorPalette = {
  lightGrey: "#c4c4c4",
  white: "#ffffff",
  black: "#000000",
  manganeseBlack: "#000000",
  darkLimeGreen: "#1b8d0c",
  dimGray: "#6C6C6C",
  cyanBlue: "#1F3B6F",
  bluishGreen: "#6AC7A5",
  salmon: "#FC8763",
  supernova: "#FAC403",
  nero: "#242424",
  ghost: "#C7CCD5",
  blueBayoux: "#4C6188",
  midnightExpress: "#0F1F3E",
};

export default colorPalette;
