/* eslint-disable array-callback-return */
// library imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import posthog from 'posthog-js'

// Actions
import { editUser, getWebsiteSettings, editWebsiteSettings } from "../../../store/actions/dashboard";
import { logOut } from "../../../store/actions/auth";
import PieLoader from "../../../components/PieLoader";
import { useParams, useNavigate } from "react-router-dom";
import { BsFillExclamationSquareFill } from "react-icons/bs";

// token expired handler
import TokenExpired from "../../../utils/TokenExpired";

/**
 * @function ViewUser
 * @returns {JSX}
 */
const GeneralInfo = (props) => {
  const navigate = useNavigate();
  posthog.init('phc_HApzVimArr2wjXzSywrUeTf3rVaB6JBUD87G16OyDIe', { api_host: 'https://app.posthog.com' })
  const { triggerGetWebsiteSettings, triggerEditWebsiteSettings, triggerLogout, activeSubscription, orgsList,selectedOrgId } = props;
  const { id } = useParams();
  const [autoApply, setAutoApply] = useState(true);
  const [status, setStatus] = useState(true);
  const [archive, setArchive] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [websiteSettingsInfo, setWebsiteSettingsInfo] = useState({})
  const isCurrentWebsiteActive = orgsList.find(i => i.id === selectedOrgId).isActive
  
  useEffect(() => {
    triggerGetWebsiteSettings({ 
      payload: {
        id: id,
      },
      onSuccess: (res) => {
        setWebsiteSettingsInfo(res)
      }, 
      onError: () => {} 
    });
  }, [id, triggerGetWebsiteSettings]);

  const [gInfo, setGInfo] = useState({
    siteShortName: websiteSettingsInfo.name,
    frequency: websiteSettingsInfo.frequency,
  });

  useEffect(() => {
    setGInfo({
      siteShortName: websiteSettingsInfo.name,
      frequency: websiteSettingsInfo.frequency,
    })
    setAutoApply(websiteSettingsInfo?.auto_apply_fixes);
    setStatus(websiteSettingsInfo?.is_active);
    setArchive(websiteSettingsInfo?.isDeleted);
  }, [websiteSettingsInfo]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  const onChangeHandler = (e) => {
    setErrorFromApi(null);
    setGInfo({
      ...gInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onSuccess = (res) => {
    if((websiteSettingsInfo?.auto_apply_fixes !== res?.auto_apply_fixes)) {
      if(res?.auto_apply_fixes) {
        posthog.capture('Apply Wally Fixes Turned ON', { property: 'value' })
      } else {
        posthog.capture('Apply Wally Fixes Turned OFF', { property: 'value' })
      }
    }
    setErrorFromApi({
      type: "success",
      msg: "General Info has been updated successfully !",
    });
    setWebsiteSettingsInfo(res);
    setLoading(false);
    setTimeout(() => {
      setErrorFromApi(null);
      if(res?.isDeleted) {
        navigate(-1)
      }
    }, 3000);
  };

  const onError = (errorMsg) => {
    setLoading(false);
    setErrorFromApi({
      type: "error",
      msg: errorMsg || "Something went wrong!!!",
    });
    setTimeout(() => {
      setErrorFromApi(null);
    }, 3000);
    if (errorMsg === "Token expired") {
      TokenExpired();
      setTimeout(() => {
        triggerLogout();
      }, 1500);
    }
    setLoading(false);
  };

  const submit = (e) => {
    if(isCurrentWebsiteActive){
      setLoading(true);
      if (errors.siteShortName) {
        toast.dismiss();
        setLoading(false);
        toast.error("Kindly fill basic details");
        return;
      }
      const payload = {
        id: id
      }
      const payload_1 = {
          name: gInfo.siteShortName,
          frequency: gInfo.frequency,
          is_active: status,
          auto_apply_fixes: autoApply,
          isDeleted: archive
      }
      triggerEditWebsiteSettings({ payload, payload_1, onSuccess, onError });
    }
  };
  const frequencyOptionsFree = [
    "monthly"
  ];
  const frequencyOptionsPro = [
    "weekly","monthly"
  ];
  const frequencyOptionsUnlimited = [
    "daily","weekly","monthly"
  ];

  const isUpdated = 
  gInfo.siteShortName !== websiteSettingsInfo.name ||
  gInfo.frequency !== websiteSettingsInfo.frequency ||
  autoApply !== websiteSettingsInfo.auto_apply_fixes ||
  status !== websiteSettingsInfo.is_active ||
  archive !== websiteSettingsInfo.isDeleted

  return (
    <section>
      <div id="modal" className="text-black">
        <div className="overflow-x-hidden outline-none focus:outline-none">
          <div className="relative sm:p-4">
            <div className="w-full sm:max-w-md">
              <form onSubmit={handleSubmit(submit)} className="w-full">
                <>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-base text-black font-bold mb-2">
                      Site short name{" "}
                      {/* <span className="text-lg text-red-500 font-bold">
                        {"*"}
                      </span> */}
                    </div>
                    <input
                      value={gInfo?.siteShortName}
                      type="text"
                      className={`w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] py-2 px-3 font-medium focus:border-primary ${
                        errors.siteShortName? "border-red-500": "border-gray"
                      }`}
                      id="siteShortName"
                      name="siteShortName"
                      placeholder="Enter site short name"
                      {...register("siteShortName", {
                        maxLength: {
                          value: 40,
                          message: "* Maximum 40 characters allowed",
                        },
                        minLength: {
                          value: 2,
                          message: "* Minimum 2 characters required",
                        },
                        pattern: {
                          value: /^[A-Za-z0-9 ]+$/,
                          message: "* Only alphanumeric characters allowed",
                        },
                      })}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {errors.siteShortName && (
                      <div className="text-left text-xs -mb-2 text-red-500 font-semibold">
                        {errors.siteShortName.message}
                      </div>
                    )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-base text-black font-bold mb-2">
                      Status
                    </div>
                    <div className="flex items-center">
                      <div
                        className={`h-6 w-12 p-1 cursor-pointer border ${
                          status
                            ? "border-primary bg-aliceBlue"
                            : "border-grayNobel bg-white"
                        }`}
                        onClick={() => {
                          setStatus(!status);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <div
                          className={`h-4 w-4 transform duration-300 ease-in-out ${
                            status
                              ? "bg-primary transform translate-x-6"
                              : "bg-grayNobel "
                          } `}
                        ></div>
                      </div>
                      <p className={`font-normal pl-2 ${!status && 'text-grayDeep'}`}>
                        {status ? "Active" : "Inactive"}
                      </p>
                    </div>
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-base text-black font-bold mb-2">
                      Apply Wally Fixes
                    </div>
                    <div className="flex items-center">
                      <div
                        className={`h-6 w-12 p-1 cursor-pointer border ${
                          autoApply
                            ? "border-primary bg-aliceBlue"
                            : "border-grayNobel bg-white"
                        }`}
                        onClick={() => {
                          setAutoApply(!autoApply);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <div
                          className={`h-4 w-4 transform duration-300 ease-in-out ${
                            autoApply
                              ? "bg-primary transform translate-x-6"
                              : "bg-grayNobel "
                          } `}
                        ></div>
                      </div>
                      <p className={`font-normal pl-2 ${!autoApply && 'text-grayDeep'}`}>
                        {autoApply ? "Yes" : "No"}
                      </p>
                    </div>
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                      <div className="text-base text-black font-bold mb-2">
                        Frequency
                        {/* <span className="text-lg text-red-500 font-bold">
                          {"*"}
                        </span> */}
                      </div>
                      <select
                        className={`capitalize w-full placeholder-[#7E7E7E] placeholder:font-semibold outline-none border-b-2 border-[#000000] focus:border-primary py-2 px-3 font-medium ${errors.frequency? "border-red-500": "border-gray"}`}
                        id="frequency"
                        name="frequency"
                        {...register("frequency",{
                        })}
                        value = {gInfo?.frequency}
                        onChange={(e) => onChangeHandler(e)}
                      >
                        {activeSubscription?.config?.no_of_scheduled_audits_per_month < 5 && !activeSubscription?.is_unlimited ?
                          <>
                            {frequencyOptionsFree.map((freq, index) => {
                              return (
                                <option
                                  key={`frequency${index}`}
                                  value={freq}
                                >
                                  {freq}
                                </option>
                              );
                            })}
                          </>
                          : activeSubscription?.is_unlimited ?
                          <>
                            {frequencyOptionsUnlimited.map((freq, index) => {
                              return (
                                <option
                                  key={`frequency${index}`}
                                  value={freq}
                                >
                                  {freq}
                                </option>
                              );
                            })}
                          </>
                          :
                          <>
                            {frequencyOptionsPro.map((freq, index) => {
                              return (
                                <option
                                  key={`frequency${index}`}
                                  value={freq}
                                >
                                  {freq}
                                </option>
                              );
                            })}
                          </>
                        }
                      </select>
                      {errors.frequency && (
                        <span className="text-xs text-red-500 font-semibold">
                          {errors.frequency.message}
                        </span>
                      )}
                  </fieldset>
                  <fieldset className="w-full m-auto text-sm mb-4">
                    <div className="text-base text-black font-bold mb-2">
                      Archive
                    </div>
                    <div className="flex items-center">
                      <div
                        className={`h-6 w-12 p-1 cursor-pointer border ${
                          archive
                            ? "border-primary bg-aliceBlue"
                            : "border-grayNobel bg-white"
                        }`}
                        onClick={() => {
                          setArchive(!archive);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <div
                          className={`h-4 w-4 transform duration-300 ease-in-out ${
                            archive
                              ? "bg-primary transform translate-x-6"
                              : "bg-grayNobel "
                          } `}
                        ></div>
                      </div>
                      <p className={`font-normal pl-2 ${!archive && 'text-grayDeep'}`}>
                        {archive ? "Yes" : "No"}
                      </p>
                    </div>
                  </fieldset>
                  {errorFromApi !== null && (
                    <div
                      className={`${
                        errorFromApi?.type === "error"
                          ? "bg-floralWhite "
                          : "bg-aliceBlue"
                      } border border-grayLight p-3 mt-4 flex items-center flex-row overflow-x-auto`}
                    >
                      <div className="mr-2">
                        <BsFillExclamationSquareFill
                          color={
                            errorFromApi?.type === "error"
                              ? "#FF5353"
                              : "#000000"
                          }
                          className="text-2xl"
                        />
                      </div>
                      <p className="text-sm text-left text-black font-light">{`${errorFromApi?.msg}`}</p>
                    </div>
                  )}
                  <div className="flex my-12">
                    <button
                      type="submit"
                      className={`sm:w-4/6 border cursor-pointer text-white font-bold p-2 px-8 tracking-small ${
                        loading || !isUpdated || errorFromApi || !isCurrentWebsiteActive
                          ? "pointer-events-none border-gray bg-gray"
                          : "border-primary bg-cyanBlue"
                      }`}
                    >
                      {loading ? (
                        <div className="flex flex-row justify-center items-center">
                          <PieLoader
                              color={"white"}
                              loading={loading}
                              size={5}
                            />
                          <span className="ml-4">Loading</span>
                        </div>
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// map app's state to current component's props
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    selectedOrgId: state.dashboard.selectedOrganization,
    orgsList: state.dashboard.data?.organizations || [],
    activeSubscription: state.dashboard.activeSubscription,
  };
};

// map app's action dispatchers to current component's props
const mapDispatchToProps = {
  triggerUpdateUser: editUser,
  triggerLogout: logOut,
  triggerGetWebsiteSettings: getWebsiteSettings,
  triggerEditWebsiteSettings: editWebsiteSettings,
};

// exports
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
